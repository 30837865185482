import { withRouter } from "react-router-dom"
import { Helmet } from "react-helmet"
import { toast, ToastContainer } from "react-toastify"

// HoC
import { AuthHoc } from "containers/Auth"
import { AppHoc } from "containers/App"

// Components
import { FullScreenBoxLoader } from "components/Loader/FullScreenBoxLoader"
import { Scroller } from "components/Scroller"
import { ErrorBoundary } from "components/ErrorBoundary"

import { APP_INITIALIZE_REQUEST } from "constants"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth
}))
class App extends React.Component {

  componentDidMount() {
    const { dispatch } = this.props

    dispatch({
      type: APP_INITIALIZE_REQUEST,
    })
  }

  render() {
    const { app: { initialized }, history, location } = this.props

    if (!initialized) {
      return <FullScreenBoxLoader />
    }

    const shouldRenderAuth = location.pathname.startsWith("/auth/")

    return (
      <main className={"app-container"}>
        <Helmet>
          <title>MyOGO 2.0</title>
          <meta name="description" content="myOGO 2.0" />
        </Helmet>

        <ErrorBoundary>
          {shouldRenderAuth && <AuthHoc />}
          {!shouldRenderAuth && <AppHoc history={history} />}
        </ErrorBoundary>
        <ToastContainer />
        <Scroller />
      </main>
    )
  }
}

export { App }
