import { Input } from "components/Input"

import "./index.scss"

const sortCountries = countries => {
  return countries.sort((a, b) => b.warehouses.length - a.warehouses.length)
}

export const CountryWarehouseMapper = props => {
  const [countries, setCountries] = React.useState(sortCountries(props.countries))
  const [search, setSearch] = React.useState("")
  const [open, setOpen] = React.useState("")

  const getCountries = () => {
    if (open) {
      return countries.filter(country => country.value === open)
    }

    if (search) {
      return countries.filter(country => (
        country.label.toLowerCase().includes(search.toLowerCase()) ||
        country.value.toLowerCase().includes(search.toLowerCase())
      ))
    }

    return countries
  }

  const onToggleWarehouse = (country, warehouse) => {
    const clone = [...countries]
    const index = clone.findIndex(c => c.value === country.value)
    const includes = clone[index].warehouses.includes(warehouse.id)

    if (includes) {
      clone[index].warehouses = clone[index].warehouses.filter(w => w !== warehouse.id)
    } else {
      clone[index].warehouses = [...clone[index].warehouses, warehouse.id]
    }

    const final = sortCountries(clone)
    setCountries(final)

    props.onChange && props.onChange.apply(this, [
      final.filter(country => country.warehouses.length > 0).map(country => (
        { countryCode: country.value, warehouses: country.warehouses }
      ))
    ])
  }

  return (
    <section className="CountryWarehouseMapper">
      <Input
        placeholder="Search for a country..."
        onChange={text => setSearch(text)}
        value={search}
      />

      <div className="scrollable-area">
        {getCountries().map((country) => {
          return (
            <React.Fragment key={country.value}>
              <p
                className={classnames({
                  "is-selected": country.warehouses.length > 0
                })}
                onClick={() => setOpen(prev => prev === country.value ? "" : country.value)}
              >
                {country.label} {country.warehouses.length > 0 && `(${country.warehouses.length} warehouses)`}
              </p>

              {open === country.value && (
                <div className="warehouses-dropdown">
                  <hr />
                  {props.warehouses.map(warehouse => (
                    <p
                      key={warehouse.id}
                      className={classnames({
                        "is-selected": country.warehouses.includes(warehouse.id)
                      })}
                      onClick={() => onToggleWarehouse(country, warehouse)}
                    >
                      {warehouse.code}
                    </p>
                  ))}
                </div>
              )}
            </React.Fragment>
          )
        })}
      </div>
    </section>
  )
}