import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Selectbox, Datepicker } from "components/Ui"

import { Table, useTableHook } from "components/Table"

import { STOCK_UPDATE_RECEIVE_REQUEST } from "constants"

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    columns={[
      { title: "Code", attr: "_1" },
      { title: "Name", attr: "_2" },
      { title: "Expire date", attr: "_3" },
      { title: "Purchase price", attr: "_4" },
      { title: "Alarm level", attr: "_5" },
      { title: "Stock", attr: "_6" },
      { title: "Reserved", attr: "_7" },
      { title: "Quantity", attr: "_8" },
    ]}
    actions={[
      {
        title: translate("delete"),
        isRed: true,
        action: (rows, i) => {
          console.log("Delete action")
          console.log(rows)
          console.log(i)
        }
      },
    ]}
    noFoot
    noHead
  />
)

export const ProductsToBeReceivedArea = props => {
  return (
    <>
      <Box>
        <h2>Stock Update Details</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              reference: "",
              warehouse: { value: "warehouse1", label: "Warehouse 1" },
              date: null,
            }
          }}
          rules={{
            reference: Rules.string().required(),
            warehouse: Rules.string().required(),
            date: Rules.string().required(),
          }}
          render={[
            {
              label: "Reference",
              name: "reference",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Warehouse",
              name: "warehouse",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "warehouse1", label: "Warehouse 1" },
                    { value: "warehouse2", label: "Warehouse 2" },
                    { value: "warehouse3", label: "Warehouse 3" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Delivery date (best estimate)",
              name: "date",
              node: ({ field, fieldState }) => (
                <Datepicker
                  onChange={date => {
                    field.onChange(date)}
                  }
                  selected={field.value}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Box noPadding>
        <TableRegion
          loading={false}
          rows={[
            {
              id: "a1",
              _1: "DEMO-1",
              _2: "Demo1",
              _3: "21.08.2022",
              _4: "15.47 (USD)",
              _5: 2,
              _6: 3,
              _7: 4,
              _8: 5,
            },
            {
              id: "a2",
              _1: "DEMO-2",
              _2: "Demo2",
              _3: "21.08.2022",
              _4: "15.47 (USD)",
              _5: 2,
              _6: 3,
              _7: 4,
              _8: 5,
            },
            {
              id: "a3",
              _1: "DEMO-3",
              _2: "Demo3",
              _3: "21.08.2022",
              _4: "15.47 (USD)",
              _5: 2,
              _6: 3,
              _7: 4,
              _8: 5,
            },
          ]}
          onRefresh={() => {

          }}
        />
      </Box>
    </>
  )
}