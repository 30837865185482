import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"

export const TypographyArea = props => (
  <>
    <Boxlist>
      <Box className="typography-area">
        <h1>H1: Header 1</h1>
        <h2>H2: Header 2</h2>
        <h3>H3: Header 3</h3>
        <p className="body-l-400">P: body-l-400</p>
        <p className="body-m-400">P: body-m-400</p>
        <p className="body-m-500">P: body-m-500</p>
        <p className="body-s-500">P: body-s-500</p>
        <p className="body-s-400">P: body-s-400</p>
        <p className="caption-s-500">P: caption-s-500</p>
        <p className="caption-s-400">P: caption-s-400</p>
      </Box>
    </Boxlist>
  </>
)