import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Tooltip, Warning, Selectbox } from "components/Ui"

export const SenderInformationArea = props => {
  return (
    <>
      <Box className="sender-information-area">
        <h2>Sender information (shown on shipping labels)</h2>
        <hr />
        <Warning text="Notice: This information is only valid for warehouses TLL, VNT & GOT. Other warehouses will use the warehouse address." />
        <Form
          data={{
            defaultValues: {
              senderName: "",
              companyStreetAddress: "",
              companyPostCode: "",
              companyCity: "",
              country: { value: "fi", label: "Finland" },
              email: "",
              customerLanguage: { value: "en", label: "English" },
            }
          }}
          rules={{
            senderName: Rules.string().required(),
            companyPostCode: Rules.string().required(),
            companyCity: Rules.string().required(),
            country: Rules.string().required(),
            email: Rules.string().required(),
            customerLanguage: Rules.string().required(),
          }}
          render={[
            {
              label: "Sender name",
              name: "senderName",
              tip: "This company / business name that will be visible on the shipping labels. Some shipping companies require that we add your official company name and/or OGOship's company name. We will do this automatically if needed.",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Company Street Address",
              name: "companyStreetAddress",
              tip: "This will be visible on the shipping labels. The shipment might return to this address if they are not picked up so please provide an address where you/we are able to receive returning parcels occasionally. Some shipping companies require that we add OGOship's address to the label. We will do this automatically if needed.",
              node: ({ field, fieldState }) => (
                <Input
                  textarea
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Company Post Code",
              name: "companyPostCode",
              tip: "This will be visible on the shipping labels. Some shipping companies require that we add OGOship's address to the label. We will do this automatically if needed.",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Company City",
              name: "companyCity",
              tip: "This will be visible on the shipping labels. Some shipping companies require that we add OGOship's address to the label. We will do this automatically if needed.",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Country",
              name: "country",
              tip: "This will be visible on the shipping labels. Some shipping companies require that we add OGOship's address to the label. We will do this automatically if needed. (use ISO-2 code e.g. DE)",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "fi", label: "Finland" },
                    { value: "tr", label: "Turkey" },
                    { value: "us", label: "USA" }
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Email",
              name: "email",
              tip: "Some shipping companies might be in contact with you occasionally.",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Customer language",
              name: "customerLanguage",
              tip: "Language we use to communicate with customer. Requested printed papers and emails.",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "en", label: "English" },
                    { value: "fi", label: "Finnish" },
                    { value: "de", label: "German" }
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Tooltip />
    </>
  )
}