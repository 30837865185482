import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Modal  } from "components/Layout/Modal"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Selectbox, Checkbox, Datepicker } from "components/Ui"
import { ShippingMethod } from "components/ShippingMethod"

export const ModalScreenSelectMerchant = props => {
  const { onClose, onSwitch, name } = props
  const [ option, setOption ] = React.useState({ label: name, value: name })

  return (
    <Modal
      className="modal-ModalScreenSelectMerchant"
      title={translate("select-merchant")}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.PRIMARY}
            title={translate("switch")}
            onClick={() => onSwitch.apply(this, [option])}
          />
        </>
      }
      onClose={onClose}
    >
      <p>{translate("active-merchant-switch-notice")}</p>
      <Selectbox
        value={option}
        options={[
          { label: "ReBuy Test Merchant", value: "2ff43e19-ed4a-45e8-8be7-cb58ee372dd6" },
        ]}
        onChange={option => setOption(option)}
      />
    </Modal>
  )
}

export const ModalScreenActivateShippingMethod = props => {
  const { value, onClose } = props

  const [ methods, setMethods ] = React.useState(value)

  const toggle = (index, activated) => {
    const clone = [...methods]
    clone[index].activated = activated
    setMethods(clone)
  }

  return (
    <Modal
      title={"Add shipping method"}
      onClose={() => onClose && onClose.apply(this, [methods])}
    >
      {methods.map((method, i) => (
        <React.Fragment key={i}>
          <ShippingMethod
            name={method.name}
            description={method.description}
            logo={method.logo}
            isActivated={method.activated}
            onClick={activated => toggle(i, activated)}
          />
          <hr />
        </React.Fragment>
      ))}
    </Modal>
  )
}

export const ModalScreenAddOrUpdateSupplier = props => {
  const { title, onClose, value, onSave } = props
  const [ state, setState ] = React.useState({
    name: value?.name || "",
    dirty: false
  })

  return (
    <Modal
      title={title}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={value ? "Save" : "Add"}
            isDisabled={!state.name}
            onClick={() => onSave && onSave.apply(this, [state.name])}
          />
        </>
      }
    >
      <Input
        label="Supplier name"
        error={(state.dirty && !state.name) ? "Supplier name is required" : ""}
        onChange={name => setState({ name, dirty: true })}
        value={state.name}
      />
    </Modal>
  )
}

export const ModalScreenRemoveSupplier = props => {
  const { value, onClose, onRemove } = props

  return (
    <Modal
      title={"Remove Supplier"}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={translate("remove")}
            onClick={onRemove}
          />
        </>
      }
    >
      <p>Are you sure you want to remove the supplier: <b>{value.name}</b>?</p>
    </Modal>
  )
}

export const ModalScreenAddOrUpdateProductGroup = props => {
  const { title, onClose, value, onSave } = props
  const [ state, setState ] = React.useState({
    name: value?.name || "",
    dirty: false
  })

  return (
    <Modal
      title={title}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={value ? "Save" : "Add"}
            isDisabled={!state.name}
            onClick={() => onSave && onSave.apply(this, [state.name])}
          />
        </>
      }
    >
      <Input
        label="Product group name"
        error={(state.dirty && !state.name) ? "Product group name is required" : ""}
        onChange={name => setState({ name, dirty: true })}
        value={state.name}
      />
    </Modal>
  )
}

export const ModalScreenRemoveProductGroup = props => {
  const { value, onClose, onRemove } = props

  return (
    <Modal
      title={"Remove product group"}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={translate("remove")}
            onClick={onRemove}
          />
        </>
      }
    >
      <p>Are you sure you want to remove the product group: <b>{value.name}</b>?</p>
    </Modal>
  )
}

export const ModalScreenAddOrUpdateStock = props => {
  const { title, onClose, value, onSave } = props

  return (
    <Modal
      title={title}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            form="form-add-stock"
            type={BUTTON_TYPES.GRAY}
            title={translate("save")}
          />
        </>
      }
    >
      <Form
        id="form-add-stock"
        data={{
          defaultValues: {
            name: value?.name || "",
            location: value?.location || "",
            warehouse: { value: "Public testing warehouse", label: "Public testing warehouse" },
            type: { value: "P120", label: "Pientavara 120cm" },
            prioritize: false,
            quarantine: false,
          }
        }}
        rules={{
          name: Rules.string().required(),
          location: Rules.string().required(),
        }}
        render={[
          {
            label: "Name",
            name: "name",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Location",
            name: "location",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Warehouse",
            name: "warehouse",
            node: ({ field, fieldState }) => (
              <Selectbox
                options={[
                  { value: "OGOship EE-TLL", label: "OGOship EE-TLL" },
                  { value: "OGOship GB-PFS", label: "OGOship GB-PFS" },
                  { value: "OGOship SE-GOT", label: "OGOship SE-GOT" },
                  { value: "OGOship NL-VNL", label: "OGOship NL-VNL" },
                  { value: "OGOship FI-JKT", label: "OGOship FI-JKT" },
                  { value: "OGOship SE-SKP", label: "OGOship SE-SKP" },
                  { value: "OGOship FI-VNT", label: "OGOship FI-VNT" },
                  { value: "Public testing warehouse", label: "Public testing warehouse" },
                ]}
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Stock Type",
            name: "type",
            node: ({ field, fieldState }) => (
              <Selectbox
                options={[
                  { value: "COLD", label: "Cold stock" },
                  { value: "DEMO", label: "Demo" },
                  { value: "EUR", label: "Eurolava" },
                  { value: "EUR_H", label: "Eurolava Korkea" },
                  { value: "EUR_H133", label: "Eurolava 133cm" },
                  { value: "EUR_H140", label: "Eurolava 140cm" },
                  { value: "EUR_H140-220", label: "Eurolava 140-220cm" },
                  { value: "EUR_H200", label: "Eurolava 200cm" },
                  { value: "EUR_HEUR200", label: "Eurolava 200cm (VNT)" },
                  { value: "EUR_R", label: "Eurolava Remote" },
                  { value: "HALFEUR", label: "Puolilava" },
                  { value: "K_BIN_STORAGE", label: "Warehouse k bin storage" },
                  { value: "L", label: "L" },
                  { value: "OTHER", label: "Muu" },
                  { value: "P120", label: "Pientavara 120cm" },
                  { value: "P50", label: "Pientavara" },
                  { value: "P50_2", label: "Pientavara s koko" },
                  { value: "P50M", label: "Pientavara m koko" },
                  { value: "PL50", label: "Pientavara syvä" },
                  { value: "SMALL", label: "Small item shelves" },
                  { value: "SPADDON", label: "Addon stock" },
                  { value: "TB", label: "Tote bin" },
                  { value: "VIRTUAL", label: "Virtual stock" },
                  { value: "XL", label: "XL" },
                ]}
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            name: "prioritize",
            node: ({ field, fieldState }) => (
              <Checkbox
                title="Prioritize"
                onClick={() => {
                  field.onChange(!field.value)
                }}
                checked={field.value}
              />
            )
          },
          {
            name: "quarantine",
            node: ({ field, fieldState }) => (
              <Checkbox
                title="Quarantine"
                onClick={() => {
                  field.onChange(!field.value)
                }}
                checked={field.value}
              />
            )
          },
        ]}
        onSubmit={onSave}
      />
    </Modal>
  )
}

export const ModalScreenRemoveStock = props => {
  const { value, onClose, onRemove } = props

  return (
    <Modal
      title={"Remove Stock"}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={translate("remove")}
            onClick={onRemove}
          />
        </>
      }
    >
      <p>Are you sure you want to remove the stock: <b>{value.name}</b>?</p>
    </Modal>
  )
}

export const ModalScreenRemoveProductBundle = props => {
  const { value, onClose, onRemove } = props

  return (
    <Modal
      title={"Remove bundle"}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={translate("remove")}
            onClick={onRemove}
          />
        </>
      }
    >
      <p>Are you sure you want to remove the bundle: <b>{value.name}</b>?</p>
    </Modal>
  )
}

export const ModalScreenAddOrUpdateAdmin = props => {
  const { title, onClose, value, onSave } = props

  return (
    <Modal
      title={title}
      onClose={onClose}
      isSidebar={true}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            form="form-manage-admin"
            type={BUTTON_TYPES.GRAY}
            title={translate("save")}
          />
        </>
      }
    >
      {!value?.name && <p className="soft">Invite new admin user.</p>}
      <Form
        id="form-manage-admin"
        data={{
          defaultValues: {
            name: value?.name || "",
            email: value?.email || "",
            phone: value?.phone || "",
            roles: value?.roles ? value.roles.map(role => ({ value: role, label: role })) : [],
            warehouses: value?.warehouses ? value.warehouses.map(w => ({ value: w, label: w })) : []
          }
        }}
        rules={{
          name: Rules.string().required(),
          email: Rules.string().required(),
          phone: Rules.string().required(),
          roles: Rules.array().min(1),
          warehouses: Rules.array().min(1),
        }}
        render={[
          {
            label: "Name",
            name: "name",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Email",
            name: "email",
            node: ({ field, fieldState }) => (
              <Input
                type="email"
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Phone",
            name: "phone",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Roles",
            name: "roles",
            node: ({ field, fieldState }) => (
              <Selectbox
                options={[
                  { value: "Admin", label: "Admin" },
                  { value: "Tech", label: "Tech" },
                  { value: "Staff", label: "Staff" },
                  { value: "PM", label: "PM" },
                ]}
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
                isMulti={true}
              />
            )
          },
          {
            label: "Warehouse visibility",
            name: "warehouses",
            node: ({ field, fieldState }) => (
              <Selectbox
                options={[
                  { value: "JKT", label: "JKT" },
                  { value: "EEV", label: "EEV" },
                  { value: "SLK", label: "SLK" },
                  { value: "BBT", label: "BBT" },
                ]}
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
                isMulti={true}
              />
            )
          },
        ]}
        onSubmit={onSave}
      />
    </Modal>
  )
}

export const ModalScreenRemoveAdmin = props => {
  const { value, onClose, onRemove } = props

  return (
    <Modal
      className={"modal-ModalScreenRemoveAdmin"}
      title={"Remove Admin"}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={translate("remove")}
            onClick={onRemove}
          />
        </>
      }
    >
      <Icons.Big.Cancel />
      <p>Are you sure you want to remove the following admin?</p>
      <p><b>{value.email}</b></p>
    </Modal>
  )
}

export const ModalScreenAddOrUpdateCustomer = props => {
  const { title, onClose, value, onSave } = props

  return (
    <Modal
      title={title}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            form="form-manage-customer"
            type={BUTTON_TYPES.GRAY}
            title={translate("save")}
          />
        </>
      }
    >
      <Form
        id="form-manage-customer"
        data={{
          defaultValues: {
            name: value?.name || "",
            email: value?.email || "",
            registry_no: value?.registry_no || "",
          }
        }}
        rules={{
          name: Rules.string().required(),
          email: Rules.string().required(),
        }}
        render={[
          {
            label: "Name",
            name: "name",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Email",
            name: "email",
            node: ({ field, fieldState }) => (
              <Input
                type="email"
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Registry no",
            name: "registry_no",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
        ]}
        onSubmit={onSave}
      />
    </Modal>
  )
}

export const ModalScreenRemoveCustomer = props => {
  const { value, onClose, onRemove } = props

  return (
    <Modal
      className={"modal-ModalScreenRemoveCustomer"}
      title={"Remove Customer"}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={translate("remove")}
            onClick={onRemove}
          />
        </>
      }
    >
      <Icons.Big.Cancel />
      <p>Are you sure you want to remove the following customer?</p>
      <p><b>{value.email}</b></p>
    </Modal>
  )
}

export const ModalScreenAddOrUpdateConnectedUser = props => {
  const { title, onClose, value, onSave } = props

  return (
    <Modal
      title={title}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            form="form-manage-connected-user"
            type={BUTTON_TYPES.GRAY}
            title={translate("save")}
          />
        </>
      }
    >
      <Form
        id="form-manage-connected-user"
        data={{
          defaultValues: {
            name: value?.name || "",
            email: value?.email || "",
            title: value?.title || "",
          }
        }}
        rules={{
          name: Rules.string().required(),
          email: Rules.string().required(),
          title: Rules.string().required(),
        }}
        render={[
          {
            label: "Name",
            name: "name",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Email",
            name: "email",
            node: ({ field, fieldState }) => (
              <Input
                type="email"
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Title",
            name: "title",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
        ]}
        onSubmit={onSave}
      />
    </Modal>
  )
}

export const ModalScreenRemoveConnectedUser = props => {
  const { value, onClose, onRemove } = props

  return (
    <Modal
      className={"modal-ModalScreenRemoveConnectedUser"}
      title={"Remove Connected User"}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={translate("remove")}
            onClick={onRemove}
          />
        </>
      }
    >
      <Icons.Big.Cancel />
      <p>Are you sure you want to remove the following connected user from the selected merchant?</p>
      <p><b>{value.email}</b></p>
    </Modal>
  )
}

export const ModalScreenAddOrUpdateMerchantDiscount = props => {
  const { title, onClose, value, onSave } = props

  return (
    <Modal
      title={title}
      onClose={onClose}
      isSidebar={true}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            form="form-manage-merchant-discount"
            type={BUTTON_TYPES.GRAY}
            title={translate("save")}
          />
        </>
      }
    >
      <Form
        id="form-manage-merchant-discount"
        data={{
          defaultValues: {
            name: value?.name || "",
            groups: value?.groups ? value.groups.map(group => ({ value: group, label: group })) : [],
            start_date: value?.start_date ? new Date(value?.start_date) : new Date(),
            end_date: value?.start_date ? new Date(value?.end_date) : new Date(),
          }
        }}
        rules={{
          name: Rules.string().required(),
          groups: Rules.array().min(1),
          start_date: Rules.string().required(),
          end_date: Rules.string().required(),
        }}
        render={[
          {
            label: "Name",
            name: "name",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Start Date",
            name: "start_date",
            node: ({ field, fieldState }) => (
              <Datepicker
                onChange={date => {
                  field.onChange(date)}
                }
                selected={field.value}
              />
            )
          },
          {
            label: "End Date",
            name: "end_date",
            node: ({ field, fieldState }) => (
              <Datepicker
                onChange={date => {
                  field.onChange(date)}
                }
                selected={field.value}
              />
            )
          },
          {
            label: "Groups",
            name: "groups",
            node: ({ field, fieldState }) => (
              <Selectbox
                options={[
                  { value: "A1", label: "A1" },
                  { value: "A2", label: "A2" },
                  { value: "B1", label: "B1" },
                  { value: "B2", label: "B2" },
                ]}
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
                isMulti={true}
              />
            )
          },
        ]}
        onSubmit={onSave}
      />
    </Modal>
  )
}

export const ModalScreenRemoveMerchantDiscount = props => {
  const { value, onClose, onRemove } = props

  return (
    <Modal
      className={"modal-ModalScreenRemoveMerchantDiscount"}
      title={"Remove Discount"}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={translate("remove")}
            onClick={onRemove}
          />
        </>
      }
    >
      <Icons.Big.Cancel />
      <p>Are you sure you want to remove the following discount from the selected merchant?</p>
      <p><b>{value.name}</b></p>
    </Modal>
  )
}

export const ModalScreenUpdateLanguage = props => {
  const { title, onClose, value, onSave } = props

  const rules = {}

  Object.keys(value).forEach(key => (rules[key] = Rules.string().min(1).required()))

  return (
    <Modal
      title={title}
      onClose={onClose}
      isSidebar={true}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            form="form-update-language"
            type={BUTTON_TYPES.GRAY}
            title={translate("save")}
          />
        </>
      }
    >
      <Form
        id="form-update-language"
        data={{
          defaultValues: value
        }}
        rules={rules}
        render={Object.keys(value).map(key => (
          {
            label: translate(`language.${key}`),
            name: key,
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          }
        ))}
        onSubmit={onSave}
      />
    </Modal>
  )
}

export const ModalScreenRemoveReturnReason = props => {
  const { value, onClose, onRemove } = props

  return (
    <Modal
      title={"Remove Return Reason"}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={translate("remove")}
            onClick={onRemove}
          />
        </>
      }
    >
      <p>Are you sure you want to remove the return reason: <b>{value.code}</b>?</p>
    </Modal>
  )
}

export const ModalScreenRemoveReturnDeliveryType = props => {
  const { value, onClose, onRemove } = props

  return (
    <Modal
      title={"Remove Return Delivery Type"}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={translate("remove")}
            onClick={onRemove}
          />
        </>
      }
    >
      <p>Are you sure you want to remove the return reason: <b>{value.provider}</b>?</p>
    </Modal>
  )
}

export const ModalScreenShowWarehousesOfCountry = props => {
  const { value: { country, warehouses }, countriesDict, onClose } = props

  return (
    <Modal
      title={`Warehouses of ${countriesDict[country.countryCode].label}`}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("close")}
            onClick={onClose}
          />
        </>
      }
    >
      {country.warehouses.map((id, i) => {
        return warehouses.find(warehouse => warehouse.id === id)?.code || "Unknown"
      }).join(", ")}
    </Modal>
  )
}

export const ModalScreenRemoveTransactionalMessage = props => {
  const { value, onClose, onRemove } = props

  return (
    <Modal
      title={"Remove Transactional Message"}
      onClose={onClose}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={translate("remove")}
            onClick={onRemove}
          />
        </>
      }
    >
      <p>Are you sure you want to remove the transactional message?</p>
    </Modal>
  )
}

export const ModalScreenUpdateCostCategory = props => {
  const { title, onClose, value, onSave } = props

  return (
    <Modal
      title={title}
      onClose={onClose}
      isSidebar
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            form="form-manage-cost-category"
            type={BUTTON_TYPES.GRAY}
            title={translate("save")}
          />
        </>
      }
    >
      <Form
        id="form-manage-cost-category"
        data={{
          defaultValues: {
            name: value?.name || "",
            code: value?.code || "",
          }
        }}
        rules={{
          name: Rules.string().required(),
          code: Rules.string().required(),
        }}
        render={[
          {
            label: "Name",
            name: "name",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Code",
            name: "code",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
        ]}
        onSubmit={onSave}
      />
    </Modal>
  )
}
