import { Route, Redirect, withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { LanguageSelector } from "components/LanguageSelector"

import { SignIn } from "containers/Auth/SignIn"
import { ForgotPassword } from "containers/Auth/ForgotPassword"
import { TwoFactor } from "containers/Auth/TwoFactor"
import { UpdatePhone } from "containers/Auth/UpdatePhone"
import { Callback } from "containers/Auth/Callback"

import { LANGUAGE_CHANGE } from "constants"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
}))
class AuthHoc extends React.Component {

  state = {
    open: false
  }

  render() {
    const { app: { language }, dispatch, location } = this.props
    const { open } = this.state

    if (location.pathname.includes("auth/return")) {
      return (
        <Route exact path="/auth/return">
          <Callback />
        </Route>
      )
    }

    return (
      <main className="auth-screen">
        <Icons.BoxIcon2 />
        <Icons.BoxIcon2 />
        <img src={"https://www.ogoship.com/hubfs/company%20logo/ogoship-logo.svg"} alt="Ogoship logo" />
        <LanguageSelector
          open={open}
          onOpen={() => { this.setState({ open: true }) }}
          onClose={() => { this.setState({ open: false }) }}
          language={language}
          onSelect={language => {
            dispatch({
              type: LANGUAGE_CHANGE,
              payload: { language }
            })
          }}
        />
        <Route exact path="/">
          <Redirect to="/auth/sign-in" />
        </Route>
        <Route exact path="/auth/sign-in">
          <SignIn />
        </Route>
        <Route exact path="/auth/forgot-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/auth/two-factor">
          <TwoFactor />
        </Route>
        <Route exact path="/auth/update-phone">
          <UpdatePhone />
        </Route>
        <Route path="*">
          <Redirect to="/auth/sign-in" />
        </Route>
      </main>
    )
  }
}

export { AuthHoc }
