import { Icons } from "icons"

import { Box, useToggleHook } from "components/Box"
import { Liquid } from "components/Liquid"
import { Input } from "components/Input"

export const EditorSection = props => {
  const [template, setTemplate] = React.useState(`<p>Thanks for buying from us: {{ merchantName }}</p>`)
  const [data, setData] = React.useState({
    merchantName: ""
  })

  return (
    <>
      <Box {...useToggleHook({ toggled: true} )}>
        <h2>Parameters</h2>
        <p
          onClick={() => {
            setData(prev => ({
              ...prev,
              merchantName: "OGOship"
            }))
          }}
        >
          {`{{ merchantName }}`}
        </p>
      </Box>
      <Box {...useToggleHook({ toggled: true} )}>
        <h2>Editor</h2>
        <Input
          textarea
          value={template}
          onChange={text => setTemplate(text)}
        />
      </Box>
      <Box {...useToggleHook({ toggled: true} )}>
        <h2>Preview</h2>
        <Liquid
          template={template}
          data={data}
        />
      </Box>
    </>
  )
}