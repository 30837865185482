import ScrollToTop from "react-scroll-up"
import { Icons } from "icons"

import "./index.scss"

export class Scroller extends React.Component {

  static propTypes = {
    showUnder: PropTypes.number,
  }

  static defaultProps = {
    showUnder: 20,
  }

  render () {
    const { showUnder } = this.props

    return (
      <ScrollToTop
        showUnder={showUnder}
        style={{
          bottom: 20,
          right: 20,
        }}
      >
        <div className="com-Scroller">
          <Icons.ArrowUp />
        </div>
      </ScrollToTop>
    )
  }
}