class Backend {
  constructor(json) {
    this.json = []
    this.original = json
  }

  update(qs) {
    const length = this.json.length
    this.json = this.original

    // Load more
    if (qs.limit.skip > 0) {
      this.json = [
        ...this.json,
        ...this.original.map(x => ({
            ...x,
            id: (Math.random() + 1).toString(36).substring(2),
            orderId: (Math.random() + 1).toString(36).substring(2),
        }))
      ]
    }

    // Global Search
    if (qs.globalSearch) {
      this.json = this.json.filter(line => {
        return ["name", "age", "work", "gender", "activity"].filter(attr => {
          return String(line[attr]).toLowerCase().includes(qs.globalSearch.toLowerCase())
        }).length > 0
      })
    }

    // Inline Search
    if (Object.keys(qs.inlineSearch).length > 0) {
      Object.keys(qs.inlineSearch).forEach(attr => {
        this.json = this.json.filter(line => {
          return String(line[attr]).toLowerCase().includes(qs.inlineSearch[attr].toLowerCase())
        })
      })
    }

    // Sort
    if (qs.sort.attr) {
      this.json.sort((a, b) => a[qs.sort.attr] - b[qs.sort.attr])
    }

    return this
  }

  getJson() {
    return this.json
  }

  getOriginal() {
    return this.original
  }
}

export const fakePlaygroundBackend = new Backend([
  {
    id: `abfd18af01`,
    orderId: `abfd18af01`,
    name: `Jonathan Cruds`,
    age: 25,
    gender: "M",
    activity: "Take a virtual tour of a stadium",
    work: "Information Security Analyst",
    department: "Management",
    date: "2011-10-05T14:48:00.000Z",
  },
  {
    id: `z81hja8f84`,
    orderId: `z81hja8f84`,
    name: `Keri Franklin`,
    age: 25,
    gender: "M",
    activity: "Donate money to helping the homeless",
    work: "Nurse Practitioner",
    department: "Management",
    date: "2011-10-05T14:48:00.000Z",
  },
  {
    id: `h8ah81hja9`,
    orderId: `h8ah81hja9`,
    name: `Arissa Bartlett`,
    age: 25,
    gender: "F",
    activity: "Make a rainbow heart out of paper hearts",
    work: "Physician Assistant",
    department: "Engineering",
    date: "2011-10-05T14:48:00.000Z",
  },
  {
    id: `ja91ha8fad`,
    orderId: `ja91ha8fad`,
    name: `Makayla Craig`,
    age: 18,
    gender: "F",
    activity: "Make a monster from some blankets",
    work: "Medical and Health Services Manager",
    department: "Engineering",
    date: "2011-10-05T14:48:00.000Z",
  },
  {
    id: `z18anjffaf`,
    orderId: `z18anjffaf`,
    name: `Kayson Parker`,
    age: 21,
    gender: "M",
    activity: "Volunteer online",
    work: "Software Developer",
    department: "QA",
    date: "2011-10-05T14:48:00.000Z",
  },
  {
    id: `ha81ha8f1n`,
    orderId: `ha81ha8f1n`,
    name: `Elyse Diaz`,
    age: 88,
    gender: "F",
    activity: "Write a poem about a magical land.",
    work: "Data Scientist",
    department: "QA",
    date: "2011-10-05T14:48:00.000Z",
  },
  {
    id: `lpa91nzia1`,
    orderId: `lpa91nzia1`,
    name: `Kaelan Rasmussen`,
    age: 5,
    gender: "M",
    activity: "Invent a new type of ventilator",
    work: "Financial Manager",
    department: "Human Resources",
    date: "2011-10-05T14:48:00.000Z",
  },
  {
    id: `7ha9f901na`,
    orderId: `7ha9f901na`,
    name: `Gage Weber`,
    age: 54,
    gender: "M",
    activity: "Make your own gloves from an old sheet",
    work: "Statistician",
    department: "Human Resources",
    date: "2011-10-05T14:48:00.000Z",
  },
  {
    id: `9j198ah81f`,
    orderId: `9j198ah81f`,
    name: `Bethan Ashley`,
    age: 32,
    gender: "M",
    activity: "Host a quiz via video chat",
    work: "Doctor",
    department: "Accounting",
    date: "2011-10-05T14:48:00.000Z",
  },
  {
    id: `jmclak1a9f`,
    orderId: `jmclak1a9f`,
    name: `Shelly Bush`,
    age: 19,
    gender: "F",
    activity: "Make a list of things you'd like to do during the rest of your life",
    work: "Baby Caretaker",
    department: "Accounting",
    date: "2011-10-05T14:48:00.000Z",
  },
])

export const fakeOrdersBackend = new Backend([
  {
    id: "1",
    _1: "Mukama, OY",
    _2: "Ship Via Pallet",
    _3: "16.04.2022 22:48",
    _4: "16.04.2022 22:48",
    _5: "#1827213",
    _6: "NEW",
    _7: "Helsinki",
    _8: "FI",
    _9: "Anil",
    _10: "OGOship",
    _11: "Delivery Type",
    _12: "",
    _13: "",
    _14: "",
    _15: "9261",
    _16: "Posti",
    _17: "Public testing warehouse",
  },
  {
    id: "2",
    _1: "Mukama, OY",
    _2: "Ship Via Pallet",
    _3: "16.04.2022 22:48",
    _4: "16.04.2022 22:48",
    _5: "#1827213",
    _6: "NEW",
    _7: "Helsinki",
    _8: "FI",
    _9: "Anil",
    _10: "OGOship",
    _11: "Delivery Type",
    _12: "",
    _13: "",
    _14: "",
    _15: "9261",
    _16: "Posti",
    _17: "Public testing warehouse",
  },
  {
    id: "3",
    _1: "Mukama, OY",
    _2: "Ship Via Pallet",
    _3: "16.04.2022 22:48",
    _4: "16.04.2022 22:48",
    _5: "#1827213",
    _6: "NEW",
    _7: "Helsinki",
    _8: "FI",
    _9: "Anil",
    _10: "OGOship",
    _11: "Delivery Type",
    _12: "",
    _13: "",
    _14: "",
    _15: "9261",
    _16: "Posti",
    _17: "Public testing warehouse",
  },
  {
    id: "4",
    _1: "Mukama, OY",
    _2: "Ship Via Pallet",
    _3: "16.04.2022 22:48",
    _4: "16.04.2022 22:48",
    _5: "#1827213",
    _6: "SHIPPED",
    _7: "Helsinki",
    _8: "FI",
    _9: "Anil",
    _10: "OGOship",
    _11: "Delivery Type",
    _12: "",
    _13: "",
    _14: "",
    _15: "9261",
    _16: "Posti",
    _17: "Public testing warehouse",
  },
  {
    id: "5",
    _1: "Mukama, OY",
    _2: "Ship Via Pallet",
    _3: "16.04.2022 22:48",
    _4: "16.04.2022 22:48",
    _5: "#1827213",
    _6: "SHIPPED",
    _7: "Helsinki",
    _8: "FI",
    _9: "Anil",
    _10: "OGOship",
    _11: "Delivery Type",
    _12: "",
    _13: "",
    _14: "",
    _15: "9261",
    _16: "Posti",
    _17: "Public testing warehouse",
  },
  {
    id: "6",
    _1: "Mukama, OY",
    _2: "Ship Via Pallet",
    _3: "16.04.2022 22:48",
    _4: "16.04.2022 22:48",
    _5: "#1827213",
    _6: "SHIPPED",
    _7: "Tokyo",
    _8: "FI",
    _9: "Anil",
    _10: "OGOship",
    _11: "Delivery Type",
    _12: "",
    _13: "",
    _14: "",
    _15: "9261",
    _16: "Posti",
    _17: "Public testing warehouse",
  },
  {
    id: "7",
    _1: "Mukama, OY",
    _2: "Ship Via Pallet",
    _3: "16.04.2022 22:48",
    _4: "16.04.2022 22:48",
    _5: "#1827213",
    _6: "SHIPPED",
    _7: "Tokyo",
    _8: "FI",
    _9: "Anil",
    _10: "OGOship",
    _11: "Delivery Type",
    _12: "",
    _13: "",
    _14: "",
    _15: "9261",
    _16: "Posti",
    _17: "Public testing warehouse",
  },
  {
    id: "8",
    _1: "Mukama, OY",
    _2: "Ship Via Pallet",
    _3: "16.04.2022 22:48",
    _4: "16.04.2022 22:48",
    _5: "#1827213",
    _6: "SHIPPED",
    _7: "Tokyo",
    _8: "FI",
    _9: "Anil",
    _10: "OGOship",
    _11: "Delivery Type",
    _12: "",
    _13: "",
    _14: "",
    _15: "9261",
    _16: "Posti",
    _17: "Public testing warehouse",
  },
])

export const fakeOrderReturnsBackend = new Backend([
  {
    id: "_1",
    returnNumber: "123132",
    created: "Today",
    state: "In Stock",
    merchant: "Mukama Oy",
    order: "12931893-1212",
    sender: "Thomas John",
    deliveryType: "Other",
    warehouse: "VNL",
  }
])

export const fakeProductsBackend = new Backend([
  {
    id: "_1",
    _2: "_2",
    _3: "_3",
    _4: "_4",
    _5: "_5",
    _6: "_6",
    _7: "_7",
    _8: "_8",
    _9: "_9",
    _10: "_10",
    _11: "_11",
    _12: "_12",
    _13: "_13",
    _14: "_14",
    _15: "_15",
  }
])

export const fakeStockUpdatesBackend = new Backend([
  {
    id: "_1",
    _2: "_2",
    _3: "_3",
    _4: "_4",
    _5: "_5",
    _6: "_6",
    _7: "_7",
    _8: "_8",
    _9: "_9",
    _10: "_10",
    _11: "_11",
  }
])

export const fakeSuppliersBackend = new Backend([
  {
    id: "1",
    name: "Test Supplier 1",
  },
  {
    id: "2",
    name: "Test Supplier 2",
  },
])

export const fakeStocksBackend = new Backend([
  {
    id: "1",
    name: "Stock 1",
    warehouse: "OGOship EE-TLL",
    location: "TLL",
    merchant: "Punora Group Oy",
    productsRows: 1573,
    productsAmount: 17293,
    stockType: "Cold stock"
  },
  {
    id: "2",
    name: "Stock 2",
    warehouse: "OGOship SE-GOT",
    location: "TLL",
    merchant: "Punora Group Oy",
    productsRows: 1573,
    productsAmount: 17293,
    stockType: "Eurolava Korkea"
  },
])

export const fakeProductGroupsBackend = new Backend([
  {
    id: "1",
    name: "Group 1",
  },
  {
    id: "2",
    name: "Group 2",
  },
])

export const fakeProductBundlesBackend = new Backend([
  {
    id: "1",
    name: "Bundle 1",
    code: "BNDL-1",
    description: "Discounted items",
    productsAmount: 4,
  },
  {
    id: "2",
    name: "Bundle 2",
    code: "BNDL-2",
    description: "Sports equipment set",
    productsAmount: 16,
  },
])

export const fakeAdminsBackend = new Backend([
  {
    id: "0001",
    name: "Anil",
    email: "anil@ogoship.com",
    phone: "+38091237124",
    roles: ["Admin", "Tech", "Staff"],
    warehouses: ["JKT", "EEV"],
  },
  {
    id: "0002",
    name: "Pekka",
    email: "pekka@ogoship.com",
    phone: "+42131312412",
    roles: ["Admin", "Tech"],
    warehouses: ["JKT", "EEV", "SLK", "BBT"],
  },
])


export const fakeCustomersBackend = new Backend([
  {
    id: "1",
    customer_id: "10001",
    name: "Terveinjaloin.fi c/o JET-Logistics",
    email: "tio@iom.com",
    registry_no: "001",
  },
  {
    id: "2",
    customer_id: "10002",
    name: "Healthylife.fi - Oleupea.fi",
    email: "rauno@oleupea.fi",
    registry_no: "002",
  },
])

export const fakeMerchantConnectedUsersBackend = new Backend([
  {
    id: "1",
    name: "Pekka Ylenius",
    email: "pekka@ogoship.com",
    title: "CTO",
    status: 1,
  },
  {
    id: "2",
    name: "Anil Unal",
    email: "anil@ogoship.com",
    title: "Software Engineer",
    status: 0,
  },
])

export const fakeMerchantDiscountsBackend = new Backend([
  {
    id: "1",
    name: "40% OFF",
    start_date: "01.01.2021",
    end_date: "01.01.2022",
    groups: ["A1", "A2", "B1"],
  },
  {
    id: "2",
    name: "HOLIDAY SALE 20% OFF",
    start_date: "11.11.2021",
    end_date: "11.12.2022",
    groups: ["A1"],
  },
])

export const fakeFeedbacksBackend = new Backend([
  {
    id: "1",
    rate: 5,
    date: "01.01.2021",
    order: "#12312341",
    merchant: "Mukama OY",
    comment: "Very happy! 😀😀😀😀😀",
    type: "order_processing",
  },
  {
    id: "2",
    rate: 2,
    date: "01.01.2021",
    order: "#12312342",
    merchant: "Mukama OY",
    comment: "I will never buy from this merchant ever again.",
    type: "order_processing",
  },
  {
    id: "3",
    rate: 1,
    date: "01.01.2021",
    order: "#12312343",
    merchant: "Vacier",
    comment: "Items were wrong!!! 🤬",
    type: "order_tracking",
  },
  {
    id: "4",
    rate: 3,
    date: "01.01.2021",
    order: "#12312344",
    merchant: "Vacier",
    comment: "It was good. Nothing special.",
    type: "order_tracking",
  },
  {
    id: "5",
    rate: 5,
    date: "01.01.2021",
    order: "#12312344",
    merchant: "Vacier",
    comment: "Best order!",
    type: "order_tracking",
  },
])

export const fakeTransactionalMessagesBackend = new Backend([
  {
    id: "0000-0000-0000",
    recipientType: 0,
    approvalType: 0,
    recipientStatus: 0,
  },
  {
    id: "1111-1111-1111",
    recipientType: 1,
    approvalType: 1,
    recipientStatus: 1,
  },
  {
    id: "2222-2222-2222",
    recipientType: 3,
    approvalType: 1,
    recipientStatus: 3,
  },
])
