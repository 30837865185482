import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { ScaleLoader } from "components/Loader/ScaleLoader"

import {
  Checkbox,
  List,
  Tag,
  TAG_TYPES,
  Selectbox,
  Datepicker,
  Tooltip
} from "components/Ui"

export const UiArea = props => {
  const [date1, setDate1] = React.useState(new Date())
  const [date2, setDate2] = React.useState(new Date())
  const [date3, setDate3] = React.useState(new Date())
  const [blocking, setBlocking] = React.useState(false)

  return (
    <Boxlist>
      <Box>
        <div className="ui-area-divider">
          <h2>Checkboxes</h2>
          <Checkbox title="I am a checkbox"/>
          <Checkbox title="I am an active checkbox" checked/>
        </div>

        <div className="ui-area-divider">
          <h2>Tags: Normal</h2>

          <List hasVerticalMargin>
            <Tag
              title="Primary Tag"
              type={TAG_TYPES.PRIMARY}
              icon={<Icons.Calendar />}
            />
            <Tag
              title="Secondary Tag"
              type={TAG_TYPES.SECONDARY}
              icon={<Icons.Cancel />}
            />
            <Tag
              title="Red Tag"
              type={TAG_TYPES.RED}
              icon={<Icons.Chart />}
            />
            <Tag
              title="Yellow Tag"
              type={TAG_TYPES.YELLOW}
              icon={<Icons.Clock />}
            />
            <Tag
              title="Orange Tag"
              type={TAG_TYPES.ORANGE}
              icon={<Icons.Close />}
            />
            <Tag
              title="Blue Tag"
              type={TAG_TYPES.BLUE}
              icon={<Icons.Delete />}
            />
            <Tag
              title="Teal Tag"
              type={TAG_TYPES.TEAL}
              icon={<Icons.DocumentFilled />}
            />
          </List>

          <h2>Tags: Compact</h2>

          <List hasVerticalMargin>
            <Tag
              title="Primary Tag"
              type={TAG_TYPES.PRIMARY}
              icon={<Icons.Calendar />}
              compact
            />
            <Tag
              title="Secondary Tag"
              type={TAG_TYPES.SECONDARY}
              icon={<Icons.Cancel />}
              compact
            />
            <Tag
              title="Red Tag"
              type={TAG_TYPES.RED}
              icon={<Icons.Chart />}
              compact
            />
            <Tag
              title="Yellow Tag"
              type={TAG_TYPES.YELLOW}
              icon={<Icons.Clock />}
              compact
            />
            <Tag
              title="Orange Tag"
              type={TAG_TYPES.ORANGE}
              icon={<Icons.Close />}
              compact
            />
            <Tag
              title="Blue Tag"
              type={TAG_TYPES.BLUE}
              icon={<Icons.Delete />}
              compact
            />
            <Tag
              title="Teal Tag"
              type={TAG_TYPES.TEAL}
              icon={<Icons.DocumentFilled />}
              compact
            />
          </List>
        </div>

        <div className="ui-area-divider">
          <h2>List</h2>
          <p className="body-m-400">
            List component renders the elements in horizontal format.
          </p>
          <List>
            <p>Some paragraph</p>
            <Button
              type={BUTTON_TYPES.GRAY}
              title={"Some button"}
              icon={<Icons.Reload />}
            />
            <Tag
              title="Refresh"
              type={TAG_TYPES.TEAL}
              icon={<Icons.Reload />}
              compact
            />
          </List>
        </div>

        <div className="ui-area-divider">
          <h2>Input: Placeholder</h2>
          <Input placeholder="I am an Input"/>

          <h2>Input: Disabled</h2>
          <Input placeholder="I am disabled" disabled/>

          <h2>Input: Label - Error</h2>
          <Input label="Label" placeholder="I have an error!" error="Oh no, something is not right!"/>

          <h2>Input: Inline Button</h2>
          <Input
            button={
              <Button
                text="Copy"
                type={BUTTON_TYPES.GRAY}
                icon={<Icons.Copy />}
              />
            }
          />

          <h2>Input: Inline Button - Disabled</h2>
          <Input
            button={
              <Button
                text="Copy"
                type={BUTTON_TYPES.GRAY}
                icon={<Icons.Copy />}
              />
            }
            disabled
          />

          <h2>Input: Icon on Left - Clearable</h2>
          <Input placeholder="Search..." value="I am a value" leftIcon={<Icons.Search />} clearable/>

          <h2>Input: Textarea</h2>
          <Input placeholder="I am a Textarea" textarea />

          <h2>Input: Textarea - Clearable</h2>
          <Input clearable textarea/>

          <h2>Input: Textarea - Error - Disabled</h2>
          <Input placeholder="I am a Textarea" textarea disabled error="Textarea have an error" />
        </div>

        <div className="ui-area-divider">
          <h2>Selectbox</h2>
          <Selectbox
            options={[
              { label: "Test 1", value: "Test 1" },
              { label: "Test 2", value: "Test 2" },
              { label: "Test 3", value: "Test 3" },
              { label: "Test 4", value: "Test 4" },
              { label: "Test 5", value: "Test 5" },
              { label: "Test 6", value: "Test 6" },
              { label: "Test 7", value: "Test 7" },
              { label: "Test 8", value: "Test 8" },
              { label: "Test 9", value: "Test 9" },
            ]}
          />
        </div>

        <div className="ui-area-divider">
          <h2>Date Select</h2>
          <Datepicker
            selected={date1}
            onChange={date => setDate1(date)}
          />

          <h2>Time select</h2>
          <Datepicker
            selected={date2}
            onChange={date => setDate2(date)}
            showTime
            hideMonth
          />

          <h2>Date + Time Select</h2>
          <Datepicker
            selected={date3}
            onChange={date => setDate3(date)}
            showTime
          />

          <h2>Tooltips</h2>
          <p data-tip="I am a tooltip">Hover me for tooltip.</p>
        </div>
      </Box>
      <Box>
        <Button
          title="Toggle Block"
          onClick={() => setBlocking(prev => !prev)}
        />
      </Box>
      <Box isBlocking={blocking} blocksWith={<ScaleLoader />}>
        <h2>I am being blocked!</h2>
        <p>...</p>
      </Box>
    </Boxlist>
  )
}