import { call, put, fork, all, takeLatest } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"
import store from "store2"

import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
  AUTH_2FA_REQUEST,
  AUTH_2FA_SUCCESS,
  AUTH_2FA_FAIL,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  MESSAGE_RECEIVE_SUCCESS,
  NOTIFICATION_RECEIVE_SUCCESS,
  AUTH_LOGIN_VIA_API_REQUEST,
  AUTH_LOGIN_VIA_API_SUCCESS,
  AUTH_LOGIN_VIA_API_FAIL,
} from "constants"

function* login(action) {
  try {
    const { username, password } = action.payload
    const token = "test-token"
    const requires2FA = true

    yield put({
      type: AUTH_LOGIN_SUCCESS,
      payload: {
        username,
        token,
      }
    })

    action.onSuccess && action.onSuccess.apply(this, [requires2FA])

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: AUTH_LOGIN_FAIL })
  }
}

function* loginViaApi(action) {
  try {
    yield put({
      type: AUTH_LOGIN_VIA_API_SUCCESS
    })

    action.onSuccess && action.onSuccess.apply(this, [])

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: AUTH_LOGIN_VIA_API_FAIL })
  }
}

function* twoFactor(action) {
  try {
    const { code } = action.payload

    if (code !== "111111") {
      yield put({ type: AUTH_2FA_FAIL })
      return
    }

    yield put({
      type: AUTH_2FA_SUCCESS,
    })

    action.onSuccess.apply(this, [])

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: AUTH_2FA_FAIL })
  }
}

function* logout(action) {
  try {
    store.clearAll()
    yield put({ type: AUTH_LOGOUT_SUCCESS })
    location.reload()
  } catch (err) {
    yield call(errorHandler, err)
  }
}

function* loginSaga() {
  yield takeLatest(AUTH_LOGIN_REQUEST, login)
}

function* loginViaApiSaga() {
  yield takeLatest(AUTH_LOGIN_VIA_API_REQUEST, loginViaApi)
}

function* twoFactorSaga() {
  yield takeLatest(AUTH_2FA_REQUEST, twoFactor)
}

function* logoutSaga() {
  yield takeLatest(AUTH_LOGOUT_REQUEST, logout)
}

export function* auth() {
  yield all([
    call(loginSaga),
    call(loginViaApiSaga),
    call(twoFactorSaga),
    call(logoutSaga),
  ])
}
