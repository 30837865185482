import {
  PRODUCT_RECEIVE_REQUEST,
  PRODUCT_RECEIVE_SUCCESS,
  PRODUCT_RECEIVE_FAIL,
  PRODUCT_GROUP_RECEIVE_REQUEST,
  PRODUCT_GROUP_RECEIVE_SUCCESS,
  PRODUCT_GROUP_RECEIVE_FAIL,
  PRODUCT_BUNDLE_RECEIVE_REQUEST,
  PRODUCT_BUNDLE_RECEIVE_SUCCESS,
  PRODUCT_BUNDLE_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: false,
  products: [],
  productGroups: [],
}

export default function product(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case PRODUCT_RECEIVE_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        loading: false,
      }

    case PRODUCT_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case PRODUCT_GROUP_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case PRODUCT_GROUP_RECEIVE_SUCCESS:
      return {
        ...state,
        productGroups: action.payload.productGroups,
        loading: false,
      }

    case PRODUCT_GROUP_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case PRODUCT_BUNDLE_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case PRODUCT_BUNDLE_RECEIVE_SUCCESS:
      return {
        ...state,
        productBundles: action.payload.productBundles,
        loading: false,
      }

    case PRODUCT_BUNDLE_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
