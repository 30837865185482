import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Boxlist } from "components/Boxlist"
import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Selectbox } from "components/Ui"
import { Uploader } from "components/Uploader"

import "./index.scss"

@withRouter
@connect((state) => ({
  order: state.order,
}))
class OrderReturnCreate extends React.Component {

  render() {
    const { order: { loading }, dispatch } = this.props

    const shouldShowTypeSelectboxes = ({ values }) => {
      const value = values().state.value
      return ["draft", "waiting"].includes(value)
    }

    return (
      <main className="OrderReturnCreate-screen">
        <Boxlist>
          <Box>
            <h2>State and Delivery</h2>
            <hr />
            <Form
              data={{
                defaultValues: {
                  state: { value: "draft", label: "Draft" },
                  deliveryType: null,
                  reasonType: null,
                  internalComments: "",
                  warehouseComments: "",
                }
              }}
              render={[
                {
                  label: "State",
                  name: "state",
                  node: ({ field, fieldState }) => (
                    <Selectbox
                      options={[
                        { value: "draft", label: "Draft" },
                        { value: "waiting", label: "Waiting" },
                        { value: "returned", label: "Returned" },
                        { value: "instock", label: "Instock" },
                        { value: "cancelled", label: "Cancelled" },
                      ]}
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
                {
                  label: "Delivery type",
                  name: "deliveryType",
                  condition: shouldShowTypeSelectboxes,
                  node: ({ field, fieldState }) => (
                    <Selectbox
                      options={[
                        { value: "1", label: "GLS" },
                        { value: "2", label: "Posti 633917 return (with tracking code)" },
                        { value: "3", label: "Omniva IBRS (weight > 500 g)" },
                        { value: "4", label: "Omniva IBRS (weight < 500 g)" },
                        { value: "5", label: "Posti 633917 return (NO tracking code)" },
                        { value: "6", label: "DHL Sweden return" },
                        { value: "7", label: "DHL Express" },
                        { value: "8", label: "Other return method" },
                      ]}
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
                {
                  label: "Reason type",
                  name: "reasonType",
                  condition: shouldShowTypeSelectboxes,
                  node: ({ field, fieldState }) => (
                    <Selectbox
                      options={[
                        { value: "1", label: "Common - order not delivered" },
                        { value: "2", label: "Common - unknown return reason" },
                      ]}
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
                {
                  label: "Merchant's internal comments",
                  name: "internalComments",
                  node: ({ field, fieldState }) => (
                    <Input
                      textarea
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
                {
                  label: "Warehouse comments",
                  name: "warehouseComments",
                  node: ({ field, fieldState }) => (
                    <Input
                      textarea
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
              ]}
              button={
                <Button
                  title={translate("save")}
                  type={BUTTON_TYPES.GRAY}
                />
              }
              onSubmit={data => {
                console.log(data)
              }}
            />
          </Box>
          <Box>
            <h2>Related documents (PDF only)</h2>
            <hr />
            <Uploader />
          </Box>
          <Box>
            <h2>Tracking codes</h2>
            <hr />
            <p>TODO</p>
          </Box>
          <Box>
            <h2>Sender information</h2>
            <hr />
            <Form
              data={{
                defaultValues: {
                  name: "",
                  company: "",
                  street: "",
                  postcode: "",
                  city: "",
                  email: "",
                  phone: "",
                  country: { value: "fi", label: "Finland" },
                }
              }}
              rules={{
                name: Rules.string().required(),
                street: Rules.string().required(),
                postcode: Rules.string().required(),
                city: Rules.string().required(),
              }}
              render={[
                {
                  label: "Name",
                  name: "name",
                  node: ({ field, fieldState }) => (
                    <Input
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
                {
                  label: "Company",
                  name: "company",
                  node: ({ field, fieldState }) => (
                    <Input
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
                {
                  label: "Street",
                  name: "street",
                  node: ({ field, fieldState }) => (
                    <Input
                      textarea
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
                {
                  label: "Post code",
                  name: "postcode",
                  node: ({ field, fieldState }) => (
                    <Input
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
                {
                  label: "City",
                  name: "city",
                  node: ({ field, fieldState }) => (
                    <Input
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
                {
                  label: "Country",
                  name: "country",
                  node: ({ field, fieldState }) => (
                    <Selectbox
                      options={[
                        { value: "fi", label: "Finland" },
                        { value: "tr", label: "Turkey" },
                        { value: "us", label: "USA" }
                      ]}
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
                {
                  label: "Email",
                  name: "email",
                  node: ({ field, fieldState }) => (
                    <Input
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },,
                {
                  label: "Phone",
                  name: "phone",
                  node: ({ field, fieldState }) => (
                    <Input
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
              ]}
              button={
                <Button
                  title={translate("save")}
                  type={BUTTON_TYPES.GRAY}
                />
              }
              onSubmit={data => {
                console.log(data)
              }}
            />
          </Box>
          <Box>
            <h2>Receiver information</h2>
            <hr />
            <Form
              data={{
                defaultValues: {
                  warehouse: { value: "Public testing warehouse", label: "Public testing warehouse" },
                }
              }}
              rules={{
                warehouse: Rules.string().required(),
              }}
              render={[
                {
                  label: "Warehouse",
                  name: "warehouse",
                  node: ({ field, fieldState }) => (
                    <Selectbox
                      options={[
                        { value: "OGOship EE-TLL", label: "OGOship EE-TLL" },
                        { value: "OGOship GB-PFS", label: "OGOship GB-PFS" },
                        { value: "OGOship SE-GOT", label: "OGOship SE-GOT" },
                        { value: "OGOship NL-VNL", label: "OGOship NL-VNL" },
                        { value: "OGOship FI-JKT", label: "OGOship FI-JKT" },
                        { value: "OGOship SE-SKP", label: "OGOship SE-SKP" },
                        { value: "OGOship FI-VNT", label: "OGOship FI-VNT" },
                        { value: "Public testing warehouse", label: "Public testing warehouse" },
                      ]}
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
              ]}
              button={
                <Button
                  title={translate("save")}
                  type={BUTTON_TYPES.GRAY}
                />
              }
              onSubmit={data => {
                console.log(data)
              }}
            />
          </Box>
        </Boxlist>
      </main>
    )
  }
}

export { OrderReturnCreate }
