import { DebounceInput } from "react-debounce-input"
import { Icons } from "icons"

import "./index.scss"

export class Input extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    button: PropTypes.node,
    label: PropTypes.string,
    textarea: PropTypes.bool,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    leftIcon: PropTypes.node,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onEnter: PropTypes.func,
    debounceTimeout: PropTypes.number,
    frozen: PropTypes.bool,
    type: PropTypes.string,
    step: PropTypes.number,
    min: PropTypes.any,
    max: PropTypes.any,
    qaSelector: PropTypes.string,
  }

  static defaultProps = {
    className: "",
    placeholder: "",
    error: "",
    button: null,
    label: "",
    textarea: false,
    disabled: false,
    clearable: false,
    leftIcon: null,
    value: "",
    onChange: null,
    onClick: null,
    onEnter: null,
    debounceTimeout: 600,
    frozen: false,
    type: "text",
    step: 1,
    min: null,
    max: null,
    qaSelector: "",
  }

  onChange = e => {
    const { onChange } = this.props

    // If we use the Form hook, it expects SyntheticEvent.
    if (this.props.field) {
      return onChange && onChange.apply(this, [e])
    }

    // Our components expect a string.
    onChange && onChange.apply(this, [e.target.value])
  }

  onKeyPress = e => {
    const { onEnter } = this.props

    if (e.key !== "Enter") {
      return
    }

    if (!onEnter) {
      return
    }

    onEnter.apply(this, [e.target.value])
  }

  render () {
    const {
      className,
      textarea,
      placeholder,
      label,
      error,
      button,
      debounceTimeout,
      value,
      disabled,
      clearable,
      leftIcon,
      onChange,
      onClick,
      frozen,
      type,
      step,
      min,
      max,
      qaSelector,
    } = this.props

    const isNumber = typeof value === "number"
    const additionalProps = {}

    if (textarea) {
      additionalProps.rows = 3
    }

    if (min !== null) {
      additionalProps.min = min
    }

    if (max !== null) {
      additionalProps.max = max
    }

    if (qaSelector) {
      additionalProps["data-qa-selector"] = qaSelector
    }

    return (
      <section className={classnames("com-Input", className, {
        "has-error": error,
        "is-disabled": disabled,
        "is-frozen": frozen,
        "has-inline-button": button,
      })}>
        {label && (
          <div className={classnames("label-container")}>
            <label htmlFor={label}>{label}</label>
          </div>
        )}
        <div className={classnames("input-container")}>
          <div className={classnames("input-absolute-container", {
            "has-left-icon": leftIcon,
            "has-right-icon": clearable,
          })}>
            {leftIcon}
            <DebounceInput
              id={label}
              element={textarea ? "textarea" : "input"}
              type={type}
              step={step}
              debounceTimeout={debounceTimeout}
              value={value}
              placeholder={placeholder}
              onChange={this.onChange}
              onKeyPress={this.onKeyPress}
              disabled={disabled}
              onClick={onClick}
              readOnly={frozen}
              { ...additionalProps}
              { ...this.props.field}
            />
            {clearable && (
              <Icons.Close />
            )}
          </div>
          {button && (
            <div className="inline-button">
              {button}
            </div>
          )}
        </div>
        {error && (
          <div className="error-message-container">
            <Icons.Warning16 />
            <p>{error}</p>
          </div>
        )}
      </section>
    )
  }
}