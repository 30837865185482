import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { ScaleLoader } from "components/Loader/ScaleLoader"
import { FullScreenBoxLoader } from "components/Loader/FullScreenBoxLoader"

export const LoaderArea = props => (
  <>
    <Boxlist>
      <Box>
        <h2>ScaleLoader</h2>
        <ScaleLoader />
      </Box>
    </Boxlist>
    <Boxlist>
      <Box>
        <h2>FullScreenBoxLoader</h2>
        <FullScreenBoxLoader />
      </Box>
    </Boxlist>
  </>
)
