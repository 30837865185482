import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { Button, BUTTON_TYPES } from "components/Button"

import {
  PRODUCT_GROUP_RECEIVE_REQUEST,
  MODAL_OPEN,
  MODAL_SCREEN_ADD_PRODUCT_GROUP,
  MODAL_SCREEN_UPDATE_PRODUCT_GROUP,
  MODAL_SCREEN_REMOVE_PRODUCT_GROUP,
} from "constants"

import "./index.scss"

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    title={"Product groups"}
    description="Review and manage all the product groups"
    columns={[
      { title: "Name", attr: "name" },
    ]}
    actions={[
      {
        title: "Open",
        massActionDisabled: true,
        action: props.onOpen
      },
      {
        title: "Delete",
        action: props.onDelete,
        isRed: true,
      },
    ]}
    buttons={
      <>
        <Button
          type={BUTTON_TYPES.GRAY}
          icon={<Icons.Plus />}
          onClick={props.onAddNew}
          hasSmallRadius
        />
      </>
    }
    noFilters
    noCheckbox
  />
)

@withRouter
@connect((state) => ({
  product: state.product,
}))
class ProductGroupListing extends React.Component {

  render() {
    const { product: { productGroups, loading }, dispatch, history } = this.props

    return (
      <main className="ProductGroupListing-screen">
        <Boxlist>
          <Box noPadding>
            <TableRegion
              dispatch={dispatch}
              loading={loading}
              rows={productGroups}
              onRefresh={qs => {
                dispatch({
                  type: PRODUCT_GROUP_RECEIVE_REQUEST,
                  payload: {
                    qs
                  }
                })
              }}
              onAddNew={() => {
                dispatch({
                  type: MODAL_OPEN,
                  payload: {
                    screen: MODAL_SCREEN_ADD_PRODUCT_GROUP
                  }
                })
              }}
              onOpen={rows => {
                dispatch({
                  type: MODAL_OPEN,
                  payload: {
                    screen: MODAL_SCREEN_UPDATE_PRODUCT_GROUP,
                    value: rows[0],
                  }
                })
              }}
              onDelete={rows => {
                dispatch({
                  type: MODAL_OPEN,
                  payload: {
                    screen: MODAL_SCREEN_REMOVE_PRODUCT_GROUP,
                    value: rows[0],
                  }
                })
              }}
            />
          </Box>
        </Boxlist>
      </main>
    )
  }
}

export { ProductGroupListing }
