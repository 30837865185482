import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { List, Checkbox, Tag, TAG_TYPES } from "components/Ui"
import { Uploader } from "components/Uploader"

export const HistoryArea = props => {
  const [tag, setTag] = React.useState("")
  const [tags, setTags] = React.useState(["Electronic Product"])

  const addTag = () => {
    setTags(prev => [...new Set([...prev, tag])])
    setTag("")
  }

  return (
    <>
      <Box className="actions-area">
        <h2>History</h2>
        <hr />
        <p>Nothing yet</p>
        <h2>Undelivered orders</h2>
        <hr />
        <p>Nothing yet</p>
        <h2>Processing orders</h2>
        <hr />
        <p>Nothing yet</p>
        <h2>Issues</h2>
        <hr />
        <Button
          title={"Product issue"}
          type={BUTTON_TYPES.TERTIARY}
        />
        <Button
          title="Question or note"
          type={BUTTON_TYPES.TERTIARY}
        />
        <h2>Documents</h2>
        <hr />
        <Uploader />
        <h2>Normal product features</h2>
        <hr />
        <Checkbox title="Require serial number when shipping" />
        <Checkbox title="Require serial number when receiving" />
        <Checkbox title="Expiring product (with due date)" />
        <Checkbox title="Batch product" />
        <Checkbox title="Dangerous goods product" />
        <Checkbox title="Add-on product" />
        <Checkbox title="This product is a bundle" />
        <Checkbox title="Remove from order if not enough in stock" />
        <Checkbox title="Hidden" />
        <Checkbox title="Product is a part of a bundle" />
        <h2>Special product features</h2>
        <hr />
        <Checkbox title="Virtual / electronic product" />
        <Checkbox title="Can be shipped in original package" />
        <Checkbox title="Add-on flyer" />
        <Checkbox title="Reminder / service task" />
        <Checkbox title="Packaging material" />
        <Checkbox title="Sticker" />
        <h2>Tags</h2>
        <hr />
        <List>
          {tags.map(tag => (
            <Tag
              key={tag}
              title={tag}
              type={TAG_TYPES.BLUE}
            />
          ))}
        </List>
        <Input
          placeholder="Add a new tag"
          value={tag}
          onChange={text => setTag(text)}
          debounceTimeout={100}
          onEnter={addTag}
          button={
            <Button
              title="Add"
              type={BUTTON_TYPES.GRAY}
              isDisabled={!tag}
              onClick={addTag}
            />
          }
        />
      </Box>
    </>
  )
}