import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { PageHeader } from "components/PageHeader"
import { Button, BUTTON_TYPES } from "components/Button"
import { Breadcrumbs, Checkbox, Selectbox } from "components/Ui"
import { Modal } from "components/Layout/Modal"

import { FeedbackManagerArea } from "./FeedbackManagerArea"
import { CurrentStatusArea } from "./CurrentStatusArea"
import { TableArea } from "./TableArea"

import {
  FEEDBACK_RECEIVE_REQUEST,
} from "constants"

import "./index.scss"

const FiltersModal = props => {
  const { onSave, onClose } = props

  return (
    <Modal
      title="Table Filters"
      onClose={onClose}
      isSidebar={true}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={"Cancel"}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={"Save"}
            onClick={onSave}
          />
        </>
      }
    >
      <label>Filter by: Types</label>
      <Checkbox
        title={"Order processing"}
        checked={true}
      />
      <Checkbox
        title={"Order tracking"}
        checked={true}
      />
      <Checkbox
        title={"Pick-up point"}
        checked={false}
      />
      <Checkbox
        title={"Courier service"}
        checked={false}
      />
      <Checkbox
        title={"Packaging"}
        checked={false}
      />
      <label>Filter by: Merchants</label>
      <Selectbox
        options={[
          { value: "Mukama OY", label: "Mukama OY" },
          { value: "Vacier", label: "Vacier" },
          { value: "Apple", label: "Apple" },
        ]}
        value={[
          { value: "Mukama OY", label: "Mukama OY" },
          { value: "Vacier", label: "Vacier" },
        ]}
        isMulti
      />
    </Modal>
  )
}

const FeedbackModal = props => {
  const { value, onClose } = props

  return (
    <Modal
      title="Feedback"
      onClose={onClose}
      isSidebar={true}
      className="feedback-modal"
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={"Close"}
            onClick={onClose}
          />
        </>
      }
    >
    <p>Rating: {value.rate}</p>
    <p>Type: <b>{value.type}</b></p>
    <p>Date: <b>{value.date}</b></p>
    <p>Merchant: <b>{value.merchant}</b></p>
    <p>Order: <b>{value.order}</b></p>
    <p>Comment: <b>{value.comment}</b></p>

    <h4>Want to take action?</h4>
    <Button
      title="Create a Support Ticket"
      type={BUTTON_TYPES.PRIMARY}
    />
    <Button
      title="Give Discount Code"
      type={BUTTON_TYPES.PRIMARY}
    />
    <Button
      title="Send Apogolize Email"
      type={BUTTON_TYPES.PRIMARY}
    />
    </Modal>
  )
}

@withRouter
@connect((state) => ({
  feedback: state.feedback,
}))
class FeedbackListing extends React.Component {

  state = {
    filtersModal: false,
    value: null,
    feedbackModal: false,
  }

  openFiltersModal = () => {
    this.setState({
      feedbackModal: false,
      value: null,
      filtersModal: true
    })
  }

  closeFiltersModal = () => {
    this.setState({
      feedbackModal: false,
      value: null,
      filtersModal: false
    })
  }

  openFeedbackModal = rows => {
    this.setState({
      feedbackModal: true,
      value: rows[0],
      filtersModal: false
    })
  }

  closeFeedbackModal = () => {
    this.setState({
      feedbackModal: false,
      feedback: null,
      filtersModal: false
    })
  }

  render() {
    const { feedback: { feedbacks, loading }, dispatch, history } = this.props
    const { filtersModal, feedbackModal, value } = this.state

    return (
      <>
        <PageHeader
          prefix={"Review and manage all the feedbacks"}
          title={"Feedbacks"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "feedbacks",
                },
              ]}
              activeIndex={1}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="feedback-listing-screen">
          <FeedbackManagerArea
            dispatch={dispatch}
            options={[
              {
                title: "Order processing",
                subtitles: [
                  {
                    title: "Delivery took longer than estimated"
                  },
                  {
                    title: "Lengthy processing before the send-out"
                  },
                  {
                    title: "Unforeseen delivery expenses "
                  },
                  {
                    title: "Order was never delivered"
                  }
                ]
              },
              {
                title: "Order tracking",
                subtitles: [
                  {
                    title: "Inaccurate tracking data"
                  },
                  {
                    title: "Actual situation not reflected"
                  },
                  {
                    title: "Lack of communication"
                  },
                  {
                    title: "Spammy notifications"
                  }
                ]
              },
              {
                title: "Pick-up point",
                subtitles: [
                  {
                    title: "Delivered to the wrong pick-up point"
                  },
                  {
                    title: "Located too faraway from me"
                  },
                  {
                    title: "Exhausting long queues"
                  },
                  {
                    title: "Lack of self pick-up options"
                  }
                ]
              },
              {
                title: "Courier service",
                subtitles: [
                  {
                    title: "Left in the unsuitable place"
                  },
                  {
                    title: "Delivered to the wrong person "
                  },
                  {
                    title: "Delivery schedule not followed"
                  },
                  {
                    title: "Rude courier’s behavior"
                  }
                ]
              },
            ]}
          />
          <CurrentStatusArea />
          <TableArea
            loading={loading}
            rows={feedbacks}
            onRefresh={qs => {
              dispatch({
                type: FEEDBACK_RECEIVE_REQUEST,
                payload: {
                  qs
                }
              })
            }}
            onEdit={this.openFeedbackModal}
            onDelete={rows => {
              console.log(rows)
            }}
            onFilterClick={this.openFiltersModal}
          />
          {filtersModal && (
            <FiltersModal
              onSave={this.onSaveFilters}
              onClose={this.closeFiltersModal}
            />
          )}
          {feedbackModal && (
            <FeedbackModal
              value={value}
              onClose={this.closeFiltersModal}
            />
          )}
        </main>
      </>
    )
  }
}

export { FeedbackListing }
