import "./index.scss"

export class Plusminus extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    entity: PropTypes.string,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    className: "",
    value: 0,
    entity: "",
    onChange: null,
  }

  render () {
    const { className, value, entity, onChange } = this.props

    return (
      <section className={classnames("com-Plusminus", className)}>
        <div
          className="minus-section"
          onClick={() => onChange && onChange.apply(this, [Math.max(0, value - 1)])}
        >
          -
        </div>
        <div className="input-section">
          <input
            type="number"
            value={value}
            onChange={e => onChange && onChange.apply(this, [Math.max(0, e.currentTarget.value)])}
           />
        </div>
        {entity && (<div className="entity-section">{entity}</div>)}
        <div
          className="plus-section"
          onClick={() => onChange && onChange.apply(this, [value + 1])}
        >
          +
        </div>
      </section>
    )
  }
}