import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"

import { PRODUCT_RECEIVE_REQUEST } from "constants"

import "./index.scss"

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    title={translate("products")}
    description="Review and manage all the products"
    columns={[
      { title: "Name", attr: "_1" },
      { title: "Code", attr: "_2" },
      { title: "EAN", attr: "_3" },
      { title: "Supplier code", attr: "_4" },
      { title: "Tag", attr: "_5" },
      { title: "Supplier", attr: "_6" },
      { title: "Stock", attr: "_7" },
      { title: "Reserved", attr: "_8" },
      { title: "Available stock", attr: "_9" },
      { title: "Alarm level", attr: "_10" },
      { title: "Stickers", attr: "_11" },
      { title: "Edited", attr: "_12" },
      { title: "Stock changed", attr: "_13" },
      { title: "Stock update", attr: "_14" },
      { title: "Product group", attr: "_15" },
    ]}
  />
)

@withRouter
@connect((state) => ({
  product: state.product,
}))
class ProductListing extends React.Component {

  render() {
    const { product: { products, loading }, dispatch } = this.props

    return (
      <main className="ProductListing-screen">
        <Boxlist>
          <Box noPadding>
            <TableRegion
              loading={loading}
              rows={products}
              onRefresh={qs => {
                dispatch({
                  type: PRODUCT_RECEIVE_REQUEST,
                  payload: {
                    qs
                  }
                })
              }}
            />
          </Box>
        </Boxlist>
      </main>
    )
  }
}

export { ProductListing }
