import { translateNode } from "helpers/i18n"
import { Icons } from "icons"

import "./index.scss"

export class Footer extends React.Component {

  static propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    bg: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      to: PropTypes.string,
    })),
    date: PropTypes.number,
  }

  static defaultProps = {
    name: "",
    color: "",
    bg: "",
    links: [],
    date: new Date().getFullYear(),
  }

  render () {
    const {
      name,
      color,
      bg,
      links,
      date,
    } = this.props

    return (
      <footer>
        <section className="top-footer">
          <div className="boxes-container">
            <Icons.BoxIcon1 className="box-1" />
            <Icons.BoxIcon2 className="box-2" />
            <Icons.BoxIcon3 className="box-3" />
            <p>{translateNode("proudly-powered-by-text", { by: <span>{name}</span> })}</p>
          </div>
        </section>
        <section className="bottom-footer">
          <p>© {date} {name}</p>

          {links.map(link => (
            <a key={link.title} href={link.to} target="_blank">
              {link.title}
            </a>
          ))}
        </section>
      </footer>
    )
  }
}