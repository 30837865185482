import onClickOutside from "react-onclickoutside"
import { translate } from "helpers/i18n"
import { Icons } from "icons"
import { Flag } from "components/Flag"

import {
  LANGUAGE_DEFAULT,
  LANGUAGE_EN,
  LANGUAGE_FI,
  LANGUAGE_SV,
  LANGUAGE_DE,
} from "constants"

import "./index.scss"

class LanguagePicker extends React.Component {

  static propTypes = {
    open: PropTypes.bool,
    language: PropTypes.string,
    onSelect: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  }

  static defaultProps = {
    open: false,
    language: LANGUAGE_DEFAULT,
    onSelect: null,
    onOpen: null,
    onClose: null,
  }

  handleClickOutside = (evt) => {
    const { onClose } = this.props

    onClose && onClose.call(this)
  }

  getLanguages() {
    const { language } = this.props

    const hash = {
      [LANGUAGE_EN]: <Flag code="GB" />,
      [LANGUAGE_FI]: <Flag code="FI" />,
      [LANGUAGE_DE]: <Flag code="DE" />,
      [LANGUAGE_SV]: <Flag code="SE" />,
    }

    // Get langs except selected language
    const filtered = Object.keys(hash).filter(key => key !== language)

    return [language, ...filtered].map(key => ({ key, flag: hash[key] }))
  }

  render() {
    const { open, onOpen, onClose, onSelect } = this.props

    if (!open) {
      return (
        <div className="com-LanguageSelector">
          <div className="language-dropdown">
            <div
              className="language-line"
              onClick={onOpen}
            >
              {this.getLanguages()[0].flag}
              <Icons.ArrowDown />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="com-LanguageSelector">
        <div className="language-dropdown active">
          {this.getLanguages().map((entry, i) => (
            <div
              key={entry.key}
              className={classnames("language-line", {
                "no-last-child": i > 0
              })}
              onClick={() => {
                onSelect.apply(this, [entry.key])
                onClose.call(this)
              }}
            >
              {entry.flag}
              <Icons.ArrowDown />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export const LanguageSelector = onClickOutside(LanguagePicker)