import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"

import {
  ORDER_RECEIVE_REQUEST,
  ORDER_RECEIVE_SUCCESS,
  ORDER_RECEIVE_FAIL,
  ORDER_RETURN_RECEIVE_REQUEST,
  ORDER_RETURN_RECEIVE_SUCCESS,
  ORDER_RETURN_RECEIVE_FAIL,
} from "constants"

import {
  fakeOrdersBackend,
  fakeOrderReturnsBackend
} from "mock"

function* receive(action) {
  try {
    const data = fakeOrdersBackend.update(action.payload?.qs).getJson()

    yield delay(1500)

    yield put({
      type: ORDER_RECEIVE_SUCCESS,
      payload: {
        orders: data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: ORDER_RECEIVE_FAIL })
  }
}

function* receiveOrderReturn(action) {
  try {
    const data = fakeOrderReturnsBackend.update(action.payload?.qs).getJson()

    yield delay(1500)

    yield put({
      type: ORDER_RETURN_RECEIVE_SUCCESS,
      payload: {
        returns: data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: ORDER_RETURN_RECEIVE_FAIL })
  }
}

function* orderReceiveSaga() {
  yield takeLatest(ORDER_RECEIVE_REQUEST, receive)
}

function* orderReturnReceiveSaga() {
  yield takeLatest(ORDER_RETURN_RECEIVE_REQUEST, receiveOrderReturn)
}

export function* order() {
  yield all([
    call(orderReceiveSaga),
    call(orderReturnReceiveSaga),
  ])
}
