import {
  COUNTRY_RECEIVE_REQUEST,
  COUNTRY_RECEIVE_SUCCESS,
  COUNTRY_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  countries: [],
  countriesDict: {}
}

export default function country(state = initialState, action) {
  switch (action.type) {
    case COUNTRY_RECEIVE_SUCCESS:
      return {
        ...state,
        countries: action.payload.countries,
        countriesDict: action.payload.countries.reduce((a, v) => ({ ...a, [v.value]: v }), {})
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState
      }

    default:
      return state
  }
}
