import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Liquid } from "components/Liquid"

export const LiquidArea = props => {
  const template = '<p>Welcome to <span style="color: #781;">{{ name }}</span></p>'

  const data = {
    name: "Ogoship",
  }

  return (
    <>
      <Boxlist>
        <Box>
          <Liquid template={template} data={data}/>
        </Box>
      </Boxlist>
    </>
  )
}
