import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"

import {
  PRODUCT_RECEIVE_REQUEST,
  PRODUCT_RECEIVE_SUCCESS,
  PRODUCT_RECEIVE_FAIL,
  PRODUCT_GROUP_RECEIVE_REQUEST,
  PRODUCT_GROUP_RECEIVE_SUCCESS,
  PRODUCT_GROUP_RECEIVE_FAIL,
  PRODUCT_BUNDLE_RECEIVE_REQUEST,
  PRODUCT_BUNDLE_RECEIVE_SUCCESS,
  PRODUCT_BUNDLE_RECEIVE_FAIL,
} from "constants"

import {
  fakeProductsBackend,
  fakeProductGroupsBackend,
  fakeProductBundlesBackend,
} from "mock"

function* receive(action) {
  try {
    const data = fakeProductsBackend.update(action.payload?.qs).getJson()

    yield delay(1500)

    yield put({
      type: PRODUCT_RECEIVE_SUCCESS,
      payload: {
        product: data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: PRODUCT_RECEIVE_FAIL })
  }
}

function* receiveProductGroups(action) {
  try {
    const data = fakeProductGroupsBackend.update(action.payload?.qs).getJson()

    yield delay(1500)

    yield put({
      type: PRODUCT_GROUP_RECEIVE_SUCCESS,
      payload: {
        productGroups: data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: PRODUCT_GROUP_RECEIVE_FAIL })
  }
}

function* receiveProductBundles(action) {
  try {
    const data = fakeProductBundlesBackend.update(action.payload?.qs).getJson()

    yield delay(1500)

    yield put({
      type: PRODUCT_BUNDLE_RECEIVE_SUCCESS,
      payload: {
        productBundles: data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: PRODUCT_BUNDLE_RECEIVE_FAIL })
  }
}

function* productReceiveSaga() {
  yield takeLatest(PRODUCT_RECEIVE_REQUEST, receive)
}

function* productGroupReceiveSaga() {
  yield takeLatest(PRODUCT_GROUP_RECEIVE_REQUEST, receiveProductGroups)
}

function* productBundleReceiveSaga() {
  yield takeLatest(PRODUCT_BUNDLE_RECEIVE_REQUEST, receiveProductBundles)
}

export function* product() {
  yield all([
    call(productReceiveSaga),
    call(productGroupReceiveSaga),
    call(productBundleReceiveSaga),
  ])
}
