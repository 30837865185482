import {
  COST_CATEGORY_RECEIVE_REQUEST,
  COST_CATEGORY_RECEIVE_SUCCESS,
  COST_CATEGORY_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: true,
  err: "",
  costCategories: [],
}

export default function costCategory(state = initialState, action) {
  switch (action.type) {
    case COST_CATEGORY_RECEIVE_REQUEST:
      return {
        ...state,
        err: "",
        loading: true,
      }

    case COST_CATEGORY_RECEIVE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        err: "",
      }

    case COST_CATEGORY_RECEIVE_FAIL:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
