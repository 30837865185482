import "./index.scss"

export class Tab extends React.Component {

  static propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
      hasBottomSeperator: PropTypes.bool,
      upperTitle: PropTypes.string,
      icon: PropTypes.node,
    })),
    onClick: PropTypes.func,
    horizontal: PropTypes.bool,
  }

  static defaultProps = {
    tabs: [],
    onClick: null,
    horizontal: false,
  }

  render () {
    const { tabs, onClick, horizontal } = this.props

    return (
      <section className={classnames("com-Tab", {
        "is-horizontal": horizontal
      })}>
        {tabs.map((tab, i) => (
          <React.Fragment key={i}>
            {tab.upperTitle && (
              <div className="tab-title">
                {tab.upperTitle}
              </div>
            )}
            <div
              className={classnames("tab", {
                active: tab.active
              })}
              onClick={() => onClick && onClick.apply(this, [tab, i])}
            >
              <div className="tab-inner">
                {tab.icon}
                <p>{tab.name}</p>
              </div>
            </div>
            {tab.hasBottomSeperator && <div className="tab-separator" />}
          </React.Fragment>
        ))}
      </section>
    )
  }
}