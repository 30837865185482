import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"
import { toast } from "react-toastify"

import {
  WAREHOUSE_RECEIVE_REQUEST,
  WAREHOUSE_RECEIVE_SUCCESS,
  WAREHOUSE_RECEIVE_FAIL,
} from "constants"

function* receive(action) {
  try {
    const res = yield call(Api.warehouse.getAllWarehouses)

    yield put({
      type: WAREHOUSE_RECEIVE_SUCCESS,
      payload: {
        warehouses: res.data
      }
    })

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: WAREHOUSE_RECEIVE_FAIL })
  }
}

function* warehouseReceiveSaga() {
  yield takeLatest(WAREHOUSE_RECEIVE_REQUEST, receive)
}

export function* warehouse() {
  yield all([
    call(warehouseReceiveSaga),
  ])
}
