import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Selectbox } from "components/Ui"

export const EditOrderArea = props => {
  return (
    <>
      <Box>
        <h2>Edit Order</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              order_number: "",
              reference: "",
              warehouse: { value: "Public testing warehouse", label: "Public testing warehouse" },
              status: { value: "draft", label: "Draft" },
            }
          }}
          rules={{
            order_number: Rules.string().required(),
            reference: Rules.string().required(),
            warehouse: Rules.string().required(),
            status: Rules.string().required(),
          }}
          render={[
            {
              label: "Status",
              name: "status",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "draft", label: "Draft" },
                    { value: "reserved", label: "Reserved" },
                    { value: "pending", label: "Pending" },
                    { value: "new", label: "New" },
                    { value: "returned", label: "Returned" },
                    { value: "canceled", label: "Canceled" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Warehouse",
              name: "warehouse",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "OGOship EE-TLL", label: "OGOship EE-TLL" },
                    { value: "OGOship GB-PFS", label: "OGOship GB-PFS" },
                    { value: "OGOship SE-GOT", label: "OGOship SE-GOT" },
                    { value: "OGOship NL-VNL", label: "OGOship NL-VNL" },
                    { value: "OGOship FI-JKT", label: "OGOship FI-JKT" },
                    { value: "OGOship SE-SKP", label: "OGOship SE-SKP" },
                    { value: "OGOship FI-VNT", label: "OGOship FI-VNT" },
                    { value: "Public testing warehouse", label: "Public testing warehouse" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Reference number",
              name: "reference",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Order number",
              name: "order_number",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
    </>
  )
}