import { Box, useToggleHook } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { CircularProgress } from "components/CircularProgress"

export const CurrentStatusArea = props => {
  return (
    <Boxlist>
      <Box {...useToggleHook({ toggled: false} )}>
        <h2>Current Status</h2>
        <hr />
        <p>Your positive feedback rate is <b>90%</b> and you are doing better than <b>85.4%</b> of the merchants on OGOship. Good work!</p>
        <a href="#">Learn more</a>
        <div className="progress-bars-wrapper">
          <CircularProgress
            circleRatio={0.8}
            rotation={0.6}
            hideText
            strokeWidth={4}
            value={5}
            colors={{
              textColor: "var(--color-red500)",
              pathColor: "var(--color-red900)",
              trailColor: "var(--color-red50)",
            }}
          >
            <h3 className="color-red">1 stars</h3>
            <p className="body-m-400 color-red">5%</p>
            <p className="body-s-500 color-red">5 Feedbacks</p>
          </CircularProgress>
          <CircularProgress
            circleRatio={0.8}
            rotation={0.6}
            hideText
            strokeWidth={4}
            value={5}
            colors={{
              textColor: "var(--color-red500)",
              pathColor: "var(--color-red900)",
              trailColor: "var(--color-red50)",
            }}
          >
            <h3 className="color-red">2 stars</h3>
            <p className="body-m-400 color-red">5%</p>
            <p className="body-s-500 color-red">5 Feedbacks</p>
          </CircularProgress>
          <CircularProgress
            circleRatio={0.8}
            rotation={0.6}
            hideText
            strokeWidth={4}
            value={15}
            colors={{
              textColor: "var(--color-yellow500)",
              pathColor: "var(--color-yellow900)",
              trailColor: "var(--color-yellow50)",
            }}
          >
            <h3 className="color-yellow">3 stars</h3>
            <p className="body-m-400 color-yellow">15%</p>
            <p className="body-s-500 color-yellow">15 Feedbacks</p>
          </CircularProgress>
          <CircularProgress
            circleRatio={0.8}
            rotation={0.6}
            hideText
            strokeWidth={4}
            value={15}
            colors={{
              textColor: "var(--color-teal500)",
              pathColor: "var(--color-teal900)",
              trailColor: "var(--color-teal50)",
            }}
          >
            <h3 className="color-teal">4 stars</h3>
            <p className="body-m-400 color-teal">15%</p>
            <p className="body-s-500 color-teal">15 Feedbacks</p>
          </CircularProgress>
          <CircularProgress
            circleRatio={0.8}
            rotation={0.6}
            hideText
            strokeWidth={4}
            value={60}
            colors={{
              textColor: "var(--color-teal500)",
              pathColor: "var(--color-teal900)",
              trailColor: "var(--color-teal50)",
            }}
          >
            <h3 className="color-teal">5 stars</h3>
            <p className="body-m-400 color-teal">60%</p>
            <p className="body-s-500 color-teal">60 Feedbacks</p>
          </CircularProgress>
        </div>
      </Box>
    </Boxlist>
  )
}
