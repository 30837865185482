import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { Button, BUTTON_TYPES } from "components/Button"
import { Tag, TAG_TYPES, Selectbox, Datepicker } from "components/Ui"
import { Exporter } from "components/Exporter"

import { fakePlaygroundBackend } from "mock"

export const TableArea = props => {
  const [rows, setRows] = React.useState([])

  return (
    <Boxlist>
      <Box noPadding>
        <Table
          {...useTableHook({
            rows,
            columns: [
              {
                title: "Order ID",
                attr: "orderId"
              },
              {
                title: "Department",
                attr: "department",
                sortable: true,
                searchable: true,
                interceptor: entry => {
                  return entry.value
                },
                tagTransform: entry => {
                  return entry.label
                },
                searchNode: (setValue, value) => {
                  return (
                    <Selectbox
                      options={[
                        { value: "Management", label: "Management" },
                        { value: "Engineering", label: "Engineering" },
                        { value: "QA", label: "QA" },
                        { value: "Human Resources", label: "Human Resources" },
                        { value: "Accounting", label: "Accounting" },
                      ]}
                      value={value}
                      onChange={v => setValue(v, "department")}
                    />
                  )
                }
              },
              {
                title: "Date",
                attr: "date",
                sortable: true,
                searchable: true,
                tagTransform: value => {
                  return value.toLocaleString("tr-TR").substring(0, 10)
                },
                searchNode: (setValue, value) => (
                  <Datepicker
                    selected={value}
                    onChange={date => setValue(date, "date")}
                  />
                )
              },
              {
                title: "Date Range",
                attr: "dateRange",
                sortable: true,
                searchable: true,
                tagTransform: values => {
                  return values.filter(Boolean).map(v => v.toLocaleString("tr-TR").substring(0, 10)).join(" to ")
                },
                searchNode: (setValue, value) => {
                  const value0 = value ? value[0] : null
                  const value1 = value ? value[1] : null

                  return (
                    <>
                      <Datepicker
                        selected={value0}
                        customInput={
                          <p className="caption-s-400 soft">
                            {value0 ? `From: ${value[0].toLocaleDateString()}` : `From: All`}
                          </p>
                        }
                        onChange={date => setValue(date, "dateRange", 0)}
                      />
                      <Datepicker
                        selected={value1}
                        customInput={
                          <p className="caption-s-400 soft">
                            {value1 ? `To: ${value[1].toLocaleDateString()}` : `To: All`}
                          </p>
                        }
                        onChange={date => setValue(date, "dateRange", 1)}
                      />
                    </>
                  )
                }
              },
              {
                title: "Buyer",
                attr: "name",
                sortable: true,
                searchable: true
              },
              {
                title: "Age",
                attr: "age",
                sortable: true,
              },
              {
                title: "Work",
                attr: "work"
              },
              {
                title: "Dynamic Column",
                attr: "dynamic"
              },
              {
                title: "Gender",
                attr: "gender",
                groupable: true
              },
              {
                title: "Hobby",
                attr: "activity"
              },
            ],
            onRefresh: async (qs) => {
              console.log(qs)
              const data = await fakePlaygroundBackend.update(qs).getJson()
              setRows([...data])
            }
          })}
          title="Users List"
          description="In this table you can see all the users of all merchants"
          compact={!!props.compact}
          actions={[
            {
              title: translate("open-in-new-tab"),
              icon: <Icons.Pin />,
              action: (rows, i) => {
                console.log("Open in new tab")
                console.log(rows)
                console.log(i)
                props.onOpenTabs(rows)
              }
            },
            {
              title: translate("edit"),
              icon: <Icons.Edit />,
              action: (rows, i) => {
                console.log("Edit action")
                console.log(rows)
                console.log(i)
              },
              massActionDisabled: true,
            },
            {
              title: (rows, i) => i < 3 ? translate("delete") : "Something else",
              isRed: (rows, i) => i < 3,
              icon: (rows, i) => i < 3 ? <Icons.Delete /> : <Icons.Regenerate />,
              action: (rows, i) => {
                console.log("Delete action")
                console.log(rows)
                console.log(i)
              }
            },
          ]}
          columnRender={{
            work: row => (
              <Tag
                title={row.work}
                type={TAG_TYPES.PRIMARY}
              />
            ),
            dynamic: (_, index) => (
              <Button
                title={`Dynamic ${index}`}
                type={BUTTON_TYPES.TERTIARY}
                onClick={() => {
                  alert("I am a dynamic attribute. I am not a part of JSON.")
                }}
              />
            ),
            gender: row => (
              <Button
                title={row.gender === "F" ? "Female" : "Male"}
                type={row.gender === "F" ? BUTTON_TYPES.YELLOW : BUTTON_TYPES.BLUE}
                onClick={() => {
                  console.log(row)
                }}
              />
            ),
            dateRange: row => (
              <span>Example</span>
            ),
          }}
          buttons={
            <>
              <Button
                type={BUTTON_TYPES.GRAY}
                icon={<Icons.Shipping />}
                hasSmallRadius
              />
              <Button
                title={translate("filters")}
                type={BUTTON_TYPES.GRAY}
                leftIcon={<Icons.Settings />}
                onClick={props.onFilterClick}
                hasSmallRadius
              />
              <Exporter
                headers={[
                  { title: "Buyer", attr: "name" },
                  { title: "Age", attr: "age" },
                  { title: "Work", attr: "work" }
                ]}
                rows={rows}
                render={
                  <Button
                    title={translate("export")}
                    type={BUTTON_TYPES.GRAY}
                    icon={<Icons.Download />}
                    hasSmallRadius
                  />
                }
              />
            </>
          }
          tags={[
            <>
              <Tag
                title="Helsinki Warehouse"
                type={TAG_TYPES.PRIMARY}
                compact
              />
              <Tag
                title="Lviv Warehouse"
                type={TAG_TYPES.PRIMARY}
                compact
              />
            </>
          ]}
        />
      </Box>
    </Boxlist>
  )
}
