import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Boxlist } from "components/Boxlist"
import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { PageHeader } from "components/PageHeader"
import { Breadcrumbs } from "components/Ui"
import { ScaleLoader } from "components/Loader/ScaleLoader"

import { RecipientBox } from "./RecipientBox"
import { MessageBox } from "./MessageBox"
import { RecipientsModal } from "./RecipientsModal"

import {
  TM_CREATE_REQUEST,
  TM_UPDATE_REQUEST,
  TM_RECEIVE_SINGLE_REQUEST,
  TM_RECIPIENT_TYPES,
  TM_APPROVAL_TYPES,
  MODAL_OPEN,
  MODAL_CLOSE,
  MODAL_SCREEN_TM_UPDATE_RECIPIENTS,
  LANGUAGE_EN,
  LANGUAGE_DE,
  LANGUAGE_FI,
  LANGUAGE_SV,
  LANGUAGE_LIST,
} from "constants"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  modal: state.modal,
  transactionalMessage: state.transactionalMessage,
}))
class TransactionalMessageUpsert extends React.Component {

  state = {
    loaded: !this.props.updateMode,
    err: "",
    data: {},
    form1Data: {},
    [LANGUAGE_EN]: {},
    [LANGUAGE_DE]: {},
    [LANGUAGE_FI]: {},
    [LANGUAGE_SV]: {},
  }

  componentDidMount() {
    const { updateMode, dispatch, match: { params: { tmId } } } = this.props

    if (updateMode) {
      dispatch({
        type: TM_RECEIVE_SINGLE_REQUEST,
        payload: {
          id: tmId
        },
        success: data => {
          this.setState({
            data,
            loaded: true
          })
        },
        fail: err => {
          this.setState({ err })
        }
      })
    }
  }

  get pagedata() {
    const { updateMode } = this.props

    const res = {
      action: updateMode ? TM_UPDATE_REQUEST : TM_CREATE_REQUEST,
      prefix: updateMode ? `Update the Transactional Message` : "Create a Transactional Message",
      title: updateMode ? "Update" : "Create",
      form1: {
        defaultValues: this.getDefaultValues().form1,
      },
      form2: {
        defaultValues: this.getDefaultValues().form2,
      },
    }

    return res
  }

  getDefaultValues() {
    const { updateMode } = this.props
    const { data } = this.state

    const recipientType = data.recipientType || 0
    const approvalType = data.approvalType || 0

    return {
      form1: {
        recipientType: { value: recipientType, label: TM_RECIPIENT_TYPES[recipientType] },
        approvalType: { value: approvalType, label: TM_APPROVAL_TYPES[approvalType] },
        visibleFrom: data.visibleFrom ? new Date(data.visibleFrom) : new Date(),
        visibleTo: data.visibleTo ? new Date(data.visibleTo) : new Date(),
        approvalDeadline: data.approvalDeadline ? new Date(data.approvalDeadline) : new Date(),
      },
      form2: LANGUAGE_LIST.reduce((acc, lang) => {
        acc[lang] = {
          locale: lang,
          title: data?.message?.find(m => m.locale === lang)?.title || "",
          body: data?.message?.find(m => m.locale === lang)?.body || "",
        }

        return acc
      }, {})
    }
  }

  onSubmit = () => {
    const { updateMode, dispatch, history, match: { params: { tmId } } } = this.props
    const { form1Data, en, sv, de, fi } = this.state

    const payload = {
      data: {
        message: [en, sv, de, fi],
        recipientType: Number(form1Data.recipientType.value),
        approvalType: Number(form1Data.approvalType.value),
        visibleFrom: form1Data.visibleFrom,
        approvalDeadline: form1Data.approvalDeadline,
        visibleTo: form1Data.visibleTo,
      }
    }

    if (updateMode) {
      payload.id = tmId
      payload.data.uid = tmId
    }

    dispatch({
      type: this.pagedata.action,
      payload,
      success: () => {
        history.push("/admin/transactional-messages")
      }
    })
  }

  setForm1Data = form1Data => {
    this.setState({ form1Data })
  }

  setMessageData = (data, language) => {
    this.setState({
      [language]: data
    })
  }

  render() {
    const {
      transactionalMessage: { loading },
      modal,
      history,
      updateMode,
      dispatch,
    } = this.props
    const { loaded, data, err } = this.state

    return (
      <>
        <PageHeader
          prefix={this.pagedata.prefix}
          title={this.pagedata.title}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "admin",
                  onClick: () => {
                    history.push("/admin/")
                  }
                },
                {
                  title: "transactional-messages",
                  onClick: () => {
                    history.push("/admin/transactional-messages")
                  }
                },
                {
                  title: this.pagedata.title,
                },
              ]}
              activeIndex={3}
            />
          }
        />
        <main className="TransactionalMessageUpsert-screen">
          {err && (
            <Boxlist>
              <Box>
                <p>Error: {err.message}</p>
              </Box>
            </Boxlist>
          )}
          {!err && !loaded && (
            <ScaleLoader />
          )}

          {!err && loaded && (
            <Boxlist>
              <RecipientBox
                updateMode={updateMode}
                onWatch={this.setForm1Data}
                defaultValues={this.pagedata.form1.defaultValues}
                onViewRecipients={() => {
                  dispatch({
                    type: MODAL_OPEN,
                    payload: {
                      screen: MODAL_SCREEN_TM_UPDATE_RECIPIENTS,
                      value: data
                    }
                  })
                }}
              />
              <MessageBox
                language={LANGUAGE_EN}
                onWatch={this.setMessageData}
                defaultValues={this.pagedata.form2.defaultValues[LANGUAGE_EN]}
              />
              <MessageBox
                language={LANGUAGE_DE}
                onWatch={this.setMessageData}
                defaultValues={this.pagedata.form2.defaultValues[LANGUAGE_DE]}
              />
              <MessageBox
                language={LANGUAGE_SV}
                onWatch={this.setMessageData}
                defaultValues={this.pagedata.form2.defaultValues[LANGUAGE_SV]}
              />
              <MessageBox
                language={LANGUAGE_FI}
                onWatch={this.setMessageData}
                defaultValues={this.pagedata.form2.defaultValues[LANGUAGE_FI]}
              />
              <Box ghost>
                <Button
                  onClick={this.onSubmit}
                  title={translate("save")}
                  type={BUTTON_TYPES.GRAY}
                />
              </Box>
              {modal.open && modal.screen === MODAL_SCREEN_TM_UPDATE_RECIPIENTS && (
                <RecipientsModal
                  value={modal.value}
                  onClose={() => {
                    dispatch({ type: MODAL_CLOSE })
                  }}
                />
              )}
            </Boxlist>
          )}
        </main>
      </>
    )
  }
}

export { TransactionalMessageUpsert }
