import { translate } from "helpers/i18n"
import { Icons } from "icons"
import { Checkbox, Tag, TAG_TYPES, List } from "components/Ui"
import { Input } from "components/Input"
import { Button, BUTTON_TYPES } from "components/Button"

import "./index.scss"

export const useTableHook = props => {
  const reference = React.useRef()
  // Outside triggerers which will cause onRefresh to apply
  const refreshers = props.refreshers || []
  const tableDefaults = props.tableDefaults || {}

  const take = 10

  // Internal memory of Table
  const [checked, setChecked] = React.useState([])
  const [sort, setSort] = React.useState({})
  const [limit, setLimit] = React.useState({
    skip: 0,
    take: take,
  })
  const [inlineSearch, setInlineSearch] = React.useState(tableDefaults)
  const [globalSearch, setGlobalSearch] = React.useState("")
  const [actionsIndex, setActionsIndex] = React.useState(null)
  const [groupBy, setGroupBy] = React.useState([])

  const getNormalizedInlineSearch = () => {
    const search = { ...inlineSearch }

    Object.keys(search).forEach(attr => {
      const column = props.columns.find(c => c.attr === attr)

      if (column?.interceptor) {
        search[attr] = column.interceptor.apply(this, [search[attr]])
      }
    })

    return search
  }

  const onRefresh = () => {
    props.onRefresh && props.onRefresh.apply(this, [{
      limit: { ...limit },
      inlineSearch: getNormalizedInlineSearch(),
      globalSearch: globalSearch,
      sort: { ...sort },
      custom: props.custom || {},
    }])
  }

  React.useLayoutEffect(() => {
    onRefresh.call(this)
  }, [sort, limit, inlineSearch, globalSearch, ...refreshers])

  React.useLayoutEffect(() => {
    if (reference.current) {
      reference.current.scrollTop = 100000
    }
  }, [props.rows])

  return {
    rows: props.rows,
    columns: props.columns,
    loading: props.loading,
    reference: reference,
    checked,
    globalSearch,
    inlineSearch,
    groupBy,
    onCheck: (row, isUncheck) => {
      setChecked(prev => isUncheck ? prev.filter(id => id !== row.id) : [row.id, ...prev])
    },
    onCheckAll: rows => {
      setChecked(checked.length === 0 ? rows.map(row => row.id) : [])
    },
    onCheckGroup: (rows, isUncheck) => {
      setChecked(prev => isUncheck ?
        prev.filter(id => !rows.some(r => r.id === id)) :
        [...prev.filter(id => !rows.some(r => r.id === id)), ...rows.map(row => row.id)]
    )},
    sort,
    onSort: attr => {
      setSort(prev => prev.attr === attr ? {} : { attr })
    },
    onGroup: attr => {
      setGroupBy(prev => prev.includes(attr) ? [] : [attr])
    },
    limit,
    onLoadMore: () => {
      setLimit(prev => ({ ...prev, take: prev.take + take }))
    },
    onInlineSearch: (text, attr, arrayIndex = null) => {
      setInlineSearch(prev => {
        const clone = { ...prev }
        if (!text) {
          delete clone[attr]
          return { ...clone}
        }

        // Some items such as ranged date pickers need at least two values attached to an attr.
        if (arrayIndex !== null) {
          const cloneAttr = clone[attr] || []
          cloneAttr[arrayIndex] = text
          return { ...prev, [attr]: cloneAttr }
        }

        return { ...prev, [attr]: text }
      })
    },
    onGlobalSearch: text => {
      setGlobalSearch(text)
    },
    actionsIndex,
    onActionsClick: index => {
      setActionsIndex(prev => prev === index ? null : index)
    }
  }
}

export class Table extends React.Component {

  static propTypes = {
    loading: PropTypes.bool,
    reference: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string,
    tableEmptyMessage: PropTypes.string,
    compact: PropTypes.bool,
    semiCompact: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      attr: PropTypes.string,
      sortable: PropTypes.bool,
      groupable: PropTypes.bool,
      searchable: PropTypes.bool,
      searchNode: PropTypes.func,
      tagTransform: PropTypes.func,
    })),
    rows: PropTypes.arrayOf(PropTypes.any),
    columnRender: PropTypes.objectOf(PropTypes.func),
    checked: PropTypes.arrayOf(PropTypes.string),
    limit: PropTypes.shape({
      skip: PropTypes.number,
      take: PropTypes.number,
    }),
    groupBy: PropTypes.arrayOf(PropTypes.string),
    actionsIndex: PropTypes.any,
    actions: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      isRed: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
      action: PropTypes.func,
      massActionDisabled: PropTypes.bool,
    })),
    globalSearch: PropTypes.string,
    inlineSearch: PropTypes.any,
    buttons: PropTypes.node,
    tags: PropTypes.arrayOf(PropTypes.node),
    sort: PropTypes.shape({ attr: PropTypes.string }),
    onLoadMore: PropTypes.func,
    onSort: PropTypes.func,
    onGroup: PropTypes.func,
    onCheck: PropTypes.func,
    onCheckAll: PropTypes.func,
    onInlineSearch: PropTypes.func,
    onGlobalSearch: PropTypes.func,
    onActionsClick: PropTypes.func,
    noHead: PropTypes.bool,
    noGlobalSearch: PropTypes.bool,
    noFilters: PropTypes.bool,
    noFoot: PropTypes.bool,
    noCheckbox: PropTypes.bool,
  }

  static defaultProps = {
    loading: false,
    reference: null,
    title: "",
    description: "",
    tableEmptyMessage: "",
    compact: false,
    semiCompact: false,
    columns: [],
    rows: [],
    columnRender: {},
    checked: [],
    limit: {
      skip: 0,
      take: 10,
    },
    groupBy: [],
    actionsIndex: null,
    actions: [],
    globalSearch: "",
    inlineSearch: {},
    buttons: null,
    tags: [],
    sort: {},
    onLoadMore: null,
    onSort: null,
    onGroup: null,
    onCheck: null,
    onCheckAll: null,
    onInlineSearch: null,
    onGlobalSearch: null,
    onActionsClick: null,
    noHead: false,
    noGlobalSearch: false,
    noFilters: false,
    noFoot: false,
    noCheckbox: false,
  }

  renderTagsRow() {
    const {
      globalSearch,
      inlineSearch,
      tags,
      columns,
      noFilters,
      onGlobalSearch,
      onInlineSearch,
    } = this.props

    if (noFilters) {
      return null
    }

    const inlineSearchKeys = Object.keys(inlineSearch)
    const hasAtLeastOneTag = globalSearch || inlineSearchKeys.length > 0 || tags.length > 0

    return (
      <div className="table-row tags-row no-hover">
        <List hasVerticalMargin>
          <Tag
            title={hasAtLeastOneTag ? translate("filters") : translate("no-filters")}
            type={TAG_TYPES.SECONDARY}
            leftIcon={hasAtLeastOneTag ? <Icons.Filter /> : null}
            compact
          />
          {globalSearch && (
            <Tag
              title={translate("searching-text", {
                attr: globalSearch
              })}
              type={TAG_TYPES.PRIMARY}
              icon={
                <Icons.Close
                  onClick={() => {
                    onGlobalSearch && onGlobalSearch.apply(this, [""])
                  }}
                />
              }
              compact
            />
          )}
          {inlineSearchKeys.map(key => {
            const title = columns.find(c => c.attr === key)?.title
            let tagValue = inlineSearch[key]
            const tagTransformer = columns.find(c => c.attr === key)?.tagTransform

            if (tagTransformer) {
              tagValue = tagTransformer.apply(this, [inlineSearch[key]])
            }

            return (
              <Tag
                key={key}
                title={`${title}: ${tagValue}`}
                type={TAG_TYPES.PRIMARY}
                icon={
                  <Icons.Close
                    onClick={() => {
                      onInlineSearch && onInlineSearch.apply(this, [null, key])
                    }}
                  />
                }
                compact
              />
            )
          })}
          {tags}
        </List>
      </div>
    )
  }

  renderSearchables() {
    const {
      columns,
      rows,
      checked,
      actionsIndex,
      actions,
      noCheckbox,
      inlineSearch,
      onActionsClick,
      onInlineSearch,
    } = this.props

    if (columns.some(column => column.searchable) === false) {
      return null
    }

    return (
      <div className="table-row no-hover">
        {!noCheckbox && (
          <div key={"check-all"} className="table-cell has-checkbox">

          </div>
        )}
        {columns.map((column, i) => {
          let res = null

          if (column.searchable) {
            if (!column.searchNode) {
              res = (
                <Input
                  placeholder={column.title}
                  onChange={text => onInlineSearch && onInlineSearch.apply(this, [text, column.attr])}
                />
              )
            } else {
              res = column.searchNode.apply(this, [onInlineSearch, inlineSearch[column.attr] ])
            }
          }

          return (
            <div
              key={column.title}
              className={classnames("table-cell", {
                "has-searchNode": !!column.searchNode
              })}
              data-attr={column.attr}
            >
              {res}
            </div>
          )
        })}
        {actions.length > 0 && (
          <div key={"actions"} className="table-cell has-actions">
            {checked.length > 0 && (
              <>
                <Icons.More onClick={() => {
                  onActionsClick && onActionsClick.apply(this, [-1])
                }}/>
                {actionsIndex === -1 && (
                  <div className="table-actions-dropdown">
                    <ul>
                      {actions.filter(action => !action.massActionDisabled).map(action => (
                        <li
                          className={classnames({
                            "is-red": action.isRed
                          })}
                          key={action.title}
                          onClick={() => {
                            action.action && action.action.apply(this, [
                              rows.filter(row => checked.includes(row.id)),
                              -1
                            ])
                            onActionsClick && onActionsClick.apply(this, [null])
                          }}
                        >
                          {action.icon}
                          <div className="title-holder">
                            {action.title}
                            <span>{translate("with-selected-n-items", { attr: checked.length })}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    )
  }

  renderHead() {
    const {
      loading,
      reference,
      title,
      description,
      columns,
      buttons,
      rows,
      groupBy,
      sort,
      onSort,
      onGroup,
      checked,
      actionsIndex,
      actions,
      onCheckAll,
      onActionsClick,
      onInlineSearch,
      onGlobalSearch,
      noHead,
      noCheckbox,
      noGlobalSearch,
      globalSearch,
    } = this.props

    return (
      <>
        {!noHead && title && (
          <div className="table-row title-row no-hover">
            <h1>{title}</h1>
            {description && <p className="body-l-400">{description}</p>}
          </div>
        )}
        {!noHead && (
          <div className="table-row settings-row no-hover">
            <Input
              value={globalSearch}
              placeholder={translate("search")}
              onChange={text => onGlobalSearch && onGlobalSearch.apply(this, [text])}
              leftIcon={<Icons.Search />}
              className={classnames({
                "is-invisible": noGlobalSearch
              })}
            />
            {buttons && (
              <div className="buttons-area-col">
                {buttons}
              </div>
            )}
          </div>
        )}

        {!noHead && this.renderTagsRow()}

        <div className={classnames("table-scrollable-overlay", {
          loading: loading && rows.length > 0
        })}>
          {loading && rows.length > 0 && (
            <div className="loading-box-container">
              <Icons.BoxIcon2 className="loading-box" />
            </div>
          )}
          <div ref={reference} className={classnames("table-scrollable-content")}>
            <div className="table-row header-row no-hover">
              {!noCheckbox && (
                <div key={"check-all"} className="table-cell has-checkbox">
                  <Checkbox
                    checked={checked.length === rows.length && rows.length > 0}
                    halfChecked={checked.length > 0 && checked.length < rows.length}
                    onClick={() => onCheckAll && onCheckAll.apply(this, [rows])}
                  />
                </div>
              )}
              {columns.map((column, i) => {
                return (
                  <div
                    key={column.title}
                    className={classnames("table-cell", {
                      active: sort.attr === column.attr
                    })}
                    data-attr={column.attr}
                  >
                    {column.title}
                    <div className="table-cell-buttons">
                      {column.sortable && (
                        <div
                          className="table-cell-button"
                          onClick={() => {
                            onSort && onSort.apply(this, [column.attr])
                          }}
                        >
                          {sort.attr === column.attr ? <Icons.ArrowDown /> : <Icons.Dropdown />}
                        </div>
                       )}
                      {column.groupable && (
                        <div
                          className={classnames("table-cell-button", "has-grouping", {
                            "has-active-grouping": groupBy.includes(column.attr),
                          })}
                          onClick={() => {
                            onGroup && onGroup.apply(this, [column.attr])
                          }}
                        >
                          <Icons.Star />
                        </div>
                       )}
                    </div>
                  </div>
                )
              })}
              {actions.length > 0 && (
                <div key={"actions"} className="table-cell has-actions">
                  {translate("actions")}
                </div>
              )}
            </div>
            {this.renderSearchables()}
            {this.renderRows()}
          </div>
        </div>
      </>
    )
  }

  renderRows() {
    const {
      loading,
      columns,
      rows,
      columnRender,
      onCheck,
      groupBy,
      checked,
      tableEmptyMessage,
      actions,
      actionsIndex,
      onActionsClick,
      onCheckGroup,
      noCheckbox,
    } = this.props

    if (loading && rows.length === 0) {
      return (
        <div key={"loading"} className={classnames("table-row", "main-row", "no-hover", "loading")}>
          <Icons.BoxIcon2 className="loading-box" />
        </div>
      )
    }

    if (rows.length === 0) {
      return (
        <div key={"no-results"} className={classnames("table-row", "main-row", "no-hover", "no-results")}>
          {tableEmptyMessage && <h4 className="table-empty-message">{tableEmptyMessage}</h4>}
          {!tableEmptyMessage && <h4 className="table-empty-message">{translate("no-results-found")}</h4>}
        </div>
      )
    }

    const renderGroup = {}

    if (groupBy.length > 0) {
      rows.forEach(row => {
        renderGroup[row[groupBy[0]]] = (
          renderGroup[row[groupBy[0]]] ? [row, ...renderGroup[row[groupBy[0]]]] : [row]
        )
      })
    } else {
      renderGroup.main = rows
    }

    return Object.keys(renderGroup).map(key => {
      const res = renderGroup[key].map((row, i) => {
        const isChecked = checked.includes(row.id)
        return (
          <div key={`row-${i}`} className={classnames("table-row", "main-row", {
            "is-checked-row": isChecked,
          })}>
            {!noCheckbox && (
              <div key={"checkbox"} className="table-cell has-checkbox">
                <Checkbox
                  checked={isChecked}
                  onClick={() => {
                    onCheck && onCheck.apply(this, [row, isChecked])
                  }}
                />
              </div>
            )}
            {columns.map((column, j) => {
              const isFunc = columnRender.hasOwnProperty(column.attr)
              return (
                <div key={`col-${j}`} className="table-cell" data-attr={column.attr}>
                  {!isFunc ? row[column.attr] : columnRender[column.attr].apply(this, [row, i])}
                </div>
              )
            })}
            {actions.length > 0 && (
              <div key={"actions"} className="table-cell has-actions">
                <Icons.More onClick={() => {
                  onActionsClick && onActionsClick.apply(this, [i, row])
                }}/>
                {i === actionsIndex && (
                  <div className="table-actions-dropdown">
                    <ul>
                      {actions.map(action => (
                        <li
                          className={classnames({
                            "is-red": typeof action.isRed === "function" ? action.isRed.apply(this, [[row], i]) : action.isRed
                          })}
                          key={typeof action.title === "function" ? action.title.apply(this, [[row], i]) : action.title}
                          onClick={() => {
                            action.action && action.action.apply(this, [[row], i])
                            onActionsClick && onActionsClick.apply(this, [null])
                          }}
                        >
                          {typeof action.icon === "function" ? action.icon.apply(this, [[row], i]) : action.icon}
                          <div className="title-holder">
                            {typeof action.title === "function" ? action.title.apply(this, [[row], i]) : action.title}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )
      })

      if (groupBy.length === 0) {
        return (
          <div key={"main-group"} className="table-group">
            {res}
          </div>
        )
      }

      const isChecked = renderGroup[key].every(r => checked.includes(r.id))
      const isHalfChecked = renderGroup[key].some(r => checked.includes(r.id))

      return (
        <div key={key} className="table-group">
          <div className="group-header">
            <h3>{columns.find(c => c.attr === groupBy[0])?.title}: {key}</h3>
            {!noCheckbox && (
              <Checkbox
                title={<p className="body-m-400">{translate("select-all")}</p>}
                checked={isChecked}
                halfChecked={isHalfChecked}
                onClick={() => onCheckGroup && onCheckGroup.apply(this, [renderGroup[key], isChecked])}
              />
            )}
          </div>
          {res}
        </div>
      )
    })
  }

  renderFoot() {
    const { limit, onLoadMore, rows } = this.props
    const isDisabled = rows.length === 0

    if (isDisabled) {
      return null
    }

    return (
      <div className="table-foot">
        <Button
          type={BUTTON_TYPES.TERTIARY}
          title={isDisabled ? translate("end-of-the-list") : translate("load-more")}
          disabled={isDisabled}
          onClick={() => onLoadMore && onLoadMore.apply(this, [])}
          hasSmallRadius
        />
      </div>
    )
  }

  render () {
    const { compact, semiCompact, noFoot } = this.props

    return (
      <section className={classnames("com-Table", { compact, semiCompact })}>
        {this.renderHead()}
        {!noFoot && this.renderFoot()}
      </section>
    )
  }
}
