import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { PageHeader } from "components/PageHeader"
import { Button, BUTTON_TYPES } from "components/Button"
import { Breadcrumbs, Tag, TAG_TYPES } from "components/Ui"

import {
  CUSTOMER_RECEIVE_REQUEST,
  MODAL_OPEN,
  MODAL_SCREEN_ADD_CUSTOMER,
  MODAL_SCREEN_UPDATE_CUSTOMER,
  MODAL_SCREEN_REMOVE_CUSTOMER,
} from "constants"

import "./index.scss"

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    columns={[
      { title: "Customer ID", attr: "customer_id" },
      { title: "Name", attr: "name" },
      { title: "Email", attr: "email" },
      { title: "Registry number", attr: "registry_no" },
    ]}
    actions={[
      {
        title: translate("edit"),
        icon: <Icons.Edit />,
        massActionDisabled: true,
        action: props.onEdit,
      },
      {
        title: translate("delete"),
        isRed: true,
        massActionDisabled: true,
        icon: <Icons.Delete />,
        action: props.onDelete,
      }
    ]}
    buttons={
      <>
        <Button
          type={BUTTON_TYPES.GRAY}
          icon={<Icons.Plus />}
          onClick={props.onAdd}
          hasSmallRadius
        />
      </>
    }
    noCheckbox
    noFilters
  />
)

@withRouter
@connect((state) => ({
  customer: state.customer,
}))
class CustomerListing extends React.Component {

  render() {
    const { customer: { customers, loading }, dispatch, history } = this.props

    return (
      <>
        <PageHeader
          prefix={"Review and manage all the customers"}
          title={"Customers"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "customers",
                },
              ]}
              activeIndex={1}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="customer-listing-screen">
          <Boxlist>
            <Box noPadding>
              <TableRegion
                loading={loading}
                rows={customers}
                onRefresh={qs => {
                  dispatch({
                    type: CUSTOMER_RECEIVE_REQUEST,
                    payload: {
                      qs
                    }
                  })
                }}
                onAdd={() => {
                  dispatch({
                    type: MODAL_OPEN,
                    payload: {
                      screen: MODAL_SCREEN_ADD_CUSTOMER,
                    }
                  })
                }}
                onEdit={rows => {
                  dispatch({
                    type: MODAL_OPEN,
                    payload: {
                      screen: MODAL_SCREEN_UPDATE_CUSTOMER,
                      value: rows[0],
                    }
                  })
                }}
                onDelete={rows => {
                  dispatch({
                    type: MODAL_OPEN,
                    payload: {
                      screen: MODAL_SCREEN_REMOVE_CUSTOMER,
                      value: rows[0],
                    }
                  })
                }}
              />
            </Box>
          </Boxlist>
        </main>
      </>
    )
  }
}

export { CustomerListing }
