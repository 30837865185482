import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Form } from "components/Form"
import { Checkbox } from "components/Ui"

export const ProServicesArea = props => {
  return (
    <>
      <Box>
        <h2>PRO-services</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              checkbox1: false,
              checkbox2: false,
              checkbox3: false,
              checkbox4: false,
              checkbox5: false,
            }
          }}
          render={[
            {
              name: "checkbox1",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Stop DHL orders to DHL's remote area</p>
                      <p className="caption-s-400 soft">All "remote area" orders from the store via the API will be stopped and put into the RESERVED state. They need to be manually changed into the NEW state by the merchant before shipping. 1.00 € / stopped shipment.</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox2",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Fragile shipment packing</p>
                      <p className="caption-s-400 soft">If the package contains fragile goods, the shipment will be packed more carefully. 0.25 € / shipment.</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox3",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Plastic free shipment</p>
                      <p className="caption-s-400 soft">Plastic free (paper tape, only paper filling) and "Plastic free shipment" add-in.</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox4",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Disable myOGO documentation for shipments</p>
                      <p className="caption-s-400 soft">Disable the creation of invoices or proforma invoices for outgoing shipments.</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox5",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Priority fulfillment</p>
                      <p className="caption-s-400 soft">Priority fulfillment orders are picked and packed first. The fulfillment will be made during the same day if the order is registered before 14:00. 0.90 € / shipment.</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
    </>
  )
}