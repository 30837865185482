import ScaleLoader from "react-spinners/ScaleLoader"

class ScaleLoaderComponent extends React.Component {

  static propTypes = {
  }

  static defaultProps = {
  }

  render () {
    return (
      <ScaleLoader />
    )
  }
}

export {
  ScaleLoaderComponent as ScaleLoader
}