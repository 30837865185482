import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Button, BUTTON_TYPES } from "components/Button"
import { PageHeader } from "components/PageHeader"
import { Breadcrumbs } from "components/Ui"

import { TabsArea } from "./TabsArea"
import { CompanyInformationArea } from "./CompanyInformationArea"
import { SenderInformationArea } from "./SenderInformationArea"
import { InvoicingInformationArea } from "./InvoicingInformationArea"
import { ConnectedUsersArea } from "./ConnectedUsersArea"
import { IntegrationsArea } from "./IntegrationsArea"
import { ContactPersonArea } from "./ContactPersonArea"
import { ShippingMethodsArea } from "./ShippingMethodsArea"
import { OrderProcessingArea } from "./OrderProcessingArea"
import { ProServicesArea } from "./ProServicesArea"
import { MerchantSettingsArea } from "./MerchantSettingsArea"
import { MerchantHistoryArea } from "./MerchantHistoryArea"
import { CommercialSettingsArea } from "./CommercialSettingsArea"

import {
  MODAL_OPEN,
  MODAL_SCREEN_ACTIVATE_SHIPPING_METHOD,
} from "constants"

const shippingImage = require("/assets/images/ogoship/choose-shipping-method.svg")
const integrationsImage = require("/assets/images/ogoship/setup-integrations.svg")

import "./index.scss"

const SHIPPING_METHODS = [
  {
    name: "Budbee - Home",
    logo: "https://my.ogoship.com/Images/Budbee_logo.png",
    description: "Budbee home delivery after work hours",
    activated: false
  },
  {
    name: "DHL Express Worldwide",
    logo: "https://my.ogoship.com/Images/DHL_logo.png",
    description: "Door-to-door shipments worldwide, usually 1 day shipping time.",
    activated: false
  },
  {
    name: "Omniva - International standard parcel",
    logo: "https://my.ogoship.com/Images/Omniva_logo.png",
    description: "This is a standard tracked international postal parcel.",
    activated: false
  },
  {
    name: "Posti",
    logo: "https://my.ogoship.com/Images/Posti_logo.png",
    description: "Postal EMS service that is the courier services offered by postal companies globally.",
    activated: false
  },
]

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  merchant: state.merchant,
}))
class MerchantEdit extends React.Component {

  state = {
    activeTab: 0,
    shippingMethods: SHIPPING_METHODS
  }

  openAddShippingMethodsModal = () => {
    const { dispatch } = this.props
    const { shippingMethods } = this.state

    dispatch({
      type: MODAL_OPEN,
      payload: {
        screen: MODAL_SCREEN_ACTIVATE_SHIPPING_METHOD,
        value: SHIPPING_METHODS,
        onClose: shippingMethods => {
          this.setState({
            shippingMethods
          })
        }
      }
    })
  }

  // @todo inactive on new designs
  renderSideTab() {
    const { activeTab } = this.state

    return (
      <>
        {activeTab >= 4 && (
          <Box className="side-tab" noPadding>
            <img src={shippingImage} />
            <hr />
            <section className="inner-content">
              <h4>Choose shipping methods.</h4>
              <p>Before starting with stock update.</p>
              <Button
                title="Set up shipping"
                type={BUTTON_TYPES.GRAY}
              />
            </section>
          </Box>
        )}
        <Box className="side-tab" noPadding>
          <img src={integrationsImage} />
          <hr />
          <section className="inner-content">
            <h4>Welcome to myOGO.</h4>
            <p>Please, fill your company information</p>
            <Button
              title="Provide info"
              type={BUTTON_TYPES.GRAY}
            />
          </section>
        </Box>
        {activeTab === 0 && (
          <Box>
            <h2>Staff Links</h2>
            <hr />

            <Button
              title="Shipment Portal"
              type={BUTTON_TYPES.TERTIARY}
            />
          </Box>
        )}
        {activeTab === 0 && (
          <Box>
            <h2>History</h2>
            <hr />

            <p>History 1</p>
            <p>History 2</p>
            <p>History 3</p>
            <p>History 4</p>
            <p>History 5</p>
            <p>History 6</p>
          </Box>
        )}
      </>
    )
  }

  renderActiveTab() {
    const { merchant: { loading, connectedUsers, discounts }, dispatch } = this.props
    const { activeTab, shippingMethods } = this.state

    switch (activeTab) {
      case 0:
        return <CompanyInformationArea />

      case 1:
        return <SenderInformationArea />

      case 2:
        return <InvoicingInformationArea />

      case 3:
        return (
          <ConnectedUsersArea
            dispatch={dispatch}
            loading={loading}
            rows={connectedUsers}
          />
        )

      case 4:
        return <IntegrationsArea />

      case 5:
        return <ContactPersonArea />

      case 6:
        return (
          <ShippingMethodsArea
            shippingMethods={shippingMethods.filter(method => method.activated)}
            onDeactivateClick={index => {
              this.setState({
                shippingMethods: shippingMethods.filter((_, i) => i !== index)
              })
            }}
            onAddNewClick={this.openAddShippingMethodsModal}
          />
        )

      case 7:
        return (
          <OrderProcessingArea />
        )

      case 8:
        return (
          <ProServicesArea />
        )

      case 9:
        return (
          <MerchantSettingsArea
            onSave={data => {
              console.log(data)
            }}
          />
        )

      case 10:
        return (
          <MerchantHistoryArea />
        )

      case 11:
        return (
          <CommercialSettingsArea
            dispatch={dispatch}
            loading={loading}
            rows={discounts}
          />
        )

      default:
        return (
          <Box>
            <p>This feature is not enabled yet.</p>
          </Box>
        )
    }
  }

  render() {
    const { app: { activeMerchant }, history } = this.props
    const { activeTab } = this.state

    return (
      <>
        <PageHeader
          prefix={"Edit Merchant"}
          title={activeMerchant.name}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "merchants",
                  onClick: () => {
                    history.push("/merchants")
                  }
                },
                {
                  title: activeMerchant.name,
                },
              ]}
              activeIndex={2}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="merchant-edit-screen">
          <Boxlist>
            <Box noPadding>
              <TabsArea
                activeTab={activeTab}
                onClick={(_, activeTab) => {
                  this.setState({ activeTab })
                }}
              />
            </Box>
          </Boxlist>
          <Boxlist>
            {this.renderActiveTab()}
          </Boxlist>
        </main>
      </>
    )
  }
}

export { MerchantEdit }
