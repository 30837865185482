import { translate } from "helpers/i18n"
import { Footer as FooterComponent } from "components/Layout/Footer"

@connect((state) => ({
  app: state.app,
  auth: state.auth,
}))
class Footer extends React.PureComponent {

  render() {
    return (
      <FooterComponent
        name="MyOGO 2.0"
        links={[
          { title: translate("conditions-of-use"), to: "https://my.ogoship.com/go/terms-conditions" },
          { title: translate("privacy-policy"), to: "https://www.ogoship.com/privacypolicy" },
        ]}
      />
    )
  }
}

export { Footer }