import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"

import {
  SUPPLIER_RECEIVE_REQUEST,
  SUPPLIER_RECEIVE_SUCCESS,
  SUPPLIER_RECEIVE_FAIL,
} from "constants"

import { fakeSuppliersBackend } from "mock"

function* receive(action) {
  try {
    const data = fakeSuppliersBackend.update(action.payload?.qs).getJson()

    yield delay(1500)

    yield put({
      type: SUPPLIER_RECEIVE_SUCCESS,
      payload: {
        suppliers: data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: SUPPLIER_RECEIVE_FAIL })
  }
}

function* supplierReceiveSaga() {
  yield takeLatest(SUPPLIER_RECEIVE_REQUEST, receive)
}

export function* supplier() {
  yield all([
    call(supplierReceiveSaga),
  ])
}
