import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { PageHeader } from "components/PageHeader"
import { Button, BUTTON_TYPES } from "components/Button"
import { Breadcrumbs, Tag, TAG_TYPES } from "components/Ui"

import { TabsSection } from "./TabsSection"
import { EditorSection } from "./EditorSection"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
}))
class TemplateEditor extends React.Component {

  state = {
    activeTab: 0
  }

  renderTabContent() {
    const { activeTab } = this.state

    switch (activeTab) {
      case 0:
        return (
          <EditorSection />
        )

      case 1:
        return (
          <Box>
            <p>Preview</p>
          </Box>
        )

      default:
        return (
          <Box>
            <h2>Unsupported tab</h2>
            <hr />
          </Box>
        )
    }

  }

  render() {
    const { dispatch, history } = this.props
    const { activeTab } = this.state

    return (
      <>
        <PageHeader
          prefix={"Manage your templates"}
          title={"Template Editor"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "template editor",
                },
              ]}
              activeIndex={1}
            />
          }
        />
        <main className="template-editor-screen">
          <Boxlist>
            <Box noPadding>
              <TabsSection
                activeTab={activeTab}
                onClick={(_, activeTab) => {
                  this.setState({ activeTab })
                }}
              />
            </Box>
          </Boxlist>
          <Boxlist>
            {this.renderTabContent()}
          </Boxlist>
        </main>
      </>
    )
  }
}

export { TemplateEditor }
