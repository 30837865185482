import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Checkbox } from "components/Ui"

export const ShipmentArea = props => {
  const [checked, setChecked] = React.useState(false)

  return (
    <>
      <Box>
        <h2>Shipment</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              company_name: "",
              reason: "",
              actions: false,
            }
          }}
          rules={{
            company_name: Rules.string().required(),
            reason: Rules.string().required(),
          }}
          render={[
            {
              label: "Shipping company name",
              name: "company_name",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "",
              name: "actions",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title="Stock update needs special actions"
                  checked={checked}
                  onClick={() => {
                    setChecked(!checked)
                  }}
                />
              )
            },
            {
              label: "Reason",
              name: "reason",
              condition: () => {
                return checked
              },
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
    </>
  )
}