import "./index.scss"

export const BUTTON_TYPES = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  TERTIARY: "TERTIARY",
  ORANGE: "ORANGE",
  YELLOW: "YELLOW",
  RED: "RED",
  BLUE: "BLUE",
  GRAY: "GRAY",
  LINK: "LINK",
}

export class Button extends React.Component {

  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.string,
    leftIcon: PropTypes.node,
    icon: PropTypes.node,
    isDisabled: PropTypes.bool,
    isHovered: PropTypes.bool,
    isActive: PropTypes.bool,
    isCompact: PropTypes.bool,
    hasSmallRadius: PropTypes.bool,
    form: PropTypes.string,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    title: "",
    type: BUTTON_TYPES.TYPE_PRIMARY,
    leftIcon: null,
    icon: null,
    isDisabled: false,
    isHovered: false,
    isActive: false,
    isCompact: false,
    hasSmallRadius: false,
    form: "",
    onClick: null,
  }

  render () {
    const {
      title,
      type,
      leftIcon,
      icon,
      isDisabled,
      isHovered,
      isActive,
      isCompact,
      hasSmallRadius,
      form,
      onClick,
    } = this.props

    return (
      <button
        onClick={onClick}
        className={classnames("com-Button", type, {
          "disabled": isDisabled,
          "hovered": isHovered,
          "active": isActive,
          "compact": isCompact,
          "has-title": title,
          "has-small-radius": hasSmallRadius,
        })}
        form={form}
        disabled={isDisabled}
      >
        {leftIcon}
        {title && <span>{title}</span>}
        {icon}
      </button>
    )
  }
}