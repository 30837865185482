import { translate, languages } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Button, BUTTON_TYPES } from "components/Button"

import { LANGUAGE_EN, LANGUAGE_LIST } from "constants"

import "./index.scss"

const resources = require("assets/dump/resources.json")

export const TranslationsManager = props => {
  const [showOld, setShowOld] = React.useState(false)
  const [problems, setProblems] = React.useState(0)

  const allKeys = Object.keys(languages[LANGUAGE_EN]).filter(key => key !== "__no-comma")

  const resourceFilter = l1 => ![
    "testtest",
    "Tutorial"
  ].includes(l1.Key)

  const ignoreErrors = {
    "amountEUR": true,
    "amountGBP": true,
    "amountSEK": true,
    "dateformat": true,
    "EUR": true,
    "GBP": true,
    "SEK": true,
    "USD": true,
  }

  let count = 0
  let success = 0
  const green = []

  setTimeout(() => {
    console.log("Amount", count)
    console.log("Failed", count - success)
    console.log("Green", green)

    console.log("--------------")
    console.log("Issues to be fixed")
    console.log("--------------")

    console.log(`○ "USD" exists, amountUSD does not exist. Needs to be added.`)
  }, 1000)

  const generateClassnames = (val, resourceIndex) => {
    const res = {}

    if (ignoreErrors[val.Key]) {
      res["error-ignored"] = true
      return res
    }

    if (val.ResourceValues[resourceIndex].Value === " ") {
      res["value-almost-null"] = true
      return res
    }

    if (resourceIndex !== 0) {
      if (
        (val.ResourceValues[0].Value === val.ResourceValues[resourceIndex].Value) ||
        (!val.ResourceValues[resourceIndex].Value)
      ) {
        res["requires-translation"] = true
        return res
      }
    } else {
      if (!val.ResourceValues[0].Value) {
        res["requires-translation"] = true
        return res
      }
    }

    if (
      val.ResourceValues[0].Value &&
      (val.ResourceValues[1].Value && val.ResourceValues[1].Value !== val.ResourceValues[0].Value) &&
      (val.ResourceValues[2].Value && val.ResourceValues[2].Value !== val.ResourceValues[0].Value) &&
      (val.ResourceValues[3].Value && val.ResourceValues[3].Value !== val.ResourceValues[0].Value)
    ) {
      res["show-green"] = true
      success += 1
      green.push(val.Key)
      return res
    }

    success += 1
    return res
  }

  return (
    <Boxlist>
      <Box className="admin-translations-manager">
        <h1>MyOGO 2.0 Translations</h1>

        <div className="translations-row header-row">
          <div className="translations-column">Attr</div>
          <div className="translations-column">English</div>
          <div className="translations-column">Deutsch</div>
          <div className="translations-column">Swedish</div>
          <div className="translations-column">Finnish</div>
        </div>

        {allKeys.map(key => (
          <div key={key} className="translations-row">
            <div key={key} className="translations-column">{key}</div>
            {LANGUAGE_LIST.map(lang => (
              <div
                key={lang}
                className={classnames("translations-column", {
                  "requires-translation": lang !== LANGUAGE_EN && languages[lang][key] === languages[LANGUAGE_EN][key]
                })}
              >
                <input defaultValue={languages[lang][key]} />
              </div>
            ))}
          </div>
        ))}

        {showOld && (
          <Button
            title="Hide old translations"
            type={BUTTON_TYPES.TERTIARY}
            onClick={() => setShowOld(false)}
          />
        )}
        {showOld && <h1 className="has-top-margin">Translations on old app</h1>}
        {showOld && resources.filter(resourceFilter).map(l1 => {

          const content = l1.Value.map(val => {
            count += 4
            return (
              <div key={val.Key} className="translations-row">
                <div className="translations-column">{val.Key}</div>
                <div className={classnames("translations-column", generateClassnames(val, 0))}>
                  <input defaultValue={val.ResourceValues[0].Value} />
                </div>
                <div className={classnames("translations-column", generateClassnames(val, 1))}>
                  <input defaultValue={val.ResourceValues[1].Value} />
                </div>
                <div className={classnames("translations-column", generateClassnames(val, 2))}>
                  <input defaultValue={val.ResourceValues[2].Value} />
                </div>
                <div className={classnames("translations-column", generateClassnames(val, 3))}>
                  <input defaultValue={val.ResourceValues[3].Value} />
                </div>
              </div>
            )
          })
          return (
            <React.Fragment key={l1.Key}>
              <h2>{l1.Key}</h2>
              {content}
            </React.Fragment>
          )
        })}
        {!showOld && (
          <Button
            title="Show old translations (warning: can be slow)"
            type={BUTTON_TYPES.TERTIARY}
            onClick={() => setShowOld(true)}
          />
        )}
      </Box>
    </Boxlist>
  )
}