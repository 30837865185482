import { translate } from "helpers/i18n"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import "./index.scss"

const propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  rules: PropTypes.object,
  render: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    tip: PropTypes.string,
    condition: PropTypes.func,
    mandatory: PropTypes.bool,
    node: PropTypes.func,
  })),
  button: PropTypes.node,
  onSubmit: PropTypes.func,
  submitButtonLabel: PropTypes.string,
}

const defaultProps = {
  className: "",
  data: {},
  rules: null,
  render: [],
  button: null,
  onSubmit: null,
  submitButtonLabel: "_submitButton",
}

const Form = props => {
  const { id, className, data, rules, render, button, onWatch, onSubmit, submitButtonLabel } = props
  const { control, handleSubmit, getValues, watch, setValue } = useForm({
    ...data,
    resolver: yupResolver(rules ? yup.object(rules) : yup.object({}))
  })

  const watcher = watch()
  const additionalProps = {}

  if (id) {
    additionalProps.id = id
  }

  let onSubmitNormalized = onSubmit

  if (!onSubmit) {
    onSubmitNormalized = () => {}
  }

  React.useEffect(() => {
    const subscription = watch(value => {
      onWatch && onWatch.apply(this, [value])
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [watcher])

  return (
    <form
      onSubmit={handleSubmit(onSubmitNormalized)}
      className={classnames("com-Form", className)}
      { ...additionalProps }
    >
      {render.map((renderable, i) => {
        if (renderable.condition && renderable.condition.apply(this, [{
          values: getValues,
          watcher,
        }]) === false) {
          return null
        }

        return (
          <div className="form-line" key={i}>
            <Controller
              name={renderable.name}
              control={control}
              rules={renderable.rules}
              render={controller => (
                <>
                  {typeof renderable.label !== "undefined" && (
                    <label
                      className={classnames({
                        "has-error": controller.fieldState.error?.message
                      })}
                      data-tip={renderable.tip}
                    >
                      {renderable.label}{renderable.mandatory && <span>&nbsp;*</span>}
                    </label>
                  )}
                  {renderable.node.apply(this, [{
                    ...controller,
                    values: getValues,
                    watcher,
                    setValue,
                  }])}
                </>
              )}
            />
          </div>
        )
      })}

      <Controller
        name={submitButtonLabel}
        control={control}
        render={controller => {
          if (button) {
            return (
              <div className="submit-wrapper" onClick={handleSubmit(onSubmit)}>
                {button}
              </div>
            )
          }

          return null
        }}
      />
    </form>
  )
}

Form.propTypes = propTypes
Form.defaultProps = defaultProps

export { yup as Rules }
export { Form }