import { withRouter } from "react-router-dom"
import { translate, translateNode } from "helpers/i18n"

import { TwoFactor as TwoFactorComponent } from "components/Auth/TwoFactor"
import { Button, BUTTON_TYPES } from "components/Button"
import { CircularProgress } from "components/CircularProgress"

import {
  AUTH_2FA_CODE_LENGTH,
  AUTH_2FA_REQUEST,
  AUTH_2FA_CODE_ENTER_TIMEOUT_SECONDS,
} from "constants"

const CircularProgressArea = props => {
  let interval
  const aSecondInValue = (100 / AUTH_2FA_CODE_ENTER_TIMEOUT_SECONDS)
  const intervalSpeed = 100

  const [restarter, setRestarter] = React.useState(props.restarter)
  const [value, setValue] = React.useState(100)

  React.useEffect(() => {
    if (props.restarter !== restarter) {
      setValue(100)
    }

    interval = setInterval(() => {
      setValue(prev => prev - (aSecondInValue / 10))
    }, intervalSpeed)

    return () => {
      clearInterval(interval)
    }
  }, [props.restarter])

  const convertedToSecs = Math.round(value / aSecondInValue)

  return (
    <div className="progress-wrapper">
      <CircularProgress
        size={100}
        value={value - aSecondInValue}
        rotation={0}
        hideText
        strokeWidth={4}
        colors={{
          textColor: "var(--color-gray900)",
          pathColor: "var(--color-teal700)",
          trailColor: "var(--color-teal50)",
        }}
      >
        <h3>{convertedToSecs < 0 ? 0 : convertedToSecs.toFixed(0)} {translate("__todo19")}</h3>
      </CircularProgress>
    </div>
  )
}

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
}))
class TwoFactor extends React.Component {

  state = {
    resendIncrement: 0,
    code: ""
  }

  onResend = () => {
    const { resendIncrement } = this.state

    this.setState({
      resendIncrement: resendIncrement + 1
    })
  }

  onCodeChange = code => {
    this.setState({
      code: code.toUpperCase()
    }, () => {
      if (code.length === 6) {
        this.onConfirm.call(this)
      }
    })
  }

  onConfirm = () => {
    const { dispatch, history } = this.props
    const { code } = this.state

    dispatch({
      type: AUTH_2FA_REQUEST,
      payload: { code },
      onSuccess: () => {
        history.push("/")
      }
    })
  }

  render() {
    const { dispatch, history, auth } = this.props
    const { code, resendIncrement } = this.state

    return (
      <section className="auth-two-factor-screen">
        <h2>{translate("__todo11")}</h2>
        <div className="inner-container">
          <h5>{translateNode("__todo12", {
            num: <span>+38 066 111 11 11.</span>
          })}</h5>
          <CircularProgressArea
            restarter={resendIncrement}
          />
          <h5 className="no-margin">{translate("__todo13")}</h5>
          <Button
            title={translate("__todo14")}
            type={BUTTON_TYPES.LINK}
            onClick={this.onResend}
          />
          <TwoFactorComponent
            maxLength={AUTH_2FA_CODE_LENGTH}
            onChange={this.onCodeChange}
            value={code}
            isInvalid={auth.twoFactorFailed}
            error={auth.twoFactorFailed ? translate("__todo15") : ""}
          />
          <div className="left-aligned">
            <h5 className="no-margin">{translate("__todo16")}</h5>
            <Button
              title={translate("__todo17")}
              type={BUTTON_TYPES.LINK}
              onClick={() => {
                history.push("/auth/update-phone")
              }}
            />
          </div>
          <div className="auth-buttons">
            <Button
              title={translate("__todo18")}
              type={BUTTON_TYPES.PRIMARY}
              isDisabled={(code.length < AUTH_2FA_CODE_LENGTH) || auth.loading}
              onClick={this.onConfirm}
            />
          </div>
        </div>
      </section>
    )
  }
}

export { TwoFactor }
