import {
  WAREHOUSE_RECEIVE_REQUEST,
  WAREHOUSE_RECEIVE_SUCCESS,
  WAREHOUSE_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: true,
  warehouses: [],
  enabledWarehouses: [],
}

export default function warehouse(state = initialState, action) {
  switch (action.type) {
    case WAREHOUSE_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case WAREHOUSE_RECEIVE_SUCCESS:
      return {
        ...state,
        warehouses: action.payload.warehouses,
        enabledWarehouses: action.payload.warehouses.filter(w => w.isEnabled),
        loading: false,
      }

    case WAREHOUSE_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
