import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { Tag, TAG_TYPES } from "components/Ui"
import { Button, BUTTON_TYPES } from "components/Button"

const getTagByFeedbackRating = rate => {
  if (rate <= 2) {
    return TAG_TYPES.RED
  }

  if (rate === 3) {
    return TAG_TYPES.YELLOW
  }

  return TAG_TYPES.TEAL
}

export const TableArea = props => {
  const { onEdit, onDelete, onFilterClick } = props

  return (
    <Boxlist>
      <Box noPadding>
        <Table
          {...useTableHook({ ...props })}
          columns={[
            { title: "Rating", attr: "rate", groupable: true, sortable: true },
            { title: "Type", attr: "type", groupable: true },
            { title: "Date", attr: "date", sortable: true },
            { title: "Merchant", attr: "merchant", groupable: true },
            { title: "Order", attr: "order" },
            { title: "Comment", attr: "comment" },
          ]}
          actions={[
            {
              title: translate("edit"),
              icon: <Icons.Edit />,
              massActionDisabled: true,
              action: onEdit,
            },
            {
              title: translate("delete"),
              isRed: true,
              icon: <Icons.Delete />,
              action: onDelete,
            }
          ]}
          columnRender={{
            rate: row => {
              return <Tag type={getTagByFeedbackRating(row.rate)} title={row.rate} compact />
            },
            type: row => {
              return (
                <Tag type={TAG_TYPES.BLUE} title={row.type} compact />
              )
            },
          }}
          buttons={
            <>
              <Button
                title={translate("filters")}
                type={BUTTON_TYPES.GRAY}
                leftIcon={<Icons.Settings />}
                onClick={onFilterClick}
                hasSmallRadius
              />
            </>
          }
          tags={[
            <Tag
              key={0}
              title="Order processing"
              type={TAG_TYPES.PRIMARY}
              compact
            />,
            <Tag
              key={1}
              title="Order tracking"
              type={TAG_TYPES.PRIMARY}
              compact
            />,
          ]}
          noCheckbox
        />
      </Box>
    </Boxlist>
  )
}
