import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { PageHeader } from "components/PageHeader"
import { Breadcrumbs } from "components/Ui"
import { Uploader } from "components/Uploader"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
}))
class FilesDashboard extends React.Component {

  render() {
    const { history } = this.props

    return (
      <>
        <PageHeader
          prefix={"Review and manage all the files"}
          title={"Files"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "admin",
                  onClick: () => {
                    history.push("/admin")
                  }
                },
                {
                  title: "files",
                },
              ]}
              activeIndex={2}
            />
          }
        />
        <main>
          <Boxlist>
            <Box>
              <Uploader />
            </Box>
          </Boxlist>
        </main>
      </>
    )
  }
}

export { FilesDashboard }
