import { useLiquid } from "react-liquid"

import "./index.scss"

export const Liquid = ({ template, data }) => {
  const { markup } = useLiquid(template, data)

  return (
    <section className={classnames("com-Liquid")}>
      <div className="liquid-render" dangerouslySetInnerHTML={{ __html: markup }} />
    </section>
  )
}