import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Form, Rules } from "components/Form"
import { Input } from "components/Input"
import { Button, BUTTON_TYPES } from "components/Button"

import { AUTH_LOGIN_REQUEST } from "constants"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
}))
class UpdatePhone extends React.Component {

  state = {
    successful: false
  }

  render() {
    const { dispatch, history, auth } = this.props
    const { successful } = this.state

    return (
      <section className="auth-update-phone-screen">
        <h2>{successful ? translate("__todo_a1") : translate("__todo_a2")}</h2>
        <div className="inner-container">
          <h5>{successful ? translate("__todo_a3") : translate("__todo_a4")}</h5>
          {!successful && (
            <Form
              id="phone-number-form"
              data={{
                defaultValues: {
                  phone: "",
                }
              }}
              rules={{
                phone: Rules.string().min(8, translate("__todo_a5")).required(translate("__todo_a6")),
              }}
              render={[
                {
                  label: translate("__todo_a7"),
                  name: "phone",
                  node: ({ field, fieldState }) => {
                    return (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  }
                }
              ]}
              onSubmit={data => {
                console.log(data)
                this.setState({
                  successful: true
                })
              }}
            />
          )}
          <div className="auth-buttons">
            {!successful && (
              <>
                <Button
                  form="phone-number-form"
                  title={translate("update")}
                  type={BUTTON_TYPES.PRIMARY}
                />
                <Button
                  title={translate("__todo_a8")}
                  type={BUTTON_TYPES.LINK}
                  onClick={() => {
                    history.push("/auth/sign-in")
                  }}
                />
              </>
            )}
            {successful && (
              <Button
                title={translate("__todo_a9")}
                type={BUTTON_TYPES.PRIMARY}
                onClick={() => {
                  history.push("/auth/sign-in")
                }}
              />
            )}
          </div>
        </div>
      </section>
    )
  }
}

export { UpdatePhone }
