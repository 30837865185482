import { withRouter, NavLink } from "react-router-dom"
import { Icons } from "icons"

import "./index.scss"

@withRouter
class Navigation extends React.Component {

  static propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      to: PropTypes.string,
      icon: PropTypes.node,
      hoverIcon: PropTypes.node,
      sublinks: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        to: PropTypes.string,
        icon: PropTypes.node,
      }))
    })),
  }

  static defaultProps = {
    links: []
  }

  state = {
    hoverIndex: null
  }

  getIcon(index) {
    const { links } = this.props
    const { hoverIndex } = this.state

    if (index !== hoverIndex || hoverIndex === null) {
      return links[index].icon
    }

    if (!links[index].hoverIcon) {
      return links[index].icon
    }

    return links[index].hoverIcon
  }

  render () {
    const { links, history } = this.props

    return (
      <ul className="com-Navigation">
        {links.map((link, i) => (
          <NavLink
            key={i}
            to={link.to}
            onMouseEnter={() => this.setState({ hoverIndex: i })}
            onMouseLeave={() => this.setState({ hoverIndex: null })}
          >
            <li>
              {this.getIcon(i)}
              <p>{link.title}</p>
              {link.sublinks && <Icons.ArrowRightRegular className="right-icon" />}
            </li>
            {link.sublinks && (
              <div className="navigation-popup">
                <h2>{link.title}</h2>
                {link.sublinks.map(sublink => (
                  <div
                    key={sublink.title}
                    className="sub-link"
                    onClick={e => {
                      e.preventDefault()
                      history.push(sublink.to)
                    }}
                  >
                    {sublink.icon}
                    <p>{sublink.title}</p>
                  </div>
                ))}
              </div>
            )}
          </NavLink>
        ))}
      </ul>
    )
  }
}

export { Navigation }