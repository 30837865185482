import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Boxlist } from "components/Boxlist"
import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Wysiwyg } from "components/Wysiwyg"
import { PageHeader } from "components/PageHeader"
import { Breadcrumbs, Selectbox } from "components/Ui"
import { ScaleLoader } from "components/Loader/ScaleLoader"

import {
  PARTNER_CREATE_REQUEST,
  PARTNER_UPDATE_REQUEST,
  PARTNER_RECEIVE_SINGLE_REQUEST,
  PARTNER_CATEGORY_RECEIVE_REQUEST,
  PARTNER_STATUSES_ENUM,
  PARTNER_CONTACT_OPTIONS_ENUM,
  PARTNER_TYPE_ENUM,
} from "constants"

import "./index.scss"

@withRouter
@connect((state) => ({
  partner: state.partner,
}))
class PartnerCreateAndUpdate extends React.Component {

  state = {
    loaded: !this.props.updateMode,
    err: "",
    data: {},
    fullDescription: `<p style=\"text-align:left;\"><strong>OGOship </strong>partner</p>\n`
  }

  componentDidMount() {
    const { updateMode, dispatch, match: { params: { partnerId } } } = this.props

    dispatch({
      type: PARTNER_CATEGORY_RECEIVE_REQUEST
    })

    if (updateMode) {
      dispatch({
        type: PARTNER_RECEIVE_SINGLE_REQUEST,
        payload: {
          id: partnerId
        },
        success: data => {
          this.setState({
            data,
            fullDescription: data.fullDescription,
            loaded: true
          })
        },
        fail: err => {
          this.setState({ err })
        }
      })
    }
  }

  get pagedata() {
    const { updateMode } = this.props

    const res = {
      action: updateMode ? PARTNER_UPDATE_REQUEST : PARTNER_CREATE_REQUEST,
      prefix: updateMode ? `Update the partner` : "Create a partner",
      title: updateMode ? "Update partner" : "Create partner",
      defaultValues: this.getDefaultValues(),
    }

    return res
  }

  getDefaultValues() {
    const { data } = this.state

    if (Object.keys(data).length === 0) {
      return {
        ...data,
        status: { value: PARTNER_STATUSES_ENUM.Draft, label: PARTNER_STATUSES_ENUM.Draft },
        contactOption: { value: PARTNER_CONTACT_OPTIONS_ENUM.Directly, label: PARTNER_CONTACT_OPTIONS_ENUM.Directly },
        partnerType: { value: PARTNER_TYPE_ENUM.Default, label: PARTNER_TYPE_ENUM.Default },
      }
    }

    return {
      ...data,
      status: { value: data.status, label: data.status },
      contactOption: { value: data.contactOption, label: data.contactOption },
      partnerType: { value: data.partnerType, label: data.partnerType },
      categories: data.categories.map(c => ({ value: c, label: c })),
    }
  }

  onSubmit = data => {
    const { updateMode, dispatch, history, match: { params: { partnerId } } } = this.props
    const { fullDescription } = this.state

    const payload = {
      data: {
        ...data,
        status: data.status.value,
        contactOption: data.contactOption.value,
        partnerType: data.partnerType.value,
        categories: data.categories.map(c => c.value),
        fullDescription
      }
    }

    if (updateMode) {
      payload.id = partnerId
    }

    dispatch({
      type: this.pagedata.action,
      payload,
      success: () => {
        history.push("/partners")
      }
    })
  }

  render() {
    const { partner: { partners, loading, categories }, history, updateMode } = this.props
    const { loaded, data, fullDescription, err } = this.state

    return (
      <>
        <PageHeader
          prefix={this.pagedata.prefix}
          title={data.name || "Create Partner"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "partners",
                  onClick: () => {
                    history.push("/partners")
                  }
                },
                {
                  title: this.pagedata.title,
                },
              ]}
              activeIndex={2}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="PartnerCreateAndUpdate-screen">
          {err && (
            <Boxlist>
              <Box>
                <p>Error: {err.message}</p>
              </Box>
            </Boxlist>
          )}
          {!err && !loaded && (
            <ScaleLoader />
          )}

          {!err && loaded && (
            <Boxlist>
              <Box>
                <h2>Partner information</h2>
                <hr />
                <Form
                  id="partner-create-form"
                  data={{
                    defaultValues: this.pagedata.defaultValues
                  }}
                  rules={{
                    name: Rules.string().required(),
                    email: Rules.string().required(),
                    shortInfo: Rules.string().required(),
                    logoUrl: Rules.string().required(),
                    categories: Rules.array().required().min(1),
                  }}
                  render={[
                    {
                      label: "Name",
                      name: "name",
                      node: ({ field, fieldState }) => (
                        <Input
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Email",
                      name: "email",
                      node: ({ field, fieldState }) => (
                        <Input
                          type="email"
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Logo",
                      name: "logoUrl",
                      node: ({ field, fieldState }) => (
                        <Input
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Short Info",
                      name: "shortInfo",
                      node: ({ field, fieldState }) => (
                        <Input
                          textarea
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Status",
                      name: "status",
                      node: ({ field, fieldState }) => (
                        <Selectbox
                          options={Object.values(PARTNER_STATUSES_ENUM).map(status => ({
                            value: status, label: status
                          }))}
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Contact Option",
                      name: "contactOption",
                      node: ({ field, fieldState }) => (
                        <Selectbox
                          options={Object.values(PARTNER_CONTACT_OPTIONS_ENUM).map(option => ({
                            value: option, label: option
                          }))}
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Partner Type",
                      name: "partnerType",
                      node: ({ field, fieldState }) => (
                        <Selectbox
                          options={Object.values(PARTNER_TYPE_ENUM).map(option => ({
                            value: option, label: option
                          }))}
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Categories",
                      name: "categories",
                      node: ({ field, fieldState }) => (
                        <>
                          <Selectbox
                            options={categories.map(category => ({
                              value: category, label: category
                            }))}
                            onChange={field.onChange}
                            value={field.value}
                            error={fieldState.error?.message}
                            isCreatable={true}
                            isMulti={true}
                          />
                          <p className="partner-create-hint-text">
                            <Icons.Info />
                            You can create your own categories by typing something in the field above.
                          </p>
                        </>
                      )
                    },
                  ]}
                  onSubmit={this.onSubmit}
                />
              </Box>
              <Box>
                <h2>Description</h2>
                <hr />
                <Wysiwyg
                  tabIndex={0}
                  value={fullDescription}
                  onBlur={fullDescription => this.setState({ fullDescription })}
                />
              </Box>
              <Box ghost>
                <Button
                  form="partner-create-form"
                  title={translate("save")}
                  type={BUTTON_TYPES.GRAY}
                />
              </Box>
            </Boxlist>
          )}
        </main>
      </>
    )
  }
}

export { PartnerCreateAndUpdate }
