import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"
import { toast } from "react-toastify"

import {
  RETURN_DELIVERY_TYPE_RECEIVE_REQUEST,
  RETURN_DELIVERY_TYPE_RECEIVE_SUCCESS,
  RETURN_DELIVERY_TYPE_RECEIVE_FAIL,
  RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_REQUEST,
  RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_SUCCESS,
  RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_FAIL,
  RETURN_DELIVERY_TYPE_CREATE_REQUEST,
  RETURN_DELIVERY_TYPE_CREATE_SUCCESS,
  RETURN_DELIVERY_TYPE_CREATE_FAIL,
  RETURN_DELIVERY_TYPE_UPDATE_REQUEST,
  RETURN_DELIVERY_TYPE_UPDATE_SUCCESS,
  RETURN_DELIVERY_TYPE_UPDATE_FAIL,
  RETURN_DELIVERY_TYPE_REMOVE_REQUEST,
  RETURN_DELIVERY_TYPE_REMOVE_SUCCESS,
  RETURN_DELIVERY_TYPE_REMOVE_FAIL,
} from "constants"

function* receive(action) {
  try {
    const res = yield call(Api.returns.getReturnDeliveryTypes)

    yield put({
      type: RETURN_DELIVERY_TYPE_RECEIVE_SUCCESS,
      payload: {
        types: res.data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: RETURN_DELIVERY_TYPE_RECEIVE_FAIL })
  }
}

function* receiveSingle(action) {
  const { payload: { id }, success, fail} = action

  try {
    const res = yield call(Api.returns.getReturnDeliveryType, id)
    const channels = yield call(Api.merchant.search, {
      limit: {
        skip: 0,
        take: 0
      },
    })

    success && success.apply(this, [{
      rdt: res.data,
      channels: channels.data,
    }])
  } catch (err) {
    yield call(errorHandler, err)
    fail && fail.apply(this, [err])
  }
}

function* create(action) {
  const { success, payload: { data } } = action

  try {
    const res = yield call(Api.returns.createReturnDeliveryType, data)

    yield put({
      type: RETURN_DELIVERY_TYPE_CREATE_SUCCESS
    })

    toast.success("Return delivery type created.")
    success && success.apply(this, [res.data])

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: RETURN_DELIVERY_TYPE_CREATE_FAIL })
  }
}

function* update(action) {
  const { success, payload: { id, data } } = action

  try {
    const res = yield call(Api.returns.updateReturnDeliveryType, id, data)

    yield put({
      type: RETURN_DELIVERY_TYPE_UPDATE_SUCCESS
    })

    toast.success("Return delivery type updated.")
    success && success.apply(this, [res.data])

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: RETURN_DELIVERY_TYPE_UPDATE_FAIL })
  }
}

function* remove(action) {
  const { id } = action.payload

  try {
    const res = yield call(Api.returns.removeReturnDeliveryType, id)

    yield put({
      type: RETURN_DELIVERY_TYPE_REMOVE_SUCCESS,
      payload: { id }
    })

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: RETURN_DELIVERY_TYPE_REMOVE_FAIL })
  }
}

function* returnDeliveryTypesReceiveSaga() {
  yield takeLatest(RETURN_DELIVERY_TYPE_RECEIVE_REQUEST, receive)
}

function* returnDeliveryTypeReceiveSingleSaga() {
  yield takeLatest(RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_REQUEST, receiveSingle)
}

function* returnDeliveryTypeCreateSaga() {
  yield takeLatest(RETURN_DELIVERY_TYPE_CREATE_REQUEST, create)
}

function* returnDeliveryTypeUpdateSaga() {
  yield takeLatest(RETURN_DELIVERY_TYPE_UPDATE_REQUEST, update)
}

function* returnDeliveryTypeRemoveSaga() {
  yield takeLatest(RETURN_DELIVERY_TYPE_REMOVE_REQUEST, remove)
}

export function* returnDeliveryType() {
  yield all([
    call(returnDeliveryTypesReceiveSaga),
    call(returnDeliveryTypeReceiveSingleSaga),
    call(returnDeliveryTypeCreateSaga),
    call(returnDeliveryTypeUpdateSaga),
    call(returnDeliveryTypeRemoveSaga),
  ])
}
