import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Button, BUTTON_TYPES } from "components/Button"
import { List } from "components/Ui"

export const ColorPaletteArea = props => {
  const [show, setShow] = React.useState(true)

  return (
    <Boxlist>
      <Box>
        <Button
          type={BUTTON_TYPES.TERTIARY}
          title={show ? "Hide text" : "Show text"}
          icon={<Icons.Reload />}
          onClick={() => {
            setShow(show => !show)
          }}
        />

        {["purple", "teal", "gray", "orange", "yellow", "red", "blue"].map(type => {
          return (
            <List className="color-palette-area" noMargin key={type}>
              {[900, 800, 700, 600, 500, 400, 300, 200, 100, 50].map(num => (
                <div key={`${type}${num}`} style={{ background: `var(--color-${type}${num})` }}>
                  {show && <p className="body-s-400">{type}{num}</p>}
                </div>
              ))}
            </List>
          )
        })}
      </Box>
    </Boxlist>
  )
}
