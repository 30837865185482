import { FlagIcon } from "react-flag-kit"

export class Flag extends React.Component {

  static propTypes = {
    code: PropTypes.string,
    size: PropTypes.number,
  }

  static defaultProps = {
    code: "US",
    size: 24,
  }

  render () {
    const { code, size } = this.props

    return (
      <FlagIcon code={code} size={size} />
    )
  }
}