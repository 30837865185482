import store from "store2"
import jwt_decode from "jwt-decode"

import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
  AUTH_2FA_REQUEST,
  AUTH_2FA_SUCCESS,
  AUTH_2FA_FAIL,
  AUTH_LOGIN_VIA_API_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const getStateFromLocalStorage = () => {
  const initialState = {
    loading: false,
    loginFailed: false,
    loggedIn: false,
    username: "",
    twoFactorApproved: false,
    twoFactorFailed: false,
    token: "",
    hasAdminRights: false,
    hasStaffRights: false,
  }

  const token = store.get("myogo-jwt")

  if (!token) {
    return initialState
  }

  const decoded = jwt_decode(token)
  const usernameClaimsKey = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
  const rolesClaimsKey = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"

  return {
    ...initialState,
    token,
    username: decoded[usernameClaimsKey] || "API User",
    loggedIn: true,
    twoFactorApproved: true,
    hasAdminRights: (decoded[rolesClaimsKey] || []).includes("Admins"),
    hasStaffRights: (decoded[rolesClaimsKey] || []).includes("Staff"),
  }
}

const initialState = getStateFromLocalStorage()

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        token: "",
        username: "",
        loading: true,
        twoFactorApproved: false,
        loginFailed: false,
      }

    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        username: action.payload.username,
        loading: false,
        loggedIn: true,
        loginFailed: false,
      }

    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        ...action.payload,
        token: "",
        username: "",
        loading: false,
        loggedIn: false,
        loginFailed: true,
        twoFactorApproved: false,
      }

    case AUTH_2FA_REQUEST:
      return {
        ...state,
        loading: true,
        twoFactorApproved: false,
        twoFactorFailed: false,
      }

    case AUTH_2FA_SUCCESS:
      return {
        ...state,
        loading: false,
        twoFactorApproved: true,
        twoFactorFailed: false,
      }

    case AUTH_2FA_FAIL:
      return {
        ...state,
        loading: false,
        twoFactorApproved: false,
        twoFactorFailed: true,
      }

    case AUTH_LOGIN_VIA_API_SUCCESS:
      return getStateFromLocalStorage()

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
