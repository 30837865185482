import { Icons } from "icons"

import "./index.scss"

export const useToggleHook = props => {
  const [toggled, setToggled] = React.useState(props.toggled)

  return {
    isToggleable: true,
    isToggled: toggled,
    onToggle: () => setToggled(prev => !prev)
  }
}

export class Box extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    ghost: PropTypes.bool,
    noPadding: PropTypes.bool,
    isBlocking: PropTypes.bool,
    blocksWith: PropTypes.node,
    isToggleable: PropTypes.bool,
    isToggled: PropTypes.bool,
    onToggle: PropTypes.func,
  }

  static defaultProps = {
    className: "",
    ghost: false,
    noPadding: false,
    isBlocking: false,
    blocksWith: null,
    isToggleable: false,
    isToggled: false,
    onToggle: null,
  }

  renderContent() {
    const {
      children,
      isBlocking,
      blocksWith,
      isToggleable,
      isToggled,
      onToggle,
     } = this.props

    if (isToggleable) {
      if (isToggled) {
        return (
          <div className="toggled-box">
            {children[0]}
            <Icons.ArrowDown onClick={onToggle} />
          </div>
        )
      } else {
        return (
          <>
            <div className="toggled-box">
              {children[0]}
              <Icons.ArrowUp onClick={onToggle} />
            </div>
            {children.filter((_, i) => i !== 0)}
          </>
        )
      }
    }

    return (
      <>
        {isBlocking && (
          <div className="box-blocking-overlay">
            {blocksWith || <p>Please wait</p>}
          </div>
        )}

        {children}
      </>
    )
  }

  render () {
    const { ghost, noPadding, className } = this.props

    return (
      <section className={classnames(
        "com-Box",
        className,
        {
          "is-ghost": ghost,
          "no-padding": noPadding,
        }
      )}>
        {this.renderContent()}
      </section>
    )
  }
}