import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { Button, BUTTON_TYPES } from "components/Button"

import { PARTNER_STATUSES_ENUM } from "constants"

export const PartnersTableArea = props => {
  const { onPreview, onEdit, onDeactivate, onAdd } = props

  const isCancelled = row => row.status === PARTNER_STATUSES_ENUM.Cancelled

  return (
    <Boxlist>
      <Box noPadding>
        <Table
          {...useTableHook({ ...props })}
          columns={[
            { title: "Logo", attr: "logoUrl" },
            { title: "Name", attr: "name" },
            { title: "Email", attr: "email" },
            { title: "Type", attr: "partnerType" },
            { title: "Status", attr: "status", groupable: true },
            { title: "Categories", attr: "categories" },
          ]}
          actions={[
            {
              title: "Preview card",
              icon: <Icons.Eye />,
              massActionDisabled: true,
              action: onPreview,
            },
            {
              title: translate("edit"),
              icon: <Icons.Edit />,
              massActionDisabled: true,
              action: onEdit,
            },
            {
              title: rows => isCancelled(rows[0]) ? "Activate" : "Deactivate",
              isRed: rows => !isCancelled(rows[0]),
              icon: rows => isCancelled(rows[0]) ? <Icons.Regenerate /> : <Icons.Cancel />,
              action: (...rest) => onDeactivate.apply(this, rest),
              massActionDisabled: true,
            }
          ]}
          columnRender={{
            logoUrl: row => {
              return <img src={row.logoUrl} />
            },
            categories: row => {
              return <>{row.categories.join(", ")}</>
            },
          }}
          buttons={
            <>
              <Button
                type={BUTTON_TYPES.GRAY}
                icon={<Icons.Plus />}
                onClick={onAdd}
                hasSmallRadius
              />
            </>
          }
          noCheckbox
          noFilters
        />
      </Box>
    </Boxlist>
  )
}
