import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"
import { store } from "index"

import {
  COUNTRY_RECEIVE_REQUEST,
  COUNTRY_RECEIVE_SUCCESS,
  COUNTRY_RECEIVE_FAIL,
} from "constants"

function* receive(action) {
  try {
    const res = yield call(Api.country.getAll)

    const countries = res.data.map(entry => {
      const lang = store.getState().app.language
      const localization = entry.localizations.find(localization => localization.lang === lang)
      const label = localization ? localization.context : entry.name

      return {
        value: entry.code,
        label: `${label} (${entry.code})`,
      }
    })

    yield put({
      type: COUNTRY_RECEIVE_SUCCESS,
      payload: { countries }
    })

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: COUNTRY_RECEIVE_FAIL })
  }
}

function* countryReceiveSaga() {
  yield takeLatest(COUNTRY_RECEIVE_REQUEST, receive)
}

export function* country() {
  yield all([
    call(countryReceiveSaga),
  ])
}
