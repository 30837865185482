import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Selectbox, Checkbox } from "components/Ui"

export const AdditionalOrderInfoArea = props => {
  return (
    <>
      <Box>
        <h2>Additional order info</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              order_customization: "",
              delivery_type : { value: "dhl", label: "DHL Express Worldwide" },
              pickup_point_code: "",
              cash_on_delivery: "",
              comments: "",
              is_test_order: false,
            }
          }}
          rules={{
            order_customization: Rules.string().required(),
            delivery_type: Rules.string().required(),
            pickup_point_code: Rules.string().required(),
          }}
          render={[
            {
              label: "Order customization",
              name: "order_customization",
              node: ({ field, fieldState }) => (
                <Input
                  textarea
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Delivery Type",
              name: "delivery_type",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "dhl", label: "DHL Express Worldwide" }
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Pickup point code",
              name: "pickup_point_code",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Cash on delivery amount",
              name: "cash_on_delivery",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Comments from OGOship",
              name: "comments",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "",
              name: "is_test_order",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Test order / do not ship</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
    </>
  )
}