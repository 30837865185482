/*
|  Icons
*/

// 16
import { ReactComponent as ArrowRightRegular16 } from "/assets/images/icons/16/Arrow right/Regular.svg"
import { ReactComponent as ArrowUp16 } from "/assets/images/icons/16/Arrow Up.svg"
import { ReactComponent as ArrowDown16 } from "/assets/images/icons/16/Arrow Down.svg"
import { ReactComponent as Reload16 } from "/assets/images/icons/16/Reload.svg"
import { ReactComponent as Dropdown16 } from "/assets/images/icons/16/Dropdown.svg"
import { ReactComponent as More16 } from "/assets/images/icons/16/More.svg"
import { ReactComponent as Warning16 } from "/assets/images/icons/16/Warning.svg"
import { ReactComponent as Copy16 } from "/assets/images/icons/16/Copy.svg"
import { ReactComponent as Filter16 } from "/assets/images/icons/16/Filter_Icon.svg"
import { ReactComponent as Star16 } from "/assets/images/icons/16/Star.svg"
import { ReactComponent as Check16 } from "/assets/images/icons/16/Check.svg"
import { ReactComponent as Eye16 } from "/assets/images/icons/16/Eye.svg"
import { ReactComponent as EyeHidden16 } from "/assets/images/icons/16/Eye hidden.svg"

// 20
import { ReactComponent as Calendar20 } from "/assets/images/icons/20/Calendar.svg"
import { ReactComponent as Cancel20 } from "/assets/images/icons/20/Cancel.svg"
import { ReactComponent as Chart20 } from "/assets/images/icons/20/Chart.svg"
import { ReactComponent as Clock20 } from "/assets/images/icons/20/Clock.svg"
import { ReactComponent as Close20 } from "/assets/images/icons/20/Close.svg"
import { ReactComponent as Delete20 } from "/assets/images/icons/20/Delete.svg"
import { ReactComponent as DocumentFilled20 } from "/assets/images/icons/20/Document Filled.svg"
import { ReactComponent as DocumentOutline20 } from "/assets/images/icons/20/Document Outline.svg"
import { ReactComponent as Download20 } from "/assets/images/icons/20/Download.svg"
import { ReactComponent as Edit20 } from "/assets/images/icons/20/Edit.svg"
import { ReactComponent as Email20 } from "/assets/images/icons/20/Email.svg"
import { ReactComponent as Id20 } from "/assets/images/icons/20/Id.svg"
import { ReactComponent as Info20 } from "/assets/images/icons/20/Info.svg"
import { ReactComponent as Location20 } from "/assets/images/icons/20/Location.svg"
import { ReactComponent as Logout20 } from "/assets/images/icons/20/Logout.svg"
import { ReactComponent as More20 } from "/assets/images/icons/20/More.svg"
import { ReactComponent as Notifications20 } from "/assets/images/icons/20/Notifications.svg"
import { ReactComponent as Package20 } from "/assets/images/icons/20/Package.svg"
import { ReactComponent as Plus20 } from "/assets/images/icons/20/Plus.svg"
import { ReactComponent as Regenerate20 } from "/assets/images/icons/20/Regenerate.svg"
import { ReactComponent as Save20 } from "/assets/images/icons/20/Save.svg"
import { ReactComponent as Search20 } from "/assets/images/icons/20/Search.svg"
import { ReactComponent as Settings20 } from "/assets/images/icons/20/Settings.svg"
import { ReactComponent as Shipping20 } from "/assets/images/icons/20/Shipping.svg"
import { ReactComponent as Supplier20 } from "/assets/images/icons/20/Supplier.svg"
import { ReactComponent as Timer20 } from "/assets/images/icons/20/Timer.svg"
import { ReactComponent as Upload20 } from "/assets/images/icons/20/Upload.svg"
import { ReactComponent as Warehouse20 } from "/assets/images/icons/20/Warehouse.svg"
import { ReactComponent as Warning20 } from "/assets/images/icons/20/Warning.svg"
import { ReactComponent as World20 } from "/assets/images/icons/20/World.svg"
import { ReactComponent as Pin20 } from "/assets/images/icons/20/Pin.svg"
import { ReactComponent as Lock20 } from "/assets/images/icons/20/Lock.svg"

// 32
import { ReactComponent as CalculatorHighlighted } from "/assets/images/icons/32/Calculator/Highlighted.svg"
import { ReactComponent as CalculatorRegular } from "/assets/images/icons/32/Calculator/Regular.svg"
import { ReactComponent as MerchantHighlighted } from "/assets/images/icons/32/Merchant/Highlighted.svg"
import { ReactComponent as MerchantRegular } from "/assets/images/icons/32/Merchant/Regular.svg"
import { ReactComponent as MiscHighlighted } from "/assets/images/icons/32/Misc/Highlighted.svg"
import { ReactComponent as MiscRegular } from "/assets/images/icons/32/Misc/Regular.svg"
import { ReactComponent as OrdersHighlighted } from "/assets/images/icons/32/Orders/Highlighted.svg"
import { ReactComponent as OrdersRegular } from "/assets/images/icons/32/Orders/Regular.svg"
import { ReactComponent as ProductsHighlighted } from "/assets/images/icons/32/Products/Highlighted.svg"
import { ReactComponent as ProductsRegular } from "/assets/images/icons/32/Products/Regular.svg"
import { ReactComponent as HomepageHighlighted } from "/assets/images/icons/32/Homepage/Highlighted.svg"
import { ReactComponent as HomepageRegular } from "/assets/images/icons/32/Homepage/Regular.svg"
import { ReactComponent as AdminHighlighted } from "/assets/images/icons/32/Admin/Highlighted.svg"
import { ReactComponent as AdminRegular } from "/assets/images/icons/32/Admin/Regular.svg"
import { ReactComponent as StockUpdateHighlighted } from "/assets/images/icons/32/Stock update/Highlighted.svg"
import { ReactComponent as StockUpdateRegular } from "/assets/images/icons/32/Stock update/Regular.svg"
import { ReactComponent as PartnerHighlighted } from "/assets/images/icons/32/Partner/Highlighted.svg"
import { ReactComponent as PartnerRegular } from "/assets/images/icons/32/Partner/Regular.svg"
import { ReactComponent as Co2Highlighted } from "/assets/images/icons/32/Co2/Highlighted.svg"
import { ReactComponent as Co2Regular } from "/assets/images/icons/32/Co2/Regular.svg"
import { ReactComponent as FeedbackHighlighted } from "/assets/images/icons/32/Feedback/Highlighted.svg"
import { ReactComponent as FeedbackRegular } from "/assets/images/icons/32/Feedback/Regular.svg"
import { ReactComponent as ReturnHighlighted } from "/assets/images/icons/32/Return/Highlighted.svg"
import { ReactComponent as ReturnRegular } from "/assets/images/icons/32/Return/Regular.svg"
import { ReactComponent as Profile } from "/assets/images/icons/32/Profile.svg"


// Big
import { ReactComponent as BigCancel } from "/assets/images/icons/big/Cancel.svg"

// Boxes
import { ReactComponent as BoxIcon1 } from "assets/images/ogo-boxes/1.svg"
import { ReactComponent as BoxIcon2 } from "assets/images/ogo-boxes/2.svg"
import { ReactComponent as BoxIcon3 } from "assets/images/ogo-boxes/3.svg"

export const Icons = {
  // 16
  ArrowRightRegular: ArrowRightRegular16,
  ArrowUp: ArrowUp16,
  ArrowDown: ArrowDown16,
  Reload: Reload16,
  Dropdown: Dropdown16,
  More: More16,
  Warning16: Warning16,
  Copy: Copy16,
  Check: Check16,
  Filter: Filter16,
  Star: Star16,
  Eye: Eye16,
  EyeHidden: EyeHidden16,

  // 20
  Calendar: Calendar20,
  Cancel: Cancel20,
  Chart: Chart20,
  Clock: Clock20,
  Close: Close20,
  Delete: Delete20,
  DocumentFilled: DocumentFilled20,
  DocumentOutline: DocumentOutline20,
  Download: Download20,
  Edit: Edit20,
  Email: Email20,
  Id: Id20,
  Info: Info20,
  Location: Location20,
  Logout: Logout20,
  More: More20,
  Notifications: Notifications20,
  Package: Package20,
  Plus: Plus20,
  Regenerate: Regenerate20,
  Save: Save20,
  Search: Search20,
  Settings: Settings20,
  Shipping: Shipping20,
  Supplier: Supplier20,
  Timer: Timer20,
  Upload: Upload20,
  Warehouse: Warehouse20,
  Warning: Warning20,
  World: World20,
  Pin: Pin20,
  Lock: Lock20,

  // 32
  CalculatorHighlighted: CalculatorHighlighted,
  CalculatorRegular: CalculatorRegular,
  MerchantHighlighted: MerchantHighlighted,
  MerchantRegular: MerchantRegular,
  MiscHighlighted: MiscHighlighted,
  MiscRegular: MiscRegular,
  OrdersHighlighted: OrdersHighlighted,
  OrdersRegular: OrdersRegular,
  ProductsHighlighted: ProductsHighlighted,
  ProductsRegular: ProductsRegular,
  StockUpdateHighlighted: StockUpdateHighlighted,
  StockUpdateRegular: StockUpdateRegular,
  AdminHighlighted: AdminHighlighted,
  AdminRegular: AdminRegular,
  HomepageHighlighted: HomepageHighlighted,
  HomepageRegular: HomepageRegular,
  FeedbackHighlighted: FeedbackHighlighted,
  FeedbackRegular: FeedbackRegular,
  PartnerHighlighted: PartnerHighlighted,
  PartnerRegular: PartnerRegular,
  Co2Highlighted: Co2Highlighted,
  Co2Regular: Co2Regular,
  ReturnHighlighted: ReturnHighlighted,
  ReturnRegular: ReturnRegular,
  Profile: Profile,

  // Boxes
  BoxIcon1: BoxIcon1,
  BoxIcon2: BoxIcon2,
  BoxIcon3: BoxIcon3,

  // Big
  Big: {
    Cancel: BigCancel,
  },
}