import {
  CURRENCY_RECEIVE_REQUEST,
  CURRENCY_RECEIVE_SUCCESS,
  CURRENCY_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  currencies: [],
  currenciesDict: {},
}

export default function currency(state = initialState, action) {
  switch (action.type) {
    case CURRENCY_RECEIVE_SUCCESS:
      return {
        ...state,
        currencies: action.payload.currencies,
        currenciesDict: action.payload.currencies.reduce((a, v) => ({ ...a, [v.value]: v }), {})
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState
      }

    default:
      return state
  }
}
