import {
  TAB_INDEX_UPDATE,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  returnsDashboardTabIndex: 0,
}

export default function tab(state = initialState, action) {
  switch (action.type) {
    case TAB_INDEX_UPDATE:
      return {
        ...state,
        [action.payload.attr]: action.payload.value,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState
      }

    default:
      return state
  }
}
