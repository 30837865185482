import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"

export const CustomsInfoArea = props => {
  return (
    <>
      <Box>
        <h2>Customs info</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              description: "",
              country : "",
              harmonization_code: "",
              composition: "",
            }
          }}
          rules={{
            description: Rules.string().required(),
            country : Rules.string().required(),
            harmonization_code:  Rules.string().required(),
            composition: Rules.string().required(),
          }}
          render={[
            {
              label: "Short customs description",
              name: "description",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Country of origin",
              name: "country",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Harmonization Code",
              name: "harmonization_code",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Product composition (for PFS warehouse)",
              name: "composition",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
    </>
  )
}