import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"

import {
  EVENT_RECEIVE_REQUEST,
  EVENT_RECEIVE_SUCCESS,
  EVENT_RECEIVE_FAIL,
} from "constants"

function* receive(action) {
  try {
    const res = yield call(Api.event.getEvent, action.payload.data)
    const maximumNumber = Math.max(...res.data.heatMapData.map(entry => entry.values).flat())

    yield put({
      type: EVENT_RECEIVE_SUCCESS,
      payload: {
        data: res.data,
        maximumNumber,
      }
    })

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: EVENT_RECEIVE_FAIL, payload: { err: err.message } })
  }
}

function* eventReceiveSaga() {
  yield takeLatest(EVENT_RECEIVE_REQUEST, receive)
}

export function* event() {
  yield all([
    call(eventReceiveSaga),
  ])
}
