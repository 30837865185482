import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Tooltip, Selectbox, Checkbox } from "components/Ui"
import { Uploader } from "components/Uploader"

export const OrderProcessingArea = props => {
  return (
    <>
      <Box>
        <h2>Order customization settings for integrations</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              checkbox1: false,
              checkbox2: false,
              checkbox3: false,
            }
          }}
          render={[
            {
              name: "checkbox1",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Stop orders and notify merchant of customizations</p>
                      <p className="caption-s-400 soft">Orders with customizations are stopped and a notification email is sent via email to the merchant. The order is left in the RESERVED state to wait for actions from the merchant.</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox2",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Forward requests to OGOship</p>
                      <p className="caption-s-400 soft">Order customization requests are forwarded to OGOship for processing without stopping the order and informing the merchant. Costs may apply. Order customization requests can be used for VNT, TLL and GOT warehouses.</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox3",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Remove order customization from orders</p>
                      <p className="caption-s-400 soft">Incoming customization requests via the integration are removed automatically without stopping the order. Manual addition of requests is still possible.</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            }
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Box>
        <h2>Merchant documents</h2>
        <hr />
        <Uploader/>
      </Box>
      <Box>
        <h2>Order processing</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              checkbox1: false,
              checkbox2: false,
              checkbox3: false,
              checkbox4: false,
              checkbox5: false,
              checkbox6: false,
              checkbox7: false,
            }
          }}
          render={[
            {
              name: "checkbox1",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">OGOship will split orders automatically, so that the products in stock will be delivered immediately and the remaining part as a back order. This setting will increase shipping costs.</p>
                      {field.value && <p className="caption-s-400 soft">When this option is enabled, all PENDING orders will automatically split in two parts. If you choose not to enable this option, you can still split orders manually when needed.</p>}
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox2",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Send email to customer when order shipped. Activate only when your online store does not support shipping confirmations.</p>
                      {field.value && <p className="caption-s-400 soft">Always prefer to create and send shipping confirmations from your online store. OGOship's shipping confirmation will show OGOship's domains.</p>}
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox3",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Always create dispatch list to all orders</p>
                      {field.value && <p className="caption-s-400 soft">If a receipt is received via the API, OGOship will not automatically create dispatch lists. By enabling this option, a dispatch list will always be created.</p>}
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox4",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Allow filling of customs prices from product selling price</p>
                      {field.value && <p className="caption-s-400 soft">Enable this only if you never use discounts in your online store.</p>}
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox5",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Automatically fill pick-up point when missing & required</p>
                      {field.value && <p className="caption-s-400 soft">Automatically fill nearest pick-up point when missing & required</p>}
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox6",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Allow package shipments without phone number and email address</p>
                      {field.value && <p className="caption-s-400 soft">Allow package shipments without phone number. There might be additional cost for letter pickup notification.</p>}
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox7",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Allow invalid phone numbers</p>
                      {field.value && <p className="caption-s-400 soft">Allow invalid phone numbers by default on incoming orders</p>}
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Box>
        <h2>Order data retention time</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              time: 180,
            }
          }}
          rules={{
            time: Rules.string().required(),
          }}
          render={[
            {
              label: "Retention Time",
              name: "time",
              tip: "Value must be between 30 - 180 days",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            }
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Tooltip />
    </>
  )
}