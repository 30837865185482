import {
  ORDER_RECEIVE_REQUEST,
  ORDER_RECEIVE_SUCCESS,
  ORDER_RECEIVE_FAIL,
  ORDER_RETURN_RECEIVE_REQUEST,
  ORDER_RETURN_RECEIVE_SUCCESS,
  ORDER_RETURN_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: false,
  orders: [],
  returns: [],
}

export default function order(state = initialState, action) {
  switch (action.type) {
    case ORDER_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ORDER_RECEIVE_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        loading: false,
      }

    case ORDER_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case ORDER_RETURN_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ORDER_RETURN_RECEIVE_SUCCESS:
      return {
        ...state,
        returns: action.payload.returns,
        loading: false,
      }

    case ORDER_RETURN_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
