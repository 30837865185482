import {
  ADMIN_RECEIVE_REQUEST,
  ADMIN_RECEIVE_SUCCESS,
  ADMIN_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: false,
  admins: [],
}

export default function admin(state = initialState, action) {
  switch (action.type) {
    case ADMIN_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ADMIN_RECEIVE_SUCCESS:
      return {
        ...state,
        admins: action.payload.admins,
        loading: false,
      }

    case ADMIN_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
