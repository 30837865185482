import { axios } from "../axios"
import { to } from "helpers/api"
import { store } from "index"

export const transactionalMessage = {
  getAll: () => {
    return axios.get(to("api/ns/transactional"), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  getSingle: id => {
    return axios.get(to(`api/ns/transactional/${id}`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  create: data => {
    return axios.post(to("api/ns/transactional"), data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  update: (id, data) => {
    return axios.put(to(`api/ns/transactional/${id}`), data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  remove: id => {
    return axios.delete(to(`api/ns/transactional/${id}`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  getRecipients: id => {
    return axios.get(to(`api/ns/transactional/${id}/recipients`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
}
