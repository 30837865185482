import * as Sentry from "@sentry/react"

import "./index.scss"

export class ErrorBoundary extends React.Component {

  state = {
    error: false
  }

  static getDerivedStateFromError(error) {
    return { error: error }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error)
  }

  render() {
    const { error } = this.state

    if (error) {
      return (
        <div className="com-ErrorBoundary">
          <h1>Application crashed.</h1>
          <p>Error: {error.message}</p>
        </div>
      )
    }

    return this.props.children
  }
}