import { call, all } from "redux-saga/effects"

import { app } from "./app"
import { auth } from "./auth"
import { merchant } from "./merchant"
import { order } from "./order"
import { product } from "./product"
import { stockUpdate } from "./stock-update"
import { supplier } from "./supplier"
import { stock } from "./stock"
import { admin } from "./admin"
import { customer } from "./customer"
import { feedback } from "./feedback"
import { partner } from "./partner"
import { warehouse } from "./warehouse"
import { returnReason } from "./returnReason"
import { returnDeliveryType } from "./returnDeliveryType"
import { currency } from "./currency"
import { country } from "./country"
import { rebuy } from "./rebuy"
import { settings } from "./settings"
import { transactionalMessage } from "./transactionalMessage"
import { event } from "./event"
import { costCategory } from "./costCategory"

export function* sagas() {
  yield all([
    call(app),
    call(auth),
    call(merchant),
    call(order),
    call(product),
    call(stockUpdate),
    call(supplier),
    call(stock),
    call(admin),
    call(customer),
    call(feedback),
    call(partner),
    call(warehouse),
    call(returnReason),
    call(returnDeliveryType),
    call(currency),
    call(country),
    call(rebuy),
    call(settings),
    call(transactionalMessage),
    call(event),
    call(costCategory),
  ])
}
