import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Selectbox } from "components/Ui"

export const NameAndAddressArea = props => {
  return (
    <>
      <Box>
        <h2>Name and address</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              country: { value: "fi", label: "Finland" },
              name: "",
              company: "",
              street: "",
              postcode: "",
              city: "",
              email: "",
              phone: "",
            }
          }}
          rules={{
            country: Rules.string().required(),
            name: Rules.string().required(),
            company: Rules.string().required(),
            street: Rules.string().required(),
            postcode: Rules.string().required(),
            city: Rules.string().required(),
            email: Rules.string().required(),
            phone: Rules.string().required(),
          }}
          render={[
            {
              label: "Name",
              name: "name",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Company",
              name: "company",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Street",
              name: "street",
              node: ({ field, fieldState }) => (
                <Input
                  textarea
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Postcode",
              name: "postcode",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "City",
              name: "city",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Country",
              name: "country",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "fi", label: "Finland" },
                    { value: "pl", label: "Poland" },
                    { value: "tr", label: "Turkey" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Email",
              name: "email",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Phone",
              name: "phone",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
    </>
  )
}