import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Form, Rules } from "components/Form"
import { Selectbox } from "components/Ui"
import { Wysiwyg } from "components/Wysiwyg"
import { Input } from "components/Input"

import {
  LANGUAGE_LIST,
} from "constants"

export const MessageBox = props => {
  const { language, onWatch, defaultValues } = props
  const ref = React.useRef()

  React.useEffect(() => {
    onWatch.apply(this, [defaultValues, language])
  }, [])

  const watch = data => {
    onWatch && onWatch.apply(this, [data, language])
  }

  return (
    <Box>
      <h2>Message ({translate(`language.${language}`)})</h2>
      <hr />
      <Form
        onWatch={watch}
        data={{ defaultValues }}
        rules={{
          locale: Rules.string().required(),
          title: Rules.string().required(),
          body: Rules.string().required(),
        }}
        render={[
          {
            label: "Title",
            name: "title",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Body",
            name: "body",
            node: ({ field, setValue }) => (
              <Wysiwyg
                reference={ref}
                tabIndex={0}
                value={field.value}
                onBlur={value => {
                  setValue("body", value)
                }}
              />
            )
          },
        ]}
        submitButtonLabel=""
      />
    </Box>
  )
}