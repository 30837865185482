import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"

import {
  MERCHANT_RECEIVE_REQUEST,
  MERCHANT_RECEIVE_SUCCESS,
  MERCHANT_RECEIVE_FAIL,
  MERCHANT_CONNECTED_USERS_RECEIVE_REQUEST,
  MERCHANT_CONNECTED_USERS_RECEIVE_SUCCESS,
  MERCHANT_CONNECTED_USERS_RECEIVE_FAIL,
  MERCHANT_DISCOUNTS_RECEIVE_REQUEST,
  MERCHANT_DISCOUNTS_RECEIVE_SUCCESS,
  MERCHANT_DISCOUNTS_RECEIVE_FAIL,
} from "constants"

import {
  fakeMerchantConnectedUsersBackend,
  fakeMerchantDiscountsBackend,
} from "mock"

function* receive(action) {
  const { payload } = action

  const qs = payload?.qs || {
    limit: {
      skip: 0,
      take: 0
    },
  }

  try {
    const res = yield call(Api.merchant.search, qs)

    yield put({
      type: MERCHANT_RECEIVE_SUCCESS,
      payload: {
        merchants: res.data
      }
    })

    action?.success && action?.success.apply(this, [res.data])
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: MERCHANT_RECEIVE_FAIL })
  }
}

function* receiveConnectedUsers(action) {
  try {
    const data = fakeMerchantConnectedUsersBackend.update(action.payload?.qs).getJson()

    yield delay(1500)

    yield put({
      type: MERCHANT_CONNECTED_USERS_RECEIVE_SUCCESS,
      payload: {
        connectedUsers: data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: MERCHANT_CONNECTED_USERS_RECEIVE_FAIL })
  }
}

function* receiveDiscounts(action) {
  try {
    const data = fakeMerchantDiscountsBackend.update(action.payload?.qs).getJson()

    yield delay(1500)

    yield put({
      type: MERCHANT_DISCOUNTS_RECEIVE_SUCCESS,
      payload: {
        discounts: data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: MERCHANT_DISCOUNTS_RECEIVE_FAIL })
  }
}

function* merchantReceiveSaga() {
  yield takeLatest(MERCHANT_RECEIVE_REQUEST, receive)
}

function* merchantReceiveConnectedUsersSaga() {
  yield takeLatest(MERCHANT_CONNECTED_USERS_RECEIVE_REQUEST, receiveConnectedUsers)
}

function* merchantReceiveDiscountsSaga() {
  yield takeLatest(MERCHANT_DISCOUNTS_RECEIVE_REQUEST, receiveDiscounts)
}

export function* merchant() {
  yield all([
    call(merchantReceiveSaga),
    call(merchantReceiveConnectedUsersSaga),
    call(merchantReceiveDiscountsSaga),
  ])
}
