import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"
import { toast } from "react-toastify"

import {
  RETURN_REASON_RECEIVE_REQUEST,
  RETURN_REASON_RECEIVE_SUCCESS,
  RETURN_REASON_RECEIVE_FAIL,
  RETURN_REASON_RECEIVE_SINGLE_REQUEST,
  RETURN_REASON_RECEIVE_SINGLE_SUCCESS,
  RETURN_REASON_RECEIVE_SINGLE_FAIL,
  RETURN_REASON_CREATE_REQUEST,
  RETURN_REASON_CREATE_SUCCESS,
  RETURN_REASON_CREATE_FAIL,
  RETURN_REASON_UPDATE_REQUEST,
  RETURN_REASON_UPDATE_SUCCESS,
  RETURN_REASON_UPDATE_FAIL,
  RETURN_REASON_REMOVE_REQUEST,
  RETURN_REASON_REMOVE_SUCCESS,
  RETURN_REASON_REMOVE_FAIL,
} from "constants"

function* receive(action) {
  try {
    const res = yield call(Api.returns.getReturnReasons)

    yield put({
      type: RETURN_REASON_RECEIVE_SUCCESS,
      payload: {
        reasons: res.data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: RETURN_REASON_RECEIVE_FAIL })
  }
}

function* receiveSingle(action) {
  const { payload: { id }, success, fail} = action

  try {
    const reason = yield call(Api.returns.getReturnReason, id)
    const channels = yield call(Api.merchant.search, {
      limit: {
        skip: 0,
        take: 0
      },
    })

    success && success.apply(this, [{
      returnReason: reason.data,
      channels: channels.data,
    }])
  } catch (err) {
    yield call(errorHandler, err)
    fail && fail.apply(this, [err])
  }
}

function* create(action) {
  const { success, payload: { data } } = action

  try {
    const res = yield call(Api.returns.createReturnReason, data)

    yield put({
      type: RETURN_REASON_CREATE_SUCCESS
    })

    toast.success("Return reason created.")
    success && success.apply(this, [res.data])

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: RETURN_REASON_CREATE_FAIL })
  }
}

function* update(action) {
  const { success, payload: { id, data } } = action

  try {
    const res = yield call(Api.returns.updateReturnReason, id, data)

    yield put({
      type: RETURN_REASON_UPDATE_SUCCESS
    })

    toast.success("Return reason updated.")
    success && success.apply(this, [res.data])

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: RETURN_REASON_UPDATE_FAIL })
  }
}

function* remove(action) {
  const { id } = action.payload

  try {
    const res = yield call(Api.returns.removeReturnReason, id)

    yield put({
      type: RETURN_REASON_REMOVE_SUCCESS,
      payload: { id }
    })

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: RETURN_REASON_REMOVE_FAIL })
  }
}

function* returnReasonsReceiveSaga() {
  yield takeLatest(RETURN_REASON_RECEIVE_REQUEST, receive)
}

function* returnReasonReceiveSingleSaga() {
  yield takeLatest(RETURN_REASON_RECEIVE_SINGLE_REQUEST, receiveSingle)
}

function* returnReasonCreateSaga() {
  yield takeLatest(RETURN_REASON_CREATE_REQUEST, create)
}

function* returnReasonUpdateSaga() {
  yield takeLatest(RETURN_REASON_UPDATE_REQUEST, update)
}

function* returnReasonRemoveSaga() {
  yield takeLatest(RETURN_REASON_REMOVE_REQUEST, remove)
}

export function* returnReason() {
  yield all([
    call(returnReasonsReceiveSaga),
    call(returnReasonReceiveSingleSaga),
    call(returnReasonCreateSaga),
    call(returnReasonUpdateSaga),
    call(returnReasonRemoveSaga),
  ])
}
