import { withRouter } from "react-router-dom"
import { Route, Redirect, Switch, NavLink } from "react-router-dom"
import { Icons } from "icons"
import { translate } from "helpers/i18n"

// Components
import { Navigation } from "components/Layout/Navigation"
import { Tooltip } from "components/Ui"
import { Box } from "components/Box"
import { ScrollToTop } from "components/ScrollToTop"

// Containers
import { Modal } from "./Layout/Modal"
import { Header } from "./Layout/Header"
import { Footer } from "./Layout/Footer"
import { Home } from "./Home"
import { Misc } from "./Misc"
import { MerchantListing } from "./Merchant/MerchantListing"
import { MerchantEdit } from "./Merchant/MerchantEdit"
import { OrderListing } from "./Order/OrderListing"
import { OrderCreate } from "./Order/OrderCreate"
import { OrderReturnListing } from "./OrderReturn/OrderReturnListing"
import { OrderReturnCreate } from "./OrderReturn/OrderReturnCreate"
import { ProductListing } from "./Product/ProductListing"
import { ProductCreate } from "./Product/ProductCreate"
import { ProductGroupListing } from "./ProductGroup/ProductGroupListing"
import { ProductBundleListing } from "./ProductBundle/ProductBundleListing"
import { ProductBundleCreate } from "./ProductBundle/ProductBundleCreate"
import { StockUpdateListing } from "./StockUpdate/StockUpdateListing"
import { StockUpdateCreate } from "./StockUpdate/StockUpdateCreate"
import { SupplierListing } from "./Supplier/SupplierListing"
import { StockListing } from "./Stock/StockListing"
import { InvoiceListing } from "./Invoice/InvoiceListing"
import { ShippingCalculator } from "./ShippingCalculator"
import { Playground } from "./Playground"
import { CustomerListing } from "./Customer/CustomerListing"
import { WarehouseListing } from "./Warehouse/WarehouseListing"
import { FeedbackListing } from "./Feedback/FeedbackListing"
import { PartnerListing } from "./Partner/PartnerListing"
import { PartnerCreateAndUpdate } from "./Partner/PartnerCreateAndUpdate"
import { SettingsDashboard } from "./Settings/SettingsDashboard"
import { TemplateEditor } from "./Liquid/TemplateEditor"
import { AdminListing } from "./Admin/AdminListing"
import { OrderListing as StaffOrderListing } from "./Staff/OrderListing"
import { TranslationsManager } from "./Admin/TranslationsManager"
import { EventsDashboard } from "./Admin/EventsDashboard"
import { FilesDashboard } from "./Admin/FilesDashboard"
import { CostCategoryDashboard } from "./Admin/CostCategoryDashboard"
import { TransactionalMessagesListing } from "./Admin/TransactionalMessagesListing"
import { TransactionalMessageUpsert } from "./Admin/TransactionalMessageUpsert"
import { ReturnsDashboard } from "./Returns/ReturnsDashboard"
import { ReturnReasonUpsert } from "./Returns/ReturnReason/Upsert"
import { ReturnDeliveryTypeUpsert } from "./Returns/DeliveryType/Upsert"

import {
  MESSAGE_RECEIVE_SUCCESS,
  NOTIFICATION_RECEIVE_SUCCESS,
  APP_CHANGE_FUNCTIONAL_PAGES_VISIBILITY,
} from "constants"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth
}))
class AppHoc extends React.Component {
  componentDidMount() {
    const { auth: { twoFactorApproved }, dispatch, history } = this.props

    if (!twoFactorApproved) {
      return history.push("/auth/sign-in")
    }

    dispatch({
      type: MESSAGE_RECEIVE_SUCCESS,
      payload: {
        messages: []
      }
    })

    dispatch({
      type: NOTIFICATION_RECEIVE_SUCCESS,
      payload: {
        notifications: []
      }
    })
  }

  renderAdminLinks() {
    const { auth: { hasAdminRights } } = this.props

    if (!hasAdminRights) {
      return (
        <main>
          <Box ghost>
            <h2>Unauthorized.</h2>
            <hr />
            <h4>You don't have rights to view this page.</h4>
          </Box>
        </main>
      )
    }

    return (
      <Switch>
        <Route exact path="/admin">
          <main>
            <Box ghost>
              <h2>Welcome to your Admin Dashboard.</h2>
            </Box>
          </main>
        </Route>
        <Route exact path="/admin/list">
          <AdminListing />
        </Route>
        <Route exact path="/admin/translations">
          <TranslationsManager />
        </Route>
        <Route exact path="/admin/events">
          <EventsDashboard />
        </Route>
        <Route exact path="/admin/cost-category">
          <CostCategoryDashboard />
        </Route>
        <Route exact path="/admin/transactional-messages">
          <TransactionalMessagesListing />
        </Route>
        <Route exact path="/admin/transactional-messages/create">
          <TransactionalMessageUpsert />
        </Route>
        <Route exact path="/admin/transactional-messages/:tmId/update">
          <TransactionalMessageUpsert updateMode={true} />
        </Route>
        <Route exact path="/admin/files">
          <FilesDashboard />
        </Route>
      </Switch>
    )
  }

  renderStaffLinks() {
    const { auth: { hasStaffRights } } = this.props

    if (!hasStaffRights) {
      return (
        <main>
          <Box ghost>
            <h2>Unauthorized.</h2>
            <hr />
            <h4>You don't have rights to view this page.</h4>
          </Box>
        </main>
      )
    }

    return (
      <Switch>
        <Route exact path="/staff">
          <main>
            <Box>
              <h2>Welcome to your Staff Dashboard.</h2>
              <hr />
              <NavLink to="/staff/orders/today">View today's orders</NavLink>
              <NavLink to="/staff/orders/new">View new orders</NavLink>
              <NavLink to="/staff/orders/shipped">View shipped orders</NavLink>
            </Box>
          </main>
        </Route>
        <Route path="/staff/orders/:type?" render={props => (
          <StaffOrderListing key={props.match.params.type} {...props} />
        )}>
        </Route>
      </Switch>
    )
  }

  getLinks() {
    const { app: { activeMerchant, showOnlyFunctionalPages } } = this.props

    if (showOnlyFunctionalPages) {
      return [
        {
          title: translate("merchants"),
          to: "/merchants",
          icon: <Icons.MerchantRegular />,
          hoverIcon: <Icons.MerchantHighlighted />,
          sublinks: [
            {
              title: "Merchants List",
              to: "/merchants",
              icon: <Icons.Edit />,
            },
          ]
        },
        {
          title: "Partner Portal",
          to: "/partners",
          icon: <Icons.PartnerRegular />,
          hoverIcon: <Icons.PartnerHighlighted />,
        },
        {
          title: "Returns Portal",
          to: "/returns",
          icon: <Icons.ReturnRegular />,
          hoverIcon: <Icons.ReturnHighlighted />,
        },
        {
          title: translate("misc"),
          to: "/misc",
          icon: <Icons.MiscRegular />,
          hoverIcon: <Icons.MiscHighlighted />,
        },
        {
          title: translate("admin-links"),
          to: "/feedbacks",
          icon: <Icons.AdminRegular />,
          hoverIcon: <Icons.AdminHighlighted />,
          sublinks: [
            {
              title: "Partner Portal",
              to: "/partners",
              icon: <Icons.Edit />,
            },
            {
              title: "Events",
              to: "/admin/events",
              icon: <Icons.Edit />,
            },
            {
              title: "Cost Category",
              to: "/admin/cost-category",
              icon: <Icons.Edit />,
            },
            {
              title: "Transactional Messages",
              to: "/admin/transactional-messages",
              icon: <Icons.Edit />,
            },
            {
              title: "File Uploader",
              to: "/admin/files",
              icon: <Icons.Edit />,
            },
          ]
        },
      ]
    }

    return [
      {
        title: "Homepage",
        to: "/",
        icon: <Icons.HomepageRegular />,
        hoverIcon: <Icons.HomepageHighlighted />,
      },
      {
        title: "UI Kit",
        to: "/playground",
        icon: <Icons.MiscRegular />,
        hoverIcon: <Icons.MiscHighlighted />,
        sublinks: [
          {
            title: "Actions",
            to: "/playground/actions",
            icon: <Icons.Edit />,
          },
          {
            title: "Color Palette",
            to: "/playground/color-palette",
            icon: <Icons.Edit />,
          },
          {
            title: "Table",
            to: "/playground/table",
            icon: <Icons.Edit />,
          },
          {
            title: "Typography",
            to: "/playground/typography",
            icon: <Icons.Edit />,
          },
          {
            title: "Boxes & Grid",
            to: "/playground/boxes",
            icon: <Icons.Edit />,
          },
          {
            title: "Progress Bars",
            to: "/playground/progress",
            icon: <Icons.Edit />,
          },
          {
            title: "Buttons",
            to: "/playground/buttons",
            icon: <Icons.Edit />,
          },
          {
            title: "UI",
            to: "/playground/ui",
            icon: <Icons.Edit />,
          },
          {
            title: "Forms",
            to: "/playground/forms",
            icon: <Icons.Edit />,
          },
          {
            title: "Icons",
            to: "/playground/icons",
            icon: <Icons.Edit />,
          },
          {
            title: "Loaders",
            to: "/playground/loader",
            icon: <Icons.Edit />,
          },
          {
            title: "Components",
            to: "/playground/components",
            icon: <Icons.Edit />,
          },
          {
            title: "Liquid Templates",
            to: "/playground/liquid",
            icon: <Icons.Edit />,
          },
          {
            title: "Modals",
            to: "/playground/modal",
            icon: <Icons.Edit />,
          },
        ]
      },
      {
        title: translate("merchants"),
        to: "/merchants",
        icon: <Icons.MerchantRegular />,
        hoverIcon: <Icons.MerchantHighlighted />,
        sublinks: [
          {
            title: "Merchants List",
            to: "/merchants",
            icon: <Icons.Edit />,
          },
          {
            title: "Add a new Merchant",
            to: "/merchants",
            icon: <Icons.Edit />,
          },
          {
            title: "Edit Merchant",
            to: `/merchants/${activeMerchant.id}/edit`,
            icon: <Icons.Edit />,
          },
          {
            title: "Deselect Merchant",
            to: "/merchants",
            icon: <Icons.Edit />,
          },
        ]
      },
      {
        title: translate("orders"),
        to: "/orders",
        icon: <Icons.OrdersRegular />,
        hoverIcon: <Icons.OrdersHighlighted />,
        sublinks: [
          {
            title: "Orders",
            to: "/orders",
            icon: <Icons.Edit />,
          },
          {
            title: "Add new Order",
            to: "/orders/create",
            icon: <Icons.Plus />,
          },
          {
            title: "Order Returns",
            to: "/order-returns",
            icon: <Icons.Edit />,
          },
          {
            title: "Metrics",
            to: "/orders",
            icon: <Icons.Edit />,
          },
        ]
      },
      {
        title: translate("products"),
        to: "/products",
        icon: <Icons.ProductsRegular />,
        hoverIcon: <Icons.ProductsHighlighted />,
        sublinks: [
          {
            title: "Create a Product",
            to: "/products/create",
            icon: <Icons.Plus />,
          },
          {
            title: "Products",
            to: "/products",
            icon: <Icons.Edit />,
          },
          {
            title: "Product Groups",
            to: "/product-groups",
            icon: <Icons.Edit />,
          },
          {
            title: "Bundles",
            to: "/product-bundles",
            icon: <Icons.Edit />,
          },
        ]
      },
      {
        title: translate("stock-updates"),
        to: "/stock-updates",
        icon: <Icons.StockUpdateRegular />,
        hoverIcon: <Icons.StockUpdateHighlighted />,
        sublinks: [
          {
            title: "Create a Stock Update",
            to: "/stock-updates/create",
            icon: <Icons.Plus />,
          },
          {
            title: "Stock Updates List",
            to: "/stock-updates",
            icon: <Icons.Edit />,
          },
          {
            title: "Suppliers",
            to: "/suppliers",
            icon: <Icons.Edit />,
          },
          {
            title: "Stock Locations",
            to: "/stock",
            icon: <Icons.Edit />,
          },
        ]
      },
      {
        title: "Partner Portal",
        to: "/partners",
        icon: <Icons.PartnerRegular />,
        hoverIcon: <Icons.PartnerHighlighted />,
      },
      {
        title: "Returns Portal",
        to: "/returns",
        icon: <Icons.ReturnRegular />,
        hoverIcon: <Icons.ReturnHighlighted />,
      },
      {
        title: translate("misc"),
        to: "/misc",
        icon: <Icons.MiscRegular />,
        hoverIcon: <Icons.MiscHighlighted />,
      },
      {
        title: translate("staff-links"),
        to: "/staff",
        icon: <Icons.AdminRegular />,
        hoverIcon: <Icons.AdminHighlighted />,
        sublinks: [
          {
            title: "To be shipped today",
            to: "/staff/orders/today",
            icon: <Icons.Edit />,
          },
          {
            title: "New orders",
            to: "/staff/orders/new",
            icon: <Icons.Edit />,
          },
          {
            title: "Shipped orders",
            to: "/staff/orders/shipped",
            icon: <Icons.Edit />,
          }
        ]
      },
      {
        title: translate("admin-links"),
        to: "/admin",
        icon: <Icons.AdminRegular />,
        hoverIcon: <Icons.AdminHighlighted />,
        sublinks: [
          {
            title: "Admins",
            to: "/admin/list",
            icon: <Icons.Edit />,
          },
          {
            title: "Customers",
            to: "/customers",
            icon: <Icons.Edit />,
          },
          {
            title: "Warehouses",
            to: "/warehouses",
            icon: <Icons.Edit />,
          },
          {
            title: "Feedbacks",
            to: "/feedbacks",
            icon: <Icons.Edit />,
          },
          {
            title: "Partner Portal",
            to: "/partners",
            icon: <Icons.Edit />,
          },
          {
            title: "Events",
            to: "/admin/events",
            icon: <Icons.Edit />,
          },
          {
            title: "Cost Category",
            to: "/admin/cost-category",
            icon: <Icons.Edit />,
          },
          {
            title: "Translation Manager",
            to: "/admin/translations",
            icon: <Icons.Edit />,
          },
          {
            title: "Transactional Messages",
            to: "/admin/transactional-messages",
            icon: <Icons.Edit />,
          },
          {
            title: "File Uploader",
            to: "/admin/files",
            icon: <Icons.Edit />,
          },
          {
            title: "Template Editor",
            to: "/liquid-templates",
            icon: <Icons.Edit />,
          },
          {
            title: "Settings",
            to: "/settings",
            icon: <Icons.Edit />,
          },
        ]
      },
      {
        title: translate("shipping-calculator"),
        to: "/shipping-calculator",
        icon: <Icons.CalculatorRegular />,
        hoverIcon: <Icons.CalculatorHighlighted />,
      },
      {
        title: "Template Editor",
        to: "/liquid-templates",
        icon: <Icons.MiscRegular />,
        hoverIcon: <Icons.MiscHighlighted />,
      },
      {
        title: "Settings",
        to: "/settings",
        icon: <Icons.MiscRegular />,
        hoverIcon: <Icons.MiscHighlighted />,
      },
    ]
  }

  render() {
    const {
      app: { isDemoMerchant, showOnlyFunctionalPages },
      auth: { hasAdminRights, hasStaffRights },
      history,
      dispatch
    } = this.props

    return (
      <>
        <section className="left-navigation">
          <img
            className="logo"
            src={"https://my.ogoship.com/images/ogo_white.svg"}
            onClick={() => history.push("/")}
          />
          <Navigation
            links={this.getLinks()}
          />
        </section>

        <Header />

        <section className="app-content">
          {isDemoMerchant && (
            <div className="app-content-global-alert">
              <p>Heads up! You are working as a Demo Merchant</p>
            </div>
          )}
          {showOnlyFunctionalPages && (
            <div className="app-content-global-alert">
              <p>
                Only functional pages are being shown.&nbsp;
                <a href="#" onClick={() => {
                  dispatch({
                    type: APP_CHANGE_FUNCTIONAL_PAGES_VISIBILITY,
                    payload: {
                      value: false
                    }
                  })
                }}>
                  Show all
                </a>
              </p>
            </div>
          )}
          <ScrollToTop>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/merchants">
                <MerchantListing />
              </Route>
              <Route exact path="/merchants/:merchantId/edit">
                <MerchantEdit />
              </Route>
              <Route exact path="/orders">
                <OrderListing />
              </Route>
              <Route exact path="/orders/create">
                <OrderCreate />
              </Route>
              <Route exact path="/order-returns">
                <OrderReturnListing />
              </Route>
              <Route exact path="/order-returns/create">
                <OrderReturnCreate />
              </Route>
              <Route exact path="/products">
                <ProductListing />
              </Route>
              <Route exact path="/products/create">
                <ProductCreate />
              </Route>
              <Route exact path="/product-groups">
                <ProductGroupListing />
              </Route>
              <Route exact path="/product-bundles">
                <ProductBundleListing />
              </Route>
              <Route exact path="/product-bundles/create">
                <ProductBundleCreate />
              </Route>
              <Route exact path="/stock-updates">
                <StockUpdateListing />
              </Route>
              <Route exact path="/suppliers">
                <SupplierListing />
              </Route>
              <Route exact path="/stock">
                <StockListing />
              </Route>
              <Route exact path="/stock-updates/create">
                <StockUpdateCreate />
              </Route>
              <Route exact path="/invoices">
                <InvoiceListing />
              </Route>
              <Route exact path="/misc">
                <Misc />
              </Route>
              <Route exact path="/returns">
                <ReturnsDashboard />
              </Route>
              <Route exact path="/returns/reasons/create">
                <ReturnReasonUpsert />
              </Route>
              <Route exact path="/returns/reasons/:reasonId/update">
                <ReturnReasonUpsert updateMode={true} />
              </Route>
              <Route exact path="/returns/delivery-types/create">
                <ReturnDeliveryTypeUpsert />
              </Route>
              <Route exact path="/returns/delivery-types/:typeId/update">
                <ReturnDeliveryTypeUpsert updateMode={true} />
              </Route>
              <Route exact path="/shipping-calculator">
                <ShippingCalculator />
              </Route>
              <Route exact path="/partners">
                <PartnerListing />
              </Route>
              <Route exact path="/partners/create">
                <PartnerCreateAndUpdate />
              </Route>
              <Route exact path="/partners/:partnerId/update">
                <PartnerCreateAndUpdate updateMode={true} />
              </Route>
              <Route exact path="/settings">
                <SettingsDashboard />
              </Route>
              <Route exact path="/liquid-templates">
                <TemplateEditor />
              </Route>
              <Route exact path="/playground">
                <Playground />
              </Route>
              <Route exact path="/playground/actions">
                <Playground display="actions" />
              </Route>
              <Route exact path="/playground/table">
                <Playground display="table" />
              </Route>
              <Route exact path="/playground/color-palette">
                <Playground display="color-palette" />
              </Route>
              <Route exact path="/playground/typography">
                <Playground display="typography" />
              </Route>
              <Route exact path="/playground/boxes">
                <Playground display="boxes" />
              </Route>
              <Route exact path="/playground/progress">
                <Playground display="progress" />
              </Route>
              <Route exact path="/playground/buttons">
                <Playground display="buttons" />
              </Route>
              <Route exact path="/playground/ui">
                <Playground display="ui" />
              </Route>
              <Route exact path="/playground/forms">
                <Playground display="forms" />
              </Route>
              <Route exact path="/playground/icons">
                <Playground display="icons" />
              </Route>
              <Route exact path="/playground/loader">
                <Playground display="loader" />
              </Route>
              <Route exact path="/playground/components">
                <Playground display="components" />
              </Route>
              <Route exact path="/playground/liquid">
                <Playground display="liquid" />
              </Route>
              <Route exact path="/playground/modal">
                <Playground display="modal" />
              </Route>
              <Route exact path="/playground/resources">
                <Playground display="resources" />
              </Route>
              {hasAdminRights && (
                <Route exact path="/customers">
                  <CustomerListing />
                </Route>
              )}
              {hasAdminRights && (
                <Route exact path="/warehouses">
                  <WarehouseListing />
                </Route>
              )}
              {hasAdminRights && (
                <Route exact path="/feedbacks">
                  <FeedbackListing />
                </Route>
              )}
              <Route path="/admin">
                {this.renderAdminLinks()}
              </Route>
              <Route path="/staff">
                {this.renderStaffLinks()}
              </Route>
              <Route component={() => (
                <main>
                  Page not found (or not implemented yet)
                </main>
              )} />
            </Switch>
          </ScrollToTop>
          <Tooltip />
          <Modal />
          <Footer />
        </section>
      </>
    )
  }
}

export { AppHoc }