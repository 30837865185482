import {
  LANGUAGE_EN,
  LANGUAGE_FI,
  LANGUAGE_DE,
  LANGUAGE_SV,
  LANGUAGE_DEFAULT,
  LANGUAGE_MISSING_TRANSLATION_MESSAGE,
} from "constants"

export const languages = {
  [LANGUAGE_EN]: require("translations/en-GB.json"),
  [LANGUAGE_FI]: require("translations/fi-FI.json"),
  [LANGUAGE_DE]: require("translations/de-DE.json"),
  [LANGUAGE_SV]: require("translations/sv-SE.json"),
}

import { store } from "index"

export function translate(key, params = {}) {
  const locale = store.getState().app.language
  key = key.toLowerCase()

  let translation = languages[locale][key]

  if (!translation) {
    console.error(`Translation key not found for locale ${locale} and key ${key}`)
    translation = key
  }

  const errorMessage = `${LANGUAGE_MISSING_TRANSLATION_MESSAGE}: ${key}`
  const replacements = Object.keys(params)

  // We will replace each parameter (e.g :name) with actual values.
  replacements.forEach(key => {
    translation = translation.replace(`:${key}`, params[key])
  })

  return translation || errorMessage
}

export function translateNode(key, params = {}) {
  const locale = store.getState().app.language
  key = key.toLowerCase()

  const words = languages[locale][key].split(" ")

  words.forEach((word, i) => {
    if (word.startsWith(":")) {
      words[i] = <React.Fragment key={i}>{params[word.replace(":", "")]}</React.Fragment>
      return
    }

    words[i] = <React.Fragment key={i}>{`${words[i]} `}</React.Fragment>
  })

  return <>{words}</>
}