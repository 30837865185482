import { Icons } from "icons"

import "./index.scss"

export class Heatmap extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    headers: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.any),
    })),
    maximumNumber: PropTypes.number,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    className: "",
    headers: [],
    values: [],
    maximumNumber: 0,
    onClick: null,
  }

  state = {
    hidden: false
  }

  toggle = () => {
    const { hidden } = this.state

    this.setState({ hidden: !hidden })
  }

  getColorIndex(num) {
    const { maximumNumber } = this.props
    return Math.round((num / maximumNumber) * 10)
  }

  render () {
    const { className, headers, values, onClick } = this.props
    const { hidden } = this.state

    return (
      <section className={classnames("com-Heatmap", className)}>
        <div key={"header"} className="heatmap-line">
          <div
            className={classnames("heatmap-cell", "informal-cell")}
            onClick={this.toggle}
          >
            {hidden ? <Icons.EyeHidden /> : <Icons.Eye />}
          </div>
          {headers.map((header, i) => {
            return (
              <div key={i} className={classnames("heatmap-cell", "header-cell", "no-hover")}>
                <p>{header}</p>
              </div>
            )
          })}
        </div>
        {values.map((value, i) => {
          return (
            <div key={i} className="heatmap-line">
              <div className={classnames("heatmap-cell", "informal-cell", "no-hover")}>
                <p>{value.name}</p>
              </div>
              {value.values.map((num, j) => {
                return (
                  <div
                    key={j}
                    className={classnames("heatmap-cell", `color-${this.getColorIndex(num)}`)}
                    onClick={() => {
                      onClick && onClick.apply(this, [i, j])
                    }}
                  >
                    {hidden && <p>&nbsp;</p>}
                    {!hidden && <p>{num}</p>}
                  </div>
                )
              })}
            </div>
          )
        })}
      </section>
    )
  }
}