import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box, useToggleHook } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Tab } from "components/Tab"
import { Table, useTableHook } from "components/Table"

export const MerchantHistoryArea = props => {
  const { dispatch, loading, rows, onRefresh, onAdd, onEdit, onDelete } = props
  const [tabIndex, setTabIndex] = React.useState(0)

  return (
    <>
      <Box
        className="merchant-history-area"
        {...useToggleHook({ toggled: false} )}
      >
        <h2>Merchant Statistics</h2>
        <hr />
        <p>Created at: <b>October 1, 2021</b></p>
        <p>Created by: <b>ridkous@qubstudio.com</b></p>
        <p>Modified at: <b>November 13, 2021</b></p>
        <p>Modified by: <b>tom@ogoship.com</b></p>
        <p>First stock update received: <b>March 2, 2022</b></p>
        <p>First shipment out: <b>February 18, 2022</b></p>
      </Box>

      <Box
        noPadding
        {...useToggleHook({ toggled: true} )}
      >
        <h2>History</h2>
        <hr />
        <Tab
          tabs={[
            {
              name: "Merchant History",
              active: tabIndex === 0,
            },
            {
              name: "Log History",
              active: tabIndex === 1,
            },
          ]}
          onClick={(_, i) => setTabIndex(i)}
          horizontal={true}
        />
        {tabIndex === 0 && (
          <Table
            {...useTableHook({
              rows: [
                {
                  id: "1",
                  date: "01/03/2021",
                  time: "10:49:17 PM",
                  user: "ridkous@qubstudio.com",
                  event: "PriceCurrency",
                  new_value: "SEK",
                  old_value: "",
                },
                {
                  id: "2",
                  date: "01/03/2021",
                  time: "10:49:17 PM",
                  user: "ridkous@qubstudio.com",
                  event: "StatusCode",
                  new_value: "Reserved",
                  old_value: "",
                },
              ]
            })}
            columns={[
              { title: "Date", attr: "date" },
              { title: "Time", attr: "time" },
              { title: "User", attr: "user" },
              { title: "Event", attr: "event" },
              { title: "New value", attr: "new_value" },
              { title: "Old value", attr: "old_value" },
            ]}
            noCheckbox
            noHead
            noFoot
            compact
          />
        )}
        {tabIndex === 1 && (
          <Table
            {...useTableHook({
              rows: [
                {
                  id: "1",
                  date: "01/05/2021",
                  time: "13:49:17 PM",
                  type: "Sync",
                  message: "Quick sync completed",
                },
                {
                  id: "2",
                  date: "01/05/2021",
                  time: "10:49:17 PM",
                  type: "Sync",
                  message: "Quick sync started",
                },
                {
                  id: "3",
                  date: "01/03/2021",
                  time: "11:49:17 PM",
                  type: "Sync",
                  message: "Quick sync completed",
                },
                {
                  id: "4",
                  date: "01/03/2021",
                  time: "10:00:00 PM",
                  type: "Sync",
                  message: "Quick sync started",
                },
              ]
            })}
            columns={[
              { title: "Date", attr: "date" },
              { title: "Time", attr: "time" },
              { title: "Type", attr: "type" },
              { title: "Message", attr: "message" },
            ]}
            noCheckbox
            noHead
            noFoot
            compact
          />
        )}
      </Box>
    </>
  )
}