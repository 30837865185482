import { translate } from "helpers/i18n"
import { useAsyncEffect } from "use-async-effect"
import { Api } from "api"

import { Modal } from "components/Layout/Modal"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { ScaleLoader } from "components/Loader/ScaleLoader"
import { Table, useTableHook } from "components/Table"

import { TM_RECIPIENT_STATUSES } from "constants"

export const RecipientsModal = props => {
  const { value, onClose } = props
  const [text, setText] = React.useState("")
  const [show, setShow] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [recipients, setRecipients] = React.useState([])

  useAsyncEffect(async () => {
    const res = await Api.transactionalMessage.getRecipients(value.uid)
    setRecipients(res.data)
    setLoading(false)
  }, [text])

  return (
    <Modal
      title={`View Recipients`}
      onClose={onClose}
      isSidebar
      noPadding
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("close")}
            onClick={onClose}
          />
        </>
      }
    >
      {/* @todo enable this feature when we get endpoints. */}
      {false && (
        <Input
          onChange={text => setText(text)}
          value={text}
          error={(show === false && text.length > 0) ? "No user found" : ""}
        />
      )}
      {show === true && (
        <Button
          type={BUTTON_TYPES.TERTIARY}
          title="Add to list"
          onClick={() => {
            setText("")
            setShow(false)
          }}
        />
      )}

      {loading && <ScaleLoader />}
      {!loading && (
        <Table
          columns={[
            { title: "Recipient", attr: "recipient" },
            { title: "Status", attr: "status" },
          ]}
          rows={recipients}
          columnRender={{
            status: row => {
              return TM_RECIPIENT_STATUSES[row.status]
            }
          }}
          noFoot
          noHead
          noFilters
          noCheckbox
          compact
        />
      )}
    </Modal>
  )
}