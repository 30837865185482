import { CSVLink } from "react-csv"

export class Exporter extends React.Component {

  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      attr: PropTypes.string,
    })),
    rows: PropTypes.arrayOf(PropTypes.any),
    render: PropTypes.node.isRequired,
  }

  static defaultProps = {
    headers: [],
    rows: [],
    render: null,
  }

  render () {
    const { headers, rows, render } = this.props

    const generatedHeaders = []
    const generatedRows = []

    headers.forEach(header => {
      generatedHeaders.push(header.title)
    })

    // @todo maybe memoize
    rows.forEach(row => {
      generatedRows.push([
        ...headers.map(header => row[header.attr])
      ])
    })

    return (
      <CSVLink
        filename={"export.csv"}
        data={[
          [...generatedHeaders],
          ...generatedRows
        ]}
      >
        {render}
      </CSVLink>
    )
  }
}