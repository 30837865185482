import { translate } from "helpers/i18n"

import { Box, useToggleHook } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Table, useTableHook } from "components/Table"
import { Tooltip, Selectbox, Checkbox } from "components/Ui"

export const InvoicingInformationArea = props => {
  return (
    <>
      <Box>
        <h2>Invoicing information</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              invoicingMethod: { value: "option-1", label: "Send using mail" },
              merchantType: { value: "type-1", label: "Type 1" },
              dueDays: null,
              note: "",
              differentAddress: "",
              useDifferentEmail: false,
              noVerificationNeeded: false,
            }
          }}
          rules={{
            note: Rules.string().nullable(),
            differentAddress: Rules.string().nullable(),
            dueDays: Rules.number().min(0).nullable(),
          }}
          render={[
            {
              label: "Invoicing Method",
              name: "invoicingMethod",
              tip: "Select invoicing method you wish to use.",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "option-1", label: "Send using mail" },
                    { value: "option-2", label: "Send using email" },
                    { value: "option-3", label: "Electronic Invoice (in Finland only)" }
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "",
              name: "useDifferentEmail",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Use a different address</p>
                      <p className="caption-s-400 soft">This option will let you add a different address</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              label: "Use Different Address",
              name: "differentAddress",
              condition: ({ values }) => (values().useDifferentEmail),
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                  textarea
                />
              )
            },
            {
              label: "Merchant Type",
              name: "merchantType",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "type-1", label: "Type 1" },
                    { value: "type-2", label: "Type 2" },
                    { value: "type-3", label: "Type 3" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Due (days)",
              name: "dueDays",
              node: ({ field, fieldState }) => (
                <Input
                  type="number"
                  min={0}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "",
              name: "noVerificationNeeded",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">No verification needed</p>
                      <p className="caption-s-400 soft">This option will ignore verification process</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              label: "Notes",
              name: "note",
              tip: "Text note visible at invoices",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                  textarea
                />
              )
            }
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Box
        noPadding
        {...useToggleHook({ toggled: false} )}
      >
        <h2>Recurring (monthly) Costs</h2>
        <hr />
        <Table
          {...useTableHook({
            rows: [
              {
                id: "1",
                service: "Premium service",
                period: "Monthly",
                start: "01.01.2020",
                end: "",
                price: "59.99 USD"
              },
              {
                id: "3",
                service: "OGOTracking+",
                period: "Annually",
                start: "01.01.2021",
                end: "01.09.2022",
                price: "150.00 USD"
              },
            ]
          })}
          columns={[
            { title: "Service", attr: "service" },
            { title: "Period", attr: "period" },
            { title: "Start", attr: "start" },
            { title: "End", attr: "end" },
            { title: "Price", attr: "price" },
          ]}
          noCheckbox
          noHead
          noFoot
          compact
        />
      </Box>
      <Tooltip />
    </>
  )
}