import { axios } from "../axios"
import { to } from "helpers/api"
import { store } from "index"

export const event = {
  getEvent: data => {
    return axios.post(to(`api/config/heatmap`), data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
}
