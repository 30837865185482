import { isDevelopment } from "helpers/env"

export const getBaseApiUrl = () => {
  if (isDevelopment()) {
    return `https://devapi.ogoship.com`
  }

  if (window.location.host.includes("beta")) {
    return `https://betaapi.ogoship.com`
  }

  return `https://api.ogoship.com`
}

export const getBaseUrl = () => {
  return window.location.origin
}

export const getAuthApiBaseUrl = () => {
  if (isDevelopment()) {
    return `https://beta.ogoship.com`
  }

  if (window.location.host.includes("beta")) {
    return `https://beta.ogoship.com`
  }

  return `https://my.ogoship.com`
}

export const to = (path) => {
  return `${getBaseApiUrl()}/${path}`
}
