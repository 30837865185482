import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Button, BUTTON_TYPES } from "components/Button"
import { Plusminus } from "components/Plusminus"
import { List } from "components/Ui"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  modal: state.modal,
}))
class ShippingCalculator extends React.Component {

  state = {
    warehouse: null,
    package: null,
    width: 0,
    length: 0,
    height: 0,
    weight: 0,
  }

  getWarehouses() {
    return [
      "OGOship EE-TLL",
      "OGOship GB-PFS",
      "OGOship SE-GOT",
      "OGOship NL-VNL",
      "OGOship FI-JKT",
      "OGOship SE-SKP",
      "OGOship FI-VNT",
      "Public testing warehouse"
    ]
  }

  getPackages() {
    return [
      {
        name: "Large Letter",
        width: 20,
        length: 30,
        height: 2,
        weight: 200,
      },
      {
        name: "Extra Large Letter",
        width: 20,
        length: 30,
        height: 5,
        weight: 500,
      },
      {
        name: "S sized parcel",
        width: 10,
        length: 10,
        height: 10,
        weight: 500,
      },
      {
        name: "Larger S sized parcel",
        width: 20,
        length: 20,
        height: 15,
        weight: 500,
      },
      {
        name: "L sized parcel",
        width: 31,
        length: 31,
        height: 31,
        weight: 7000,
      },
      {
        name: "Larger L sized parcel",
        width: 40,
        length: 50,
        height: 40,
        weight: 6000,
      },
      {
        name: "Other/custom",
        width: 0,
        length: 0,
        height: 0,
        weight: 0,
      },
    ]
  }

  generatePackageTitleNode(p, isSelected) {
    const { width, length, height, weight } = this.state

    const xSeperator = " × "
    const cmSeperator = " cm"
    const gSeperator = " g"

    const widthModified = isSelected && width !== p.width
    const lengthModified = isSelected && length !== p.length
    const heightModified = isSelected && height !== p.height
    const weightModified = isSelected && weight !== p.weight

    return (
      <>
        <p>{p.name}</p>
        <p>
          <span className={widthModified ? "bold" : ""}>{widthModified ? width : p.width}</span>
          {xSeperator}
          <span className={lengthModified ? "bold" : ""}>{lengthModified ? length : p.length}</span>
          {xSeperator}
          <span className={heightModified ? "bold" : ""}>{heightModified ? height : p.height}</span>
          {cmSeperator}
        </p>
        <p>
          <span className={weightModified ? "bold" : ""}>{weightModified ? weight : p.weight}</span>
          {gSeperator}
        </p>
      </>
    )
  }

  render() {
    const { width, length, height, weight } = this.state

    return (
      <main className="shipping-calculator-screen">
        <Boxlist>
          <Box>
            <h2>Shipment information</h2>
            <hr />
            <p>From</p>
            <List>
              {this.getWarehouses().map(warehouse => (
                <Button
                  key={warehouse}
                  title={warehouse}
                  isActive={warehouse === this.state.warehouse}
                  type={BUTTON_TYPES.TERTIARY}
                  onClick={() => {
                    this.setState({ warehouse })
                  }}
                />
              ))}
            </List>
          </Box>
          <Box>
            <h2>Choose or enter your package size</h2>
            <hr />
            <List>
              {this.getPackages().map(p => (
                <Button
                  key={p.name}
                  title={this.generatePackageTitleNode(p, p.name === this.state.package?.name)}
                  isActive={p.name === this.state.package?.name}
                  type={BUTTON_TYPES.TERTIARY}
                  onClick={() => {
                    this.setState({
                      package: p,
                      width: p.width,
                      length: p.length,
                      height: p.height,
                      weight: p.weight,
                    })
                  }}
                />
              ))}
            </List>
            <h3 data-tip="Width can not be bigger than 10.000 cm">Width <i>(Max: 10.000 cm)</i></h3>
            <Plusminus entity="cm" value={width} onChange={num => this.setState({ width: num })}/>
            <h3 data-tip="Length can not be bigger than 10.000 cm">Length <i>(Max: 10.000 cm)</i></h3>
            <Plusminus entity="cm" value={length} onChange={num => this.setState({ length: num })}/>
            <h3 data-tip="Height can not be bigger than 10.000 cm">Height <i>(Max: 10.000 cm)</i></h3>
            <Plusminus entity="cm" value={height} onChange={num => this.setState({ height: num })}/>
            <h3 data-tip="Weight can not be bigger than 1.000.000 g">Weight <i>(Max: 1.000.000 g)</i></h3>
            <Plusminus entity="g" value={weight} onChange={num => this.setState({ weight: num })}/>
          </Box>
        </Boxlist>
        <Boxlist>
          <Box>
            <h2>Instructions</h2>
            <hr />
            <p>
              This shipping cost calculator calculates the freight costs of shipments sent by OGOship.
              The calculator lists most popular OGOship shipping methods.
            </p>
            <p>The prices listed by the calculator are estimations, not binding offers.</p>
            <p>Shortcuts to example shipments:</p>
            <Button title="Sweden, Stockholm" type={BUTTON_TYPES.PRIMARY} />
            <Button title="Finland, Helsinki" type={BUTTON_TYPES.PRIMARY} />
            <Button title="USA, New York" type={BUTTON_TYPES.PRIMARY} />
            <Button title="France, Paris" type={BUTTON_TYPES.PRIMARY} />
            <p>Some shipping options require only the destination country but some require a zip code and/or city.</p>
            <p>
              The costs are calculated either by the actual weight of the parcel or by the volumetric weight.
              By hovering your pointer over the given price you can verify how the cost is calculated.
            </p>
          </Box>
        </Boxlist>
      </main>
    )
  }
}

export { ShippingCalculator }
