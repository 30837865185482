import { sortableContainer, sortableElement } from "react-sortable-hoc"
import { Icons } from "icons"
import { FeedbackLine } from "components/Feedback/FeedbackLine"

import {
  LANGUAGE_EN,
  LANGUAGE_DE,
  LANGUAGE_SV,
  LANGUAGE_FI,
} from "constants"

import "./index.scss"

const SortableItem = sortableElement(({value}) => value)

const SortableContainer = sortableContainer(({children}) => {
  return (
    <div className="sortable-container">
      {children}
    </div>
  )
})

export class FeedbackManager extends React.Component {

  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      subtitles: PropTypes.array
    })),
    onSortEnd: PropTypes.func,
    onEditClick: PropTypes.func,
  }

  static defaultProps = {
    items: [],
    onSortEnd: null,
    onEditClick: null,
  }

  state = {
    openIndexes: {},
  }

  onActiveClick(isActive, index, subindex = null) {
    const { openIndexes } = this.state
    const indexes = { ...openIndexes }
    const active = this.isActive(index, subindex)

    if (active) {
      delete indexes[index]
      this.setState({
        openIndexes: indexes
      })
      return
    }

    indexes[index] = []
    this.setState({
      openIndexes: indexes
    })
  }

  isActive(index, subindex = null) {
    const { openIndexes } = this.state
    const isMainActive = openIndexes.hasOwnProperty(index)

    if (subindex === null) {
      return isMainActive
    }

    return isMainActive && openIndexes[index].includes(subindex)
  }

  isAtLeastOneOpen() {
    const { openIndexes } = this.state

    return Object.keys(openIndexes).length > 0
  }

  render () {
    const { items, onSortEnd, onEditClick } = this.props

    return (
      <section className="com-FeedbackManager">
        <SortableContainer
          onSortEnd={onSortEnd}
          lockAxis="y"
          distance={0}
          shouldCancelStart={e => {
            // Fix for bug: https://github.com/clauderic/react-sortable-hoc/issues/461
            const tag = e.target.tagName.toLowerCase()

            if (this.isAtLeastOneOpen()) {
              return true
            }

            if (tag !== "div") {
              return true
            }

            return false
          }}
        >
          {items.map((item, index) => {
            return (
              <SortableItem
                key={index}
                index={index}
                value={
                  <FeedbackLine
                    key={`main-${index}`}
                    title={item.title}
                    active={this.isActive(index)}
                    onActiveClick={isActive => this.onActiveClick(isActive, index)}
                    onEditClick={() => {
                      onEditClick && onEditClick.apply(this, [
                        {
                          [LANGUAGE_EN]: item.title,
                          [LANGUAGE_DE]: item.title,
                          [LANGUAGE_SV]: item.title,
                          [LANGUAGE_FI]: item.title,
                        },
                        index
                      ])
                    }}
                    render={item.subtitles.map((subtitle, subindex) => {
                      return (
                        <FeedbackLine
                          key={`sub-${subindex}`}
                          title={subtitle.title}
                          active={this.isActive(index, subindex)}
                          onActiveClick={isActive => this.onActiveClick(isActive, index, subindex)}
                          onEditClick={() => {
                            onEditClick && onEditClick.apply(this, [
                              {
                                [LANGUAGE_EN]: subtitle.title,
                                [LANGUAGE_DE]: subtitle.title,
                                [LANGUAGE_SV]: subtitle.title,
                                [LANGUAGE_FI]: subtitle.title,
                              },
                              index,
                              subindex
                            ])
                          }}
                          hideActive
                        />
                      )
                    })}
                  />
                }
              />
            )
          })}
        </SortableContainer>
      </section>
    )
  }
}