import {
  TEST_ADD_NEW_CONNECTED_USER,
  MERCHANT_RECEIVE_REQUEST,
  MERCHANT_RECEIVE_SUCCESS,
  MERCHANT_RECEIVE_FAIL,
  MERCHANT_CONNECTED_USERS_RECEIVE_REQUEST,
  MERCHANT_CONNECTED_USERS_RECEIVE_SUCCESS,
  MERCHANT_CONNECTED_USERS_RECEIVE_FAIL,
  MERCHANT_DISCOUNTS_RECEIVE_REQUEST,
  MERCHANT_DISCOUNTS_RECEIVE_SUCCESS,
  MERCHANT_DISCOUNTS_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: false,
  merchants: [],
  connectedUsers: [],
  discounts: [],
}

export default function merchant(state = initialState, action) {
  switch (action.type) {
    case MERCHANT_RECEIVE_REQUEST:
    case MERCHANT_CONNECTED_USERS_RECEIVE_REQUEST:
    case MERCHANT_DISCOUNTS_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case MERCHANT_RECEIVE_SUCCESS:
      return {
        ...state,
        merchants: action.payload.merchants,
        loading: false,
      }

    case MERCHANT_CONNECTED_USERS_RECEIVE_SUCCESS:
      return {
        ...state,
        connectedUsers: action.payload.connectedUsers,
        loading: false,
      }

    case MERCHANT_DISCOUNTS_RECEIVE_SUCCESS:
      return {
        ...state,
        discounts: action.payload.discounts,
        loading: false,
      }

    case TEST_ADD_NEW_CONNECTED_USER:
      return {
        ...state,
        connectedUsers: [...state.connectedUsers, action.payload.user]
      }

    case MERCHANT_RECEIVE_FAIL:
    case MERCHANT_CONNECTED_USERS_RECEIVE_FAIL:
    case MERCHANT_DISCOUNTS_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
