import { translate } from "helpers/i18n"
import { Input } from "components/Input"
import { SpaceBetween } from "components/Ui"
import { Icons } from "icons"

import "./index.scss"

export class Header extends React.Component {

  static propTypes = {
    name: PropTypes.string,
    notifications: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string
    })),
    messages: PropTypes.arrayOf(PropTypes.shape({
      sender: PropTypes.string,
      message: PropTypes.string,
    })),
    languages: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      isSelected: PropTypes.bool,
    })),
    username: PropTypes.string,
    onSearch: PropTypes.func,
    onLanguageClick: PropTypes.func,
    onMerchantChangeClick: PropTypes.func,
    onRevertMerchant: PropTypes.func,
    onLogout: PropTypes.func,
  }

  static defaultProps = {
    name: "",
    notifications: [],
    messages: [],
    languages: [],
    username: "",
    onLanguageClick: null,
    onSearch: null,
    onMerchantChangeClick: null,
    onRevertMerchant: null,
    onLogout: null,
  }

  render () {
    const {
      name,
      notifications,
      messages,
      languages,
      username,
      onSearch,
      onLanguageClick,
      onMerchantChangeClick,
      onRevertMerchant,
      onLogout,
    } = this.props

    return (
      <header>
        <div className="header-left-side">
          <Input
            placeholder={"Search anything..."}
            leftIcon={<Icons.Search />}
            onChange={onSearch}
          />
        </div>
        <div className="header-right-side">
          <div className="active-merchant">
            <p>{name}</p>
            {onMerchantChangeClick && <Icons.ArrowDown onClick={onMerchantChangeClick} />}
            {onRevertMerchant && <Icons.Logout onClick={onRevertMerchant} />}
          </div>
          <div className={classnames("header-icon", {
            "has-highlight": false
          })}>
            <Icons.Notifications />
            <div className="header-dropdown notifications-dropdown">
              <h2>{translate("notifications")}</h2>

              <ul>
                {notifications.map((line, i) => (
                  <li key={i}>{line.title}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className={classnames("header-icon", {
            "has-highlight": false
          })}>
            <Icons.Email />
            <div className="header-dropdown email-dropdown">
              <h2>{translate("messages")}</h2>

              <ul>
                {messages.map((line, i) => (
                  <li key={i}>
                    <b>{line.sender}</b>
                    {line.message}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="header-icon">
            <Icons.World />
            <div className="header-dropdown language-dropdown">
              <ul>
                {languages.map(language => (
                  <li
                    key={language.key}
                    className={classnames({ "is-active": language.isSelected })}
                    onClick={() => onLanguageClick && onLanguageClick.apply(this, [language])}
                  >
                    {translate(`language.${language.key}`)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="header-icon">
            <Icons.Profile />
            <div className="header-dropdown profile-dropdown">
              <h2>{translate("profile")}</h2>

              <ul>
                <li>{username}</li>
                <li onClick={onLogout}>{translate("logout")}</li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    )
  }
}