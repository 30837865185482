import { Icons } from "icons"
import { Tab } from "components/Tab"

export const TabsArea = props => {
  const tabs = [
    "Official Merchant Information",
    "Channel information",
    "Invoicing information",
    "Connected Users",
    "Integrations",
    "Contact person",
    "Shipping Methods",
    "Shipments & Order Processing",
    "PRO-services",
    "Merchant Settings",
    "Merchant History",
    "Commercial Settings",
  ].map((tab, i) => ({
    name: tab,
    active: i === props.activeTab,
    hasBottomSeperator: i === 4 || i === 7,
    upperTitle: i === 8 ? "Admin" : "",
    icon: i > 7 ? <Icons.Lock /> : null
  }))

  return (
    <Tab
      tabs={tabs}
      onClick={props.onClick}
    />
  )
}