import {  withRouter } from "react-router-dom"

const ScrollToTop = withRouter(({ history, children }) => {
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [])

  return <>{children}</>
})

export { ScrollToTop }