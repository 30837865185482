import {
  SUPPLIER_RECEIVE_REQUEST,
  SUPPLIER_RECEIVE_SUCCESS,
  SUPPLIER_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: false,
  suppliers: [],
}

export default function supplier(state = initialState, action) {
  switch (action.type) {
    case SUPPLIER_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case SUPPLIER_RECEIVE_SUCCESS:
      return {
        ...state,
        suppliers: action.payload.suppliers,
        loading: false,
      }

    case SUPPLIER_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
