import { Icons } from "icons"

import { Box } from "components/Box"
import { Form } from "components/Form"

import "./index.scss"

export const NotificationSettings = props => {
  return (
    <Box>
      <h2>Notification Settings</h2>
      <hr />
      <p>Notification Settings</p>
    </Box>
  )
}