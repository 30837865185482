import { Icons } from "icons"
import { Input } from "components/Input"

import "./index.scss"

export const FeedbackLine = props => {
  const {
    title,
    hideActive,
    render,
    active,
    onActiveClick,
    onEditClick,
  } = props

  return (
    <div className="com-FeedbackLine">
      <div className="feedback-line-header">
        {title}
        <div className="icon-container">
          <Icons.Edit onClick={onEditClick} />
          {!hideActive && !active && <Icons.ArrowDown onClick={() => onActiveClick && onActiveClick.call(this, true)} />}
          {!hideActive && active && <Icons.ArrowUp onClick={() => onActiveClick && onActiveClick.call(this, false)} />}
        </div>
      </div>
      {active && (
        <div className="feedback-line-content">
          {render && (
            <div className="feedback-line-render-area">
              {render}
            </div>
          )}
        </div>
      )}
    </div>
  )
}