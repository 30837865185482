import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { PageHeader } from "components/PageHeader"
import { Button, BUTTON_TYPES } from "components/Button"
import { Breadcrumbs, Tag, TAG_TYPES } from "components/Ui"

import {
  WAREHOUSE_RECEIVE_REQUEST,
} from "constants"

import "./index.scss"

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    columns={[
      { title: "Warehouse Code", attr: "code" },
      { title: "Enabled", attr: "isEnabled", groupable: true },
    ]}
    columnRender={{
      isEnabled: (row, i) => {
        return (
          <Tag
            key={row.id}
            type={row.isEnabled ? TAG_TYPES.TEAL : TAG_TYPES.RED }
            title={row.isEnabled ? "Enabled" : "Disabled" }
            compact
          />
        )
      },
    }}
    noCheckbox
    noFilters
    noHead
    noFoot
  />
)

@withRouter
@connect((state) => ({
  warehouse: state.warehouse,
}))
class WarehouseListing extends React.Component {

  render() {
    const { warehouse: { warehouses, loading }, dispatch, history } = this.props

    return (
      <>
        <PageHeader
          prefix={"Review and manage all the warehouses"}
          title={"Warehouses"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "warehouses",
                },
              ]}
              activeIndex={1}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="warehouse-listing-screen">
          <Boxlist>
            <Box noPadding>
              <TableRegion
                loading={loading}
                rows={warehouses}
                onRefresh={qs => {
                  dispatch({
                    type: WAREHOUSE_RECEIVE_REQUEST,
                    payload: {
                      qs
                    }
                  })
                }}
              />
            </Box>
          </Boxlist>
        </main>
      </>
    )
  }
}

export { WarehouseListing }
