import {
  RETURN_REASON_RECEIVE_REQUEST,
  RETURN_REASON_RECEIVE_SUCCESS,
  RETURN_REASON_RECEIVE_FAIL,
  RETURN_REASON_REMOVE_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: true,
  reasons: [],
}

export default function returnReason(state = initialState, action) {
  switch (action.type) {
    case RETURN_REASON_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case RETURN_REASON_RECEIVE_SUCCESS:
      return {
        ...state,
        reasons: action.payload.reasons,
        loading: false,
      }

    case RETURN_REASON_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case RETURN_REASON_REMOVE_SUCCESS:
      return {
        ...state,
        reasons: state.reasons.filter(reason => reason.id !== action.payload.id),
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
