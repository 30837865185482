import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { List, Tag, TAG_TYPES } from "components/Ui"

export const SummaryArea = props => {
  return (
    <>
      <Box>
        <h2>Summary</h2>
        <hr />

        <List>
          <h3>Created</h3>
          <Tag title="03/16/2022 10:08 AM" type={TAG_TYPES.PRIMARY} compact />
        </List>
        <hr />

        <List>
          <h3>Estimated delivery date</h3>
          <Tag title="03/24/2022" type={TAG_TYPES.PRIMARY} compact />
        </List>
        <hr />

        <List>
          <h3>Warehouse</h3>
          <Tag title="Open demo-warehouse" type={TAG_TYPES.PRIMARY} compact />
        </List>
        <hr />

        <List>
          <h3>OGOship reference</h3>
          <Tag title="0322..8" type={TAG_TYPES.PRIMARY} compact />
        </List>
        <hr />

        <List>
          <h3>Containers</h3>
          <Tag title="5" type={TAG_TYPES.PRIMARY} compact />
        </List>
        <hr />

        <List>
          <h3>Supplier</h3>
          <Tag title="Test Supplier" type={TAG_TYPES.PRIMARY} compact />
        </List>
        <hr />

        <List>
          <h3>Product titles</h3>
          <Tag title="1" type={TAG_TYPES.PRIMARY} compact />
        </List>
        <hr />

        <List>
          <h3>Products</h3>
          <Tag title="2" type={TAG_TYPES.PRIMARY} compact />
        </List>
        <hr />

        <List>
          <h3>Stock update state</h3>
          <Tag title="NEW" type={TAG_TYPES.PRIMARY} compact />
        </List>
        <hr />
      </Box>
    </>
  )
}