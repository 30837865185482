import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box, useToggleHook } from "components/Box"
import { Form, Rules } from "components/Form"
import { Selectbox, Checkbox } from "components/Ui"
import { Input } from "components/Input"
import { Button, BUTTON_TYPES } from "components/Button"

import { SETTINGS_PROVIDER_RECEIVE_REQUEST } from "constants"

import "./index.scss"

export const ProviderSettings = props => {
  const {
    dispatch,
    onSave,
    settings: {
      loading,
      providers,
      ogomail,
      mailgun,
      smsTo,
      smsOgoship,
    },
  } = props

  const [emailProvider, setEmailProvider] = React.useState("mailgun")
  const [smsProvider, setSmsProvider] = React.useState("sms.to")

  React.useEffect(() =>  {
    dispatch({
      type: SETTINGS_PROVIDER_RECEIVE_REQUEST,
    })
  }, [])

  return (
    <>
      <Box
        {...useToggleHook({ toggled: true} )}
      >
        <h2>Email Provider Settings</h2>
        <hr />
        {!loading && (
          <>
            <Form
              className="has-bottom-margin-12"
              onWatch={data => {
                setEmailProvider(data.provider.value)
              }}
              data={{
                defaultValues: {
                  provider: providers.emailProviders.find(p => p.value === emailProvider),
                }
              }}
              rules={{
                provider: Rules.object(),
              }}
              render={[
                {
                  label: "Email Provider",
                  name: "provider",
                  node: ({ field, fieldState }) => (
                    <Selectbox
                      options={providers.emailProviders}
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
              ]}
              submitButtonLabel=""
              onSubmit={onSave}
            />
            {emailProvider === "ogomail" && (
              <Form
                id="settings-email-tab"
                data={{
                  defaultValues: {
                    SenderEmail: ogomail?.SenderEmail,
                    SenderName: ogomail?.SenderName,
                  }
                }}
                rules={{
                  SenderEmail: Rules.string().required().nullable(),
                  SenderName: Rules.string().required().nullable(),
                }}
                render={[
                  {
                    label: "Sender Email",
                    name: "SenderEmail",
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                  {
                    label: "Sender Name",
                    name: "SenderName",
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                ]}
                submitButtonLabel=""
                onSubmit={onSave}
              />
            )}
            {emailProvider === "mailgun" && (
              <Form
                id="settings-email-tab"
                data={{
                  defaultValues: {
                    ApiKey: mailgun?.ApiKey,
                    Domain: mailgun?.Domain,
                    SenderEmail: mailgun?.SenderEmail,
                    SenderName: mailgun?.SenderName,
                  }
                }}
                rules={{
                  ApiKey: Rules.string().required().nullable(),
                  Domain: Rules.string().required().nullable(),
                  SenderEmail: Rules.string().required().nullable(),
                  SenderName: Rules.string().required().nullable(),
                }}
                render={[
                  {
                    label: "Sender Email",
                    name: "SenderEmail",
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                  {
                    label: "Sender Name",
                    name: "SenderName",
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                  {
                    label: "API Key",
                    name: "ApiKey",
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                  {
                    label: "Domain",
                    name: "Domain",
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                ]}
                submitButtonLabel=""
                onSubmit={onSave}
              />
            )}
            <Button
              form="settings-email-tab"
              type={BUTTON_TYPES.GRAY}
              title={translate("save")}
            />
          </>
        )}
      </Box>

      <Box
        {...useToggleHook({ toggled: true} )}
      >
        <h2>SMS Provider Settings</h2>
        <hr />
        {!loading && (
          <>
            <Form
              className="has-bottom-margin-12"
              onWatch={data => {
                setSmsProvider(data.provider.value)
              }}
              data={{
                defaultValues: {
                  provider: providers.smsProviders.find(p => p.value === smsProvider),
                }
              }}
              rules={{
                provider: Rules.object(),
              }}
              render={[
                {
                  label: "SMS Provider",
                  name: "provider",
                  node: ({ field, fieldState }) => (
                    <Selectbox
                      options={providers.smsProviders}
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
              ]}
              submitButtonLabel=""
              onSubmit={onSave}
            />
            {smsProvider === "sms.ogoship" && (
              <Form
                id="settings-sms-tab"
                data={{
                  defaultValues: {
                    SenderId: smsOgoship?.SenderId,
                  }
                }}
                rules={{
                  SenderId: Rules.string().required().nullable(),
                }}
                render={[
                  {
                    label: "Sender ID",
                    name: "SenderId",
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                ]}
                submitButtonLabel=""
                onSubmit={onSave}
              />
            )}
            {smsProvider === "sms.to" && (
              <Form
                id="settings-sms-tab"
                data={{
                  defaultValues: {
                    SenderId: smsTo?.SenderId,
                    ClientSecret: smsTo?.ClientSecret,
                    ClientId: smsTo?.ClientId,
                    CallbackUrl: smsTo?.CallbackUrl,
                  }
                }}
                rules={{
                  SenderId: Rules.string().required().nullable(),
                  ClientSecret: Rules.string().required().nullable(),
                  ClientId: Rules.string().required().nullable(),
                  CallbackUrl: Rules.string().required().nullable(),
                }}
                render={[
                  {
                    label: "Sender ID",
                    name: "SenderId",
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                  {
                    label: "Client Secret",
                    name: "ClientSecret",
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                  {
                    label: "Client ID",
                    name: "ClientId",
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                  {
                    label: "Callback Url",
                    name: "CallbackUrl",
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                ]}
                submitButtonLabel=""
                onSubmit={onSave}
              />
            )}
            <Button
              form="settings-sms-tab"
              type={BUTTON_TYPES.GRAY}
              title={translate("save")}
            />
          </>
        )}
      </Box>
    </>
  )
}