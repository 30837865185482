import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"

import {
  FEEDBACK_RECEIVE_REQUEST,
  FEEDBACK_RECEIVE_SUCCESS,
  FEEDBACK_RECEIVE_FAIL,
} from "constants"

import { fakeFeedbacksBackend } from "mock"

function* receive(action) {
  try {
    const data = fakeFeedbacksBackend.update(action.payload?.qs).getJson()

    yield delay(1500)

    yield put({
      type: FEEDBACK_RECEIVE_SUCCESS,
      payload: {
        feedbacks: data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: FEEDBACK_RECEIVE_FAIL })
  }
}

function* feedbackReceiveSaga() {
  yield takeLatest(FEEDBACK_RECEIVE_REQUEST, receive)
}

export function* feedback() {
  yield all([
    call(feedbackReceiveSaga),
  ])
}
