import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { PageHeader } from "components/PageHeader"
import { Button, BUTTON_TYPES } from "components/Button"
import { Partner } from "components/Partner"
import { Breadcrumbs } from "components/Ui"
import { Modal } from "components/Layout/Modal"

import { PartnersTableArea } from "./PartnersTableArea"
import { CategoriesTableArea } from "./CategoriesTableArea"

import {
  MODAL_OPEN,
  PARTNER_RECEIVE_REQUEST,
  PARTNER_CATEGORY_RECEIVE_REQUEST,
  PARTNER_UPDATE_STATUS_REQUEST,
  PARTNER_STATUSES_ENUM,
} from "constants"

import "./index.scss"

@withRouter
@connect((state) => ({
  partner: state.partner,
}))
class PartnerListing extends React.Component {

  state = {
    value: null
  }

  closeModal = () => {
    this.setState({ value: null })
  }

  render() {
    const { partner: { partners, categories, loading }, dispatch, history } = this.props
    const { value } = this.state

    return (
      <>
        <PageHeader
          prefix={"List of the partners"}
          title={"Partners"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "partners",
                },
              ]}
              activeIndex={1}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="partner-listing-screen">
          <PartnersTableArea
            loading={loading}
            rows={partners}
            onRefresh={qs => {
              dispatch({
                type: PARTNER_RECEIVE_REQUEST,
                payload: {
                  qs
                }
              })
            }}
            onAdd={() => {
              history.push("/partners/create")
            }}
            onEdit={rows => {
              history.push(`/partners/${rows[0].id}/update`)
            }}
            onPreview={rows => {
              this.setState({ value: rows[0] })
            }}
            onDeactivate={rows => {
              dispatch({
                type: PARTNER_UPDATE_STATUS_REQUEST,
                payload: {
                  id: rows[0].id,
                  status: (rows[0].status === PARTNER_STATUSES_ENUM.Cancelled ?
                    PARTNER_STATUSES_ENUM.WaitingApproval :
                    PARTNER_STATUSES_ENUM.Cancelled
                  )
                }
              })
            }}
          />
          <CategoriesTableArea
            partners={partners}
            loading={loading}
            rows={categories.map(c => ({ name: c }))}
            onRefresh={qs => {
              dispatch({
                type: PARTNER_CATEGORY_RECEIVE_REQUEST
              })
            }}
            onEdit={rows => {
              history.push(`/partners/${rows[0].id}/update`)
            }}
          />
          {value && (
            <Modal
              title="Preview card"
              onClose={this.closeModal}
            >
              <Partner
                key={value.id}
                logo={value.logoUrl}
                name={value.name}
                type={`${value.partnerType} Partner`}
                description={value.shortInfo}
                render={
                  <Button
                    type={BUTTON_TYPES.PRIMARY}
                    title="Read more"
                  />
                }
              />
            </Modal>
          )}
        </main>
      </>
    )
  }
}

export { PartnerListing }
