import {
  CUSTOMER_RECEIVE_REQUEST,
  CUSTOMER_RECEIVE_SUCCESS,
  CUSTOMER_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: false,
  customers: [],
}

export default function customer(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case CUSTOMER_RECEIVE_SUCCESS:
      return {
        ...state,
        customers: action.payload.customers,
        loading: false,
      }

    case CUSTOMER_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
