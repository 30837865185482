import { withRouter } from "react-router-dom"
import { translate, translateNode } from "helpers/i18n"
import { isDevelopment } from "helpers/env"
import { getAuthApiBaseUrl, getBaseUrl } from "helpers/api"
import { Icons } from "icons"

import { Form, Rules } from "components/Form"
import { Input } from "components/Input"
import { Button, BUTTON_TYPES } from "components/Button"
import { ScaleLoader } from "components/Loader/ScaleLoader"

import { AUTH_LOGIN_REQUEST } from "constants"

export const AUTH_CALLBACK_API_URL = `${getAuthApiBaseUrl()}/OAuth/Authorize?response_type=token&redirect_uri=${getBaseUrl()}/auth/return&client_id=myOgo2Admin&redir=/`

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
}))
class SignIn extends React.Component {

  state = {
    showPassword: false,
    render: false,
  }

  componentDidMount() {
    // @todo This will be removed when we allow login through our system.
    // Currently only supported thing is auth via API.
    const { auth: { loggedIn } } = this.props

    if (!loggedIn && !isDevelopment()) {
      window.location = AUTH_CALLBACK_API_URL
      return
    }

    this.setState({
      render: true
    })
  }

  render() {
    const { dispatch, history, auth } = this.props
    const { showPassword, render } = this.state

    if (!render) {
      return (
        <section className="auth-sign-in-screen">
          <ScaleLoader />
        </section>
      )
    }

    return (
      <section className="auth-sign-in-screen">
        <h2>{translate("__todo1")}</h2>
        <div className="inner-container">
          <h5>{translateNode("__todo2", {
            num: <span>+38 066 111 11 11.</span>
          })}</h5>
          <Form
            id="sign-in-form"
            data={{
              defaultValues: {
                username: "",
                password: "",
              }
            }}
            rules={{
              username: Rules.string().required(translate("__todo3")),
              password: Rules.string().required(translate("__todo4")),
            }}
            render={[
              {
                label: translate("__todo5"),
                name: "username",
                node: ({ field, fieldState }) => {
                  return (
                    <Input
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                }
              },
              {
                label: translate("__todo6"),
                name: "password",
                node: ({ field, fieldState }) => (
                  <Input
                    type={showPassword ? "text" : "password"}
                    onChange={field.onChange}
                    value={field.value}
                    error={fieldState.error?.message}
                    button={
                      <>
                        {showPassword && (
                          <Icons.Eye
                            onClick={() => this.setState({ showPassword: false })}
                          />
                        )}
                        {!showPassword && (
                          <Icons.EyeHidden
                            onClick={() => this.setState({ showPassword: true })}
                          />
                        )}
                      </>
                    }
                  />
                )
              },
            ]}
            onSubmit={data => {
              dispatch({
                type: AUTH_LOGIN_REQUEST,
                payload: data,
                onSuccess: requires2FA => {
                  history.push(requires2FA ? "/auth/two-factor" : "/")
                }
              })
            }}
          />
          <div className="auth-buttons">
            <Button
              form="sign-in-form"
              title={translate("login")}
              type={BUTTON_TYPES.PRIMARY}
              isDisabled={auth.loading}
            />
            <Button
              title={translate("__todo7")}
              type={BUTTON_TYPES.LINK}
              onClick={() => {
                history.push("/auth/forgot-password")
              }}
            />
            <Button
              title={"test 2fa"}
              type={BUTTON_TYPES.LINK}
              onClick={() => {
                history.push("/auth/two-factor")
              }}
            />
            <Button
              title={"Login via callback"}
              type={BUTTON_TYPES.PRIMARY}
              onClick={() => {
                window.location = AUTH_CALLBACK_API_URL
              }}
            />
          </div>
        </div>
      </section>
    )
  }
}

export { SignIn }
