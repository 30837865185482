import { Icons } from "icons"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import { Input } from "components/Input"
import { translate } from "helpers/i18n"
import DatePicker from "react-datepicker"
import ReactTooltip from "react-tooltip"

import "react-datepicker/dist/react-datepicker.css"

import "./index.scss"

export const Tooltip = props => (
  <ReactTooltip {...props} />
)

export const SpaceBetween = props => {
  return (
    <div className="ui-SpaceBetween">
      {props.children}
    </div>
  )
}

export const Checkbox = props => {
  return (
    <div className={classnames("ui-Checkbox")} onClick={props.onClick}>
      <div className={classnames("ui-Checkbox-square", {
        checked: props.checked,
        halfChecked: props.halfChecked && !props.checked,
      })}></div>
      {props.title && (
        <div className="checkbox-label-container">
          {props.title}
        </div>
      )}
    </div>
  )
}

export const CheckboxList = props => {
  return (
    <div className={classnames("ui-Checkbox-List")}>
      {props.children}
    </div>
  )
}

export const TAG_TYPES = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  RED: "RED",
  YELLOW: "YELLOW",
  ORANGE: "ORANGE",
  BLUE: "BLUE",
  TEAL: "TEAL",
}

export const Tag = props => {
  return (
    <div
      onClick={props.onClick}
      className={classnames("ui-Tag", props.type, {
        "is-compact": props.compact
      })}
    >
      {props.leftIcon}
      <span>{props.title}</span>
      {props.icon}
    </div>
  )
}

export const List = props => {
  return (
    <div className={classnames("ui-List", props.className, {
      "no-margin": props.noMargin,
      "has-vertical-margin": props.hasVerticalMargin,
    })}>
      {props.children}
    </div>
  )
}

export const Warning = props => {
  return (
    <div className={classnames("ui-Warning")}>
      <p>{props.text}</p>
    </div>
  )
}

export const HeaderTab = props => {
  return (
    <div className={classnames("ui-Tab")}>
      <ul className="tab-list">
        {props.tabs.map((tab, i) => (
          <li
            key={i}
            className={classnames("tab-content", {
              active: props.activeTab === i,
              frozen: tab.frozen,
            })}
            onClick={() => props.activeTab !== i && props.onClick && props.onClick.apply(this, [i])}
          >
            {tab.name}
            {!tab.frozen && (
              <div
                className="tab-content-close"
                onClick={e => {
                  e.stopPropagation()
                  props.onRemove && props.onRemove.apply(this, [i])
                }}
              >
                X
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export const Selectbox = props => {
  const {
    name,
    options,
    value,
    onChange,
    isDisabled,
    isCreatable,
    error,
    isMulti,
  } = props

  const params = {
    name: name,
    classNamePrefix: "react-select",
    className: "react-select",
    options: options,
    onChange: onChange,
    value: value,
    noOptionsMessage: () => translate("no-options"),
    isDisabled: !!isDisabled,
    isMulti: isMulti,
  }

  return (
    <div className={classnames("ui-Selectbox", {
      "has-error": error
    })}>
      {isCreatable ? <CreatableSelect {...params} /> : <Select {...params} />}
      {error && (
        <div className="error-message-container">
          <Icons.Warning16 />
          <p>{error}</p>
        </div>
      )}
    </div>
  )
}

export const Datepicker = props => {
  const customInput = props.customInput || <Input frozen={true} />

  const monthFormat = "P"
  const timeFormat = "p"
  const dateFormat = `${!props.hideMonth ? monthFormat : ""}${props.showTime ? timeFormat : ""}`

  return (
    <div className={classnames("ui-Datepicker", {
      "is-month-hidden": props.hideMonth
    })}>
      <DatePicker
        dateFormat={dateFormat}
        monthsShown={1}
        customInput={customInput}
        selected={props.selected}
        showTimeSelect={props.showTime}
        onChange={props.onChange}
      />
    </div>
  )
}

export const Breadcrumbs = props => {
  const { links, activeIndex } = props
  return (
    <ul className={classnames("ui-Breadcrumbs")}>
      {links.map((link, i) => (
        <li
          key={link.title}
          className={classnames({
            active: i === activeIndex
          })}
          onClick={() => link.onClick.apply(this, [i])}
        >
          <p>{link.title}</p>
        </li>
      ))}
    </ul>
  )
}
