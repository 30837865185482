import { axios } from "../axios"
import { to } from "helpers/api"
import { store } from "index"

export const settings = {
  getProviders: () => {
    return axios.get(to(`api/ns/settings/providers`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  getProviderModel: provider => {
    return axios.get(to(`api/ns/settings/providers/${provider}/parameters/model`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
}
