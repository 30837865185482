import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Selectbox } from "components/Ui"
import { Uploader } from "components/Uploader"

export const AdditionalInformationArea = props => {
  return (
    <>
      <Box>
        <h2>Additional Information</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              supplier: { value: "supplier1", label: "Supplier 1" },
              containers: 0,
              pallets: 0,
              parcels: 0,
            }
          }}
          rules={{
            supplier: Rules.string().required(),
            containers: Rules.number().positive().required(),
            pallets: Rules.number().positive().required(),
            parcels: Rules.number().positive().required(),
          }}
          render={[
            {
              label: "Supplier",
              name: "supplier",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "supplier1", label: "Supplier 1" },
                    { value: "supplier2", label: "Supplier 2" },
                    { value: "supplier3", label: "Supplier 3" },
                    { value: "supplier4", label: "Supplier 4" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Containers",
              name: "containers",
              node: ({ field, fieldState }) => (
                <Input
                  type="number"
                  min={0}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Pallets",
              name: "pallets",
              node: ({ field, fieldState }) => (
                <Input
                  type="number"
                  min={0}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Parcels",
              name: "parcels",
              node: ({ field, fieldState }) => (
                <Input
                  type="number"
                  min={0}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Box>
        <h2>Related documents (PDF only)</h2>
        <hr />
        <Uploader />
      </Box>
    </>
  )
}