import { translate } from "helpers/i18n"
import { Icons } from "icons"

import "./index.scss"

export class FullScreenBoxLoader extends React.PureComponent {

  static propTypes = {
  }

  static defaultProps = {
  }

  render () {
    return (
      <section className="com-FullScreenBoxLoader">
        <h1>MyOGO 2.0</h1>
        <h3>{translate("loading")}...</h3>

        <Icons.BoxIcon1 />
        <Icons.BoxIcon2 />
        <Icons.BoxIcon3 />
      </section>
    )
  }
}
