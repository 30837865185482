import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Table, useTableHook } from "components/Table"
import { Button, BUTTON_TYPES } from "components/Button"

export const ReasonsTable = props => (
  <Table
    {...useTableHook({ ...props })}
    columns={[
      { title: "Code", attr: "code" },
      { title: "Warehouse Reason Code", attr: "warehouseReasonCode" },
      { title: "Channels", attr: "channelReferences" },
      { title: "Title", attr: "localizations" },
    ]}
    actions={[
      {
        title: translate("edit"),
        icon: <Icons.Edit />,
        massActionDisabled: true,
        action: props.onEdit,
      },
      {
        title: translate("delete"),
        isRed: true,
        massActionDisabled: true,
        icon: <Icons.Delete />,
        action: props.onDelete,
      }
    ]}
    buttons={
      <>
        <Button
          type={BUTTON_TYPES.GRAY}
          icon={<Icons.Plus />}
          onClick={props.onAdd}
          hasSmallRadius
        />
      </>
    }
    columnRender={{
      channelReferences: row => {
        if ((row.channelReferences || []).length === 0) {
          return "All Channels"
        }

        return row.channelReferences.map(c => c.channelId).join(", ")
      },
      localizations: row => {
        const found = row.localizations.find(t => t.lang === props.language)
        return found ? found.context : row.code
      },
    }}
    noCheckbox
    noFilters
  />
)
