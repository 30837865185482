import { Icons } from "icons"
import { useClickOutside } from "helpers/hooks"

import "./index.scss"

export const TreeLine = props => {
  const {
    id,
    name,
    code,
    parentId,
    onClick,
    openByDefault,
    actions,
    children,
  } = props

  const [ open, setOpen ] = React.useState(openByDefault)
  const { ref, isVisible, setIsVisible } = useClickOutside(false)

  return (
    <div className="com-TreeLine">
      <div className="main-line">
        <div
          className="tree-left-side"
          onClick={() => setOpen(prev => !prev)}
        >
          <div
            className={classnames("svg-container", {
              "has-children": children.length > 0
            })}
          >
            <Icons.ArrowRightRegular />
          </div>
          <div className="text-container">
            <p>{name}</p>
            <span>{code}</span>
          </div>
        </div>
        <div
          className="tree-right-side"
          onClick={() => actions.length > 0 && setIsVisible(true)}
        >
          <Icons.More/>
        </div>
        {isVisible && (
          <div ref={ref} className="tree-dropdown">
            <ul>
              {actions.map((action, i) => (
                <li
                  key={i}
                  className={classnames({
                    "is-red": action.isRed,
                  })}
                  onClick={() => {
                    action.onClick.apply(this, [{
                      id: id || null,
                      name: name,
                      code: code,
                      parentId: parentId || null,
                    }])
                    setIsVisible(false)
                  }}
                >
                  {action.title}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {children && open && (
        <div className="child-line">
          {children}
        </div>
      )}
    </div>
  )
}
