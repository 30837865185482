import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { PageHeader } from "components/PageHeader"
import { Button, BUTTON_TYPES } from "components/Button"
import { Breadcrumbs } from "components/Ui"
import { Tab } from "components/Tab"

import { DeliveryTypesTable } from "../DeliveryType/Table"
import { ReasonsTable } from "../ReturnReason/Table"

import {
  RETURN_REASON_RECEIVE_REQUEST,
  RETURN_DELIVERY_TYPE_RECEIVE_REQUEST,
  TAB_INDEX_UPDATE,
  MODAL_OPEN,
  MODAL_SCREEN_REMOVE_RETURN_REASON,
  MODAL_SCREEN_REMOVE_RETURN_DELIVERY_TYPE,
  MODAL_SCREEN_SHOW_WAREHOUSES_OF_COUNTRY,
  WAREHOUSE_RECEIVE_REQUEST,
} from "constants"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  tab: state.tab,
  returnReason: state.returnReason,
  returnDeliveryType: state.returnDeliveryType,
  warehouse: state.warehouse,
}))
class ReturnsDashboard extends React.Component {

  componentDidMount() {
    const { dispatch } = this.props

    dispatch({
      type: WAREHOUSE_RECEIVE_REQUEST
    })
  }

  render() {
    const {
      app: { language },
      tab: { returnsDashboardTabIndex },
      warehouse: { warehouses },
      returnReason,
      returnDeliveryType,
      dispatch,
      history,
    } = this.props

    return (
      <>
        <PageHeader
          prefix={"Review and manage all the returns"}
          title={"Returns"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "returns",
                },
              ]}
              activeIndex={1}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="returns-dashboard-screen">
          <Boxlist>
            <Box noPadding>
              <Tab
                tabs={["Reasons", "Delivery Types"].map((entry, i) => (
                  {
                    name: entry,
                    active: i === returnsDashboardTabIndex,
                  }
                ))}
                onClick={(_, i) => {
                  dispatch({
                    type: TAB_INDEX_UPDATE,
                    payload: {
                      attr: "returnsDashboardTabIndex",
                      value: i
                    }
                  })
                }}
                horizontal={true}
              />
            </Box>
          </Boxlist>
          <Boxlist>
            {returnsDashboardTabIndex === 0 && (
              <Box noPadding>
                <h2>Return Reasons</h2>
                <hr />
                <ReasonsTable
                  language={language}
                  loading={returnReason.loading}
                  rows={returnReason.reasons.filter(r => !r.isRemoved)}
                  onRefresh={qs => {
                    dispatch({
                      type: RETURN_REASON_RECEIVE_REQUEST,
                      payload: {
                        qs
                      }
                    })
                  }}
                  onAdd={() => {
                    history.push("/returns/reasons/create")
                  }}
                  onEdit={rows => {
                    history.push(`/returns/reasons/${rows[0].id}/update`)
                  }}
                  onDelete={rows => {
                    dispatch({
                      type: MODAL_OPEN,
                      payload: {
                        screen: MODAL_SCREEN_REMOVE_RETURN_REASON,
                        value: rows[0],
                      }
                    })
                  }}
                />
              </Box>
            )}

            {returnsDashboardTabIndex === 1 && (
              <Box noPadding>
                <h2>Return Delivery Types</h2>
                <hr />
                <DeliveryTypesTable
                  language={language}
                  loading={returnDeliveryType.loading}
                  rows={returnDeliveryType.types}
                  onRefresh={qs => {
                    dispatch({
                      type: RETURN_DELIVERY_TYPE_RECEIVE_REQUEST,
                      payload: {
                        qs
                      }
                    })
                  }}
                  onAdd={() => {
                    history.push("/returns/delivery-types/create")
                  }}
                  onEdit={rows => {
                    history.push(`/returns/delivery-types/${rows[0].id}/update`)
                  }}
                  onDelete={rows => {
                    dispatch({
                      type: MODAL_OPEN,
                      payload: {
                        screen: MODAL_SCREEN_REMOVE_RETURN_DELIVERY_TYPE,
                        value: rows[0],
                      }
                    })
                  }}
                  onCountryClick={country => {
                    dispatch({
                      type: MODAL_OPEN,
                      payload: {
                        screen: MODAL_SCREEN_SHOW_WAREHOUSES_OF_COUNTRY,
                        value: {
                          country,
                          warehouses
                        }
                      }
                    })
                  }}
                />
              </Box>
            )}
          </Boxlist>
        </main>
      </>
    )
  }
}

export { ReturnsDashboard }
