import { Box } from "components/Box"
import { Form, Rules } from "components/Form"
import { Button, BUTTON_TYPES } from "components/Button"
import { Selectbox, Datepicker } from "components/Ui"

import {
  TM_RECIPIENT_TYPES,
  TM_APPROVAL_TYPES,
} from "constants"

export const RecipientBox = props => {
  const { onWatch, defaultValues, onViewRecipients, updateMode } = props

  React.useEffect(() => {
    onWatch.apply(this, [defaultValues])
  }, [])

  return (
    <Box>
      <h2>General Information</h2>
      <hr />
      <Form
        onWatch={onWatch}
        data={{ defaultValues }}
        rules={{
          recipientType: Rules.object().required(),
          approvalType: Rules.object().required(),
          visibleFrom: Rules.string().required(),
          visibleTo: Rules.string().required(),
          approvalDeadline: Rules.string().required(),
        }}
        render={[
          {
            label: "Message recipient",
            name: "recipientType",
            mandatory: true,
            node: ({ field, fieldState }) => (
              <Selectbox
                options={Object.keys(TM_RECIPIENT_TYPES).map(key => (
                  {
                    value: key,
                    label: TM_RECIPIENT_TYPES[key]
                  }
                ))}
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "",
            name: "_recipients",
            condition: ({ values }) => {
              if (!updateMode) {
                return false
              }

              return [
                TM_RECIPIENT_TYPES[2],
                TM_RECIPIENT_TYPES[3],
              ].includes(values().recipientType.label)
            },
            node: ({ values }) => (
              <Button
                type={BUTTON_TYPES.TERTIARY}
                title={`View ${values().recipientType.label}`}
                onClick={onViewRecipients}
              />
            )
          },
          {
            label: "Approval required",
            name: "approvalType",
            mandatory: true,
            node: ({ field, fieldState }) => (
              <Selectbox
                options={Object.keys(TM_APPROVAL_TYPES).map(key => (
                  {
                    value: key,
                    label: TM_APPROVAL_TYPES[key]
                  }
                ))}
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            label: "Visible from",
            name: "visibleFrom",
            mandatory: true,
            node: ({ field, fieldState }) => (
              <Datepicker
                onChange={date => {
                  field.onChange(date)}
                }
                selected={field.value}
              />
            )
          },
          {
            label: "Visible to",
            name: "visibleTo",
            mandatory: true,
            node: ({ field, fieldState }) => (
              <Datepicker
                onChange={date => {
                  field.onChange(date)}
                }
                selected={field.value}
              />
            )
          },
          {
            label: "Approval deadline",
            name: "approvalDeadline",
            mandatory: true,
            node: ({ field, fieldState }) => (
              <Datepicker
                onChange={date => {
                  field.onChange(date)}
                }
                selected={field.value}
              />
            )
          },
        ]}
        submitButtonLabel=""
      />
    </Box>
  )
}