import qs from "qs"
import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Button, BUTTON_TYPES } from "components/Button"
import { PageHeader } from "components/PageHeader"
import { Form, Rules } from "components/Form"
import { Input } from "components/Input"
import { Breadcrumbs, Selectbox, Datepicker } from "components/Ui"
import { Heatmap } from "components/Heatmap"
import { ScaleLoader } from "components/Loader/ScaleLoader"

import { EVENT_RECEIVE_REQUEST } from "constants"

import "./index.scss"

const RESOLUTIONS = {
  Hour: {
    label: "Hourly",
    value: "Hour"
  },
  Day: {
    label: "Daily",
    value: "Day"
  },
}

const ONE_WEEK_AGO = Date.now() - (7 * 24 * 60 * 60 * 1000)

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  event: state.event,
}))
class EventsDashboard extends React.Component {

  componentDidMount() {
    const { history, location } = this.props

    if (!location.search) {
      history.push({
        pathname: "/admin/events",
        search: `?${new URLSearchParams({
          code1: "order",
          code2: "shipped",
          code3: "",
          start: new Date(ONE_WEEK_AGO).toLocaleDateString("en-US"),
          end: new Date().toLocaleDateString("en-US"),
          resolution: RESOLUTIONS.Day.value,
        }).toString()}`
      })
    }

    this.watch(this.defaults)
  }

  watch = data => {
    const { dispatch, history } = this.props

    history.push({
      pathname: "/admin/events",
      search: `?${new URLSearchParams({
        code1: data.code1,
        code2: data.code2,
        code3: data.code3,
        start: data.start.toLocaleDateString("en-US"),
        end: data.end.toLocaleDateString("en-US"),
        resolution: data.resolution.value,
      }).toString()}`
    })

    dispatch({
      type: EVENT_RECEIVE_REQUEST,
      payload: {
        data: {
          code1: data.code1,
          code2: data.code2,
          code3: data.code3,
          from: data.start.toISOString(),
          to: data.end.toISOString(),
          resolution: data.resolution.value,
        }
      }
    })
  }

  get defaults() {
    const { location: { search } } = this.props
    const parsed = qs.parse(search.charAt(0) === "?" ? search.substring(1) : search)

    return {
      code1: parsed.code1 || "order",
      code2: parsed.code2 || "shipped",
      code3: parsed.code3 || "",
      start: parsed.start ? new Date(parsed.start) : new Date(ONE_WEEK_AGO),
      end: parsed.end ? new Date(parsed.end) : new Date(),
      resolution: parsed.resolution ? RESOLUTIONS[parsed.resolution] : RESOLUTIONS.Day,
    }
  }

  render() {
    const { dispatch, history, event } = this.props

    return (
      <>
        <PageHeader
          prefix={"Review and manage all the events"}
          title={"Events"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "admin",
                  onClick: () => {
                    history.push("/admin")
                  }
                },
                {
                  title: "events",
                },
              ]}
              activeIndex={2}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="admin-events-screen">
          <Boxlist>
            <Box>
              <Form
                onWatch={this.watch}
                data={{
                  defaultValues: this.defaults
                }}
                rules={{
                  code1: Rules.string(),
                  code2: Rules.string(),
                  code3: Rules.string(),
                  start: Rules.string(),
                  end: Rules.string(),
                  resolution: Rules.object(),
                }}
                render={[
                  {
                    label: "Code 1",
                    name: "code1",
                    mandatory: true,
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                  {
                    label: "Code 2",
                    name: "code2",
                    mandatory: true,
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                  {
                    label: "Code 3",
                    name: "code3",
                    mandatory: true,
                    node: ({ field, fieldState }) => (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                  {
                    label: "Start",
                    name: "start",
                    node: ({ field, fieldState }) => (
                      <Datepicker
                        onChange={date => {
                          field.onChange(date)}
                        }
                        selected={field.value}
                      />
                    )
                  },
                  {
                    label: "End",
                    name: "end",
                    node: ({ field, fieldState }) => (
                      <Datepicker
                        onChange={date => {
                          field.onChange(date)}
                        }
                        selected={field.value}
                      />
                    )
                  },
                  {
                    label: "Resolution",
                    name: "resolution",
                    mandatory: true,
                    node: ({ field, fieldState }) => (
                      <Selectbox
                        options={Object.values(RESOLUTIONS)}
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  },
                ]}
              />
            </Box>
            <Box
              isBlocking={event.loading}
              blocksWith={<ScaleLoader />}
            >
              {!event.err && (
                <Heatmap
                  headers={event.data.headers || []}
                  values={event.data.heatMapData || []}
                  maximumNumber={event.maximumNumber}
                  onClick={(index, subindex) => {
                    console.log(index)
                    console.log(subindex)
                  }}
                />
              )}

              {event.err && (
                <p>{event.err}</p>
              )}
            </Box>
          </Boxlist>
        </main>
      </>
    )
  }
}

export { EventsDashboard }
