import JoditEditor from "jodit-react"

import "./index.scss"

export const Wysiwyg = props => {
  const { reference, onBlur, tabIndex, value } = props

  const attributes = {}

  if (reference) {
    attributes.ref = reference
  }

  return (
    <JoditEditor
      value={value}
      tabIndex={tabIndex}
      onBlur={value => onBlur && onBlur.apply(this, [value])}
      {...attributes}
    />
  )
}
