import { translate } from "helpers/i18n"

import { Input } from "components/Input"
import { Wysiwyg } from "components/Wysiwyg"
import { Form, Rules } from "components/Form"

import { LANGUAGE_LIST } from "constants"

export const LanguageHandler = props => {
  const { initialValues, onChange, type } = props

  const refs = React.useRef(Array.from({
    length: LANGUAGE_LIST.length
  }, () => React.createRef()))

  const inform = values => {
    const data = LANGUAGE_LIST.map(lang => (
      { lang, text: (values[lang] || "") }
    ))

    onChange.call(this, data)
  }

  const defaultValues = LANGUAGE_LIST.reduce((accumulator, lang) => {
    return {
      ...accumulator,
      [lang]: ((initialValues || []).find(val => val.lang === lang)?.text || "")
    }
  }, {})

  const getType1Values = () => {
    const values = refs.current.reduce((acc, ref, i) => {
      acc[LANGUAGE_LIST[i]] = ref?.current?.value || ""
      return acc
    }, {})

    return values
  }

  return (
    <Form
      data={{ defaultValues }}
      render={LANGUAGE_LIST.map((lang, i) => (
        {
          label: translate(`language.${lang}`),
          name: lang,
          node: ({ field, fieldState, values }) => {
            if (type === 0) {
              return (
                <Input
                  onChange={rest => {
                    field.onChange.call(this, rest)
                    inform(values())
                  }}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            }

            return (
              <Wysiwyg
                reference={refs.current[i]}
                tabIndex={i}
                value={field.value}
                onBlur={() => {
                  inform(getType1Values())
                }}
              />
            )
          }
        }
      ))}
    />
  )
}