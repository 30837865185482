import {
  SETTINGS_PROVIDER_RECEIVE_REQUEST,
  SETTINGS_PROVIDER_RECEIVE_SUCCESS,
  SETTINGS_PROVIDER_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: true,
  providers: [],
  ogomail: {},
  mailgun: {},
  smsTo: {},
  smsOgoship: {},
}

export default function settings(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_PROVIDER_RECEIVE_REQUEST:
      return {
        ...initialState
      }

    case SETTINGS_PROVIDER_RECEIVE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }

    case SETTINGS_PROVIDER_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
