import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"

export const BoxesArea = props => (
  <>
    <Boxlist>
      <Box>
        <p>BOX: Boxes are automatically 100% width.</p>
      </Box>
    </Boxlist>
    <Boxlist horizontal>
      <Box>
        <p>Boxes in Boxlist component are scaled automatically.</p>
      </Box>
      <Box>
        <p>Boxes in Boxlist component are scaled automatically.</p>
      </Box>
    </Boxlist>
  </>
)
