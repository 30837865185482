import {
  ModalScreenSelectMerchant,
  ModalScreenActivateShippingMethod,
  ModalScreenAddOrUpdateSupplier,
  ModalScreenRemoveSupplier,
  ModalScreenAddOrUpdateStock,
  ModalScreenRemoveStock,
  ModalScreenAddOrUpdateProductGroup,
  ModalScreenRemoveProductGroup,
  ModalScreenRemoveProductBundle,
  ModalScreenAddOrUpdateAdmin,
  ModalScreenRemoveAdmin,
  ModalScreenAddOrUpdateCustomer,
  ModalScreenRemoveCustomer,
  ModalScreenAddOrUpdateConnectedUser,
  ModalScreenRemoveConnectedUser,
  ModalScreenAddOrUpdateMerchantDiscount,
  ModalScreenRemoveMerchantDiscount,
  ModalScreenUpdateLanguage,
  ModalScreenRemoveReturnReason,
  ModalScreenRemoveReturnDeliveryType,
  ModalScreenShowWarehousesOfCountry,
  ModalScreenRemoveTransactionalMessage,
  ModalScreenUpdateCostCategory,
} from "containers/App/Layout/Modal/Screens"

import { ModalScreenSelectChannel } from "./Screens/SelectChannel"

import {
  MODAL_CLOSE,
  TEST_ADD_NEW_CONNECTED_USER,
  APP_CHANGE_ACTIVE_MERCHANT,
  RETURN_REASON_REMOVE_REQUEST,
  RETURN_DELIVERY_TYPE_REMOVE_REQUEST,
  TM_REMOVE_REQUEST,
  MODAL_SCREEN_SELECT_MERCHANT,
  MODAL_SCREEN_SELECT_CHANNEL,
  MODAL_SCREEN_ACTIVATE_SHIPPING_METHOD,
  MODAL_SCREEN_ADD_SUPPLIER,
  MODAL_SCREEN_UPDATE_SUPPLIER,
  MODAL_SCREEN_REMOVE_SUPPLIER,
  MODAL_SCREEN_ADD_STOCK,
  MODAL_SCREEN_UPDATE_STOCK,
  MODAL_SCREEN_REMOVE_STOCK,
  MODAL_SCREEN_ADD_PRODUCT_GROUP,
  MODAL_SCREEN_UPDATE_PRODUCT_GROUP,
  MODAL_SCREEN_REMOVE_PRODUCT_GROUP,
  MODAL_SCREEN_REMOVE_PRODUCT_BUNDLE,
  MODAL_SCREEN_ADD_ADMIN,
  MODAL_SCREEN_UPDATE_ADMIN,
  MODAL_SCREEN_REMOVE_ADMIN,
  MODAL_SCREEN_ADD_CUSTOMER,
  MODAL_SCREEN_UPDATE_CUSTOMER,
  MODAL_SCREEN_REMOVE_CUSTOMER,
  MODAL_SCREEN_ADD_CONNECTED_USER,
  MODAL_SCREEN_UPDATE_CONNECTED_USER,
  MODAL_SCREEN_REMOVE_CONNECTED_USER,
  MODAL_SCREEN_ADD_MERCHANT_DISCOUNT,
  MODAL_SCREEN_UPDATE_MERCHANT_DISCOUNT,
  MODAL_SCREEN_REMOVE_MERCHANT_DISCOUNT,
  MODAL_SCREEN_UPDATE_LANGUAGE,
  MODAL_SCREEN_REMOVE_RETURN_REASON,
  MODAL_SCREEN_REMOVE_RETURN_DELIVERY_TYPE,
  MODAL_SCREEN_SHOW_WAREHOUSES_OF_COUNTRY,
  MODAL_SCREEN_REMOVE_TM,
  MODAL_SCREEN_COST_CATEGORY_UPDATE,
} from "constants"

import "./index.scss"

@connect((state) => ({
  app: state.app,
  auth: state.auth,
  modal: state.modal,
  country: state.country,
}))
class Modal extends React.PureComponent {

  close = values => {
    const { dispatch, modal: { onClose } } = this.props

    onClose && onClose.apply(this, [values])

    dispatch({ type: MODAL_CLOSE })
  }

  render() {
    const {
      app: { activeMerchant },
      country,
      modal,
      dispatch
    } = this.props

    if (!modal.open) {
      return null
    }

    return (
      <main>
        {modal.screen === MODAL_SCREEN_SELECT_MERCHANT && (
          <ModalScreenSelectMerchant
            name={activeMerchant.name}
            onClose={this.close}
            onSwitch={option => {
              dispatch({
                type: APP_CHANGE_ACTIVE_MERCHANT,
                payload: {
                  activeMerchant: {
                    name: option.value
                  }
                }
              })
              this.close()
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_SELECT_CHANNEL && (
          <ModalScreenSelectChannel
            value={modal.value}
            dispatch={dispatch}
            onClose={this.close}
            onSelect={options => {
              modal.onSave.apply(this, [options])
              this.close()
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_ACTIVATE_SHIPPING_METHOD && (
          <ModalScreenActivateShippingMethod
            value={modal.value}
            onClose={this.close}
          />
        )}
        {modal.screen === MODAL_SCREEN_ADD_SUPPLIER && (
          <ModalScreenAddOrUpdateSupplier
            title="Add Supplier"
            onClose={this.close}
            onSave={name => {
              alert(name)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_UPDATE_SUPPLIER && (
          <ModalScreenAddOrUpdateSupplier
            title="Update Supplier"
            value={modal.value}
            onClose={this.close}
            onSave={name => {
              alert(name)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_REMOVE_SUPPLIER && (
          <ModalScreenRemoveSupplier
            value={modal.value}
            onClose={this.close}
            onRemove={() => {
              alert("to be removed")
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_ADD_STOCK && (
          <ModalScreenAddOrUpdateStock
            title="Add Stock"
            onClose={this.close}
            onSave={data => {
              console.log("onSave")
              console.log(data)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_UPDATE_STOCK && (
          <ModalScreenAddOrUpdateStock
            title="Update Stock"
            value={modal.value}
            onClose={this.close}
            onSave={data => {
              console.log("onSave")
              console.log(data)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_REMOVE_STOCK && (
          <ModalScreenRemoveStock
            value={modal.value}
            onClose={this.close}
            onRemove={() => {
              alert("to be removed")
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_ADD_PRODUCT_GROUP && (
          <ModalScreenAddOrUpdateProductGroup
            title="Add Product Group"
            onClose={this.close}
            onSave={name => {
              alert(name)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_UPDATE_PRODUCT_GROUP && (
          <ModalScreenAddOrUpdateProductGroup
            title="Update Product Group"
            value={modal.value}
            onClose={this.close}
            onSave={name => {
              alert(name)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_REMOVE_PRODUCT_GROUP && (
          <ModalScreenRemoveProductGroup
            value={modal.value}
            onClose={this.close}
            onRemove={() => {
              alert("to be removed")
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_REMOVE_PRODUCT_BUNDLE && (
          <ModalScreenRemoveProductBundle
            value={modal.value}
            onClose={this.close}
            onRemove={() => {
              alert("to be removed")
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_ADD_ADMIN && (
          <ModalScreenAddOrUpdateAdmin
            title="Add a new Admin"
            onClose={this.close}
            onSave={data => {
              console.log("onSave")
              console.log(data)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_UPDATE_ADMIN && (
          <ModalScreenAddOrUpdateAdmin
            title="Update Admin"
            value={modal.value}
            onClose={this.close}
            onSave={data => {
              console.log("onSave")
              console.log(data)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_REMOVE_ADMIN && (
          <ModalScreenRemoveAdmin
            value={modal.value}
            onClose={this.close}
            onRemove={() => {
              alert("to be removed")
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_ADD_CUSTOMER && (
          <ModalScreenAddOrUpdateCustomer
            title="Add a new Customer"
            onClose={this.close}
            onSave={data => {
              console.log("onSave")
              console.log(data)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_UPDATE_CUSTOMER && (
          <ModalScreenAddOrUpdateCustomer
            title="Update Customer"
            value={modal.value}
            onClose={this.close}
            onSave={data => {
              console.log("onSave")
              console.log(data)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_REMOVE_CUSTOMER && (
          <ModalScreenRemoveCustomer
            value={modal.value}
            onClose={this.close}
            onRemove={() => {
              alert("to be removed")
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_ADD_CONNECTED_USER && (
          <ModalScreenAddOrUpdateConnectedUser
            title="Connect a new user"
            onClose={this.close}
            onSave={data => {
              dispatch({
                type: TEST_ADD_NEW_CONNECTED_USER,
                payload: {
                  user: {
                    ...data,
                    status: 0
                  }
                }
              })
              this.close()
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_UPDATE_CONNECTED_USER && (
          <ModalScreenAddOrUpdateConnectedUser
            title="Update Connected User"
            value={modal.value}
            onClose={this.close}
            onSave={data => {
              console.log("onSave")
              console.log(data)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_REMOVE_CONNECTED_USER && (
          <ModalScreenRemoveConnectedUser
            value={modal.value}
            onClose={this.close}
            onRemove={() => {
              alert("to be removed")
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_ADD_MERCHANT_DISCOUNT && (
          <ModalScreenAddOrUpdateMerchantDiscount
            title="Add a new discount"
            onClose={this.close}
            onSave={data => {
              console.log("onSave")
              console.log(data)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_UPDATE_MERCHANT_DISCOUNT && (
          <ModalScreenAddOrUpdateMerchantDiscount
            title="Edit Discount"
            value={modal.value}
            onClose={this.close}
            onSave={data => {
              console.log("onSave")
              console.log(data)
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_REMOVE_MERCHANT_DISCOUNT && (
          <ModalScreenRemoveMerchantDiscount
            value={modal.value}
            onClose={this.close}
            onRemove={() => {
              alert("to be removed")
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_UPDATE_LANGUAGE && (
          <ModalScreenUpdateLanguage
            title="Update translations"
            value={modal.value}
            onClose={this.close}
            onSave={modal.onSave}
          />
        )}
        {modal.screen === MODAL_SCREEN_REMOVE_RETURN_REASON && (
          <ModalScreenRemoveReturnReason
            value={modal.value}
            onClose={this.close}
            onRemove={() => {
              dispatch({
                type: RETURN_REASON_REMOVE_REQUEST,
                payload: {
                  id: modal.value.id
                }
              })
              this.close()
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_REMOVE_RETURN_DELIVERY_TYPE && (
          <ModalScreenRemoveReturnDeliveryType
            value={modal.value}
            onClose={this.close}
            onRemove={() => {
              dispatch({
                type: RETURN_DELIVERY_TYPE_REMOVE_REQUEST,
                payload: {
                  id: modal.value.id
                }
              })
              this.close()
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_SHOW_WAREHOUSES_OF_COUNTRY && (
          <ModalScreenShowWarehousesOfCountry
            countriesDict={country.countriesDict}
            value={modal.value}
            onClose={this.close}
          />
        )}
        {modal.screen === MODAL_SCREEN_REMOVE_TM && (
          <ModalScreenRemoveTransactionalMessage
            value={modal.value}
            onClose={this.close}
            onRemove={() => {
              dispatch({
                type: TM_REMOVE_REQUEST,
                payload: {
                  id: modal.value.uid
                }
              })
              this.close()
            }}
          />
        )}
        {modal.screen === MODAL_SCREEN_COST_CATEGORY_UPDATE && (
          <ModalScreenUpdateCostCategory
            title="Update Cost Category"
            value={modal.value}
            onClose={this.close}
            onSave={data => {
              modal.onSave.apply(this, [data])
              this.close()
            }}
          />
        )}
      </main>
    )
  }
}

export { Modal }