import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { CircularProgress } from "components/CircularProgress"

export const ProgressArea = props => (
  <Boxlist>
    <Box className="progress-area">
      <h2>Default</h2>
      <CircularProgress value={50}/>

      <h2>Size: 100</h2>
      <CircularProgress value={50} size={100} />

      <h2>strokeWidth: 2</h2>
      <CircularProgress value={50} strokeWidth={2} />

      <h2>Children and hideText</h2>
      <CircularProgress value={50} strokeWidth={4} hideText>
        <p>Ogoship</p>
      </CircularProgress>

      <h2>CircleRatio</h2>
      <CircularProgress value={50} circleRatio={0.5} />

      <h2>CircleRatio and Rotation</h2>
      <CircularProgress value={50} circleRatio={0.5} rotation={0.75} hideText>
        <p>Ogoship</p>
      </CircularProgress>

      <h2>Colors (css variables passable)</h2>
      <CircularProgress value={50} colors={
        {
          textColor: "var(--color-blue500)",
          pathColor: "var(--color-blue900)",
          trailColor: "var(--color-blue50)",
        }
      }/>
    </Box>
  </Boxlist>
)
