// Containers
import { ActionsArea } from "./ActionsArea"
import { ColorPaletteArea } from "./ColorPaletteArea"
import { TableArea } from "./TableArea"
import { TypographyArea } from "./TypographyArea"
import { BoxesArea } from "./BoxesArea"
import { ProgressArea } from "./ProgressArea"
import { ButtonsArea } from "./ButtonsArea"
import { UiArea } from "./UiArea"
import { FormsArea } from "./FormsArea"
import { IconsArea } from "./IconsArea"
import { LoaderArea } from "./LoaderArea"
import { ComponentsArea } from "./ComponentsArea"
import { LiquidArea } from "./LiquidArea"
import { ModalArea } from "./ModalArea"

// Components
import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Modal } from "components/Layout/Modal"
import { Button, BUTTON_TYPES } from "components/Button"
import { Checkbox, HeaderTab } from "components/Ui"

import "./index.scss"

export const FilterArea = props => {
  const warehouses = [
    "Helsinki Warehouse",
    "Tampere Warehouse",
    "Lviv Warehouse",
  ]

  return (
    <Modal
      title="Table Filters"
      onClose={props.onClose}
      isSidebar={true}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={"Cancel"}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            title={"Save"}
          />
        </>
      }
    >
      <h3>Warehouse</h3>
      {warehouses.map((warehouse, i) => (
        <Checkbox
          key={warehouse}
          title={warehouse}
          checked={props.checkedWarehouses.includes(i)}
        />
      ))}
    </Modal>
  )
}

class Playground extends React.Component {

  state = {
    isFilterOpen: false,
    tabs: [{ name: "Users List", frozen: true }],
    activeTab: 0,
    checkedWarehouses: [0, 2],
  }

  render() {
    const { display } = this.props
    const { tabs, activeTab, checkedWarehouses, isFilterOpen } = this.state

    if (display === "actions") {
      return (
        <main className="playground-screen">
          <ActionsArea />
        </main>
      )
    }

    if (display === "color-palette") {
      return (
        <main className="playground-screen">
          <ColorPaletteArea />
        </main>
      )
    }

    if (display === "table") {
      return (
        <>
          <HeaderTab
            tabs={tabs}
            activeTab={activeTab}
            onClick={activeTab => {
              this.setState({ activeTab })
            }}
            onRemove={index => {
              this.setState({
                activeTab: index - 1,
                tabs: tabs.filter((_, i) => i !== index)
              })
            }}
          />
          <main className="playground-screen">

            {activeTab === 0 && (
              <TableArea
                onFilterClick={() => {
                  this.setState({ isFilterOpen: !isFilterOpen })
                }}
                onOpenTabs={rows => {
                  console.log([
                      ...tabs,
                      rows.map(row => ({ name: row.name }))
                    ])
                  this.setState({
                    tabs: [
                      ...tabs,
                      ...rows.map(row => ({ name: row.name, data: row }))
                    ]
                  })
                }}
              />
            )}

            {activeTab > 0 && (
              <Boxlist>
                <Box>
                  <FormsArea name={tabs[activeTab].data.name} />
                </Box>
              </Boxlist>
            )}

            {isFilterOpen && (
              <FilterArea
                onClose={() => {
                  this.setState({ isFilterOpen: false })
                }}
                checkedWarehouses={checkedWarehouses}
              />
            )}
          </main>
        </>
      )
    }

    if (display === "typography") {
      return (
        <main className="playground-screen">
          <TypographyArea />
        </main>
      )
    }

    if (display === "boxes") {
      return (
        <main className="playground-screen">
          <BoxesArea />
        </main>
      )
    }

    if (display === "progress") {
      return (
        <main className="playground-screen">
          <ProgressArea />
        </main>
      )
    }

    if (display === "buttons") {
      return (
        <main className="playground-screen">
          <ButtonsArea />
        </main>
      )
    }

    if (display === "ui") {
      return (
        <main className="playground-screen">
          <UiArea />
        </main>
      )
    }

    if (display === "forms") {
      return (
        <main className="playground-screen">
          <FormsArea />
        </main>
      )
    }

    if (display === "icons") {
      return (
        <main className="playground-screen">
          <IconsArea />
        </main>
      )
    }

    if (display === "loader") {
      return (
        <main className="playground-screen">
          <LoaderArea />
        </main>
      )
    }

    if (display === "components") {
      return (
        <main className="playground-screen">
          <ComponentsArea />
        </main>
      )
    }

    if (display === "liquid") {
      return (
        <main className="playground-screen">
          <LiquidArea />
        </main>
      )
    }

    if (display === "modal") {
      return (
        <main className="playground-screen">
          <ModalArea />
        </main>
      )
    }

    return (
      <main className="playground-screen">
        <p>There is nothing on the main page of playground.</p>
      </main>
    )
  }
}

export { Playground }
