import {
  PARTNER_RECEIVE_REQUEST,
  PARTNER_RECEIVE_SUCCESS,
  PARTNER_RECEIVE_FAIL,
  PARTNER_CATEGORY_RECEIVE_REQUEST,
  PARTNER_CATEGORY_RECEIVE_SUCCESS,
  PARTNER_CATEGORY_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: false,
  partners: [],
  categories: [],
}

export default function partner(state = initialState, action) {
  switch (action.type) {
    case PARTNER_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case PARTNER_RECEIVE_SUCCESS:
      return {
        ...state,
        partners: action.payload.partners,
        loading: false,
      }

    case PARTNER_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case PARTNER_CATEGORY_RECEIVE_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
