import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import * as actions from "constants"

export const ActionsArea = props => {
  return (
    <Boxlist>
      <Box className="actions-area">
        <h2>Actions of App</h2>
        <pre>
        {Object.keys(actions).map(action => (
          <p key={action}>{action}</p>
        ))}
        </pre>
      </Box>
    </Boxlist>
  )
}