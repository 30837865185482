import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { List, Tag, TAG_TYPES  } from "components/Ui"
import { ShippingMethod } from "components/ShippingMethod"

export const ShippingMethodsArea = props => {
  const { shippingMethods, onDeactivateClick, onAddNewClick } = props

  return (
    <>
      <Box className="shipping-methods-area">
        <h2>Shipping Methods</h2>
        <hr />
        {shippingMethods.map((method, i) => (
          <React.Fragment key={i}>
            <ShippingMethod
              name={method.name}
              description={method.description}
              logo={method.logo}
              isActivated={method.activated}
              onClick={() => {
                onDeactivateClick && onDeactivateClick.apply(this, [i])
              }}
              footer={
                <List>
                  <Tag
                    title="Tag"
                    type={TAG_TYPES.SECONDARY}
                    compact
                  />
                </List>
              }
            />
            <hr />
          </React.Fragment>
        ))}
        <Button
          title={"New shipping method"}
          type={BUTTON_TYPES.GRAY}
          onClick={onAddNewClick}
        />
      </Box>
    </>
  )
}