import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Checkbox } from "components/Ui"

import {
  APP_CHANGE_FUNCTIONAL_PAGES_VISIBILITY
} from "constants"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
}))
class Misc extends React.Component {

  render() {
    const { app: { showOnlyFunctionalPages }, dispatch } = this.props

    return (
      <main className="misc-screen">
        <Boxlist>
          <Box>
            <h2>Misc</h2>
            <hr />
            <Checkbox
              title={showOnlyFunctionalPages ? "Show only functional pages" : "Show all pages"}
              checked={showOnlyFunctionalPages}
              onClick={() => {
                dispatch({
                  type: APP_CHANGE_FUNCTIONAL_PAGES_VISIBILITY,
                  payload: {
                    value: !showOnlyFunctionalPages
                  }
                })
              }}
            />
          </Box>
        </Boxlist>
      </main>
    )
  }
}

export { Misc }
