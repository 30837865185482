import { axios } from "../axios"
import { to } from "helpers/api"
import { store } from "index"

export const returns = {
  getReturnDeliveryTypes: () => {
    return axios.get(to("api/os/ReturnDeliveryTypes/GetAll?showHidden=true"), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  getReturnDeliveryType: (id) => {
    return axios.get(to(`api/os/ReturnDeliveryTypes/${id}`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  createReturnDeliveryType: (data) => {
    return axios.post(to("api/os/ReturnDeliveryTypes"), data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  updateReturnDeliveryType: (id, data) => {
    return axios.put(to(`api/os/ReturnDeliveryTypes/${id}`), data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  removeReturnDeliveryType: (id, data) => {
    return axios.delete(to(`api/os/ReturnDeliveryTypes/${id}`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  getReturnReasons: () => {
    return axios.get(to("api/config/ReturnReasons/GetFullList"), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  getReturnReason: (id) => {
    return axios.get(to(`api/config/ReturnReasons/${id}`))
  },
  createReturnReason: (data) => {
    return axios.post(to("api/config/ReturnReasons"), data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  updateReturnReason: (id, data) => {
    return axios.put(to(`api/config/ReturnReasons/${id}`), data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  removeReturnReason: (id, data) => {
    return axios.delete(to(`api/config/ReturnReasons/${id}`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
}
