import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Form, Rules } from "components/Form"
import { Selectbox, Checkbox } from "components/Ui"
import { Input } from "components/Input"
import { Button, BUTTON_TYPES } from "components/Button"

import "./index.scss"

export const GeneralSettings = props => {
  const { levels, onSave } = props

  return (
    <>
      <Box>
        <h2>General Settings</h2>
        <hr />
        <Form
          id="settings-general-tab"
          data={{
            defaultValues: {
              enabled: false,
              level: { value: "Free", label: "Free" },
              url: "",
            }
          }}
          rules={{
            level: Rules.object(),
          }}
          render={[
            {
              label: "",
              name: "enabled",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Enable Feature</p>
                      <p className="caption-s-400 soft">If enabled, your users will be able to use the ReBuy features, such as the Tracking Service.</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              label: "Package Level",
              name: "level",
              condition: ({ values }) => (values().enabled),
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={levels.map(level => (
                    { value: level.Item2, label: level.Item1 }
                  ))}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "URL",
              name: "url",
              condition: ({ values }) => (values().enabled),
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
          ]}
          submitButtonLabel=""
          onSubmit={onSave}
        />
        <Button
          form="settings-general-tab"
          type={BUTTON_TYPES.GRAY}
          title={translate("save")}
        />
      </Box>
    </>
  )
}