import { call, put, all, take, takeLatest } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"

import {
  APP_INITIALIZE_REQUEST,
  APP_INITIALIZE_SUCCESS,
  APP_INITIALIZE_FAIL,
  CURRENCY_RECEIVE_REQUEST,
  CURRENCY_RECEIVE_SUCCESS,
  COUNTRY_RECEIVE_REQUEST,
  COUNTRY_RECEIVE_SUCCESS,
  WAREHOUSE_RECEIVE_REQUEST,
  WAREHOUSE_RECEIVE_SUCCESS,
} from "constants"

function* initialize(action) {
  try {
    yield put({ type: CURRENCY_RECEIVE_REQUEST })
    yield take(CURRENCY_RECEIVE_SUCCESS)

    yield put({ type: COUNTRY_RECEIVE_REQUEST })
    yield take(COUNTRY_RECEIVE_SUCCESS)

    yield put({ type: WAREHOUSE_RECEIVE_REQUEST })
    yield take(WAREHOUSE_RECEIVE_SUCCESS)

    yield put({ type: APP_INITIALIZE_SUCCESS })

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: APP_INITIALIZE_FAIL })
  }
}

function* appInitializeSaga() {
  yield takeLatest(APP_INITIALIZE_REQUEST, initialize)
}

export function* app() {
  yield all([
    call(appInitializeSaga),
  ])
}
