import { TreeLine } from "./TreeLine"

import "./index.scss"

export class InfiniteTree extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
      options: PropTypes.shape({
        openByDefault: PropTypes.bool
      }),
      childCategories: PropTypes.array,
    })),
    actions: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    className: "",
    data: [],
    actions: [],
  }

  renderRecursive(entry) {
    const { actions } = this.props

    return (
      <TreeLine
        key={entry.code}
        id={entry.id}
        parentId={entry.parentId}
        name={entry.name}
        code={entry.code}
        openByDefault={!!entry.options?.openByDefault}
        actions={actions || []}
      >
        {entry.childCategories && entry.childCategories.map(e => {
          return this.renderRecursive(e)
        })}
      </TreeLine>
    )
  }

  render () {
    const { data, className } = this.props

    return (
      <section className={classnames("com-InfiniteTree", className)}>
        {data.map(entry => {
          return this.renderRecursive(entry)
        })}
      </section>
    )
  }
}