import { arrayMoveImmutable } from "array-move"

import { Box, useToggleHook } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { FeedbackManager } from "components/Feedback/FeedbackManager"

import {
  MODAL_OPEN,
  MODAL_CLOSE,
  MODAL_SCREEN_UPDATE_LANGUAGE
} from "constants"

export const FeedbackManagerArea = props => {
  const { dispatch, options } = props

  const [items, setItems] = React.useState(options)

  const updateState = (data, index, subindex) => {
    const clone = [...items]

    if (typeof subindex !== "number") {
      clone[index].title = data.en
    } else {
      clone[index].subtitles[subindex].title = data.en
    }

    setItems(clone)
  }

  return (
    <Boxlist>
      <Box {...useToggleHook({ toggled: true} )}>
        <h2>Manage Feedbacks</h2>
        <hr />
        <FeedbackManager
          items={items}
          onSortEnd={sort => {
            setItems(arrayMoveImmutable(items, sort.oldIndex, sort.newIndex))
          }}
          onEditClick={(data, index, subindex) => {
            dispatch({
              type: MODAL_OPEN,
              payload: {
                screen: MODAL_SCREEN_UPDATE_LANGUAGE,
                value: data,
                onSave: data => {
                  updateState(data, index, subindex),
                  dispatch({ type: MODAL_CLOSE })
                }
              }
            })
          }}
        />
      </Box>
    </Boxlist>
  )
}
