import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { Button, BUTTON_TYPES } from "components/Button"

import {
  MODAL_OPEN,
  MODAL_SCREEN_ADD_STOCK,
  MODAL_SCREEN_UPDATE_STOCK,
  MODAL_SCREEN_REMOVE_STOCK,
  STOCK_RECEIVE_REQUEST
} from "constants"

import "./index.scss"

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    title={"Stock Locations"}
    description="Review and manage all the stock locations"
    columns={[
      { title: "Name", attr: "name" },
      { title: "Warehouse", attr: "warehouse" },
      { title: "Location", attr: "location" },
      { title: "Merchant", attr: "merchant" },
      { title: "Products Rows", attr: "productsRows" },
      { title: "Products Amount", attr: "productsAmount" },
      { title: "Stock Type", attr: "stockType" },
    ]}
    actions={[
      {
        title: translate("edit"),
        action: (rows, i) => {
          props.dispatch({
            type: MODAL_OPEN,
            payload: {
              screen: MODAL_SCREEN_UPDATE_STOCK,
              value: rows[0],
            }
          })
        }
      },
      {
        title: translate("delete"),
        isRed: true,
        action: (rows, i) => {
          props.dispatch({
            type: MODAL_OPEN,
            payload: {
              screen: MODAL_SCREEN_REMOVE_STOCK,
              value: rows[0],
            }
          })
        }
      },
    ]}
    buttons={
      <>
        <Button
          type={BUTTON_TYPES.GRAY}
          icon={<Icons.Plus />}
          onClick={() => {
            props.dispatch({
              type: MODAL_OPEN,
              payload: {
                screen: MODAL_SCREEN_ADD_STOCK
              }
            })
          }}
          hasSmallRadius
        />
      </>
    }
    noFilters
  />
)

@withRouter
@connect((state) => ({
  stock: state.stock,
}))
class StockListing extends React.Component {

  render() {
    const { stock: { stocks, loading }, dispatch } = this.props

    return (
      <main className="StockListing-screen">
        <Boxlist>
          <Box noPadding>
            <TableRegion
              dispatch={dispatch}
              loading={loading}
              rows={stocks}
              onRefresh={qs => {
                dispatch({
                  type: STOCK_RECEIVE_REQUEST,
                  payload: {
                    qs
                  }
                })
              }}
            />
          </Box>
        </Boxlist>
      </main>
    )
  }
}

export { StockListing }
