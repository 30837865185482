import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Button, BUTTON_TYPES } from "components/Button"
import { List } from "components/Ui"

export const ButtonsArea = props => (
  <>
    <Boxlist>
      <Box>
        <pre>
          <p>title: string</p>
          <p>type: string</p>
          <p>leftIcon: node</p>
          <p>icon: node</p>
          <p>noShadow: bool</p>
          <p>isDisabled: bool</p>
          <p>isHovered: bool</p>
          <p>isCompact: bool</p>
          <p>isActive: bool</p>
          <p>onClick: func</p>
        </pre>

        {Object.values(BUTTON_TYPES).map(val => {
          return (
            <List key={val}>
              <Button
                type={BUTTON_TYPES[val]}
                title={val}
                icon={<Icons.Reload />}
                leftIcon={<Icons.Download />}
              />

              <Button
                type={BUTTON_TYPES[val]}
                title="hovered"
                icon={<Icons.Reload />}
                isHovered
              />

              <Button
                type={BUTTON_TYPES[val]}
                title="active"
                icon={<Icons.Reload />}
                isActive
              />

              <Button
                type={BUTTON_TYPES[val]}
                title="disabled"
                icon={<Icons.Reload />}
                isDisabled
              />

              <Button
                type={BUTTON_TYPES[val]}
                title="compact"
                icon={<Icons.Reload />}
                isCompact
              />
            </List>
          )
        })}

        <h3>Icon Button</h3>
        <List>
          <Button
            type={BUTTON_TYPES.GRAY}
            icon={<Icons.Reload />}
          />
          <Button
            type={BUTTON_TYPES.GRAY}
            icon={<Icons.Reload />}
            isCompact
            hasSmallRadius
          />
        </List>

        <h3>Onboarding Button</h3>
        <List className="onboarding-button-list">
          <Button
            title="Onboarding"
            type={BUTTON_TYPES.GRAY}
            icon={<Icons.Reload />}
            hasSmallRadius
          />
          <Button
            title="Onboarding"
            type={BUTTON_TYPES.GRAY}
            icon={<Icons.Reload />}
            isDisabled
            hasSmallRadius
          />
        </List>

        <h3>Dynamic Title</h3>
        <Button
          title={
            <>
              <h3>You can pass custom JSX as title.</h3>
              <p className="body-s-400">Here is a cat image inside button.</p>
              <img src="https://placekitten.com/g/200/100" />
            </>
          }
          hasSmallRadius
          type={BUTTON_TYPES.TERTIARY}
        />
      </Box>
    </Boxlist>
  </>
)
