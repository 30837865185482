import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Button, BUTTON_TYPES } from "components/Button"
import { PageHeader } from "components/PageHeader"
import { Breadcrumbs } from "components/Ui"
import { InfiniteTree } from "components/InfiniteTree"
import { ScaleLoader } from "components/Loader/ScaleLoader"

import {
  COST_CATEGORY_RECEIVE_REQUEST,
  COST_CATEGORY_CREATE_REQUEST,
  COST_CATEGORY_REMOVE_REQUEST,
  COST_CATEGORY_UPDATE_REQUEST,
  MODAL_OPEN,
  MODAL_SCREEN_COST_CATEGORY_UPDATE,
} from "constants"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  costCategory: state.costCategory,
}))
class CostCategoryDashboard extends React.Component {

  componentDidMount() {
    const { dispatch } = this.props

    dispatch({
      type: COST_CATEGORY_RECEIVE_REQUEST,
    })
  }

  render() {
    const { dispatch, history, costCategory: { loading, costCategories, err } } = this.props

    return (
      <>
        <PageHeader
          prefix={"Manage all the cost categories"}
          title={"Cost Category"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "admin",
                  onClick: () => {
                    history.push("/admin")
                  }
                },
                {
                  title: "cost-category",
                },
              ]}
              activeIndex={2}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main>
          <Boxlist>
            <Box
              isBlocking={loading}
              blocksWith={<ScaleLoader />}
            >
              {err && (
                <p>{err}</p>
              )}

              {!err && (
                <InfiniteTree
                  data={costCategories}
                  actions={[
                    {
                      title: "Create: here",
                      onClick: category => {
                        dispatch({
                          type: COST_CATEGORY_CREATE_REQUEST,
                          payload: {
                            data: {
                              parentId: category.parentId,
                              name: "New category",
                              code: "New category",
                              childCategories: [],
                            }
                          }
                        })
                      }
                    },
                    {
                      title: "Create: under",
                      onClick: category => {
                        dispatch({
                          type: COST_CATEGORY_CREATE_REQUEST,
                          payload: {
                            data: {
                              parentId: category.id,
                              name: "New category",
                              code: "New category",
                              childCategories: [],
                            }
                          }
                        })
                      }
                    },
                    {
                      title: "Edit",
                      onClick: category => {
                        dispatch({
                          type: MODAL_OPEN,
                          payload: {
                            screen: MODAL_SCREEN_COST_CATEGORY_UPDATE,
                            value: category,
                            onSave: data => {
                              dispatch({
                                type: COST_CATEGORY_UPDATE_REQUEST,
                                payload: {
                                  id: category.id,
                                  data: {
                                    parentId: category.parentId,
                                    name: data.name,
                                    code: data.code,
                                  }
                                }
                              })
                            }
                          },
                        })
                      }
                    },
                    {
                      title: "Remove",
                      isRed: true,
                      onClick: category => {
                        dispatch({
                          type: COST_CATEGORY_REMOVE_REQUEST,
                          payload: {
                            id: category.id
                          }
                        })
                      }
                    },
                  ]}
                />
              )}
            </Box>
          </Boxlist>
        </main>
      </>
    )
  }
}

export { CostCategoryDashboard }
