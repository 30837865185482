import {
  LANGUAGE_CHANGE,
  LANGUAGE_DEFAULT,
  APP_INITIALIZE_SUCCESS,
  APP_CHANGE_ACTIVE_MERCHANT,
  APP_CHANGE_FUNCTIONAL_PAGES_VISIBILITY,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

import { isDevelopment } from "helpers/env"

const initialState = {
  language: LANGUAGE_DEFAULT,
  initialized: false,
  activeMerchant: {
    id: "2ff43e19-ed4a-45e8-8be7-cb58ee372dd6",
    name: "ReBuy Test Merchant"
  },
  originalMerchant: {
    id: "2ff43e19-ed4a-45e8-8be7-cb58ee372dd6",
    name: "ReBuy Test Merchant"
  },
  isDemoMerchant: false,
  showOnlyFunctionalPages: !isDevelopment(),
}

export default function app(state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_CHANGE:
      return {
        ...state,
        language: action.payload.language
      }

    case APP_INITIALIZE_SUCCESS:
      return {
        ...state,
        initialized: true
      }

    case APP_CHANGE_ACTIVE_MERCHANT:
      return {
        ...state,
        activeMerchant: action.payload.activeMerchant
      }

    case APP_CHANGE_FUNCTIONAL_PAGES_VISIBILITY:
      return {
        ...state,
        showOnlyFunctionalPages: action.payload.value
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState
      }

    default:
      return state
  }
}
