import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Heatmap } from "components/Heatmap"
import { Plusminus } from "components/Plusminus"
import { InfiniteTree } from "components/InfiniteTree"
import { Uploader } from "components/Uploader"

import { Api } from "api"

export const ComponentsArea = props => {
  const [num, setNum] = React.useState(0)

  return (
    <>
      <Boxlist>
        <Box>
          <h2>Plusminus component</h2>
          <hr />
          <Plusminus entity="cm" value={num} onChange={num => setNum(num)}/>
        </Box>
        <Box>
          <h2>Heatmap component</h2>
          <hr />
          <Heatmap
            headers={[
              "01.01",
              "01.02",
              "01.03",
              "01.04",
              "01.05",
              "01.06",
              "01.07",
              "01.08",
              "01.09",
              "01.10",
            ]}
            values={[
              {
                name: "12:00",
                values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              },
              {
                name: "13:00",
                values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              },
              {
                name: "14:00",
                values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              },
              {
                name: "15:00",
                values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              },
            ]}
            maximumNumber={10}
            onClick={(index, subindex) => {
              console.log(index)
              console.log(subindex)
            }}
          />
        </Box>
        <Box>
          <h2>InfiniteTree</h2>
          <hr />
          <InfiniteTree
            data={[
              {
                id: "1",
                parentId: null,
                name: "Name 1",
                code: "Code 1",
                options: {
                  openByDefault: true
                },
                childCategories: [
                  {
                    id: "2",
                    parentId: "1",
                    name: "Name 1.1",
                    code: "Code 1.1",
                    childCategories: [],
                  },
                ]
              }
            ]}
            actions={[]}
          />
        </Box>
        <Box>
          <h2>Uploader</h2>
          <hr />
          <Uploader />
        </Box>
      </Boxlist>
    </>
  )
}

