import { withRouter, NavLink } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { CircularProgress } from "components/CircularProgress"
import { Button, BUTTON_TYPES } from "components/Button"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  modal: state.modal,
}))
class Home extends React.Component {

  render() {
    return (
      <main className="home-screen">
        <Boxlist>
          <Box className="home-progress-bars-box">
            <h2>Statuses</h2>
            <hr />
            <CircularProgress
              circleRatio={0.8}
              rotation={0.6}
              hideText
              strokeWidth={4}
              value={30}
              colors={{
                textColor: "var(--color-blue500)",
                pathColor: "var(--color-blue900)",
                trailColor: "var(--color-blue50)",
              }}
            >
              <h3 className="color-blue">New</h3>
              <p className="body-m-400 color-blue">25%</p>
              <p className="body-s-500 color-blue">100 Orders</p>
            </CircularProgress>
            <CircularProgress
              circleRatio={0.8}
              rotation={0.6}
              hideText
              strokeWidth={4}
              value={30}
              colors={{
                textColor: "var(--color-teal500)",
                pathColor: "var(--color-teal900)",
                trailColor: "var(--color-teal50)",
              }}
            >
              <h3 className="color-teal">Processing</h3>
              <p className="body-m-400 color-teal">25%</p>
              <p className="body-s-500 color-teal">100 Orders</p>
            </CircularProgress>
            <CircularProgress
              circleRatio={0.8}
              rotation={0.6}
              hideText
              strokeWidth={4}
              value={30}
              colors={{
                textColor: "var(--color-purple500)",
                pathColor: "var(--color-purple900)",
                trailColor: "var(--color-purple50)",
              }}
            >
              <h3 className="color-purple">Shipped</h3>
              <p className="body-m-400 color-purple">25%</p>
              <p className="body-s-500 color-purple">100 Orders</p>
            </CircularProgress>
            <CircularProgress
              circleRatio={0.8}
              rotation={0.6}
              hideText
              strokeWidth={4}
              value={30}
              colors={{
                textColor: "var(--color-yellow500)",
                pathColor: "var(--color-yellow900)",
                trailColor: "var(--color-yellow50)",
              }}
            >
              <h3 className="color-yellow">Pending</h3>
              <p className="body-m-400 color-yellow">25%</p>
              <p className="body-s-500 color-yellow">100 Orders</p>
            </CircularProgress>
            <CircularProgress
              circleRatio={0.8}
              rotation={0.6}
              hideText
              strokeWidth={4}
              value={30}
              colors={{
                textColor: "var(--color-red500)",
                pathColor: "var(--color-red900)",
                trailColor: "var(--color-red50)",
              }}
            >
              <h3 className="color-red">Reserved</h3>
              <p className="body-m-400 color-red">25%</p>
              <p className="body-s-500 color-red">100 Orders</p>
            </CircularProgress>
          </Box>
          <Box className="home-split-orders-box">
            <h2>Split orders to order + backorder</h2>
            <hr />
            {[
              "PetStore",
              "Abigo Medical OY",
              "Develop Consulting Ou",
              "Habanello Oy",
              "Sofiero Kafferosteri AB",
              "Klockjävel of Sweden AB",
              "Mind at Work Oy",
              "Biohacker Center BHC Oy",
              "The Basic Ingredients B.V",
              "Biohacker Center Store",
              "Lace Laboratory",
              "ENTTEC ltd",
              "Lexima AB",
              "Anatomic Functional Training Oy",
              "World of Insights",
              "carbzone.fi",
            ].map(name => (
              <Button key={name} title={name} />
            ))}
          </Box>
          <Box className="home-staff-links-box">
            <h2>Staff Links</h2>
            <hr />
              <NavLink to="/To be shipped today">To be shipped today</NavLink>
              <br />
              <NavLink to="/New orders">New orders</NavLink>
              <NavLink to="/Collecting orders">Collecting orders</NavLink>
              <NavLink to="/Shipped orders">Shipped orders</NavLink>
              <NavLink to="/Pending orders">Pending orders</NavLink>
              <NavLink to="/Returning orders">Returning orders</NavLink>
              <br />
              <NavLink to="/Pack orders">Pack orders</NavLink>
              <br />
              <NavLink to="/Product stock excel">Product stock excel</NavLink>
              <NavLink to="/Stock update">Stock update</NavLink>
              <br />
              <NavLink to="/Order carts">Order carts</NavLink>
              <br />
              <NavLink to="/Proposed carts">Proposed carts</NavLink>
              <br />
              <NavLink to="/Photo requests">Photo requests</NavLink>
          </Box>
        </Boxlist>
        <Boxlist className="home-right-side">
          <Box>
            <h2>Priority orders</h2>
            <hr />
            <Button title="Priority Order #1" type={BUTTON_TYPES.RED} />
            <Button title="Priority Order #2" type={BUTTON_TYPES.RED} />
            <Button title="Priority Order #3" type={BUTTON_TYPES.RED} />
            <Button title="Priority Order #4" type={BUTTON_TYPES.RED} />
            <Button title="Priority Order #5" type={BUTTON_TYPES.RED} />
          </Box>
          <Box>
            <h2>Useful Links</h2>
            <hr />
            <Button title="OGOship blog" type={BUTTON_TYPES.TERTIARY} />
            <Button title="Updates & information" type={BUTTON_TYPES.TERTIARY} />
            <Button title="Support pages & guides" type={BUTTON_TYPES.TERTIARY} />
            <Button title="Contact support" type={BUTTON_TYPES.TERTIARY} />
            <Button title="Contact us" type={BUTTON_TYPES.TERTIARY} />
          </Box>
        </Boxlist>
      </main>
    )
  }
}

export { Home }
