import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

import "./index.scss"

export class CircularProgress extends React.Component {

  static propTypes = {
    size: PropTypes.number,
    value: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    strokeWidth: PropTypes.number,
    circleRatio: PropTypes.number,
    rotation: PropTypes.number,
    hideText: PropTypes.bool,
    colors: PropTypes.object,
  }

  static defaultProps = {
    size: 164,
    value: 0,
    minValue: 0,
    maxValue: 100,
    strokeWidth: 8,
    circleRatio: 1,
    rotation: 0.5,
    hideText: false,
    colors: {
      textColor: "var(--color-gray900)",
      pathColor: "var(--color-gray900)",
      trailColor: "var(--color-gray100)",
    }
  }

  render () {
    const {
      size,
      value,
      minValue,
      maxValue,
      strokeWidth,
      circleRatio,
      hideText,
      colors,
      rotation,
      children
    } = this.props

    return (
      <section
        className="com-CircularProgress"
        style={{ height: `${size}px`, width: `${size}px` }}
      >
        <CircularProgressbarWithChildren
          value={value}
          text={hideText ? null: `${value}%`}
          minValue={minValue}
          maxValue={maxValue}
          strokeWidth={strokeWidth}
          circleRatio={circleRatio}
          styles={buildStyles({
            rotation: rotation,
            ...colors,
          })}
        >
          {children}
        </CircularProgressbarWithChildren>
      </section>
    )
  }
}