import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"
import { store } from "index"

import {
  CURRENCY_RECEIVE_REQUEST,
  CURRENCY_RECEIVE_SUCCESS,
  CURRENCY_RECEIVE_FAIL,
} from "constants"

function* receive(action) {
  try {
    const res = yield call(Api.currency.getAll)

    const currencies = res.data.map(entry => {
      return {
        value: entry.code,
        label: entry.name,
      }
    })

    yield put({
      type: CURRENCY_RECEIVE_SUCCESS,
      payload: { currencies }
    })

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: CURRENCY_RECEIVE_FAIL })
  }
}

function* currencyReceiveSaga() {
  yield takeLatest(CURRENCY_RECEIVE_REQUEST, receive)
}

export function* currency() {
  yield all([
    call(currencyReceiveSaga),
  ])
}
