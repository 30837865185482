import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { PageHeader } from "components/PageHeader"
import { Button, BUTTON_TYPES } from "components/Button"
import { Breadcrumbs, Tag, TAG_TYPES } from "components/Ui"

import {
  ADMIN_RECEIVE_REQUEST,
  MODAL_OPEN,
  MODAL_SCREEN_ADD_ADMIN,
  MODAL_SCREEN_UPDATE_ADMIN,
  MODAL_SCREEN_REMOVE_ADMIN,
} from "constants"

import "./index.scss"

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    columns={[
      { title: "Name", attr: "name" },
      { title: "Email", attr: "email" },
      { title: "Phone", attr: "phone" },
      { title: "Roles", attr: "roles" },
      { title: "Warehouses", attr: "warehouses" },
    ]}
    actions={[
      {
        title: translate("edit"),
        icon: <Icons.Edit />,
        massActionDisabled: true,
        action: props.onEdit,
      },
      {
        title: translate("delete"),
        isRed: true,
        massActionDisabled: true,
        icon: <Icons.Delete />,
        action: props.onDelete,
      }
    ]}
    columnRender={{
      roles: (row, i) => {
        return row.roles.map(role => {
          return (
            <Tag key={role} type={TAG_TYPES.TEAL} title={role} compact />
          )
        })
      },
      warehouses: (row, i) => {
        return row.warehouses.map(warehouse => {
          return (
            <Tag key={warehouse} type={TAG_TYPES.BLUE} title={warehouse} compact />
          )
        })
      },
    }}
    buttons={
      <>
        <Button
          type={BUTTON_TYPES.GRAY}
          icon={<Icons.Plus />}
          onClick={props.onAdd}
          hasSmallRadius
        />
      </>
    }
    noCheckbox
    noFilters
  />
)

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  admin: state.admin,
}))
class AdminListing extends React.Component {

  render() {
    const { admin: { admins, loading }, dispatch, history } = this.props

    return (
      <>
        <PageHeader
          prefix={"Review and manage all the admins"}
          title={"Admins"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "admin",
                  onClick: () => {
                    history.push("/admin")
                  }
                },
                {
                  title: "admins",
                },
              ]}
              activeIndex={2}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="admin-listing-screen">
          <Boxlist>
            <Box noPadding>
              <TableRegion
                loading={loading}
                rows={admins}
                onRefresh={qs => {
                  dispatch({
                    type: ADMIN_RECEIVE_REQUEST,
                    payload: {
                      qs
                    }
                  })
                }}
                onAdd={() => {
                  dispatch({
                    type: MODAL_OPEN,
                    payload: {
                      screen: MODAL_SCREEN_ADD_ADMIN,
                    }
                  })
                }}
                onEdit={rows => {
                  dispatch({
                    type: MODAL_OPEN,
                    payload: {
                      screen: MODAL_SCREEN_UPDATE_ADMIN,
                      value: rows[0],
                    }
                  })
                }}
                onDelete={rows => {
                  dispatch({
                    type: MODAL_OPEN,
                    payload: {
                      screen: MODAL_SCREEN_REMOVE_ADMIN,
                      value: rows[0],
                    }
                  })
                }}
              />
            </Box>
          </Boxlist>
        </main>
      </>
    )
  }
}

export { AdminListing }
