import { Header as HeaderComponent } from "components/Layout/Header"
import {
  LANGUAGE_CHANGE,
  LANGUAGE_LIST,
  MODAL_OPEN,
  MODAL_SCREEN_SELECT_MERCHANT,
  APP_CHANGE_ACTIVE_MERCHANT,
  AUTH_LOGOUT_REQUEST,
} from "constants"

@connect((state) => ({
  app: state.app,
  auth: state.auth,
  notification: state.notification,
  message: state.message,
}))
class Header extends React.PureComponent {

  getLanguages() {
    const { app: { language } } = this.props

    return LANGUAGE_LIST.map(key => (
      { key, isSelected: key === language }
    ))
  }

  render() {
    const {
      app: { activeMerchant, originalMerchant },
      notification: { notifications },
      auth: { username },
      message: { messages },
      dispatch,
    } = this.props

    return (
      <HeaderComponent
        name={activeMerchant.name}
        onSearch={text => console.log("Searching", text)}
        notifications={notifications}
        messages={messages}
        languages={this.getLanguages()}
        onLanguageClick={language => {
          dispatch({ type: LANGUAGE_CHANGE, payload: { language: language.key } })
        }}
        onMerchantChangeClick={() => {
          dispatch({ type: MODAL_OPEN, payload: { screen: MODAL_SCREEN_SELECT_MERCHANT } })
        }}
        onRevertMerchant={activeMerchant.name !== originalMerchant.name ? () => {
          dispatch({
            type: APP_CHANGE_ACTIVE_MERCHANT,
            payload: {
              activeMerchant: originalMerchant
            }
          })
        } : null}
        username={username}
        onLogout={() => {
          dispatch({
            type: AUTH_LOGOUT_REQUEST,
            payload: {}
          })
        }}
      />
    )
  }
}

export { Header }