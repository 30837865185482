import {
  FEEDBACK_RECEIVE_REQUEST,
  FEEDBACK_RECEIVE_SUCCESS,
  FEEDBACK_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: false,
  feedbacks: [],
}

export default function feedback(state = initialState, action) {
  switch (action.type) {
    case FEEDBACK_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case FEEDBACK_RECEIVE_SUCCESS:
      return {
        ...state,
        feedbacks: action.payload.feedbacks,
        loading: false,
      }

    case FEEDBACK_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
