import { createRoot } from "react-dom/client"
import { BrowserRouter as Router } from "react-router-dom"
import * as serviceWorker from "./serviceWorker"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import createSagaMiddleware from "redux-saga"
import { showDebugData, getCurrentEnv } from "helpers/env"
import { logger } from "redux-logger"
import { Provider } from "react-redux"
import { reducers } from "reducers"
import { sagas } from "sagas"
import { App } from "containers"

import "./index.scss"

// @todo investigate the bug why it says process is not defined after timeout errors
Sentry.init({
  dsn: "https://1129dbe9ebbb4c9ebb048659abd8c80c@o1137374.ingest.sentry.io/6190160",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
  environment: getCurrentEnv(),
})

const saga = createSagaMiddleware()
const publicURL = process.env.REACT_APP_PUBLIC_URL

export const store = createStore(reducers, composeWithDevTools(
  applyMiddleware(...[logger, saga])
))

saga.run(sagas)

showDebugData()

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <>
    <Provider store={store}>
      <Router basename={publicURL}>
        <App />
      </Router>
    </Provider>
  </>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
