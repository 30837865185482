import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Selectbox } from "components/Ui"

export const SalesInfoArea = props => {
  return (
    <>
      <Box>
        <h2>Sales info</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              sales_price: 0,
              currency: props.currencies[0],
              vat: 0,
            }
          }}
          rules={{
            sales_price: Rules.number().positive().min(0).required(),
            currency: Rules.string().required(),
            vat: Rules.number().integer().min(0).required(),
          }}
          render={[
            {
              label: "Sales price",
              name: "sales_price",
              node: ({ field, fieldState }) => (
                <Input
                  type="number"
                  step={0.01}
                  min={0}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Currency",
              name: "currency",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={props.currencies}
                  onChange={field.onChange}
                  value={field.value}
                />
              )
            },
            {
              label: "VAT (%)",
              name: "vat",
              node: ({ field, fieldState }) => (
                <Input
                  type="number"
                  min={0}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
    </>
  )
}