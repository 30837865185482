/*
|  Testing (@todo to delete)
*/

export const TEST_ADD_NEW_CONNECTED_USER = "TEST_ADD_NEW_CONNECTED_USER"

/*
|  App
*/

export const APP_INITIALIZE_REQUEST = "APP_INITIALIZE_REQUEST"
export const APP_INITIALIZE_SUCCESS = "APP_INITIALIZE_SUCCESS"
export const APP_INITIALIZE_FAIL = "APP_INITIALIZE_FAIL"

export const APP_CHANGE_ACTIVE_MERCHANT = "APP_CHANGE_ACTIVE_MERCHANT"
export const APP_CHANGE_FUNCTIONAL_PAGES_VISIBILITY = "APP_CHANGE_FUNCTIONAL_PAGES_VISIBILITY"


/*
|  Language
*/

export const LANGUAGE_CHANGE = "LANGUAGE_CHANGE"

export const LANGUAGE_EN = "en"
export const LANGUAGE_DE = "de"
export const LANGUAGE_SV = "sv"
export const LANGUAGE_FI = "fi"

export const LANGUAGE_DEFAULT = LANGUAGE_EN
export const LANGUAGE_MISSING_TRANSLATION_MESSAGE = "Missing Translation"

export const LANGUAGE_LIST = [LANGUAGE_EN, LANGUAGE_FI, LANGUAGE_DE, LANGUAGE_SV]

/*
|  Auth
*/

export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST"
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS"
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL"

export const AUTH_2FA_CODE_LENGTH = 6
export const AUTH_2FA_CODE_ENTER_TIMEOUT_SECONDS = 180

export const AUTH_2FA_REQUEST = "AUTH_2FA_REQUEST"
export const AUTH_2FA_SUCCESS = "AUTH_2FA_SUCCESS"
export const AUTH_2FA_FAIL = "AUTH_2FA_FAIL"

export const AUTH_LOGOUT_REQUEST = "AUTH_LOGOUT_REQUEST"
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS"
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL"

export const AUTH_LOGIN_VIA_API_REQUEST = "AUTH_LOGIN_VIA_API_REQUEST"
export const AUTH_LOGIN_VIA_API_SUCCESS = "AUTH_LOGIN_VIA_API_SUCCESS"
export const AUTH_LOGIN_VIA_API_FAIL = "AUTH_LOGIN_VIA_API_FAIL"

/*
|  Currency
*/

export const CURRENCY_RECEIVE_REQUEST = "CURRENCY_RECEIVE_REQUEST"
export const CURRENCY_RECEIVE_SUCCESS = "CURRENCY_RECEIVE_SUCCESS"
export const CURRENCY_RECEIVE_FAIL = "CURRENCY_RECEIVE_FAIL"

/*
|  Country
*/

export const COUNTRY_RECEIVE_REQUEST = "COUNTRY_RECEIVE_REQUEST"
export const COUNTRY_RECEIVE_SUCCESS = "COUNTRY_RECEIVE_SUCCESS"
export const COUNTRY_RECEIVE_FAIL = "COUNTRY_RECEIVE_FAIL"

/*
|  Rebuy
*/

export const REBUY_PAGE_DATA_RECEIVE_REQUEST = "REBUY_PAGE_DATA_RECEIVE_REQUEST"
export const REBUY_PAGE_DATA_RECEIVE_SUCCESS = "REBUY_PAGE_DATA_RECEIVE_SUCCESS"
export const REBUY_PAGE_DATA_RECEIVE_FAIL = "REBUY_PAGE_DATA_RECEIVE_FAIL"

/*
|  Notifications
*/

export const NOTIFICATION_RECEIVE_REQUEST = "NOTIFICATION_RECEIVE_REQUEST"
export const NOTIFICATION_RECEIVE_SUCCESS = "NOTIFICATION_RECEIVE_SUCCESS"
export const NOTIFICATION_RECEIVE_FAIL = "NOTIFICATION_RECEIVE_FAIL"

/*
|  Messages
*/

export const MESSAGE_RECEIVE_REQUEST = "MESSAGE_RECEIVE_REQUEST"
export const MESSAGE_RECEIVE_SUCCESS = "MESSAGE_RECEIVE_SUCCESS"
export const MESSAGE_RECEIVE_FAIL = "MESSAGE_RECEIVE_FAIL"

/*
|  Warehouses
*/

export const WAREHOUSE_RECEIVE_REQUEST = "WAREHOUSE_RECEIVE_REQUEST"
export const WAREHOUSE_RECEIVE_SUCCESS = "WAREHOUSE_RECEIVE_SUCCESS"
export const WAREHOUSE_RECEIVE_FAIL = "WAREHOUSE_RECEIVE_FAIL"

/*
|  Return Reasons
*/

export const RETURN_REASON_RECEIVE_REQUEST = "RETURN_REASON_RECEIVE_REQUEST"
export const RETURN_REASON_RECEIVE_SUCCESS = "RETURN_REASON_RECEIVE_SUCCESS"
export const RETURN_REASON_RECEIVE_FAIL = "RETURN_REASON_RECEIVE_FAIL"

export const RETURN_REASON_RECEIVE_SINGLE_REQUEST = "RETURN_REASON_RECEIVE_SINGLE_REQUEST"
export const RETURN_REASON_RECEIVE_SINGLE_SUCCESS = "RETURN_REASON_RECEIVE_SINGLE_SUCCESS"
export const RETURN_REASON_RECEIVE_SINGLE_FAIL = "RETURN_REASON_RECEIVE_SINGLE_FAIL"

export const RETURN_REASON_CREATE_REQUEST = "RETURN_REASON_CREATE_REQUEST"
export const RETURN_REASON_CREATE_SUCCESS = "RETURN_REASON_CREATE_SUCCESS"
export const RETURN_REASON_CREATE_FAIL = "RETURN_REASON_CREATE_FAIL"

export const RETURN_REASON_UPDATE_REQUEST = "RETURN_REASON_UPDATE_REQUEST"
export const RETURN_REASON_UPDATE_SUCCESS = "RETURN_REASON_UPDATE_SUCCESS"
export const RETURN_REASON_UPDATE_FAIL = "RETURN_REASON_UPDATE_FAIL"

export const RETURN_REASON_REMOVE_REQUEST = "RETURN_REASON_REMOVE_REQUEST"
export const RETURN_REASON_REMOVE_SUCCESS = "RETURN_REASON_REMOVE_SUCCESS"
export const RETURN_REASON_REMOVE_FAIL = "RETURN_REASON_REMOVE_FAIL"

/*
|  Return Delivery Types
*/

export const RETURN_DELIVERY_TYPE_RECEIVE_REQUEST = "RETURN_DELIVERY_TYPE_RECEIVE_REQUEST"
export const RETURN_DELIVERY_TYPE_RECEIVE_SUCCESS = "RETURN_DELIVERY_TYPE_RECEIVE_SUCCESS"
export const RETURN_DELIVERY_TYPE_RECEIVE_FAIL = "RETURN_DELIVERY_TYPE_RECEIVE_FAIL"

export const RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_REQUEST = "RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_REQUEST"
export const RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_SUCCESS = "RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_SUCCESS"
export const RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_FAIL = "RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_FAIL"

export const RETURN_DELIVERY_TYPE_CREATE_REQUEST = "RETURN_DELIVERY_TYPE_CREATE_REQUEST"
export const RETURN_DELIVERY_TYPE_CREATE_SUCCESS = "RETURN_DELIVERY_TYPE_CREATE_SUCCESS"
export const RETURN_DELIVERY_TYPE_CREATE_FAIL = "RETURN_DELIVERY_TYPE_CREATE_FAIL"

export const RETURN_DELIVERY_TYPE_UPDATE_REQUEST = "RETURN_DELIVERY_TYPE_UPDATE_REQUEST"
export const RETURN_DELIVERY_TYPE_UPDATE_SUCCESS = "RETURN_DELIVERY_TYPE_UPDATE_SUCCESS"
export const RETURN_DELIVERY_TYPE_UPDATE_FAIL = "RETURN_DELIVERY_TYPE_UPDATE_FAIL"

export const RETURN_DELIVERY_TYPE_REMOVE_REQUEST = "RETURN_DELIVERY_TYPE_REMOVE_REQUEST"
export const RETURN_DELIVERY_TYPE_REMOVE_SUCCESS = "RETURN_DELIVERY_TYPE_REMOVE_SUCCESS"
export const RETURN_DELIVERY_TYPE_REMOVE_FAIL = "RETURN_DELIVERY_TYPE_REMOVE_FAIL"

/*
|  Merchant
*/

export const MERCHANT_RECEIVE_REQUEST = "MERCHANT_RECEIVE_REQUEST"
export const MERCHANT_RECEIVE_SUCCESS = "MERCHANT_RECEIVE_SUCCESS"
export const MERCHANT_RECEIVE_FAIL = "MERCHANT_RECEIVE_FAIL"

// Merchant - Connected users
export const MERCHANT_CONNECTED_USERS_RECEIVE_REQUEST = "MERCHANT_CONNECTED_USERS_RECEIVE_REQUEST"
export const MERCHANT_CONNECTED_USERS_RECEIVE_SUCCESS = "MERCHANT_CONNECTED_USERS_RECEIVE_SUCCESS"
export const MERCHANT_CONNECTED_USERS_RECEIVE_FAIL = "MERCHANT_CONNECTED_USERS_RECEIVE_FAIL"

// Merchant - Discounts
export const MERCHANT_DISCOUNTS_RECEIVE_REQUEST = "MERCHANT_DISCOUNTS_RECEIVE_REQUEST"
export const MERCHANT_DISCOUNTS_RECEIVE_SUCCESS = "MERCHANT_DISCOUNTS_RECEIVE_SUCCESS"
export const MERCHANT_DISCOUNTS_RECEIVE_FAIL = "MERCHANT_DISCOUNTS_RECEIVE_FAIL"

export const MERCHANT_STATUSES = {
  0: "New",
  1: "Active",
  2: "Leaving",
  3: "Suspended",
  4: "Deactivated",
}

/*
|  Order
*/

export const ORDER_RECEIVE_REQUEST = "ORDER_RECEIVE_REQUEST"
export const ORDER_RECEIVE_SUCCESS = "ORDER_RECEIVE_SUCCESS"
export const ORDER_RECEIVE_FAIL = "ORDER_RECEIVE_FAIL"

/*
|  Order Returns
*/

export const ORDER_RETURN_RECEIVE_REQUEST = "ORDER_RETURN_RECEIVE_REQUEST"
export const ORDER_RETURN_RECEIVE_SUCCESS = "ORDER_RETURN_RECEIVE_SUCCESS"
export const ORDER_RETURN_RECEIVE_FAIL = "ORDER_RETURN_RECEIVE_FAIL"

/*
|  Product
*/

export const PRODUCT_RECEIVE_REQUEST = "PRODUCT_RECEIVE_REQUEST"
export const PRODUCT_RECEIVE_SUCCESS = "PRODUCT_RECEIVE_SUCCESS"
export const PRODUCT_RECEIVE_FAIL = "PRODUCT_RECEIVE_FAIL"

/*
|  Product groups
*/

export const PRODUCT_GROUP_RECEIVE_REQUEST = "PRODUCT_GROUP_RECEIVE_REQUEST"
export const PRODUCT_GROUP_RECEIVE_SUCCESS = "PRODUCT_GROUP_RECEIVE_SUCCESS"
export const PRODUCT_GROUP_RECEIVE_FAIL = "PRODUCT_GROUP_RECEIVE_FAIL"

/*
|  Product bundles
*/

export const PRODUCT_BUNDLE_RECEIVE_REQUEST = "PRODUCT_BUNDLE_RECEIVE_REQUEST"
export const PRODUCT_BUNDLE_RECEIVE_SUCCESS = "PRODUCT_BUNDLE_RECEIVE_SUCCESS"
export const PRODUCT_BUNDLE_RECEIVE_FAIL = "PRODUCT_BUNDLE_RECEIVE_FAIL"

/*
|  Stock Update
*/

export const STOCK_UPDATE_RECEIVE_REQUEST = "STOCK_UPDATE_RECEIVE_REQUEST"
export const STOCK_UPDATE_RECEIVE_SUCCESS = "STOCK_UPDATE_RECEIVE_SUCCESS"
export const STOCK_UPDATE_RECEIVE_FAIL = "STOCK_UPDATE_RECEIVE_FAIL"

/*
|  Supplier
*/

export const SUPPLIER_RECEIVE_REQUEST = "SUPPLIER_RECEIVE_REQUEST"
export const SUPPLIER_RECEIVE_SUCCESS = "SUPPLIER_RECEIVE_SUCCESS"
export const SUPPLIER_RECEIVE_FAIL = "SUPPLIER_RECEIVE_FAIL"

/*
|  Stock
*/

export const STOCK_RECEIVE_REQUEST = "STOCK_RECEIVE_REQUEST"
export const STOCK_RECEIVE_SUCCESS = "STOCK_RECEIVE_SUCCESS"
export const STOCK_RECEIVE_FAIL = "STOCK_RECEIVE_FAIL"

/*
|  Admin
*/

export const ADMIN_RECEIVE_REQUEST = "ADMIN_RECEIVE_REQUEST"
export const ADMIN_RECEIVE_SUCCESS = "ADMIN_RECEIVE_SUCCESS"
export const ADMIN_RECEIVE_FAIL = "ADMIN_RECEIVE_FAIL"

/*
|  Customer
*/

export const CUSTOMER_RECEIVE_REQUEST = "CUSTOMER_RECEIVE_REQUEST"
export const CUSTOMER_RECEIVE_SUCCESS = "CUSTOMER_RECEIVE_SUCCESS"
export const CUSTOMER_RECEIVE_FAIL = "CUSTOMER_RECEIVE_FAIL"

/*
|  Feedback
*/

export const FEEDBACK_RECEIVE_REQUEST = "FEEDBACK_RECEIVE_REQUEST"
export const FEEDBACK_RECEIVE_SUCCESS = "FEEDBACK_RECEIVE_SUCCESS"
export const FEEDBACK_RECEIVE_FAIL = "FEEDBACK_RECEIVE_FAIL"

/*
|  Settings
*/

export const SETTINGS_PROVIDER_RECEIVE_REQUEST = "SETTINGS_PROVIDER_RECEIVE_REQUEST"
export const SETTINGS_PROVIDER_RECEIVE_SUCCESS = "SETTINGS_PROVIDER_RECEIVE_SUCCESS"
export const SETTINGS_PROVIDER_RECEIVE_FAIL = "SETTINGS_PROVIDER_RECEIVE_FAIL"


/*
|  Partner
*/

export const PARTNER_RECEIVE_REQUEST = "PARTNER_RECEIVE_REQUEST"
export const PARTNER_RECEIVE_SUCCESS = "PARTNER_RECEIVE_SUCCESS"
export const PARTNER_RECEIVE_FAIL = "PARTNER_RECEIVE_FAIL"

export const PARTNER_RECEIVE_SINGLE_REQUEST = "PARTNER_RECEIVE_SINGLE_REQUEST"
export const PARTNER_RECEIVE_SINGLE_SUCCESS = "PARTNER_RECEIVE_SINGLE_SUCCESS"
export const PARTNER_RECEIVE_SINGLE_FAIL = "PARTNER_RECEIVE_SINGLE_FAIL"

export const PARTNER_CREATE_REQUEST = "PARTNER_CREATE_REQUEST"
export const PARTNER_CREATE_SUCCESS = "PARTNER_CREATE_SUCCESS"
export const PARTNER_CREATE_FAIL = "PARTNER_CREATE_FAIL"

export const PARTNER_UPDATE_REQUEST = "PARTNER_UPDATE_REQUEST"
export const PARTNER_UPDATE_SUCCESS = "PARTNER_UPDATE_SUCCESS"
export const PARTNER_UPDATE_FAIL = "PARTNER_UPDATE_FAIL"

export const PARTNER_UPDATE_STATUS_REQUEST = "PARTNER_UPDATE_STATUS_REQUEST"
export const PARTNER_UPDATE_STATUS_SUCCESS = "PARTNER_UPDATE_STATUS_SUCCESS"
export const PARTNER_UPDATE_STATUS_FAIL = "PARTNER_UPDATE_STATUS_FAIL"

export const PARTNER_CATEGORY_RECEIVE_REQUEST = "PARTNER_CATEGORY_RECEIVE_REQUEST"
export const PARTNER_CATEGORY_RECEIVE_SUCCESS = "PARTNER_CATEGORY_RECEIVE_SUCCESS"
export const PARTNER_CATEGORY_RECEIVE_FAIL = "PARTNER_CATEGORY_RECEIVE_FAIL"

export const PARTNER_STATUSES_ENUM = {
  Draft: "Draft",
  WaitingApproval: "WaitingApproval",
  Active: "Active",
  Cancelled: "Cancelled"
}

export const PARTNER_CONTACT_OPTIONS_ENUM = {
  Directly: "Directly",
  InternalForm: "InternalForm",
}

export const PARTNER_TYPE_ENUM = {
  Default: "Default",
  Rising: "Rising",
}

/*
|  Tabs
*/

export const TAB_INDEX_UPDATE = "TAB_INDEX_UPDATE"

/*
|  Post Purchase Services
*/

export const PPS_LEVELS = {
  0: "Free",
  1: "Growth",
  2: "Enterprise",
}

/*
|  Events (Heatmap)
*/

export const EVENT_RECEIVE_REQUEST = "EVENT_RECEIVE_REQUEST"
export const EVENT_RECEIVE_SUCCESS = "EVENT_RECEIVE_SUCCESS"
export const EVENT_RECEIVE_FAIL = "EVENT_RECEIVE_FAIL"

/*
|  Cost Category (InfiniteTree)
*/

export const COST_CATEGORY_RECEIVE_REQUEST = "COST_CATEGORY_RECEIVE_REQUEST"
export const COST_CATEGORY_RECEIVE_SUCCESS = "COST_CATEGORY_RECEIVE_SUCCESS"
export const COST_CATEGORY_RECEIVE_FAIL = "COST_CATEGORY_RECEIVE_FAIL"

export const COST_CATEGORY_CREATE_REQUEST = "COST_CATEGORY_CREATE_REQUEST"
export const COST_CATEGORY_CREATE_SUCCESS = "COST_CATEGORY_CREATE_SUCCESS"
export const COST_CATEGORY_CREATE_FAIL = "COST_CATEGORY_CREATE_FAIL"

export const COST_CATEGORY_UPDATE_REQUEST = "COST_CATEGORY_UPDATE_REQUEST"
export const COST_CATEGORY_UPDATE_SUCCESS = "COST_CATEGORY_UPDATE_SUCCESS"
export const COST_CATEGORY_UPDATE_FAIL = "COST_CATEGORY_UPDATE_FAIL"

export const COST_CATEGORY_REMOVE_REQUEST = "COST_CATEGORY_REMOVE_REQUEST"
export const COST_CATEGORY_REMOVE_SUCCESS = "COST_CATEGORY_REMOVE_SUCCESS"
export const COST_CATEGORY_REMOVE_FAIL = "COST_CATEGORY_REMOVE_FAIL"

/*
|  Transactional Messages (TM)
*/

export const TM_RECEIVE_REQUEST = "TM_RECEIVE_REQUEST"
export const TM_RECEIVE_SUCCESS = "TM_RECEIVE_SUCCESS"
export const TM_RECEIVE_FAIL = "TM_RECEIVE_FAIL"

export const TM_RECEIVE_SINGLE_REQUEST = "TM_RECEIVE_SINGLE_REQUEST"
export const TM_RECEIVE_SINGLE_SUCCESS = "TM_RECEIVE_SINGLE_SUCCESS"
export const TM_RECEIVE_SINGLE_FAIL = "TM_RECEIVE_SINGLE_FAIL"

export const TM_CREATE_REQUEST = "TM_CREATE_REQUEST"
export const TM_CREATE_SUCCESS = "TM_CREATE_SUCCESS"
export const TM_CREATE_FAIL = "TM_CREATE_FAIL"

export const TM_UPDATE_REQUEST = "TM_UPDATE_REQUEST"
export const TM_UPDATE_SUCCESS = "TM_UPDATE_SUCCESS"
export const TM_UPDATE_FAIL = "TM_UPDATE_FAIL"

export const TM_REMOVE_REQUEST = "TM_REMOVE_REQUEST"
export const TM_REMOVE_SUCCESS = "TM_REMOVE_SUCCESS"
export const TM_REMOVE_FAIL = "TM_REMOVE_FAIL"

export const TM_RECIPIENT_TYPES = {
  0: "AllUsers",
  1: "AllMerchants",
  2: "SpecifiedUsers",
  3: "SpecifiedMerchants",
  4: "UsersOfSpecifiedRoles",
}

export const TM_APPROVAL_TYPES = {
  0: "ShowOnly",
  1: "ApprovalRequired",
}

export const TM_RECIPIENT_STATUSES = {
  0: "Draft",
  1: "Sent",
  2: "Read",
  3: "Skipped",
  4: "Agreed",
  5: "Rejected",
}

/*
|  Modal
*/

export const MODAL_OPEN = "MODAL_OPEN"
export const MODAL_CLOSE = "MODAL_CLOSE"

export const MODAL_SCREEN_SELECT_MERCHANT = "MODAL_SCREEN_SELECT_MERCHANT"
export const MODAL_SCREEN_ACTIVATE_SHIPPING_METHOD = "MODAL_SCREEN_ACTIVATE_SHIPPING_METHOD"

export const MODAL_SCREEN_ADD_SUPPLIER = "MODAL_SCREEN_ADD_SUPPLIER"
export const MODAL_SCREEN_UPDATE_SUPPLIER = "MODAL_SCREEN_UPDATE_SUPPLIER"
export const MODAL_SCREEN_REMOVE_SUPPLIER = "MODAL_SCREEN_REMOVE_SUPPLIER"

export const MODAL_SCREEN_ADD_STOCK = "MODAL_SCREEN_ADD_STOCK"
export const MODAL_SCREEN_UPDATE_STOCK = "MODAL_SCREEN_UPDATE_STOCK"
export const MODAL_SCREEN_REMOVE_STOCK = "MODAL_SCREEN_REMOVE_STOCK"

export const MODAL_SCREEN_ADD_PRODUCT_GROUP = "MODAL_SCREEN_ADD_PRODUCT_GROUP"
export const MODAL_SCREEN_UPDATE_PRODUCT_GROUP = "MODAL_SCREEN_UPDATE_PRODUCT_GROUP"
export const MODAL_SCREEN_REMOVE_PRODUCT_GROUP = "MODAL_SCREEN_REMOVE_PRODUCT_GROUP"

export const MODAL_SCREEN_REMOVE_PRODUCT_BUNDLE = "MODAL_SCREEN_REMOVE_PRODUCT_BUNDLE"

export const MODAL_SCREEN_ADD_ADMIN = "MODAL_SCREEN_ADD_ADMIN"
export const MODAL_SCREEN_UPDATE_ADMIN = "MODAL_SCREEN_UPDATE_ADMIN"
export const MODAL_SCREEN_REMOVE_ADMIN = "MODAL_SCREEN_REMOVE_ADMIN"

export const MODAL_SCREEN_ADD_CUSTOMER = "MODAL_SCREEN_ADD_CUSTOMER"
export const MODAL_SCREEN_UPDATE_CUSTOMER = "MODAL_SCREEN_UPDATE_CUSTOMER"
export const MODAL_SCREEN_REMOVE_CUSTOMER = "MODAL_SCREEN_REMOVE_CUSTOMER"

export const MODAL_SCREEN_ADD_CONNECTED_USER = "MODAL_SCREEN_ADD_CONNECTED_USER"
export const MODAL_SCREEN_UPDATE_CONNECTED_USER = "MODAL_SCREEN_UPDATE_CONNECTED_USER"
export const MODAL_SCREEN_REMOVE_CONNECTED_USER = "MODAL_SCREEN_REMOVE_CONNECTED_USER"

export const MODAL_SCREEN_ADD_MERCHANT_DISCOUNT = "MODAL_SCREEN_ADD_MERCHANT_DISCOUNT"
export const MODAL_SCREEN_UPDATE_MERCHANT_DISCOUNT = "MODAL_SCREEN_UPDATE_MERCHANT_DISCOUNT"
export const MODAL_SCREEN_REMOVE_MERCHANT_DISCOUNT = "MODAL_SCREEN_REMOVE_MERCHANT_DISCOUNT"

export const MODAL_SCREEN_UPDATE_LANGUAGE = "MODAL_SCREEN_UPDATE_LANGUAGE"

export const MODAL_SCREEN_REMOVE_RETURN_REASON = "MODAL_SCREEN_REMOVE_RETURN_REASON"
export const MODAL_SCREEN_REMOVE_RETURN_DELIVERY_TYPE = "MODAL_SCREEN_REMOVE_RETURN_DELIVERY_TYPE"

export const MODAL_SCREEN_SHOW_WAREHOUSES_OF_COUNTRY = "MODAL_SCREEN_SHOW_WAREHOUSES_OF_COUNTRY"

export const MODAL_SCREEN_REMOVE_TM = "MODAL_SCREEN_REMOVE_TM"
export const MODAL_SCREEN_TM_UPDATE_RECIPIENTS = "MODAL_SCREEN_TM_UPDATE_RECIPIENTS"

export const MODAL_SCREEN_COST_CATEGORY_UPDATE = "MODAL_SCREEN_COST_CATEGORY_UPDATE"

export const MODAL_SCREEN_SELECT_CHANNEL = "MODAL_SCREEN_SELECT_CHANNEL"

