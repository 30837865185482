import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { PageHeader } from "components/PageHeader"
import { Breadcrumbs, Tag, TAG_TYPES, Checkbox, Datepicker } from "components/Ui"
import { Modal } from "components/Layout/Modal"
import { Button, BUTTON_TYPES } from "components/Button"

import { ORDER_RECEIVE_REQUEST } from "constants"

import "./index.scss"

const TableRegion = props => {
  const {
    onOpenNewTab,
    onEdit,
    onDelete,
    defaultState,
  } = props

  const [filterOpen, setFilterOpen] = React.useState(false)
  const [someCheckbox, setSomeCheckbox] = React.useState(false)
  const [startDate, setStartDate] = React.useState(defaultState.startDate || null)
  const [endDate, setEndDate] = React.useState(defaultState.endDate || null)

  const tags = []

  if (someCheckbox) {
    tags.push(<Tag key="checkbox" title={"Checkbox"} type={TAG_TYPES.PRIMARY} compact/>)
  }

  if (startDate) {
    tags.push(
      <Tag
        key="start_date"
        title={`From: ${startDate.toLocaleDateString()}`}
        type={TAG_TYPES.PRIMARY}
        compact
      />
    )
  }

  if (endDate) {
    tags.push(
      <Tag
        key="end_date"
        title={`To: ${endDate.toLocaleDateString()}`}
        type={TAG_TYPES.PRIMARY}
        compact
      />
    )
  }

  return (
    <>
      {filterOpen && (
        <Modal
          title="Table Filters"
          onClose={() => setFilterOpen(false)}
          isSidebar={true}
          footer={
            <>
              <Button
                type={BUTTON_TYPES.TERTIARY}
                title={"Cancel"}
                onClick={() => setFilterOpen(false)}
              />
              <Button
                type={BUTTON_TYPES.GRAY}
                title={"Save"}
                onClick={() => setFilterOpen(false)}
              />
            </>
          }
        >
          <Checkbox
            title={
              <>
                <p className="body-m-400">Stop DHL orders to DHL's remote area</p>
                <p className="caption-s-400 soft">All "remote area" orders from the store via the API will be stopped and put into the RESERVED state. They need to be manually changed into the NEW state by the merchant before shipping. 1.00 € / stopped shipment.</p>
              </>
            }
            onClick={() => setSomeCheckbox(prev => !prev)}
            checked={someCheckbox}
          />
        </Modal>
      )}
      <Table
        {...useTableHook({
          ...props,
          custom: {
            someCheckbox,
            startDate,
            endDate,
          },
          refreshers: [
            startDate,
            endDate
          ],
          tableDefaults: defaultState?.tableDefaults
        })}
        columns={[
          { title: "Merchant", attr: "_1", searchable: true, sortable: true },
          { title: "Customization", attr: "_2", searchable: true },
          {
            title: "Create time",
            attr: "_3",
            searchable: true,
            searchNode: () => (
              <>
                <Datepicker
                  selected={startDate}
                  customInput={
                    <p className="caption-s-400 soft">
                      {startDate ? `From: ${startDate.toLocaleDateString()}` : `From: All`}
                    </p>
                  }
                  onChange={date => setStartDate(date)}
                />
                <Datepicker
                  selected={endDate}
                  customInput={
                    <p className="caption-s-400 soft">
                      {endDate ? `To: ${endDate.toLocaleDateString()}` : `To: All`}
                    </p>
                  }
                  onChange={date => setEndDate(date)}
                />
              </>
            )
          },
          { title: "Modified", attr: "_4", searchable: true },
          { title: "Order number", attr: "_5", searchable: true },
          {
            title: "Status",
            attr: "_6",
            searchable: true,
            groupable: true,
          },
          {
            title: "City",
            attr: "_7",
            searchable: true,
            groupable: true,
          },
          { title: "Country", attr: "_8", searchable: true },
          { title: "Name", attr: "_9", searchable: true },
          { title: "Company", attr: "_10", searchable: true },
          { title: "Difficulty", attr: "_11", searchable: true },
          { title: "Tags", attr: "_12", searchable: true },
          { title: "Tracking number", attr: "_13", searchable: true },
          { title: "Delivery time", attr: "_14", searchable: true },
          { title: "Collect reference", attr: "_15", searchable: true },
          { title: "Delivery type", attr: "_16", searchable: true },
          { title: "Warehouse", attr: "_17", searchable: true },
        ]}
        actions={[
          {
            title: translate("open-in-new-tab"),
            icon: <Icons.Pin />,
            action: onOpenNewTab,
            massActionDisabled: true,
          },
          {
            title: translate("edit"),
            icon: <Icons.Edit />,
            action: onEdit,
            massActionDisabled: true,
          },
          {
            title: translate("delete"),
            isRed: true,
            icon: <Icons.Delete />,
            action: onDelete
          },
        ]}
        buttons={
          <>
            <Button
              title={translate("filters")}
              type={BUTTON_TYPES.GRAY}
              leftIcon={<Icons.Settings />}
              onClick={() => setFilterOpen(true)}
              hasSmallRadius
            />
          </>
        }
        tags={tags}
      />
    </>
  )
}

@withRouter
@connect((state) => ({
  order: state.order,
}))
class OrderListing extends React.Component {

  getDefaultStateFromUrl() {
    const { match: { params: { type } } } = this.props

    switch (type) {
      case "today":
        return {
          startDate: new Date(),
          endDate: new Date(),
        }

      case "new":
        return {
          tableDefaults: {
            _6: "NEW"
          }
        }

      case "shipped":
        return {
          tableDefaults: {
            _6: "SHIPPED"
          }
        }

      default:
        return {}
    }
  }

  render() {
    const { order: { orders, loading }, dispatch, history } = this.props

    return (
      <>
        <PageHeader
          prefix={"Review and manage all the orders that need your attention"}
          title={"Orders"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "staff",
                  onClick: () => {
                    history.push("/staff")
                  }
                },
                {
                  title: "orders",
                },
              ]}
              activeIndex={2}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="staff-order-listing-page">
          <Boxlist>
            <Box noPadding>
              <TableRegion
                loading={loading}
                rows={orders}
                onRefresh={qs => {
                  console.log(qs)
                  dispatch({
                    type: ORDER_RECEIVE_REQUEST,
                    payload: {
                      qs
                    }
                  })
                }}
                onOpenNewTab={() => {
                  console.log("onOpenNewTab")
                }}
                onEdit={() => {
                  console.log("onEdit")
                }}
                onDelete={() => {
                  console.log("onDelete")
                }}
                defaultState={this.getDefaultStateFromUrl()}
              />
            </Box>
          </Boxlist>
        </main>
      </>
    )
  }
}

export { OrderListing }
