import { Button, BUTTON_TYPES } from "components/Button"

import "./index.scss"

export class ShippingMethod extends React.Component {

  static propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.string,
    isActivated: PropTypes.bool,
    onClick: PropTypes.func,
    footer: PropTypes.node,
  }

  static defaultProps = {
    name: "",
    description: "",
    logo: "",
    isActivated: false,
    onClick: null,
    footer: null,
  }

  render () {
    const { name, description, logo, isActivated, onClick, footer } = this.props

    return (
      <section className={classnames("com-ShippingMethod")}>
        <div>
          {logo && <img src={logo} alt={name} />}
          <h3>{name}</h3>
        </div>
        <div>
          <p>{description}</p>
          {isActivated && (
            <Button
              title="Deactivate"
              type={BUTTON_TYPES.RED}
              onClick={() => {
                onClick && onClick.apply(this, [false])
              }}
            />
          )}
          {!isActivated && (
            <Button
              title="Activate"
              type={BUTTON_TYPES.RED}
              onClick={() => {
                onClick && onClick.apply(this, [true])
              }}
            />
          )}
        </div>
        {footer && (
          <div>
            {footer}
          </div>
        )}
      </section>
    )
  }
}