import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { Button, BUTTON_TYPES } from "components/Button"

import {
  ORDER_RETURN_RECEIVE_REQUEST
} from "constants"

import "./index.scss"

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    title={"Order Returns"}
    description="Review and manage all the order returns"
    columns={[
      { title: "Return Number", attr: "returnNumber" },
      { title: "Created", attr: "created" },
      { title: "State", attr: "state" },
      { title: "Merchant", attr: "merchant" },
      { title: "Order", attr: "order" },
      { title: "Sender", attr: "sender" },
      { title: "Delivery Type", attr: "deliveryType" },
      { title: "Warehouse", attr: "warehouse" },
    ]}
    actions={[
      {
        title: "Open",
        massActionDisabled: true,
        action: props.onOpen
      },
      {
        title: "Open related Merchant",
        massActionDisabled: true,
        action: props.onOpenMerchant
      },
      {
        title: "Open related Order",
        action: props.onOpenOrder
      },
    ]}
    buttons={
      <>
        <Button
          type={BUTTON_TYPES.GRAY}
          icon={<Icons.Plus />}
          onClick={props.onAddNew}
          hasSmallRadius
        />
      </>
    }
    noFilters
  />
)

@withRouter
@connect((state) => ({
  order: state.order,
}))
class OrderReturnListing extends React.Component {

  render() {
    const { order: { returns, loading }, dispatch, history } = this.props

    return (
      <main className="OrderReturnListing-screen">
        <Boxlist>
          <Box noPadding>
            <TableRegion
              dispatch={dispatch}
              loading={loading}
              rows={returns}
              onRefresh={qs => {
                dispatch({
                  type: ORDER_RETURN_RECEIVE_REQUEST,
                  payload: {
                    qs
                  }
                })
              }}
              onAddNew={() => {
                history.push("/order-returns/create")
              }}
              onOpen={rows => {
                console.log("onOpen")
                console.log(rows[0])
                history.push("/order-returns/create")
              }}
              onOpenMerchant={rows => {
                console.log("onOpenMerchant")
                console.log(rows[0])
                history.push("/merchants/mukama-test-id/edit")
              }}
              onOpenOrder={rows => {
                console.log("onOpenOrder")
                console.log(rows[0])
                history.push("/orders/create")
              }}
            />
          </Box>
        </Boxlist>
      </main>
    )
  }
}

export { OrderReturnListing }
