import { axios } from "../axios"
import { to } from "helpers/api"
import { store } from "index"

export const partner = {
  getPartners: qs => {
    return axios.post(
      to(`api/ms/partners/search`), qs, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      }
    )
  },
  getPartner: (id) => {
    return axios.get(to(`api/ms/partners/${id}`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  getPartnerCategories: (id) => {
    return axios.get(to(`api/ms/partners/categories`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  create: data => {
    return axios.post(to(`api/ms/partners`), data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  update: (id, data) => {
    return axios.put(to(`api/ms/partners/${id}`), data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  updateStatus: (id, status) => {
    return axios.patch(to(`api/ms/partners/${id}/status/${status}`), {}, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
}
