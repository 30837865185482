import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"

import {
  COST_CATEGORY_RECEIVE_REQUEST,
  COST_CATEGORY_RECEIVE_SUCCESS,
  COST_CATEGORY_RECEIVE_FAIL,
  COST_CATEGORY_CREATE_REQUEST,
  COST_CATEGORY_CREATE_SUCCESS,
  COST_CATEGORY_CREATE_FAIL,
  COST_CATEGORY_UPDATE_REQUEST,
  COST_CATEGORY_UPDATE_SUCCESS,
  COST_CATEGORY_UPDATE_FAIL,
  COST_CATEGORY_REMOVE_REQUEST,
  COST_CATEGORY_REMOVE_SUCCESS,
  COST_CATEGORY_REMOVE_FAIL,
} from "constants"

function* receive(action) {
  try {
    const res = yield call(Api.costCategory.get)

    yield put({
      type: COST_CATEGORY_RECEIVE_SUCCESS,
      payload: {
        costCategories: (res?.data || []),
      }
    })

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: COST_CATEGORY_RECEIVE_FAIL, payload: { err: err.message } })
  }
}

function* update(action) {
  const { id, data } = action.payload

  try {
    yield call(Api.costCategory.put, id, data)
    yield put({ type: COST_CATEGORY_UPDATE_SUCCESS })
    yield call(receive)

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: COST_CATEGORY_UPDATE_FAIL, payload: { err: err.message } })
  }
}

function* create(action) {
  const { data } = action.payload

  try {
    yield call(Api.costCategory.create, data)
    yield put({ type: COST_CATEGORY_CREATE_SUCCESS })
    yield call(receive)

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: COST_CATEGORY_CREATE_FAIL, payload: { err: err.message } })
  }
}

function* remove(action) {
  const { id } = action.payload

  try {
    yield call(Api.costCategory.remove, id)
    yield put({ type: COST_CATEGORY_REMOVE_SUCCESS })
    yield call(receive)

  } catch (err) {
    yield call(errorHandler, err, err => (
      err?.response?.status === 409 ? "Folders with children can not be removed." : err.message
    ))
    yield put({ type: COST_CATEGORY_REMOVE_FAIL, payload: { err: err.message } })
  }
}

function* costCategoryReceiveSaga() {
  yield takeLatest(COST_CATEGORY_RECEIVE_REQUEST, receive)
}

function* costCategoryCreateSaga() {
  yield takeLatest(COST_CATEGORY_CREATE_REQUEST, create)
}

function* costCategoryUpdateSaga() {
  yield takeLatest(COST_CATEGORY_UPDATE_REQUEST, update)
}

function* costCategoryRemoveSaga() {
  yield takeLatest(COST_CATEGORY_REMOVE_REQUEST, remove)
}

export function* costCategory() {
  yield all([
    call(costCategoryReceiveSaga),
    call(costCategoryCreateSaga),
    call(costCategoryUpdateSaga),
    call(costCategoryRemoveSaga),
  ])
}
