import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Table, useTableHook } from "components/Table"
import { Button, BUTTON_TYPES } from "components/Button"
import { Tag, TAG_TYPES } from "components/Ui"

export const DeliveryTypesTable = props => (
  <Table
    {...useTableHook({ ...props })}
    columns={[
      { title: "Code", attr: "code" },
      { title: "Provider", attr: "provider" },
      { title: "Available Countries", attr: "availableCountries" },
      { title: "Channels", attr: "channelReferences" },
      { title: "Title", attr: "title" },
      { title: "Visible", attr: "isVisible" },
      { title: "Removed", attr: "isRemoved" },
    ]}
    actions={[
      {
        title: translate("edit"),
        icon: <Icons.Edit />,
        massActionDisabled: true,
        action: props.onEdit,
      },
      {
        title: translate("delete"),
        isRed: true,
        massActionDisabled: true,
        icon: <Icons.Delete />,
        action: props.onDelete,
      }
    ]}
    columnRender={{
      channelReferences: row => {
        if ((row.channelReferences || []).length === 0) {
          return "All Channels"
        }

        return row.channelReferences.map(c => c.channelId).join(", ")
      },
      availableCountries: row => {
        return row.availableCountries.map(c => (
          <Tag
            key={c.countryCode}
            title={c.countryCode}
            type={TAG_TYPES.PRIMARY}
            onClick={() => {
              props.onCountryClick && props.onCountryClick.apply(this, [c])
            }}
          />
        ))
      },
      title: row => {
        const found = row.title.find(t => t.lang === props.language)
        return found ? found.text : row.code
      },
      isVisible: row => {
        return row.isVisible ?
          <Tag type={TAG_TYPES.TEAL} title="Yes" /> :
          <Tag type={TAG_TYPES.RED} title="No" />
      },
      isRemoved: row => {
        return row.isRemoved ?
          <Tag type={TAG_TYPES.RED} title="Yes" /> :
          <Tag type={TAG_TYPES.TEAL} title="No" />
      },
    }}
    buttons={
      <>
        <Button
          type={BUTTON_TYPES.GRAY}
          icon={<Icons.Plus />}
          onClick={props.onAdd}
          hasSmallRadius
        />
      </>
    }
    noCheckbox
    noFilters
  />
)