import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Form, Rules } from "components/Form"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Selectbox } from "components/Ui"

export const MerchantSettingsArea = props => {
  const { onSave } = props

  return (
    <>
      <Box>
        <h2>Merchant Settings</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              status: { value: "val1", label: "Demo" },
              warehouses: [{ value: "A1", label: "Demo warehouse" }],
              tags: [],
              notes: "",
            }
          }}
          rules={{
            warehouses: Rules.array().min(1),
            tags: Rules.array().nullable(),
            notes: Rules.string().nullable(),
          }}
          render={[
            {
              label: "Status",
              name: "status",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "val1", label: "Demo" },
                    { value: "val2", label: "Light" },
                    { value: "val3", label: "Medium" },
                    { value: "val4", label: "Premium" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Warehouses",
              name: "warehouses",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "A1", label: "Demo warehouse" },
                    { value: "A2", label: "Finland warehouse" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                  isMulti={true}
                />
              )
            },
            {
              label: "Merchant tags",
              name: "tags",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "tag1", label: "tag1" },
                    { value: "tag2", label: "tag2" },
                    { value: "tag3", label: "tag3" },
                    { value: "tag4", label: "tag4" },
                    { value: "tag5", label: "tag5" },
                    { value: "tag6", label: "tag6" },
                    { value: "tag7", label: "tag7" },
                    { value: "tag8", label: "tag8" },
                    { value: "tag9", label: "tag9" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                  isMulti={true}
                />
              )
            },
            {
              label: "Notes of Merchant",
              name: "notes",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                  placeholder="Add a note for this merchant."
                  textarea
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={onSave}
        />
      </Box>
    </>
  )
}