import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { List, Checkbox, Tag, TAG_TYPES } from "components/Ui"

export const ActionsArea = props => {
  const [tag, setTag] = React.useState("")
  const [tags, setTags] = React.useState(["Priority Order"])

  const addTag = () => {
    setTags(prev => [...new Set([...prev, tag])])
    setTag("")
  }

  return (
    <>
      <Box className="actions-area">
        <h2>Actions</h2>
        <hr />
        <Button
          title={"Copy order"}
          type={BUTTON_TYPES.TERTIARY}
          leftIcon={<Icons.Copy />}
        />
        <Button
          title="Ask a question concerning order"
          type={BUTTON_TYPES.TERTIARY}
        />
        <h2>Staff Links</h2>
        <hr />
        <Button
          title="Edit stock"
          type={BUTTON_TYPES.TERTIARY}
        />
        <Button
          title="Add cost to order"
          type={BUTTON_TYPES.TERTIARY}
        />
        <Button
          title="Stop and require action"
          type={BUTTON_TYPES.TERTIARY}
        />
        <Button
          title="Compensate this"
          type={BUTTON_TYPES.TERTIARY}
        />
        <Button
          title="Get a new collect reference"
          type={BUTTON_TYPES.TERTIARY}
        />
        <Button
          title="Tracking page"
          type={BUTTON_TYPES.TERTIARY}
        />
        <h2>Add to print queue</h2>
        <hr />
        <Button
          title="Address label"
          type={BUTTON_TYPES.TERTIARY}
        />
        <Button
          title="All documents"
          type={BUTTON_TYPES.TERTIARY}
        />
        <h2>History</h2>
        <hr />
        <p>Nothing yet</p>
        <h2>Fullfillment options</h2>
        <hr />
        <Checkbox title="Compensation order" />
        <h2>Validation exceptions</h2>
        <hr />
        <Checkbox title="Allow invalid target countries" />
        <Checkbox title="Don't validate country code for phone number" />
        <Checkbox title="DHL & GLS: Allow remote area delivery" />
        <Checkbox title="Allow order customizations through" />
        <Checkbox title="Remove order customizations" />
        <Checkbox title="Order rules ran" />
        <Checkbox title="Create new shipping label(s)" />
        <Checkbox title="Don't release products already taken when moving to DRAFT/CANCELLED" />
        <Checkbox title="Allow open actions when saving to COLLECTING/SHIPPED state" />
        <Checkbox title="Skip customs information validation" />
        <Checkbox title="Increase WMS revision until successfully created" />
        <Checkbox title="Allow PENDING order to WMS" />
        <Checkbox title="Large Order" />
        <Checkbox title="Approve large order" />
        <Checkbox title="Select the nearest pickup point" />
        <h2>Order tags</h2>
        <hr />
        <List>
        {tags.map(tag => (
          <Tag
            key={tag}
            title={tag}
            type={TAG_TYPES.BLUE}
          />
        ))}
        </List>
        <Input
          placeholder="Add a new tag"
          value={tag}
          onChange={text => setTag(text)}
          debounceTimeout={100}
          onEnter={addTag}
          button={
            <Button
              title="Add"
              type={BUTTON_TYPES.GRAY}
              isDisabled={!tag}
              onClick={addTag}
            />
          }
        />
      </Box>
    </>
  )
}