import {
  NOTIFICATION_RECEIVE_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  notifications: [],
}

export default function notification(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_RECEIVE_SUCCESS:
      return {
        ...state,
        notifications: action.payload.notifications,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
