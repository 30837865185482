import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box, useToggleHook } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Tag, TAG_TYPES } from "components/Ui"
import { Tab } from "components/Tab"
import { Table, useTableHook } from "components/Table"

import {
  MODAL_OPEN,
  MERCHANT_DISCOUNTS_RECEIVE_REQUEST,
  MODAL_SCREEN_ADD_MERCHANT_DISCOUNT,
  MODAL_SCREEN_UPDATE_MERCHANT_DISCOUNT,
  MODAL_SCREEN_REMOVE_MERCHANT_DISCOUNT,
} from "constants"

const DiscountsTableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    columns={[
      { title: "Name", attr: "name" },
      { title: "Start Date", attr: "start_date" },
      { title: "End Date", attr: "end_date" },
      { title: "Groups", attr: "groups" },
    ]}
    actions={[
      {
        title: translate("edit"),
        icon: <Icons.Edit />,
        massActionDisabled: true,
        action: props.onEdit,
      },
      {
        title: translate("delete"),
        isRed: true,
        massActionDisabled: true,
        icon: <Icons.Delete />,
        action: props.onDelete,
      }
    ]}
    columnRender={{
      groups: (row, i) => {
        return row.groups.map(group => {
          return (
            <Tag key={group} type={TAG_TYPES.BLUE} title={group} compact />
          )
        })
      },
    }}
    buttons={
      <>
        <Button
          type={BUTTON_TYPES.GRAY}
          icon={<Icons.Plus />}
          onClick={props.onAdd}
          hasSmallRadius
        />
      </>
    }
    noCheckbox
    noFilters
    noFoot
  />
)

const DiscountsHistoryTableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    columns={[
      { title: "Date", attr: "date" },
      { title: "User", attr: "email" },
      { title: "Discount group", attr: "discount_group" },
      { title: "Discount percent", attr: "discount_percent" },
    ]}
    noCheckbox
    noHead
    noFoot
    compact
  />
)

export const CommercialSettingsArea = props => {
  const { dispatch, loading, rows, onRefresh, onAdd, onEdit, onDelete } = props
  const [tabIndex, setTabIndex] = React.useState(0)

  return (
    <>
      <Box
        className="commercial-settings-area"
        noPadding
        {...useToggleHook({ toggled: false} )}
      >
        <h2>Discounts</h2>
        <hr />
        <Tab
          tabs={[
            {
              name: "Discounts",
              active: tabIndex === 0,
            },
            {
              name: "Discount Change History",
              active: tabIndex === 1,
            },
          ]}
          onClick={(_, i) => setTabIndex(i)}
          horizontal={true}
        />
        {tabIndex === 0 && (
          <DiscountsTableRegion
            loading={loading}
            rows={rows}
            onRefresh={qs => {
              dispatch({
                type: MERCHANT_DISCOUNTS_RECEIVE_REQUEST,
                payload: {
                  qs
                }
              })
            }}
            onAdd={() => {
              dispatch({
                type: MODAL_OPEN,
                payload: {
                  screen: MODAL_SCREEN_ADD_MERCHANT_DISCOUNT,
                }
              })
            }}
            onEdit={rows => {
              dispatch({
                type: MODAL_OPEN,
                payload: {
                  screen: MODAL_SCREEN_UPDATE_MERCHANT_DISCOUNT,
                  value: rows[0],
                }
              })
            }}
            onDelete={rows => {
              dispatch({
                type: MODAL_OPEN,
                payload: {
                  screen: MODAL_SCREEN_REMOVE_MERCHANT_DISCOUNT,
                  value: rows[0],
                }
              })
            }}
          />
        )}
        {tabIndex === 1 && (
          <DiscountsHistoryTableRegion
            loading={loading}
            rows={[...new Array(10)].map((_, i) => (
              {
                date: "30.03.2021",
                email: "ridkous@qubstudio.com",
                discount_group: "A1 (Shipment, pick & pack, size 1 (size S))",
                discount_percent: "40%",
              }
            ))}
          />
        )}
      </Box>
    </>
  )
}