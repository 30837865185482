import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Selectbox } from "components/Ui"

export const SupplierDetailsArea = props => {
  return (
    <>
      <Box>
        <h2>Supplier details</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              price: 0,
              currency: props.currencies[0],
              supplier: { value: "supplier1", label: "Supplier 1" },
              product_code_of_supplier: "",
              alarm_level: 0,
            }
          }}
          rules={{
            price: Rules.number().positive().required(),
            currency: Rules.string().required(),
            supplier: Rules.string().required(),
            product_code_of_supplier: Rules.string().required(),
            alarm_level: Rules.number().integer().min(0).required(),
          }}
          render={[
            {
              label: "Purchase price",
              name: "price",
              node: ({ field, fieldState }) => (
                <Input
                  type="number"
                  step={0.01}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Currency",
              name: "currency",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={props.currencies}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Supplier",
              name: "supplier",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "supplier1", label: "Supplier 1" },
                    { value: "supplier2", label: "Supplier 2" },
                    { value: "supplier3", label: "Supplier 3" },
                    { value: "supplier4", label: "Supplier 4" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Product code of supplier",
              name: "product_code_of_supplier",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Alarm level",
              name: "alarm_level",
              node: ({ field, fieldState }) => (
                <Input
                  type="number"
                  min={0}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
    </>
  )
}