import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"
import { toast } from "react-toastify"

import {
  PARTNER_RECEIVE_REQUEST,
  PARTNER_RECEIVE_SUCCESS,
  PARTNER_RECEIVE_FAIL,
  PARTNER_RECEIVE_SINGLE_REQUEST,
  PARTNER_RECEIVE_SINGLE_SUCCESS,
  PARTNER_RECEIVE_SINGLE_FAIL,
  PARTNER_CREATE_REQUEST,
  PARTNER_CREATE_SUCCESS,
  PARTNER_CREATE_FAIL,
  PARTNER_UPDATE_REQUEST,
  PARTNER_UPDATE_SUCCESS,
  PARTNER_UPDATE_FAIL,
  PARTNER_UPDATE_STATUS_REQUEST,
  PARTNER_UPDATE_STATUS_SUCCESS,
  PARTNER_UPDATE_STATUS_FAIL,
  PARTNER_CATEGORY_RECEIVE_REQUEST,
  PARTNER_CATEGORY_RECEIVE_SUCCESS,
  PARTNER_CATEGORY_RECEIVE_FAIL,
} from "constants"

function* receive(action) {
  const { qs } = action.payload

  try {
    const res = yield call(Api.partner.getPartners, qs)

    yield put({
      type: PARTNER_RECEIVE_SUCCESS,
      payload: {
        partners: res.data || []
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: PARTNER_RECEIVE_FAIL })
  }
}

function* receiveSingle(action) {
  const { payload: { id }, success, fail} = action

  try {
    const res = yield call(Api.partner.getPartner, id)

    success && success.apply(this, [res.data])
  } catch (err) {
    yield call(errorHandler, err)
    fail && fail.apply(this, [err])
  }
}

function* receiveCategories(action) {
  try {
    const res = yield call(Api.partner.getPartnerCategories)

    yield put({
      type: PARTNER_CATEGORY_RECEIVE_SUCCESS,
      payload: {
        categories: res.data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: PARTNER_CATEGORY_RECEIVE_FAIL })
  }
}

function* create(action) {
  const { success, payload: { data } } = action

  try {
    const res = yield call(Api.partner.create, data)

    yield put({
      type: PARTNER_CREATE_SUCCESS
    })

    toast.success("Partner created.")
    success && success.apply(this, [res.data])

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: PARTNER_CREATE_FAIL })
  }
}

function* update(action) {
  const { id, data } = action.payload

  try {
    const res = yield call(Api.partner.update, id, data)

    yield put({
      type: PARTNER_UPDATE_SUCCESS
    })

    toast.success("Partner updated.")

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: PARTNER_UPDATE_FAIL })
  }
}

function* updateStatus(action) {
  const { id, status } = action.payload

  try {
    const res = yield call(Api.partner.updateStatus, id, status)

    yield put({
      type: PARTNER_UPDATE_STATUS_SUCCESS
    })

    yield call(receive)

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: PARTNER_UPDATE_STATUS_FAIL })
  }
}

function* partnerReceiveSaga() {
  yield takeLatest(PARTNER_RECEIVE_REQUEST, receive)
}

function* partnerReceiveSingleSaga() {
  yield takeLatest(PARTNER_RECEIVE_SINGLE_REQUEST, receiveSingle)
}

function* partnerCreateSaga() {
  yield takeLatest(PARTNER_CREATE_REQUEST, create)
}

function* partnerUpdateSaga() {
  yield takeLatest(PARTNER_UPDATE_REQUEST, update)
}

function* partnerCategoryReceiveSaga() {
  yield takeLatest(PARTNER_CATEGORY_RECEIVE_REQUEST, receiveCategories)
}

function* partnerUpdateStatusSaga() {
  yield takeLatest(PARTNER_UPDATE_STATUS_REQUEST, updateStatus)
}

export function* partner() {
  yield all([
    call(partnerReceiveSaga),
    call(partnerReceiveSingleSaga),
    call(partnerCreateSaga),
    call(partnerUpdateSaga),
    call(partnerCategoryReceiveSaga),
    call(partnerUpdateStatusSaga),
  ])
}
