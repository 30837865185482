import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  modal: state.modal,
}))
class InvoiceListing extends React.Component {

  render() {
    return (
      <main className="InvoiceListing-screen">
        <p>InvoiceListing page</p>
      </main>
    )
  }
}

export { InvoiceListing }
