import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"
import { toast } from "react-toastify"

import {
  SETTINGS_PROVIDER_RECEIVE_REQUEST,
  SETTINGS_PROVIDER_RECEIVE_SUCCESS,
  SETTINGS_PROVIDER_RECEIVE_FAIL,
} from "constants"

function* providerReceive(action) {
  const { success } = action
  try {
    const res = yield call(Api.settings.getProviders)
    const data = {
      emailProviders: res.data.emailProviders.map(p => ({
        label: p.value,
        value: p.key
      })),
      smsProviders: res.data.smsProviders.map(p => ({
        label: p.value,
        value: p.key
      })),
    }

    const ogomail = yield call(Api.settings.getProviderModel, "ogomail")
    const mailgun = yield call(Api.settings.getProviderModel, "mailgun")
    const smsTo = yield call(Api.settings.getProviderModel, "sms.to")
    const smsOgoship = yield call(Api.settings.getProviderModel, "sms.ogoship")

    yield put({
      type: SETTINGS_PROVIDER_RECEIVE_SUCCESS,
      payload: {
        providers: data,
        ogomail: ogomail.data,
        mailgun: mailgun.data,
        smsTo: smsTo.data,
        smsOgoship: smsOgoship.data,
      }
    })

    success && success.apply(this, [data])
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: SETTINGS_PROVIDER_RECEIVE_FAIL })
  }
}

function* providerReceiveSaga() {
  yield takeLatest(SETTINGS_PROVIDER_RECEIVE_REQUEST, providerReceive)
}

export function* settings() {
  yield all([
    call(providerReceiveSaga),
  ])
}
