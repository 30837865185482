import { translate } from "helpers/i18n"

import { Modal  } from "components/Layout/Modal"
import { Button, BUTTON_TYPES } from "components/Button"
import { Selectbox } from "components/Ui"

export const ModalScreenSelectChannel = props => {
  const {
    value: { channels, existingChannels },
    onClose,
    onSelect,
    dispatch,
  } = props

  const [ option, setOption ] = React.useState([])

  return (
    <Modal
      className="modal-ModalScreenSelectChannel"
      title={"Select channels to add"}
      footer={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title={translate("cancel")}
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.PRIMARY}
            title={"Add"}
            onClick={() => onSelect.apply(this, [option])}
          />
        </>
      }
      onClose={onClose}
    >
      <Selectbox
        isMulti
        value={option}
        options={channels.filter(c => !existingChannels.includes(c.id.toString())).map(channel => (
          { label: `Company: ${channel.merchantCompany} --- Brand: ${channel.name} --- Channel: ${channel.id}`, value: channel.id }
        ))}
        onChange={option => setOption(option)}
      />
    </Modal>
  )
}
