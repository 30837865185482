import { translate } from "helpers/i18n"

import { Box, useToggleHook } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Tooltip, Selectbox } from "components/Ui"

export const CompanyInformationArea = props => {
  return (
    <>
      <Box {...useToggleHook({ toggled: true} )}>
        <h2>Official company information for contract</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              companyName: "",
              brandName: "",
              companyStreetAddress: "",
              companyPostCode: "",
              companyCity: "",
              country: { value: "fi", label: "Finland" },
              companyRegistryNumber: "",
              vatCode: "",
              eoriNumber: "",
              merchantLanguage: { value: "en", label: "English" },
              defaultCurrency: { value: "eur", label: "EUR" },
            }
          }}
          rules={{
            companyName: Rules.string().required(),
          }}
          render={[
            {
              label: "Company name",
              name: "companyName",
              tip: "Company official registered name. This is not visible to receivers.",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Brand name",
              name: "brandName",
              tip: "Brand name to use.",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Company Street Address",
              name: "companyStreetAddress",
              tip: "Company official address",
              node: ({ field, fieldState }) => (
                <Input
                  textarea
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Company Post Code",
              name: "companyPostCode",
              tip: "Company official post code",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Company City",
              name: "companyCity",
              tip: "Company official city",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Country",
              name: "country",
              tip: "Company official country",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "fi", label: "Finland" },
                    { value: "tr", label: "Turkey" },
                    { value: "us", label: "USA" }
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Company Registry Number",
              name: "companyRegistryNumber",
              tip: "Company registry number",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Vat Code (EU)",
              name: "vatCode",
              tip: "Use format beginning with countrycode FI12345678. VAT code for invoicing",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "EORU Number",
              name: "eoriNumber",
              tip: "Customs EORI number for importing outside the customs area",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Merchant language",
              name: "merchantLanguage",
              tip: "Language we use to communicate with you. Emails, reports and notifications.",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "en", label: "English" },
                    { value: "fi", label: "Finnish" },
                    { value: "de", label: "German" }
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Default Currency",
              name: "defaultCurrency",
              tip: "This currency is used when order does not have any other currency selected.",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "eur", label: "EUR" },
                    { value: "usd", label: "USD" },
                    { value: "uah", label: "UAH" }
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Box {...useToggleHook({ toggled: true} )}>
        <h2>Additional tax info</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              vat: "",
              eori: "",
              voec: "",
              ioss: "",
            }
          }}
          render={[
            {
              label: "UK Vat",
              name: "vat",
              tip: "UK VAT",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "UK EORI",
              name: "eori",
              tip: "UK EORI Number",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "VOEC",
              name: "voec",
              tip: "Valued Added Tax on E-Commerce for Norway",
              node: ({ field, fieldState }) => (
                <Input
                  textarea
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "IOSS",
              name: "ioss",
              tip: "IOSS number for when shipping from non-EU warehouses to EU",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            }
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Tooltip />
    </>
  )
}