import { Icons } from "icons"
import { Tab } from "components/Tab"

export const TabsSection = props => {
  const { activeTab } = props

  const tabs = [
    "Editor",
    "Preview",
  ].map((tab, i) => ({
    name: tab,
    active: i === activeTab,
  }))

  return (
    <Tab
      tabs={tabs}
      onClick={props.onClick}
    />
  )
}