import { axios } from "../axios"
import { to } from "helpers/api"
import { store } from "index"

export const file = {
  create: formData => {
    return axios.post(to(`api/files/merchants/${store.getState().app.activeMerchant.id}`), formData, {
      "content-type": "multipart/form-data",
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  get: () => {
    return axios.get(to(`api/files/merchants/${store.getState().app.activeMerchant.id}`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  getFile: fileId => {
    return axios.get(to(`api/files/merchants/${store.getState().app.activeMerchant.id}/${fileId}`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
}
