import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Tooltip } from "components/Ui"

export const ContactPersonArea = props => {
  return (
    <>
      <Box>
        <h2>Company contact person for OGOship's contacts (not visible to receivers)</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              name: "",
              email: "",
              phone: "",
            }
          }}
          rules={{
            name: Rules.string().required(),
            email: Rules.string().required(),
          }}
          render={[
            {
              label: "Name",
              name: "name",
              tip: "This person is the person whom OGOship will contact if we have questions concerning your products or shipments.",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Email",
              name: "email",
              tip: "OGOship will send email regarding your products and shipments to this address.",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Phone",
              name: "phone",
              tip: "This is your contact phone for OGOship to contact you if needed.",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            }
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Tooltip />
    </>
  )
}