import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Tag, TAG_TYPES } from "components/Ui"
import { Table, useTableHook } from "components/Table"

import {
  MODAL_OPEN,
  MERCHANT_CONNECTED_USERS_RECEIVE_REQUEST,
  MODAL_SCREEN_ADD_CONNECTED_USER,
  MODAL_SCREEN_UPDATE_CONNECTED_USER,
  MODAL_SCREEN_REMOVE_CONNECTED_USER,
} from "constants"

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    columns={[
      { title: "Name", attr: "name" },
      { title: "Email", attr: "email" },
      { title: "Title", attr: "title" },
      { title: "Status", attr: "status" },
    ]}
    actions={[
      {
        title: translate("edit"),
        icon: <Icons.Edit />,
        massActionDisabled: true,
        action: props.onEdit,
      },
      {
        title: translate("delete"),
        isRed: true,
        massActionDisabled: true,
        icon: <Icons.Delete />,
        action: props.onDelete,
      }
    ]}
    columnRender={{
      status: (row, i) => (
        <Tag
          key={i}
          type={row.status === 1 ? TAG_TYPES.TEAL : TAG_TYPES.ORANGE}
          title={row.status === 1 ? "Accepted" : "Declined"}
          compact
        />
      ),
    }}
    buttons={
      <>
        <Button
          type={BUTTON_TYPES.GRAY}
          icon={<Icons.Plus />}
          onClick={props.onAdd}
          hasSmallRadius
        />
      </>
    }
    noCheckbox
    noFilters
    noFoot
  />
)

export const ConnectedUsersArea = props => {
  const { dispatch, loading, rows, onRefresh, onAdd, onEdit, onDelete } = props

  return (
    <>
      <Box className="connected-users-area" noPadding>
        <h2>Connected users</h2>
        <hr />
        <TableRegion
          loading={loading}
          rows={rows}
          onRefresh={qs => {
            dispatch({
              type: MERCHANT_CONNECTED_USERS_RECEIVE_REQUEST,
              payload: {
                qs
              }
            })
          }}
          onAdd={() => {
            dispatch({
              type: MODAL_OPEN,
              payload: {
                screen: MODAL_SCREEN_ADD_CONNECTED_USER,
              }
            })
          }}
          onEdit={rows => {
            dispatch({
              type: MODAL_OPEN,
              payload: {
                screen: MODAL_SCREEN_UPDATE_CONNECTED_USER,
                value: rows[0],
              }
            })
          }}
          onDelete={rows => {
            dispatch({
              type: MODAL_OPEN,
              payload: {
                screen: MODAL_SCREEN_REMOVE_CONNECTED_USER,
                value: rows[0],
              }
            })
          }}
        />
      </Box>
    </>
  )
}