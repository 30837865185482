import { call, put } from "redux-saga/effects"
import { toast } from "react-toastify"

import {
  AUTH_LOGOUT_REQUEST,
} from "constants"

export function* errorHandler(err, cb = null) {
  yield call(console.log, err.message)

  if (!cb) {
    toast.error(err.message || "There was a problem with this action.")
  } else {
    toast.error(cb.apply(this, [err]))
  }

  if (err.message.includes("401")) {
    yield put({
      type: AUTH_LOGOUT_REQUEST,
      payload: {}
    })
  }
}