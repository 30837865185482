import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Boxlist } from "components/Boxlist"
import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { PageHeader } from "components/PageHeader"
import { Breadcrumbs, Checkbox } from "components/Ui"
import { ScaleLoader } from "components/Loader/ScaleLoader"

import { CountryWarehouseMapper } from "containers/App/Returns/DeliveryType/Upsert/CountryWarehouseMapper"
import { LanguageHandler } from "containers/App/Returns/DeliveryType/Upsert/LanguageHandler"

import {
  TAB_INDEX_UPDATE,
  WAREHOUSE_RECEIVE_REQUEST,
  MERCHANT_RECEIVE_REQUEST,
  RETURN_DELIVERY_TYPE_CREATE_REQUEST,
  RETURN_DELIVERY_TYPE_UPDATE_REQUEST,
  RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_REQUEST,
  MODAL_OPEN,
  MODAL_SCREEN_SELECT_CHANNEL,
} from "constants"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  country: state.country,
  returnDeliveryType: state.returnDeliveryType,
  warehouse: state.warehouse,
}))
class ReturnDeliveryTypeUpsert extends React.Component {

  state = {
    loaded: !this.props.updateMode,
    err: "",
    data: {},
    availableCountries: [],
    title: [],
    returnInstructions: [],
    channels: [],
  }

  componentDidMount() {
    const { updateMode, dispatch, match: { params: { typeId } } } = this.props

    dispatch({
      type: TAB_INDEX_UPDATE,
      payload: {
        attr: "returnsDashboardTabIndex",
        value: 1
      }
    })

    dispatch({
      type: WAREHOUSE_RECEIVE_REQUEST,
    })

    if (updateMode) {
      dispatch({
        type: RETURN_DELIVERY_TYPE_RECEIVE_SINGLE_REQUEST,
        payload: {
          id: typeId
        },
        success: ({ rdt, channels }) => {
          this.setState({
            data: rdt,
            channels,
            availableCountries: rdt.availableCountries,
            title: rdt.title,
            returnInstructions: rdt.returnInstructions,
            loaded: true,
          })
        },
        fail: err => {
          this.setState({ err })
        }
      })
      return
    }

    dispatch({
      type: MERCHANT_RECEIVE_REQUEST,
      success: channels => {
        this.setState({
          loaded: true,
          channels,
        })
      },
    })
  }

  get pagedata() {
    const { updateMode } = this.props

    const res = {
      action: updateMode ? RETURN_DELIVERY_TYPE_UPDATE_REQUEST : RETURN_DELIVERY_TYPE_CREATE_REQUEST,
      prefix: updateMode ? `Update the Return Delivery Type` : "Create a Return Delivery Type for your customers.",
      title: updateMode ? "update" : "create",
      defaultValues: this.getDefaultValues(),
    }

    return res
  }

  getDefaultValues() {
    const { updateMode } = this.props
    const { data } = this.state

    const values = {
      code: "",
      provider: "",
      isVisible: true,
      indexNumber: 0,
      tags: "",
      logo: "",
      channelReferences: "",
    }

    if (updateMode) {
      values.code = data.code
      values.provider = data.provider
      values.isVisible = !!data.isVisible
      values.indexNumber = data.indexNumber
      values.tags = data.tags?.join(",")
      values.logo = data.logo
      values.channelReferences = (data.channelReferences || []).map(c => c.channelId).join(",") || ""
    }

    return values
  }

  onSubmit = data => {
    const { updateMode, dispatch, history, match: { params: { typeId } } } = this.props
    const { availableCountries, returnInstructions, title } = this.state

    const channelIds = data.channelReferences.split(",").filter(Boolean)

    const payload = {
      data: {
        code: data.code,
        provider: data.provider,
        isVisible: !!data.isVisible,
        indexNumber: data.indexNumber,
        tags: data.tags.split(","),
        logo: data.logo,
        title,
        returnInstructions,
        availableCountries,
        channelReferences: channelIds.map(channelId => ({
          channelId
        }))
      }
    }

    if (updateMode) {
      payload.id = typeId
    }

    dispatch({
      type: this.pagedata.action,
      payload,
      success: () => {
        history.push("/returns")
      }
    })
  }

  setAvailableCountries = availableCountries => {
    this.setState({ availableCountries })
  }

  renderChannels(selectedChannels, setValue = null) {
    const { channels } = this.state

    const remove = id => {
      setValue("channelReferences", selectedChannels.filter(channelId => channelId !== id).join(","))
    }

    return selectedChannels.map(channelId => {
      const channel = channels.find(c => c.id.toString() === channelId.toString())

      if (!channel) {
        return (
          <div
            key={channelId}
            className="channel-info-box is-red"
          >
            <p>Channel: <span>Unknown! ({channelId})</span></p>
            <p>Company: ---</p>
            <p>Brand: ---</p>
            <Icons.Delete onClick={() => remove(channelId)} />
          </div>
        )
      }

      return (
        <div
          key={channelId}
          className="channel-info-box"
        >
          <p>Channel: <span>{channel.id}</span></p>
          <p>Company: {channel.merchantCompany}</p>
          <p>Brand: {channel.name}</p>
          <Icons.Delete onClick={() => remove(channelId)} />
        </div>
      )
    })
  }

  getChannels(value) {
    if (typeof value === "undefined") {
      return []
    }

    return value.replace(/\s+/g, "").split(",").filter(Boolean)
  }

  render() {
    const {
      returnDeliveryType: { types, loading },
      country: { countries },
      warehouse: { enabledWarehouses },
      history,
      updateMode,
      dispatch,
    } = this.props
    const { loaded, data, availableCountries, err } = this.state

    const rules = {
      code: Rules.string().required(),
      provider: Rules.string().required(),
      isVisible: Rules.bool().required(),
      indexNumber: Rules.number().min(0),
      tags: Rules.string(),
      logo: Rules.string().nullable(),
      channelReferences: Rules.string(),
    }

    return (
      <>
        <PageHeader
          prefix={this.pagedata.prefix}
          title={data.provider || "Create Delivery Type"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "returns",
                  onClick: () => {
                    history.push("/returns")
                  }
                },
                {
                  title: this.pagedata.title,
                },
              ]}
              activeIndex={2}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="ReturnDeliveryTypeUpsert-screen">
          {err && (
            <Boxlist>
              <Box>
                <p>Error: {err.message}</p>
              </Box>
            </Boxlist>
          )}
          {!err && !loaded && (
            <ScaleLoader />
          )}

          {!err && loaded && (
            <Boxlist>
              <Box>
                <h2>Return Delivery Type</h2>
                <hr />
                <Form
                  id="return-delivery-type-form"
                  data={{
                    defaultValues: this.pagedata.defaultValues
                  }}
                  rules={rules}
                  render={[
                    {
                      label: "Code",
                      name: "code",
                      node: ({ field, fieldState }) => (
                        <Input
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Provider",
                      name: "provider",
                      node: ({ field, fieldState }) => (
                        <Input
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Logo URL",
                      name: "logo",
                      node: ({ field, fieldState }) => (
                        <Input
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Channels",
                      name: "channelReferences",
                      node: ({ field, fieldState, setValue }) => {
                        const channels = this.getChannels(field.value)
                        return (
                          <>
                            <div className="channel-info-container">
                              {channels.length === 0 && (
                                <div
                                  className="channel-info-box is-green"
                                >
                                  <p>Channel: <span>All Channels</span></p>
                                  <p>Company: <span>All Companies</span></p>
                                  <p>Brand: <span>All Brands</span></p>
                                </div>
                              )}
                              {channels.length > 0 && this.renderChannels(channels, setValue)}
                            </div>
                            <Button
                              type={BUTTON_TYPES.TERTIARY}
                              title="Add Channels"
                              onClick={() => {
                                const existingChannels = field.value.split(",")

                                dispatch({
                                  type: MODAL_OPEN,
                                  payload: {
                                    screen: MODAL_SCREEN_SELECT_CHANNEL,
                                    value: {
                                      channels: this.state.channels,
                                      existingChannels,
                                    },
                                    onSave: options => {
                                      setValue("channelReferences", [
                                        ...existingChannels,
                                        ...options.map(o => o.value)
                                      ].join(","))
                                    }
                                  },
                                })
                              }}
                            />
                          </>
                        )
                      }
                    },
                    {
                      label: "Index Number",
                      name: "indexNumber",
                      node: ({ field, fieldState }) => (
                        <>
                          <Input
                            type="number"
                            min={0}
                            onChange={field.onChange}
                            value={field.value}
                            error={fieldState.error?.message}
                          />
                          <p className="index-number-hint-text">
                            <Icons.Info />
                            Index number only affects in which order the Return Delivery Type will appear. (Higher number means it will appear first in the lists)
                          </p>
                        </>
                      )
                    },
                    {
                      label: "Tags",
                      name: "tags",
                      node: ({ field, fieldState }) => (
                        <>
                          <Input
                            onChange={field.onChange}
                            value={field.value}
                            error={fieldState.error?.message}
                          />
                          <p className="index-number-hint-text">
                            <Icons.Info />
                            Separated by a comma. (e.g tag1, tag2)
                          </p>
                        </>
                      )
                    },
                    {
                      name: "isVisible",
                      node: ({ field, fieldState }) => (
                        <Checkbox
                          title="Visible?"
                          onClick={() => {
                            field.onChange(!field.value)
                          }}
                          checked={field.value}
                        />
                      )
                    },
                  ]}
                  onSubmit={this.onSubmit}
                />
              </Box>
              <Box>
                <h2>Titles</h2>
                <hr />
                <LanguageHandler
                  type={0}
                  initialValues={data.title}
                  onChange={title => {
                    this.setState({ title })
                  }}
                />
              </Box>
              <Box>
                <h2>Return Instructions</h2>
                <hr />
                <LanguageHandler
                  type={1}
                  initialValues={data.returnInstructions}
                  onChange={returnInstructions => {
                    this.setState({ returnInstructions })
                  }}
                />
              </Box>
              <Box>
                <h2>Countries and Warehouses</h2>
                <hr />
                <CountryWarehouseMapper
                  countries={countries.map(country => {
                    const found = (data.availableCountries || []).find(entry => entry.countryCode === country.value)

                    return {
                      ...country,
                      warehouses: found?.warehouses || []
                    }
                  })}
                  warehouses={enabledWarehouses}
                  onChange={this.setAvailableCountries}
                />
              </Box>
              <Box ghost>
                <Button
                  isDisabled={availableCountries.length === 0}
                  form="return-delivery-type-form"
                  title={translate("save")}
                  type={BUTTON_TYPES.GRAY}
                />
              </Box>
            </Boxlist>
          )}
        </main>
      </>
    )
  }
}

export { ReturnDeliveryTypeUpsert }
