import "./index.scss"

export class PageHeader extends React.Component {

  static propTypes = {
    prefix: PropTypes.string,
    title: PropTypes.string,
    renderLeft: PropTypes.node,
    renderRight: PropTypes.node,
  }

  static defaultProps = {
    prefix: "",
    title: "",
    renderLeft: null,
    renderRight: null,
  }

  render () {
    const {
      prefix,
      title,
      renderLeft,
      renderRight,
    } = this.props

    return (
      <section
        className={classnames("com-PageHeader")}
      >
        <div className={classnames("subheader-left-side", {
          "has-right-side": renderRight
        })}>
          {prefix && <p>{prefix}</p>}
          {title && <h2>{title}</h2>}
          {renderLeft}
        </div>
        {renderRight && (
          <div className="subheader-right-side">
            {renderRight}
          </div>
        )}
      </section>
    )
  }
}