import { translate } from "helpers/i18n"
import { Icons } from "icons"

import "./index.scss"

export class Modal extends React.Component {

  static propTypes = {
    onClose: PropTypes.func,
    isSidebar: PropTypes.bool,
    noPadding: PropTypes.bool,
    title: PropTypes.string,
    className: PropTypes.string,
    footer: PropTypes.node,
  }

  static defaultProps = {
    onClose: null,
    isSidebar: false,
    noPadding: false,
    title: "",
    className: "",
    footer: null,
  }

  noPropagation = e => {
    e.stopPropagation()
  }

  render () {
    const { children, noPadding, isSidebar, title, className, footer, onClose } = this.props

    return (
      <section
        className={classnames("com-Modal", className, {
          "is-sidebar-modal": isSidebar,
          "no-padding": noPadding,
        })}
        onClick={onClose}
      >
        <div className="modal-screen" onClick={this.noPropagation}>
          <div className="modal-screen-header">
            <h3>{title}</h3>
            {!isSidebar && <Icons.Close onClick={onClose} />}
            {isSidebar && <Icons.ArrowRightRegular onClick={onClose} />}
          </div>
          <div className={classnames("modal-screen-inner", { "has-no-footer": !footer })}>
            {children}
          </div>
          {footer && (
            <div className={classnames("modal-screen-footer")}>
              {footer}
            </div>
          )}
        </div>
      </section>
    )
  }
}