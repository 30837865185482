import {
  EVENT_RECEIVE_REQUEST,
  EVENT_RECEIVE_SUCCESS,
  EVENT_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: true,
  err: "",
  data: {},
  maximumNumber: 0,
}

export default function event(state = initialState, action) {
  switch (action.type) {
    case EVENT_RECEIVE_REQUEST:
      return {
        ...state,
        err: "",
        loading: true,
      }

    case EVENT_RECEIVE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        err: "",
      }

    case EVENT_RECEIVE_FAIL:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
