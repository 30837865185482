import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"

import {
  CUSTOMER_RECEIVE_REQUEST,
  CUSTOMER_RECEIVE_SUCCESS,
  CUSTOMER_RECEIVE_FAIL,
} from "constants"

import { fakeCustomersBackend } from "mock"

function* receive(action) {
  try {
    const data = fakeCustomersBackend.update(action.payload?.qs).getJson()

    yield delay(1500)

    yield put({
      type: CUSTOMER_RECEIVE_SUCCESS,
      payload: {
        customers: data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: CUSTOMER_RECEIVE_FAIL })
  }
}

function* customerReceiveSaga() {
  yield takeLatest(CUSTOMER_RECEIVE_REQUEST, receive)
}

export function* customer() {
  yield all([
    call(customerReceiveSaga),
  ])
}
