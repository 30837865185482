import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Boxlist } from "components/Boxlist"
import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Table, useTableHook } from "components/Table"
import { Form, Rules } from "components/Form"

import "./index.scss"

const TableRegion = props => {
  const { rows, onAdd, onDelete, onQuantityChange } = props

  return (
    <Table
      {...useTableHook({
        rows,
        onRefresh: () => {
          console.log("onRefresh")
        },
      })}
      columns={[
        { title: "Code", attr: "code" },
        { title: "Name", attr: "name" },
        { title: "Quantity", attr: "quantity" },
      ]}
      actions={[
        {
          title: translate("delete"),
          isRed: true,
          action: onDelete
        },
      ]}
      columnRender={{
        quantity: (row, i) => (
          <Input
            type="number"
            step={1}
            min={1}
            value={row.quantity}
            onChange={quantity => onQuantityChange.apply(this, [quantity, i])}
          />
        ),
      }}
      buttons={
        <>
          <Button
            type={BUTTON_TYPES.GRAY}
            icon={<Icons.Plus />}
            onClick={onAdd}
            hasSmallRadius
          />
        </>
      }
      tableEmptyMessage={"Please add at least one product to bundle."}
      noFilters
      noGlobalSearch
      noFoot
      noCheckbox
    />
  )
}

@withRouter
@connect((state) => ({
  product: state.product,
}))
class ProductBundleCreate extends React.Component {

  state = {
    products: []
  }

  onSubmit = data => {
    console.log({
      ...data,
      products: this.state.products
    })
  }

  render() {
    const { product: { loading }, dispatch } = this.props
    const { products } = this.state

    return (
      <main className="ProductBundleCreate-screen">
        <Boxlist>
          <Box>
            <h2>Basic information of the bundle</h2>
            <hr />
            <Form
              id="product-bundle-create-form"
              data={{
                defaultValues: {
                  name: "",
                  code: "",
                  description: "",
                }
              }}
              rules={{
                name: Rules.string().required(),
                code: Rules.string().required(),
              }}
              render={[
                {
                  label: "Bundle name",
                  name: "name",
                  node: ({ field, fieldState }) => (
                    <Input
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
                {
                  label: "Bundle code",
                  name: "code",
                  node: ({ field, fieldState }) => (
                    <Input
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },{
                  label: "Description",
                  name: "description",
                  node: ({ field, fieldState }) => (
                    <Input
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )
                },
              ]}
              onSubmit={this.onSubmit}
            />
          </Box>
          <Box noPadding>
            <TableRegion
              rows={products}
              onAdd={() => {
                this.setState({
                  products: [...products, {
                    name: "Added item",
                    quantity: 1,
                    code: "Code-added"
                  }]
                })
              }}
              onDelete={(_, i) => {
                this.setState({
                  products: products.filter((_, index) => index !== i)
                })
              }}
              onQuantityChange={(quantity, i) => {
                this.setState({
                  products: products.map((product, index) => {
                    if (index === i) {
                      return {
                        ...product,
                        quantity: quantity
                      }
                    }

                    return product
                  })
                })
              }}
            />
          </Box>
          <Box ghost>
            <Button
              isDisabled={products.length === 0}
              form="product-bundle-create-form"
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          </Box>
        </Boxlist>
      </main>
    )
  }
}

export { ProductBundleCreate }
