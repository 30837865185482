import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"
import { toast } from "react-toastify"

import {
  REBUY_PAGE_DATA_RECEIVE_REQUEST,
  REBUY_PAGE_DATA_RECEIVE_SUCCESS,
  REBUY_PAGE_DATA_RECEIVE_FAIL,
} from "constants"

function* receive(action) {
  const { success } = action
  try {
    const res = yield call(Api.rebuy.getRebuyPageData)

    yield put({
      type: REBUY_PAGE_DATA_RECEIVE_SUCCESS,
      payload: {
        data: res.Data
      }
    })

    success && success.apply(this, [res.Data])
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: REBUY_PAGE_DATA_RECEIVE_FAIL })
  }
}

function* rebuyReceiveSaga() {
  yield takeLatest(REBUY_PAGE_DATA_RECEIVE_REQUEST, receive)
}

export function* rebuy() {
  yield all([
    call(rebuyReceiveSaga),
  ])
}
