import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { List } from "components/Ui"

export const IconsArea = props => (
  <>
    <Boxlist>
      <Box>
        <pre>
          <p>Usage example: {"<Icons.Reload />"}</p>
        </pre>
        <h2>Icon Set: 16</h2>
        <List>
          <p>ArrowRightRegular</p> <Icons.ArrowRightRegular />
          <p>Reload</p> <Icons.Reload />
        </List>
        <h2>Icon Set: 20</h2>
        <List>
          <p>Calendar</p> <Icons.Calendar />
          <p>Cancel</p> <Icons.Cancel />
          <p>Chart</p> <Icons.Chart />
          <p>Clock</p> <Icons.Clock />
          <p>Close</p> <Icons.Close />
          <p>Delete</p> <Icons.Delete />
          <p>DocumentFilled</p> <Icons.DocumentFilled />
          <p>DocumentOutline</p> <Icons.DocumentOutline />
          <p>Download</p> <Icons.Download />
          <p>Edit</p> <Icons.Edit />
          <p>Email</p> <Icons.Email />
          <p>Id</p> <Icons.Id />
          <p>Info</p> <Icons.Info />
          <p>Location</p> <Icons.Location />
          <p>Logout</p> <Icons.Logout />
          <p>More</p> <Icons.More />
          <p>Notifications</p> <Icons.Notifications />
          <p>Package</p> <Icons.Package />
          <p>Plus</p> <Icons.Plus />
          <p>Regenerate</p> <Icons.Regenerate />
          <p>Save</p> <Icons.Save />
          <p>Search</p> <Icons.Search />
          <p>Settings</p> <Icons.Settings />
          <p>Shipping</p> <Icons.Shipping />
          <p>Supplier</p> <Icons.Supplier />
          <p>Timer</p> <Icons.Timer />
          <p>Upload</p> <Icons.Upload />
          <p>Warehouse</p> <Icons.Warehouse />
          <p>Warning</p> <Icons.Warning />
          <p>World</p> <Icons.World />
        </List>
        <h2>Icon Set: 32</h2>
        <List>
          <p>CalculatorRegular</p> <Icons.CalculatorRegular />
          <p>CalculatorHighlighted</p> <Icons.CalculatorHighlighted />
          <p>MerchantRegular</p> <Icons.MerchantRegular />
          <p>MerchantHighlighted</p> <Icons.MerchantHighlighted />
          <p>MiscRegular</p> <Icons.MiscRegular />
          <p>MiscHighlighted</p> <Icons.MiscHighlighted />
          <p>OrdersRegular</p> <Icons.OrdersRegular />
          <p>OrdersHighlighted</p> <Icons.OrdersHighlighted />
          <p>ProductsRegular</p> <Icons.ProductsRegular />
          <p>ProductsHighlighted</p> <Icons.ProductsHighlighted />
          <p>StockUpdateRegular</p> <Icons.StockUpdateRegular />
          <p>StockUpdateHighlighted</p> <Icons.StockUpdateHighlighted />
          <p>AdminRegular</p> <Icons.AdminRegular />
          <p>AdminHighlighted</p> <Icons.AdminHighlighted />
          <p>HomepageRegular</p> <Icons.HomepageRegular />
          <p>HomepageHighlighted</p> <Icons.HomepageHighlighted />
        </List>
      </Box>
    </Boxlist>
  </>
)
