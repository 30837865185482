import { getBaseApiUrl, getAuthApiBaseUrl, getBaseUrl } from "../api"

const ENVIRONMENT_DEVELOPMENT = "dev"
const ENVIRONMENT_PRODUCTION = "prod"

export function getCurrentEnv() {
  if (!process.env.REACT_APP_ENV) {
    console.warn("Invalid environment!")
    return ENVIRONMENT_DEVELOPMENT
  }

  return process.env.REACT_APP_ENV.toLowerCase()
}

export function isDevelopment() {
  return getCurrentEnv() === ENVIRONMENT_DEVELOPMENT
}

export function showDebugData() {
  const messages = []
  const options = "background: #111; color: #f14668; padding: 8px"

  messages.push(`Environment: ${getCurrentEnv()}`)
  messages.push(`Backend API: ${getBaseApiUrl()}`)
  messages.push(`Auth API: ${getAuthApiBaseUrl()}`)
  messages.push(`App Base URL: ${getBaseUrl()}`)

  messages.forEach((message) => {
    console.log(`%c${message}`, options)
  })
}
