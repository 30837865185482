import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"
import { Api } from "api"
import { toast } from "react-toastify"

import {
  TM_RECEIVE_REQUEST,
  TM_RECEIVE_SUCCESS,
  TM_RECEIVE_FAIL,
  TM_RECEIVE_SINGLE_REQUEST,
  TM_RECEIVE_SINGLE_SUCCESS,
  TM_RECEIVE_SINGLE_FAIL,
  TM_CREATE_REQUEST,
  TM_CREATE_SUCCESS,
  TM_CREATE_FAIL,
  TM_UPDATE_REQUEST,
  TM_UPDATE_SUCCESS,
  TM_UPDATE_FAIL,
  TM_REMOVE_REQUEST,
  TM_REMOVE_SUCCESS,
  TM_REMOVE_FAIL,
} from "constants"

import { fakeTransactionalMessagesBackend } from "mock"

function* receive(action) {
  try {
    const res = yield call(Api.transactionalMessage.getAll)

    yield put({
      type: TM_RECEIVE_SUCCESS,
      payload: {
        messages: res?.data || []
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: TM_RECEIVE_FAIL })
  }
}

function* receiveSingle(action) {
  const { payload: { id }, success, fail} = action

  try {
    const res = yield call(Api.transactionalMessage.getSingle, id)
    success && success.apply(this, [res.data])
  } catch (err) {
    yield call(errorHandler, err)
    fail && fail.apply(this, [err])
  }
}

function* create(action) {
  const { success, payload: { data } } = action

  try {
    const res = yield call(Api.transactionalMessage.create, data)

    yield put({
      type: TM_CREATE_SUCCESS
    })

    toast.success("Transactional message created.")
    success && success.apply(this, [res.data])

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: TM_CREATE_FAIL })
  }
}

function* update(action) {
  const { success, payload: { id, data } } = action

  try {
    const res = yield call(Api.transactionalMessage.update, id, data)

    yield put({
      type: TM_UPDATE_SUCCESS
    })

    toast.success("Transactional message updated.")
    success && success.apply(this, [res.data])

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: TM_UPDATE_FAIL })
  }
}

function* remove(action) {
  const { payload: { id } } = action

  try {
    const res = yield call(Api.transactionalMessage.remove, id)
    yield put({ type: TM_REMOVE_SUCCESS })
    yield call(receive)
    toast.success("Transactional message removed.")

  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: TM_REMOVE_FAIL })
  }
}

function* transactionalMessageReceiveSaga() {
  yield takeLatest(TM_RECEIVE_REQUEST, receive)
}

function* transactionalMessageReceiveSingleSaga() {
  yield takeLatest(TM_RECEIVE_SINGLE_REQUEST, receiveSingle)
}

function* transactionalMessageCreateSaga() {
  yield takeLatest(TM_CREATE_REQUEST, create)
}

function* transactionalMessageUpdateSaga() {
  yield takeLatest(TM_UPDATE_REQUEST, update)
}

function* transactionalMessageRemoveSaga() {
  yield takeLatest(TM_REMOVE_REQUEST, remove)
}

export function* transactionalMessage() {
  yield all([
    call(transactionalMessageReceiveSaga),
    call(transactionalMessageReceiveSingleSaga),
    call(transactionalMessageCreateSaga),
    call(transactionalMessageUpdateSaga),
    call(transactionalMessageRemoveSaga),
  ])
}
