import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"

import { STOCK_UPDATE_RECEIVE_REQUEST } from "constants"

import "./index.scss"

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    title={translate("stock-updates")}
    description="Review and manage all the stock updates"
    columns={[
      { title: "Created", attr: "_1" },
      { title: "OGO Reference", attr: "_2" },
      { title: "Reference", attr: "_3" },
      { title: "Incoming Date", attr: "_4" },
      { title: "Pallets", attr: "_5" },
      { title: "Parcels", attr: "_6" },
      { title: "Unknown products", attr: "_7" },
      { title: "State", attr: "_8" },
      { title: "Supplier", attr: "_9" },
      { title: "Comments", attr: "_10" },
      { title: "Company", attr: "_11" },
    ]}
  />
)

@withRouter
@connect((state) => ({
  stockUpdate: state.stockUpdate,
}))
class StockUpdateListing extends React.Component {

  render() {
    const { stockUpdate: { stockUpdates, loading }, dispatch } = this.props

    return (
      <main className="StockUpdateListing-screen">
        <Boxlist>
          <Box noPadding>
            <TableRegion
              loading={loading}
              rows={stockUpdates}
              onRefresh={qs => {
                dispatch({
                  type: STOCK_UPDATE_RECEIVE_REQUEST,
                  payload: {
                    qs
                  }
                })
              }}
            />
          </Box>
        </Boxlist>
      </main>
    )
  }
}

export { StockUpdateListing }
