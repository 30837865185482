import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Boxlist } from "components/Boxlist"

import { EditOrderArea } from "./EditOrderArea"
import { NameAndAddressArea } from "./NameAndAddressArea"
import { AdditionalOrderInfoArea } from "./AdditionalOrderInfoArea"
import { ActionsArea } from "./ActionsArea"

import "./index.scss"

@withRouter
@connect((state) => ({
  order: state.order,
}))
class OrderCreate extends React.Component {

  render() {
    const { order: { orders, loading }, dispatch } = this.props

    return (
      <main className="order-create-screen">
        <Boxlist>
          <EditOrderArea />
          <NameAndAddressArea />
          <AdditionalOrderInfoArea />
        </Boxlist>
        <Boxlist>
          <ActionsArea />
        </Boxlist>
      </main>
    )
  }
}

export { OrderCreate }
