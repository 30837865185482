import {
  REBUY_PAGE_DATA_RECEIVE_REQUEST,
  REBUY_PAGE_DATA_RECEIVE_SUCCESS,
  REBUY_PAGE_DATA_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: true,
  data: {},
}

export default function rebuy(state = initialState, action) {
  switch (action.type) {
    case REBUY_PAGE_DATA_RECEIVE_REQUEST:
      return {
        ...initialState
      }

    case REBUY_PAGE_DATA_RECEIVE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      }

    case REBUY_PAGE_DATA_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
