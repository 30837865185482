import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Form, Rules } from "components/Form"
import { Input } from "components/Input"
import { Button, BUTTON_TYPES } from "components/Button"

import { AUTH_LOGIN_REQUEST } from "constants"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
}))
class ForgotPassword extends React.Component {

  state = {
    successful: false
  }

  render() {
    const { dispatch, history, auth } = this.props
    const { successful } = this.state

    return (
      <section className="auth-forgot-password-screen">
        <h2>{successful ? translate("__todo111") : translate("__todo112")}</h2>
        <div className="inner-container">
          <h5>{successful ? translate("__todo113") : translate("__todo114")}</h5>
          {!successful && (
            <Form
              id="forgot-password-form"
              data={{
                defaultValues: {
                  email: "",
                }
              }}
              rules={{
                email: Rules.string().email(translate("__todo115")).required(translate("__todo116")),
              }}
              render={[
                {
                  label: "Email",
                  name: "email",
                  node: ({ field, fieldState }) => {
                    return (
                      <Input
                        onChange={field.onChange}
                        value={field.value}
                        error={fieldState.error?.message}
                      />
                    )
                  }
                }
              ]}
              onSubmit={data => {
                console.log(data)
                this.setState({
                  successful: true
                })
              }}
            />
          )}
          {!successful && (
            <div className="auth-buttons">
              <Button
                form="forgot-password-form"
                title={translate("__todo117")}
                type={BUTTON_TYPES.PRIMARY}
                isDisabled={auth.loading}
              />
              <Button
                title={translate("__todo118")}
                type={BUTTON_TYPES.LINK}
                onClick={() => {
                  history.push("/auth/sign-in")
                }}
              />
            </div>
          )}
        </div>
      </section>
    )
  }
}

export { ForgotPassword }
