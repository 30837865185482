import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Boxlist } from "components/Boxlist"

import { BasicInfoArea } from "./BasicInfoArea"
import { MeasurementsArea } from "./MeasurementsArea"
import { HistoryArea } from "./HistoryArea"
import { SupplierDetailsArea } from "./SupplierDetailsArea"
import { SalesInfoArea } from "./SalesInfoArea"
import { CustomsInfoArea } from "./CustomsInfoArea"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  product: state.product,
  currency: state.currency,
}))
class ProductCreate extends React.Component {

  render() {
    const {
      product: { products, loading },
      currency: { currencies },
      dispatch
    } = this.props

    return (
      <main className="product-create-screen">
        <Boxlist>
          <BasicInfoArea />
          <MeasurementsArea />
        </Boxlist>
        <Boxlist>
          <HistoryArea />
        </Boxlist>
        <Boxlist>
          <SupplierDetailsArea currencies={currencies} />
          <SalesInfoArea currencies={currencies} />
          <CustomsInfoArea />
        </Boxlist>
      </main>
    )
  }
}

export { ProductCreate }
