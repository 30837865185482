import {
  RETURN_DELIVERY_TYPE_RECEIVE_REQUEST,
  RETURN_DELIVERY_TYPE_RECEIVE_SUCCESS,
  RETURN_DELIVERY_TYPE_RECEIVE_FAIL,
  RETURN_DELIVERY_TYPE_REMOVE_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: true,
  types: [],
}

export default function returnDeliveryType(state = initialState, action) {
  switch (action.type) {
    case RETURN_DELIVERY_TYPE_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case RETURN_DELIVERY_TYPE_RECEIVE_SUCCESS:
      return {
        ...state,
        types: action.payload.types,
        loading: false,
      }

    case RETURN_DELIVERY_TYPE_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case RETURN_DELIVERY_TYPE_REMOVE_SUCCESS:
      return {
        ...state,
        types: state.types.filter(type => type.id !== action.payload.id),
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
