import {
  AUTH_LOGOUT_SUCCESS,
  MODAL_OPEN,
  MODAL_CLOSE,
} from "constants"

const initialState = {
  open: false,
  screen: null,
  value: null,
  onClose: null,
  onSave: null,
}

export default function modal(state = initialState, action) {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        open: true,
        screen: action.payload.screen,
        value: action.payload.value,
        onClose: action.payload.onClose,
        onSave: action.payload.onSave,
      }

    case MODAL_CLOSE:
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState
      }

    default:
      return state
  }
}
