import "./index.scss"

export class Partner extends React.Component {

  static propTypes = {
    logo: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    render: PropTypes.node,
  }

  static defaultProps = {
    logo: "",
    name: "",
    description: "",
    type: "",
    render: null,
  }

  render () {
    const { name, logo, description, type, render } = this.props

    return (
      <section className={classnames("com-Partner", {
        "is-highlighted": type,
        "is-red": type === "Rising Partner"
      })}>
        {type && <div className="highlighted-partner"></div>}
        {type && <div className="highlighted-partner-text">{type}</div>}
        {logo && <img src={logo} />}
        <h3>{name}</h3>
        {description && <p>{description}</p>}
        {render}
      </section>
    )
  }
}