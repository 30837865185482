import {
  STOCK_RECEIVE_REQUEST,
  STOCK_RECEIVE_SUCCESS,
  STOCK_RECEIVE_FAIL,
  AUTH_LOGOUT_SUCCESS,
} from "constants"

const initialState = {
  loading: false,
  stocks: [],
}

export default function stock(state = initialState, action) {
  switch (action.type) {
    case STOCK_RECEIVE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case STOCK_RECEIVE_SUCCESS:
      return {
        ...state,
        stocks: action.payload.stocks,
        loading: false,
      }

    case STOCK_RECEIVE_FAIL:
      return {
        ...state,
        loading: false,
      }

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
