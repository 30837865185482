import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"

import { ORDER_RECEIVE_REQUEST } from "constants"

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    title={translate("orders")}
    description="Review and manage all the orders"
    columns={[
      { title: "Merchant", attr: "_1" },
      { title: "Customization", attr: "_2" },
      { title: "Create time", attr: "_3" },
      { title: "Modified", attr: "_4" },
      { title: "Order number", attr: "_5" },
      { title: "Status", attr: "_6" },
      { title: "City", attr: "_7" },
      { title: "Country", attr: "_8" },
      { title: "Name", attr: "_9" },
      { title: "Company", attr: "_10" },
      { title: "Difficulty", attr: "_11" },
      { title: "Tags", attr: "_12" },
      { title: "Tracking number", attr: "_13" },
      { title: "Delivery time", attr: "_14" },
      { title: "Collect reference", attr: "_15" },
      { title: "Delivery type", attr: "_16" },
      { title: "Warehouse", attr: "_17" },
    ]}
  />
)

@withRouter
@connect((state) => ({
  order: state.order,
}))
class OrderListing extends React.Component {

  render() {
    const { order: { orders, loading }, dispatch } = this.props

    return (
      <main>
        <Boxlist>
          <Box noPadding>
            <TableRegion
              loading={loading}
              rows={orders}
              onRefresh={qs => {
                dispatch({
                  type: ORDER_RECEIVE_REQUEST,
                  payload: {
                    qs
                  }
                })
              }}
            />
          </Box>
        </Boxlist>
      </main>
    )
  }
}

export { OrderListing }
