import { auth } from "./drivers/auth"
import { merchant } from "./drivers/merchant"
import { partner } from "./drivers/partner"
import { returns } from "./drivers/returns"
import { warehouse } from "./drivers/warehouse"
import { currency } from "./drivers/currency"
import { country } from "./drivers/country"
import { rebuy } from "./drivers/rebuy"
import { settings } from "./drivers/settings"
import { transactionalMessage } from "./drivers/transactionalMessage"
import { file } from "./drivers/file"
import { event } from "./drivers/event"
import { costCategory } from "./drivers/costCategory"

export const Api = {
  auth,
  merchant,
  partner,
  returns,
  warehouse,
  currency,
  country,
  rebuy,
  settings,
  transactionalMessage,
  file,
  event,
  costCategory,
}
