import useFileUpload from "react-use-file-upload"
import { translate } from "helpers/i18n"
import { bytesToKilobytes } from "helpers"
import { Api } from "api"

import { Icons } from "icons"
import { Button, BUTTON_TYPES } from "components/Button"

import "./index.scss"

const propTypes = {
  className: PropTypes.string,
}

const defaultProps = {
  className: "",
}

const Uploader = props => {
  const { className } = props
  const { files, handleDragDropEvent, createFormData, setFiles, clearAllFiles } = useFileUpload()

  const [animation, setAnimation] = React.useState(false)
  const [dragging, setDragging] = React.useState(false)
  const [uploadedFiles, setUploadedFiles] = React.useState([])

  const inputRef = React.useRef()
  const buttonRef = React.useRef()

  const refreshUploadedItems = () => {
    return Api.file.get().then(res => {
      setUploadedFiles(res.data)
    })
  }

  React.useEffect(() => {
    refreshUploadedItems()
  }, [])

  const handleSubmit = () => {
    const formData = createFormData()
    formData.append("file", files[0])
    Api.file.create(formData).then(() => {
      refreshUploadedItems().then(() => {
        clearAllFiles()
      })
    })
  }

  const copy = (url, i) => {
    try {
      navigator.clipboard.writeText(url)

      setAnimation(i)

      // Stop animation
      setTimeout(() => {
        setAnimation(false)
      }, 1000)
    } catch (e) {
      console.warn("Your browser does not support copying text.")
    }
  }

  const renderFileLine = (isUploaded, file, index = null) => (
    <li key={file.uid || file.name}>
      <div className="item-file-format">
        <Icons.DocumentFilled />
      </div>
      <div className={classnames("item-line", {
        "is-uploading": !isUploaded
      })}>
        <p>{file.name}</p>
        <p>
          <span>{file.type || file.contentType || "Unknown"}</span>
          <span>{bytesToKilobytes(file.size, 0)}</span>
          {isUploaded && file.url && (
            <a
              href={file.url}
              target="_blank"
              className="download-button"
            >
              Download
            </a>
          )}
          {isUploaded && file.url && (
            <span className="icon-holder">
              <Icons.Copy onClick={() => copy(file.url, index)} />
              {animation === index && <span className="copy-animation">Copied</span>}
            </span>
          )}
        </p>
      </div>
    </li>
  )

  return (
    <section className={classnames("com-Uploader", className)}>
      <ul className="items-list-area">
        {uploadedFiles.map((file, i) => renderFileLine(true, file, i))}
        {files.map(file => renderFileLine(false, file))}
      </ul>

      <div
        className={classnames("drag-and-drop-area", {
          "is-dragging": dragging,
        })}
        onDragEnter={e => {
          setDragging(true)
          handleDragDropEvent(e)
        }}
        onDragLeave={e => {
          setDragging(false)
          handleDragDropEvent(e)
        }}
        onDragOver={handleDragDropEvent}
        onDrop={(e) => {
          handleDragDropEvent(e)
          setDragging(false)
          setFiles(e, "a")
          setTimeout(() => {
            buttonRef.current.click()
          }, 50)
        }}
      >

        <p>Drop files here or</p>
        <Button
          type={BUTTON_TYPES.LINK}
          title="click to upload"
          onClick={() => inputRef.current.click()}
        />

        <input
          ref={inputRef}
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={e => {
            setFiles(e, "a")
            setTimeout(() => {
              buttonRef.current.click()
            }, 50)
          }}
        />
      </div>
      <button
        className="upload-simulator"
        ref={buttonRef}
        onClick={handleSubmit}
      />
    </section>
  )
}

Uploader.propTypes = propTypes
Uploader.defaultProps = defaultProps

export { Uploader }