import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form } from "components/Form"
import { Tooltip, Selectbox, Checkbox } from "components/Ui"

export const IntegrationsArea = props => {
  return (
    <>
      <Box>
        <h2>Additional integration settings</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              checkbox1: false,
              checkbox2: false,
            }
          }}
          render={[
            {
              name: "checkbox1",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Force all orders through an integration to be shipped without cash-on-delivery, ALWAYS.</p>
                      <p className="caption-s-400 soft">This option will force all incoming orders via the API to remove the cash-on-delivery data.</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            },
            {
              name: "checkbox2",
              node: ({ field, fieldState }) => (
                <Checkbox
                  title={
                    <>
                      <p className="body-m-400">Allow updating orders from API</p>
                      <p className="caption-s-400 soft">Allows updating orders from API after creation. Set this if your integration will not blindly override changes made in myOGO.</p>
                    </>
                  }
                  onClick={() => {
                    field.onChange(!field.value)
                  }}
                  checked={field.value}
                />
              )
            }
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Box>
        <h2>API Information</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              mid: "213c9137-0399-4df0-84c2-eb4b107b99aa",
              token: "9a900140-1828-f7d3-39f9-099169b8d070"
            }
          }}
          render={[
            {
              label: "Merchant ID",
              name: "mid",
              node: ({ field, fieldState }) => (
                <Input
                  value={field.value}
                  button={
                    <Button
                      title="Copy"
                      leftIcon={<Icons.Copy />}
                      type={BUTTON_TYPES.TERTIARY}
                    />
                  }
                  frozen={true}
                />
              )
            },
            {
              label: "Secret token",
              name: "token",
              node: ({ field, fieldState }) => (
                <Input
                  value={field.value}
                  button={
                    <>
                      <Button
                        title="Copy"
                        leftIcon={<Icons.Copy />}
                        type={BUTTON_TYPES.TERTIARY}
                      />
                      <Button
                        title="Regenerate"
                        leftIcon={<Icons.Regenerate />}
                        type={BUTTON_TYPES.TERTIARY}
                      />
                    </>
                  }
                  frozen={true}
                />
              )
            },
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
      <Box>
        <h2>Integrations</h2>
        <hr />
        <p>Integrations</p>
      </Box>
      <Box>
        <h2>Output Webhooks</h2>
        <hr />
        <p>Output Webhooks</p>
      </Box>
      <Tooltip />
    </>
  )
}