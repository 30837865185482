import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { PageHeader } from "components/PageHeader"
import { Button, BUTTON_TYPES } from "components/Button"
import { Breadcrumbs, Tag, TAG_TYPES } from "components/Ui"

import {
  MODAL_OPEN,
  MODAL_SCREEN_REMOVE_TM,
  TM_RECEIVE_REQUEST,
  TM_RECIPIENT_TYPES,
  TM_APPROVAL_TYPES,
  TM_RECIPIENT_STATUSES,
} from "constants"

import "./index.scss"

const dateDiffInDays = (a, b) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
  return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24))
}

const TableRegion = props => (
  <Table
    {...useTableHook({ ...props })}
    columns={[
      { title: "Recipient Type", attr: "recipientType" },
      { title: "Approval Type", attr: "approvalType" },
      { title: "Recipients", attr: "recipientsCount" },
      { title: "Visible From", attr: "visibleFrom" },
      { title: "Visible To", attr: "visibleTo" },
      { title: "Duration", attr: "_duration" },
      { title: "Deadline", attr: "approvalDeadline" },
      { title: "Remaining", attr: "_remaining" },
    ]}
    actions={[
      {
        title: translate("edit"),
        icon: <Icons.Edit />,
        massActionDisabled: true,
        action: props.onEdit,
      },
      {
        title: translate("delete"),
        isRed: true,
        massActionDisabled: true,
        icon: <Icons.Delete />,
        action: props.onDelete,
      }
    ]}
    columnRender={{
      recipientType: row => TM_RECIPIENT_TYPES[row.recipientType],
      approvalType: row => TM_APPROVAL_TYPES[row.approvalType],
      recipientsCount: row => row.recipientsCount || 0,
      visibleTo: row => (new Date(row.visibleTo).toLocaleDateString()),
      visibleFrom: row => (new Date(row.visibleFrom).toLocaleDateString()),
      approvalDeadline: row => (new Date(row.approvalDeadline).toLocaleDateString()),
      _duration: row => {
        const days = dateDiffInDays(new Date(row.visibleFrom), new Date(row.visibleTo))
        return <Tag type={TAG_TYPES.PRIMARY} title={`${days} days`} compact />
      },
      _remaining: row => {
        const days = dateDiffInDays(new Date(), new Date(row.approvalDeadline))

        if (days < 0) {
          return <Tag type={TAG_TYPES.RED} title={`Passed`} compact />
        }

        if (days === 0) {
          return <Tag type={TAG_TYPES.TEAL} title={`Today`} compact />
        }

        return <Tag type={TAG_TYPES.TEAL} title={`${days} days`} compact />
      },
    }}
    buttons={
      <>
        <Button
          type={BUTTON_TYPES.GRAY}
          icon={<Icons.Plus />}
          onClick={props.onAdd}
          hasSmallRadius
        />
      </>
    }
    noCheckbox
    noFilters
    noFoot
    noGlobalSearch
  />
)

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  admin: state.admin,
  transactionalMessage: state.transactionalMessage,
}))
class TransactionalMessagesListing extends React.Component {

  render() {
    const { transactionalMessage: { messages, loading }, dispatch, history } = this.props

    return (
      <>
        <PageHeader
          prefix={"Review and manage all the transactional messages"}
          title={"Transactional Messages"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "admin",
                  onClick: () => {
                    history.push("/admin")
                  }
                },
                {
                  title: "transactional-messages",
                },
              ]}
              activeIndex={2}
            />
          }
        />
        <main className="admin-transactional-messages-screen">
          <Boxlist>
            <Box noPadding>
              <TableRegion
                loading={loading}
                rows={messages}
                onRefresh={qs => {
                  dispatch({
                    type: TM_RECEIVE_REQUEST,
                    payload: {
                      qs
                    }
                  })
                }}
                onAdd={() => {
                  history.push("/admin/transactional-messages/create")
                }}
                onEdit={rows => {
                  history.push(`/admin/transactional-messages/${rows[0].uid}/update`)
                }}
                onDelete={rows => {
                  dispatch({
                    type: MODAL_OPEN,
                    payload: {
                      screen: MODAL_SCREEN_REMOVE_TM,
                      value: rows[0],
                    }
                  })
                }}
              />
            </Box>
          </Boxlist>
        </main>
      </>
    )
  }
}

export { TransactionalMessagesListing }
