import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box, useToggleHook } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { Modal } from "components/Layout/Modal"

export const CategoriesTableArea = props => {
  const { partners, onEdit } = props
  const [modal, setModal] = React.useState(null)

  const getPartnersInCategory = category => partners.filter(p => p.categories.includes(category))

  const hook = {...useTableHook({
    rows: getPartnersInCategory(modal?.name)
  })}

  return (
    <>
      <Boxlist>
        <Box noPadding {...useToggleHook({ toggled: true} )}>
          <h2>Categories</h2>
          <Table
            {...useTableHook({ ...props })}
            columns={[
              { title: "Name", attr: "name" },
              { title: "Used By", attr: "dynamic" },
            ]}
            actions={[
              {
                title: "View Partners",
                icon: <Icons.Eye />,
                massActionDisabled: true,
                action: rows => {
                  setModal(rows[0])
                },
              },
            ]}
            columnRender={{
              dynamic: row => (
                <>{getPartnersInCategory(row.name).length} partners</>
              ),
            }}
            noCheckbox
            noHead
            noFoot
          />
        </Box>
      </Boxlist>
      {modal && (
        <Modal
          isSidebar={true}
          className="partners-in-category-modal"
          title={`Partners in ${modal.name}`}
          onClose={() => setModal(null)}
        >
          <Table
            {...hook}
            columns={[
              { title: "Name", attr: "name" },
            ]}
            actions={[
              {
                title: translate("edit"),
                icon: <Icons.Edit />,
                massActionDisabled: true,
                action: onEdit,
              },
            ]}
            compact
            noCheckbox
            noHead
            noFoot
          />
        </Modal>
      )}
    </>
  )
}
