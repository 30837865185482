import { axios } from "../axios"
import { to } from "helpers/api"
import { store } from "index"

export const costCategory = {
  get: () => {
    return axios.get(to(`api/config/categories`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  create: data => {
    return axios.post(to(`api/config/categories`), data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  put: (id, data) => {
    return axios.put(to(`api/config/categories/${id}`), data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  remove: id => {
    return axios.delete(to(`api/config/categories/${id}`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
}
