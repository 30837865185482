import "./index.scss"

export class Boxlist extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    horizontal: PropTypes.bool,
  }

  static defaultProps = {
    className: "",
    horizontal: false,
  }

  render () {
    const { children, className, horizontal } = this.props

    return (
      <section className={classnames("com-Boxlist", className, { horizontal })}>
        {children}
      </section>
    )
  }
}