import { axios } from "../axios"
import { to } from "helpers/api"
import { store } from "index"

export const merchant = {
  getAllMerchants: () => {
    return axios.get(to(`api/ms/`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  getMerchant: (id) => {
    return axios.get(to(`api/ms/get/merchants/${id}`), {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
  search: qs => {
    return axios.post(to(`api/ms/ui/search`), qs, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    })
  },
}
