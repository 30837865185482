import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"
import { Table, useTableHook } from "components/Table"
import { PageHeader } from "components/PageHeader"
import { Button, BUTTON_TYPES } from "components/Button"
import { Breadcrumbs, Tag, TAG_TYPES, Selectbox} from "components/Ui"

import {
  MERCHANT_RECEIVE_REQUEST,
  MERCHANT_STATUSES,
  PPS_LEVELS,
} from "constants"

import "./index.scss"

const getTag = (isGreen, title) => (
  <Tag
    type={isGreen ? TAG_TYPES.TEAL : TAG_TYPES.RED}
    title={isGreen ? (title || "Enabled") : "---"}
    compact
  />
)

const TableRegion = props => {
  const { countries, warehouses, onView } = props

  const [eye, setEye] = React.useState(false)

  const columns = [
    { title: "Client Number", attr: "id", searchable: true, },
    { title: "Status", attr: "status" },
    { title: "Name", attr: "name", searchable: true, },
    { title: "Brand", attr: "merchantBrand", searchable: true, },
    { title: "Company", attr: "merchantCompany", searchable: true, },
    {
      title: "Warehouses",
      attr: "warehouses",
      searchable: true,
      interceptor: entry => {
        return entry.value
      },
      tagTransform: entry => {
        return entry.label
      },
      searchNode: (setValue, value) => {
        return (
          <Selectbox
            options={warehouses.map(w => ({ label: w.code, value: w.id }))}
            value={value}
            onChange={v => setValue(v, "warehouses")}
          />
        )
      }
    },
    {
      title: "Country",
      attr: "country",
      searchable: true,
      interceptor: entry => {
        return entry.value
      },
      tagTransform: entry => {
        return entry.label
      },
      searchNode: (setValue, value) => {
        return (
          <Selectbox
            options={countries}
            value={value}
            onChange={v => setValue(v, "country")}
          />
        )
      }
    },
    {
      title: "PPS Package",
      attr: "rebuySettings",
      searchable: true,
      interceptor: entry => {
        return Number(entry.value)
      },
      tagTransform: entry => {
        return entry.label
      },
      searchNode: (setValue, value) => {
        return (
          <Selectbox
            options={Object.keys(PPS_LEVELS).map(key => ({
              value: key,
              label: PPS_LEVELS[key]
            }))}
            value={value}
            onChange={v => setValue(v, "rebuySettings")}
          />
        )
      }
    },
    {
      title: "OGO Returns",
      attr: "returnSettings",
      searchable: true,
      interceptor: entry => {
        return Boolean(entry.value)
      },
      tagTransform: entry => {
        return entry.label
      },
      searchNode: (setValue, value) => {
        return (
          <Selectbox
            options={[
              { label: "Enabled", value: true },
              { label: "Disabled", value: false },
            ]}
            value={value}
            onChange={v => setValue(v, "returnSettings")}
          />
        )
      }
    },
    {
      title: "CO2 Service",
      attr: "cO2Service",
      searchable: true,
      interceptor: entry => {
        return Boolean(entry.value)
      },
      tagTransform: entry => {
        return entry.label
      },
      searchNode: (setValue, value) => {
        return (
          <Selectbox
            options={[
              { label: "Enabled", value: true },
              { label: "Disabled", value: false },
            ]}
            value={value}
            onChange={v => setValue(v, "cO2Service")}
          />
        )
      }
    },
  ]

  if (eye) {
    columns.push(
      { title: "Invoicing ID", attr: "invoicingId" },
    )
  }

  return (
    <Table
      {...useTableHook({
        ...props,
        columns,
      })}
      actions={[
        {
          title: "View",
          icon: <Icons.Edit />,
          massActionDisabled: true,
          action: onView
        },
      ]}
      columnRender={{
        status: row => {
          const status = MERCHANT_STATUSES[row.status] || "Unknown"

          const type = () => {
            switch (row.status) {
              case 0:
                return TAG_TYPES.YELLOW

              case 1:
                return TAG_TYPES.TEAL

              case 3:
                return TAG_TYPES.BLUE

              case 4:
                return TAG_TYPES.RED

              default:
                return TAG_TYPES.SECONDARY
            }
          }

          return (
            <Tag
              type={type()}
              title={status}
              compact
            />
          )
        },
        rebuySettings: row => {
          const level = PPS_LEVELS[row.rebuySettings?.level]
          return getTag(row.rebuySettings?.enabled, level)
        },
        warehouses: row => {
          return (row.warehouses || []).join(", ")
        },
        returnSettings: row => getTag(row.returnSettings?.enabled),
        cO2Service: row => getTag(row.cO2Service?.enabled),
      }}
      buttons={
        <>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title="Show Closed Merchants"
            hasSmallRadius
            isCompact
          />
          <Button
            type={BUTTON_TYPES.TERTIARY}
            title="Show Demo Merchants"
            hasSmallRadius
            isCompact
          />
          <Button
            type={BUTTON_TYPES.TERTIARY}
            icon={eye ? <Icons.Eye /> : <Icons.EyeHidden />}
            onClick={() => setEye(prev => !prev)}
            hasSmallRadius
          />
        </>
      }
      noCheckbox
      semiCompact
    />
  )
}

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  merchant: state.merchant,
  country: state.country,
  warehouse: state.warehouse,
}))
class MerchantListing extends React.Component {

  render() {
    const {
      merchant: { merchants, loading },
      country: { countries },
      warehouse: { warehouses },
      dispatch,
      history,
    } = this.props

    return (
      <>
        <PageHeader
          prefix={"Review and manage all the merchants"}
          title={"Merchants"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "merchants",
                },
              ]}
              activeIndex={1}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="merchant-listing-screen">
          <Boxlist>
            <Box noPadding>
              <TableRegion
                loading={loading}
                rows={merchants}
                countries={countries}
                warehouses={warehouses}
                onRefresh={qs => {
                  dispatch({
                    type: MERCHANT_RECEIVE_REQUEST,
                    payload: {
                      qs
                    }
                  })
                }}
                onView={rows => {
                  history.push(`/merchants/${rows[0].merchantId}/edit`)
                }}
              />
            </Box>
          </Boxlist>
        </main>
      </>
    )
  }
}

export { MerchantListing }
