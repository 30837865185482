import { call, put, all, takeLatest, delay } from "redux-saga/effects"
import { errorHandler } from "helpers/saga"

import {
  ADMIN_RECEIVE_REQUEST,
  ADMIN_RECEIVE_SUCCESS,
  ADMIN_RECEIVE_FAIL,
} from "constants"

import { fakeAdminsBackend } from "mock"

function* receive(action) {
  try {
    const data = fakeAdminsBackend.update(action.payload?.qs).getJson()

    yield delay(1500)

    yield put({
      type: ADMIN_RECEIVE_SUCCESS,
      payload: {
        admins: data
      }
    })
  } catch (err) {
    yield call(errorHandler, err)
    yield put({ type: ADMIN_RECEIVE_FAIL })
  }
}

function* adminReceiveSaga() {
  yield takeLatest(ADMIN_RECEIVE_REQUEST, receive)
}

export function* admin() {
  yield all([
    call(adminReceiveSaga),
  ])
}
