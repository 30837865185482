import ReactCodeInput from "react-code-input"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import "./index.scss"

export class TwoFactor extends React.Component {

  static propTypes = {
    value: PropTypes.string,
    maxLength: PropTypes.number,
    isInvalid: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    value: "",
    maxLength: 6,
    isInvalid: false,
    error: "",
    onChange: null,
  }

  render () {
    const { value, maxLength, isInvalid, error, onChange } = this.props

    return (
      <section className={classnames("com-TwoFactor", {
        "is-invalid": isInvalid
      })}>
        <ReactCodeInput
          value={value}
          fields={maxLength}
          onChange={onChange}
        />
        {error && (
          <div className="error-message-container">
            <Icons.Warning16 />
            <p>{error}</p>
          </div>
        )}
      </section>
    )
  }
}