import { translate } from "helpers/i18n"

import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { Selectbox } from "components/Ui"

export const BasicInfoArea = props => {
  return (
    <>
      <Box>
        <h2>Basic Info</h2>
        <hr />
        <Form
          data={{
            defaultValues: {
              product_name: "",
              product_code: "",
              ean_code: "",
              harmonization_code: "",
              description: "",
              instructions: "",
              product_picture_url: "",
              product_info_url: "",
              notes: "",
              product_group: { value: "group1", label: "Group 1" },
              processing_fee: { value: "fee1", label: "Lähetyskoko 1" },
            }
          }}
          rules={{
            product_name: Rules.string().required(),
            product_code: Rules.string().required(),
            ean_code: Rules.string().required(),
            harmonization_code: Rules.string().required(),
            description: Rules.string().required(),
            instructions: Rules.string().required(),
            product_picture_url: Rules.string().required(),
            product_info_url: Rules.string().required(),
            notes: Rules.string().required(),
            product_group: Rules.string().required(),
            processing_fee: Rules.string().required(),
          }}
          render={[
            {
              label: "***Product name***",
              name: "product_name",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "***Product code***",
              name: "product_code",
              tip: "Unique code for product",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "EAN code",
              name: "ean",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Harmonization code",
              name: "harmonization_code",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Description",
              name: "description",
              node: ({ field, fieldState }) => (
                <Input
                  textarea
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Collecting instructions",
              name: "instructions",
              tip: "Leave collecting instructions to the warehouse",
              node: ({ field, fieldState }) => (
                <Input
                  textarea
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Link to product info",
              name: "product_info_url",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Link to product picture",
              name: "product_picture_url",
              node: ({ field, fieldState }) => (
                <Input
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Product group",
              name: "product_group",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "group1", label: "Group 1" },
                    { value: "group2", label: "Group 2" },
                    { value: "group3", label: "Group 3" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Processing fee",
              name: "processing_fee",
              node: ({ field, fieldState }) => (
                <Selectbox
                  options={[
                    { value: "fee1", label: "Lähetyskoko 1" },
                    { value: "fee2", label: "Lähetyskoko 2" },
                    { value: "fee3", label: "Lähetyskoko 3" },
                    { value: "fee4", label: "Lähetyskoko 4" },
                    { value: "fee5", label: "Lähetyskoko 5 (lava)" },
                  ]}
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            },
            {
              label: "Notes of product",
              name: "notes",
              node: ({ field, fieldState }) => (
                <Input
                  textarea
                  onChange={field.onChange}
                  value={field.value}
                  error={fieldState.error?.message}
                />
              )
            }
          ]}
          button={
            <Button
              title={translate("save")}
              type={BUTTON_TYPES.GRAY}
            />
          }
          onSubmit={data => {
            console.log(data)
          }}
        />
      </Box>
    </>
  )
}