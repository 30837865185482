import { withRouter } from "react-router-dom"
import qs from "qs"
import jwt_decode from "jwt-decode"
import store from "store2"

import { AUTH_LOGIN_VIA_API_REQUEST } from "constants"

@withRouter
@connect((state) => ({
  app: state.app
}))
class Callback extends React.PureComponent {

  componentDidMount() {
    const { dispatch, history, app } = this.props

    const hash = window.location.hash.substring(1)

    const parsed = qs.parse(hash)
    const token = parsed.access_token
    const decoded = jwt_decode(token)
    const redir = parsed.redir || "/"

    store.set("myogo-jwt", token)

    dispatch({
      type: AUTH_LOGIN_VIA_API_REQUEST,
      onSuccess: () => {
        history.push(redir)
      }
    })
  }

  render() {
    return null
  }
}

export { Callback }