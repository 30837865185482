import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"

import { Boxlist } from "components/Boxlist"
import { HeaderTab } from "components/Ui"

import { ProductsToBeReceivedArea } from "./ProductsToBeReceivedArea"
import { AdditionalInformationArea } from "./AdditionalInformationArea"
import { ShipmentArea } from "./ShipmentArea"
import { SummaryArea } from "./SummaryArea"

import "./index.scss"

@withRouter
@connect((state) => ({
  stockUpdate: state.stockUpdate,
}))
class StockUpdateCreate extends React.Component {

  state = {
    activeTab: 0
  }

  render() {
    const { stockUpdate: { products, loading }, dispatch } = this.props
    const { activeTab } = this.state

    return (
      <>
        <HeaderTab
          tabs={[
            { name: "Products to be received", frozen: true },
            { name: "Additional information", frozen: true },
            { name: "Shipment", frozen: true },
            { name: "Summary", frozen: true },
          ]}
          activeTab={activeTab}
          onClick={activeTab => {
            this.setState({ activeTab })
          }}
        />
        <main className="stock-update-create-screen">
          <Boxlist>
            {activeTab === 0 && <ProductsToBeReceivedArea />}
            {activeTab === 1 && <AdditionalInformationArea />}
            {activeTab === 2 && <ShipmentArea />}
            {activeTab === 3 && <SummaryArea />}
          </Boxlist>
        </main>
      </>
    )
  }
}

export { StockUpdateCreate }
