import { combineReducers } from "redux"

import app from "./app"
import auth from "./auth"
import modal from "./modal"
import tab from "./tab"
import country from "./country"
import currency from "./currency"
import notification from "./notification"
import message from "./message"
import merchant from "./merchant"
import order from "./order"
import product from "./product"
import stockUpdate from "./stock-update"
import supplier from "./supplier"
import stock from "./stock"
import admin from "./admin"
import customer from "./customer"
import feedback from "./feedback"
import partner from "./partner"
import returnReason from "./returnReason"
import returnDeliveryType from "./returnDeliveryType"
import warehouse from "./warehouse"
import rebuy from "./rebuy"
import settings from "./settings"
import transactionalMessage from "./transactionalMessage"
import event from "./event"
import costCategory from "./costCategory"

const reducers = combineReducers({
  app,
  auth,
  modal,
  tab,
  country,
  currency,
  notification,
  message,
  merchant,
  order,
  product,
  stockUpdate,
  supplier,
  stock,
  admin,
  customer,
  feedback,
  partner,
  returnReason,
  returnDeliveryType,
  warehouse,
  rebuy,
  settings,
  transactionalMessage,
  event,
  costCategory,
})

export { reducers }
