import { withRouter } from "react-router-dom"
import { translate } from "helpers/i18n"
import { Icons } from "icons"

import { Boxlist } from "components/Boxlist"
import { Box } from "components/Box"
import { Button, BUTTON_TYPES } from "components/Button"
import { Input } from "components/Input"
import { Form, Rules } from "components/Form"
import { PageHeader } from "components/PageHeader"
import { Breadcrumbs } from "components/Ui"
import { ScaleLoader } from "components/Loader/ScaleLoader"

import {
  MODAL_OPEN,
  MODAL_SCREEN_SELECT_CHANNEL,
  TAB_INDEX_UPDATE,
  RETURN_REASON_CREATE_REQUEST,
  RETURN_REASON_UPDATE_REQUEST,
  RETURN_REASON_RECEIVE_SINGLE_REQUEST,
  MERCHANT_RECEIVE_REQUEST,
  LANGUAGE_LIST,
} from "constants"

import "./index.scss"

@withRouter
@connect((state) => ({
  app: state.app,
  auth: state.auth,
  returnReason: state.returnReason,
}))
class ReturnReasonUpsert extends React.Component {

  state = {
    loaded: false,
    err: "",
    data: {},
    channels: [],
  }

  componentDidMount() {
    const { updateMode, dispatch, match: { params: { reasonId } } } = this.props

    dispatch({
      type: TAB_INDEX_UPDATE,
      payload: {
        attr: "returnsDashboardTabIndex",
        value: 0
      }
    })

    if (updateMode) {
      dispatch({
        type: RETURN_REASON_RECEIVE_SINGLE_REQUEST,
        payload: {
          id: reasonId
        },
        success: ({ returnReason, channels }) => {
          this.setState({
            data: returnReason,
            loaded: true,
            channels,
          })
        },
        fail: err => {
          this.setState({ err })
        }
      })
      return
    }

    dispatch({
      type: MERCHANT_RECEIVE_REQUEST,
      success: channels => {
        this.setState({
          loaded: true,
          channels,
        })
      },
    })
  }

  get pagedata() {
    const { updateMode } = this.props

    const res = {
      action: updateMode ? RETURN_REASON_UPDATE_REQUEST : RETURN_REASON_CREATE_REQUEST,
      prefix: updateMode ? `Update the Return Reason` : "Create a Return Reason for your customers.",
      title: updateMode ? "update" : "create",
      defaultValues: this.getDefaultValues(),
    }

    return res
  }

  getDefaultValues() {
    const { data } = this.state
    const res = { ...data }

    // ChannelReferences
    res.channelReferences = (data.channelReferences || []).map(c => c.channelId).join(",") || ""

    if (Object.keys(data).length === 0) {
      return res
    }

    // Languages
    this.getLanguagesArray().forEach(lang => {
      const localization = data.localizations.find(entry => entry.lang === lang)

      if (localization) {
        res[lang] = localization.context
      }
    })

    return res
  }

  getLanguagesArray() {
    return LANGUAGE_LIST
  }

  onSubmit = data => {
    const { updateMode, dispatch, history, match: { params: { reasonId } } } = this.props

    const channelIds = data.channelReferences.split(",").filter(Boolean)

    const payload = {
      data: {
        ...data,
        localizations: this.getLanguagesArray().map(language => (
          {
            lang: language,
            context: data[language]
          }
        )),
        channelReferences: channelIds.map(channelId => ({
          channelId
        }))
      }
    }

    if (updateMode) {
      payload.id = reasonId
    }

    dispatch({
      type: this.pagedata.action,
      payload,
      success: () => {
        history.push("/returns")
      }
    })
  }

  getChannels(value) {
    if (typeof value === "undefined") {
      return []
    }

    return value.replace(/\s+/g, "").split(",").filter(Boolean)
  }

  renderChannels(selectedChannels, setValue = null) {
    const { channels } = this.state

    const remove = id => {
      setValue("channelReferences", selectedChannels.filter(channelId => channelId !== id).join(","))
    }

    return selectedChannels.map(channelId => {
      const channel = channels.find(c => c.id.toString() === channelId.toString())

      if (!channel) {
        return (
          <div
            key={channelId}
            className="channel-info-box is-red"
          >
            <p>Channel: <span>Unknown! ({channelId})</span></p>
            <p>Company: ---</p>
            <p>Brand: ---</p>
            <Icons.Delete onClick={() => remove(channelId)} />
          </div>
        )
      }

      return (
        <div
          key={channelId}
          className="channel-info-box"
        >
          <p>Channel: <span>{channel.id}</span></p>
          <p>Company: {channel.merchantCompany}</p>
          <p>Brand: {channel.name}</p>
          <Icons.Delete onClick={() => remove(channelId)} />
        </div>
      )
    })
  }

  render() {
    const {
      returnReason: { reasons, loading },
      history,
      dispatch,
      updateMode,
    } = this.props
    const { loaded, data, err } = this.state

    const languageInputs = this.getLanguagesArray().map(lang => (
      {
        label: `Title: ${translate(`language.${lang}`)}`,
        name: lang,
        node: ({ field, fieldState }) => (
          <Input
            onChange={field.onChange}
            value={field.value}
            error={fieldState.error?.message}
          />
        )
      }
    ))

    const rules = {
      code: Rules.string().required(),
      warehouseReasonCode: Rules.string().required(),
      channelReferences: Rules.string(),
    }

    this.getLanguagesArray().forEach(lang => (
      rules[lang] = Rules.string().required(`${translate(`language.${lang}`)} is a required field`)
    ))

    return (
      <>
        <PageHeader
          prefix={this.pagedata.prefix}
          title={data.code || "Create Reason"}
          renderLeft={
            <Breadcrumbs
              links={[
                {
                  title: "home",
                  onClick: () => {
                    history.push("/")
                  }
                },
                {
                  title: "returns",
                  onClick: () => {
                    history.push("/returns")
                  }
                },
                {
                  title: this.pagedata.title,
                },
              ]}
              activeIndex={2}
            />
          }
          renderRight={
            <Button
              title="Have issues?"
              type={BUTTON_TYPES.TERTIARY}
              icon={<Icons.ArrowDown />}
              onClick={() => {
                alert("Have issues?")
              }}
            />
          }
        />
        <main className="ReturnReasonUpsert-screen">
          {err && (
            <Boxlist>
              <Box>
                <p>Error: {err.message}</p>
              </Box>
            </Boxlist>
          )}
          {!err && !loaded && (
            <ScaleLoader />
          )}

          {!err && loaded && (
            <Boxlist>
              <Box>
                <h2>Return Reason</h2>
                <hr />
                <Form
                  id="return-reason-create-form"
                  data={{
                    defaultValues: this.pagedata.defaultValues
                  }}
                  rules={rules}
                  render={[
                    {
                      label: "Code",
                      name: "code",
                      node: ({ field, fieldState }) => (
                        <Input
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Warehouse Reason Code",
                      name: "warehouseReasonCode",
                      node: ({ field, fieldState }) => (
                        <Input
                          onChange={field.onChange}
                          value={field.value}
                          error={fieldState.error?.message}
                        />
                      )
                    },
                    {
                      label: "Channels",
                      name: "channelReferences",
                      node: ({ field, fieldState, setValue }) => {
                        const channels = this.getChannels(field.value)
                        return (
                          <>
                            <div className="channel-info-container">
                              {channels.length === 0 && (
                                <div
                                  className="channel-info-box is-green"
                                >
                                  <p>Channel: <span>All Channels</span></p>
                                  <p>Company: <span>All Companies</span></p>
                                  <p>Brand: <span>All Brands</span></p>
                                </div>
                              )}
                              {channels.length > 0 && this.renderChannels(channels, setValue)}
                            </div>
                            <Button
                              type={BUTTON_TYPES.TERTIARY}
                              title="Add Channels"
                              onClick={() => {
                                const existingChannels = field.value.split(",")

                                dispatch({
                                  type: MODAL_OPEN,
                                  payload: {
                                    screen: MODAL_SCREEN_SELECT_CHANNEL,
                                    value: {
                                      channels: this.state.channels,
                                      existingChannels,
                                    },
                                    onSave: options => {
                                      setValue("channelReferences", [
                                        ...existingChannels,
                                        ...options.map(o => o.value)
                                      ].join(","))
                                    }
                                  },
                                })
                              }}
                            />
                          </>
                        )
                      }
                    },
                    ...languageInputs
                  ]}
                  onSubmit={this.onSubmit}
                />
              </Box>
              <Box ghost>
                <Button
                  form="return-reason-create-form"
                  title={translate("save")}
                  type={BUTTON_TYPES.GRAY}
                />
              </Box>
            </Boxlist>
          )}
        </main>
      </>
    )
  }
}

export { ReturnReasonUpsert }
