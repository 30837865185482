import { Box } from "components/Box"
import { Boxlist } from "components/Boxlist"

import { Form, Rules } from "components/Form"
import { Input } from "components/Input"
import { Datepicker, Selectbox, Checkbox } from "components/Ui"
import { Button, BUTTON_TYPES } from "components/Button"

export const FormsArea = props => (
  <Boxlist>
    <Box>
      <h2>Form</h2>
      <Form
        data={{
          defaultValues: {
            firstName: props.name || "",
            select: {},
            datepicker: new Date(),
            checkbox: true,
          }
        }}
        rules={{
          firstName: Rules.string().max(20).required(),
        }}
        render={[
          {
            name: "firstName",
            label: "First Name",
            node: ({ field, fieldState }) => (
              <Input
                onChange={field.onChange}
                value={field.value}
                error={fieldState.error?.message}
              />
            )
          },
          {
            name: "select",
            label: "Food",
            node: ({ field }) => (
              <Selectbox
                options={[
                  { value: "chocolate", label: "Chocolate" },
                  { value: "strawberry", label: "Strawberry" },
                  { value: "vanilla", label: "Vanilla" }
                ]}
                onChange={field.onChange}
                value={field.value}
              />
            )
          },
          {
            name: "datepicker",
            label: "Pick Date",
            node: ({ field }) => (
              <Datepicker
                showTime
                onChange={date => {
                  console.log(date)
                  field.onChange(date)}
                }
                selected={field.value}
              />
            )
          },
          {
            name: "checkbox",
            label: "Check something",
            node: ({ field }) => (
              <Checkbox
                title={
                  <>
                    <p className="body-m-400">Force all orders?</p>
                  </>
                }
                onClick={() => {
                  field.onChange(!field.value)
                }}
                checked={field.value}
              />
            )
          },
        ]}
        button={<Button type={BUTTON_TYPES.TERTIARY} title="Save" />}
        onSubmit={data => {
          console.log("submit data")
          console.log(data)
        }}
      />
    </Box>
    <Box>
      <p>Important: If you need to trigger onSubmit from another location (e.g a button outside the form) then you have to add the following:</p>
      <pre>
        {'<button form="test-form" />\r\n'}
        {'<Form id="test-form" />'}
      </pre>
    </Box>
  </Boxlist>
)
